import axios from "axios";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import get_current_user from "../../../aws_cognito/get_user";
import Loading from "../components/Loading/Loading";
import FirstLeaderCard from "./FirstLeaderCard";

import styles from "./LeaderBoard.module.css";
import OtherLeaderCard from "./OtherLeaderCard";
import avatarImg from "../dummyData/images/placeholder-avatar.jpg";

const LeaderBoard = () => {
  const [setPageName] = useOutletContext();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [leaderVolunteers, setLeaderVolunteers] = useState([]);
  const [leaderOrganizations, setLeaderOrganizations] = useState([]);
  const [leaderCorporates, setLeaderCorporates] = useState([]);
  const [leaders, setLeaders] = useState([]);

  const activeTabClasses = "font-bold border-b-[0.35rem] border-b-[#22A5FF]";

  useEffect(() => {
    const fetchAndSetLeaders = async () => {
      const user = await get_current_user("admin");
      const token = user.data.idToken;
      const {
        data: { data: resVolunteers },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/volunteer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      const {
        data: { data: resOrganizations },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/impact-org`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      const {
        data: { data: resCorporates },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/corporate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      setLeaderVolunteers(resVolunteers);
      setLeaderOrganizations(resOrganizations);
      setLeaderCorporates(resCorporates);
      setLoading(false);
    };
    fetchAndSetLeaders();
  }, []);

  useEffect(() => {
    setLeaders(
      activeTab === 1
        ? leaderVolunteers
        : activeTab === 2
        ? leaderCorporates
        : leaderOrganizations
    );
  }, [activeTab, leaderVolunteers, leaderCorporates, leaderOrganizations]);

  useEffect(() => {
    setPageName("Leaderboard");
  }, [setPageName]);

  if (loading)
    return (
      <div className="grid w-full h-screen place-items-center">
        <Loading />
      </div>
    );

  return (
    <div className="px-8 py-12">
      {/* Tabs */}
      <div className="flex items-center justify-between border-b-2 lg:border-b-transparent border-b-[#CFEBFF] md:justify-start md:gap-24">
        <button
          className={`pb-2 ${activeTab === 1 && activeTabClasses}`}
          onClick={() => setActiveTab(1)}
        >
          Volunteer
        </button>
        <button
          className={`pb-2 ${activeTab === 2 && activeTabClasses}`}
          onClick={() => setActiveTab(2)}
        >
          Corporate
        </button>
        <button
          className={`pb-2 ${activeTab === 3 && activeTabClasses}`}
          onClick={() => setActiveTab(3)}
        >
          Impact Organization
        </button>
      </div>

      {/* Title */}
      <div className="mt-8 mb-6 font-medium">
        <h4 className="text-2xl font-bold">
          Most Credits {activeTab === 3 ? "Granted" : "Received"}
        </h4>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg lg:flex-row">
        {/* Spotlight */}
        <div className={` px-4 py-8 lg:w-[35rem] ${styles.gradient}`}>
          <h2 className="mt-2 mb-6 text-2xl font-bold text-center text-white">
            Impact Maker Spotlight
          </h2>
          <div className="flex items-end justify-between w-full gap-2 md:gap-4 lg:gap-5">
            <div className="flex-grow-[6]">
              <FirstLeaderCard
                name={leaders[1]?.name}
                image={leaders[1]?.profilePic || avatarImg}
                rankUserType={activeTab == 1 ? "volunteer" : (activeTab == 2 ? "corporate" : "organization")}
                typeUser={"admin"}
                userId={leaders[1]?._id}
                second
                classes={styles.second}
                credits={
                  activeTab === 1
                    ? leaders[1]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[1]?.employeeCreditsRecieved
                    : leaders[1]?.creditsGranted
                }
              />
            </div>
            <div className="flex-grow-[7]">
              <FirstLeaderCard
                first
                typeUser={"admin"}
                rankUserType={activeTab == 1 ? "volunteer" : (activeTab == 2 ? "corporate" : "organization")}
                userId={leaders[0]?._id}
                name={leaders[0]?.name}
                image={leaders[0]?.profilePic || avatarImg}
                credits={
                  activeTab === 1
                    ? leaders[0]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[0]?.employeeCreditsRecieved
                    : leaders[0]?.creditsGranted
                }
              />
            </div>
            <div className="flex-grow-[5]">
              <FirstLeaderCard
                third
                typeUser={"admin"}
                rankUserType={activeTab == 1 ? "volunteer" : (activeTab == 2 ? "corporate" : "organization")}
                userId={leaders[2]?._id}
                classes={styles.third}
                name={leaders[2]?.name}
                image={leaders[2]?.profilePic || avatarImg}
                credits={
                  activeTab === 1
                    ? leaders[2]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[2]?.employeeCreditsRecieved
                    : leaders[2]?.creditsGranted
                }
              />
            </div>
          </div>
        </div>

        {/* Rank List */}
        <div className="px-6 py-8 font-semibold bg-white lg:w-[35rem] shadow-sm">
          <div className="flex flex-col gap-4  overflow-scroll max-h-[25rem]">
            {leaders.map((leader, idx) => {
              if (leader.rank <= 3) return null;
              return (
                <React.Fragment key={leader._id}>
                  <OtherLeaderCard
                    leader={leader}
                    rankUserType={activeTab == 1 ? "volunteer" : (activeTab == 2 ? "corporate" : "organization")}
                    rank={leader.rank}
                    typeUser={"admin"}
                    credits={
                      activeTab === 1
                        ? leader.creditsRecieved
                        : activeTab === 2
                        ? leader.employeeCreditsRecieved
                        : leader.creditsGranted
                    }
                  />
                  {idx !== leaders.length - 1 && <hr />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
