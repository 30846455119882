import React from "react";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../images/default.jpg";

export default function SearchList({ data, type }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col md:mt-5 bg-white shadow-md p-4 rounded-lg w-full">
      <div className="flex gap-2 items-center md:gap-3 px-3 md:px-5">
        <img
          src={data?.profilePic !== "" ? data?.profilePic : defaultAvatar}
          alt=""
          className="w-14 h-14 md:w-24 md:h-24 rounded-full"
        />
        <div className="flex justify-between grow">
          <div className="flex-col p-1 md:p-2 space-y-5">
            <div className="md:space-y-1">
              <div className="md:flex md:flex-row flex-wrap flex-col mb-2 text-xs md:gap-3 md:text-sm space-y-1">
                <h1 className="text-sm md:text-base font-bold my-auto">
                  {data?.name}
                </h1>
                {data?.userType === "organization" &&
                data?.thematicAreas.length !== 0 ? (
                  data?.thematicAreas.map((data, index) => {
                    return (
                      <p
                        className="font-bold rounded-md p-1 px-2 bg-[#FFE8AD] text-[#C39C37] w-max"
                        key={index}
                      >
                        {data?.label.replace(/ \([\s\S]*?\)/g, "")}
                      </p>
                    );
                  })
                ) : data?.userType === "corporate" && data?.sector ? (
                  <div className="w-max text-[10px] md:text-sm">
                    <p className="font-bold rounded-md p-1 px-2 bg-[#E7BFFF] text-[#C05EFB] truncate">
                      {data?.sector}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            {data.userType !== "volunteer" ? (
              data?.locations?.length >= 2 ? (
                <div className="flex gap-1 my-2 md: text-xs md:text-[13px]">
                  <p className="font-normal text-[10px] md:text-sm text-[#919191]">
                    {data?.locations[0].label} and{" "}
                    <span className="font-bold text-[10px] md:text-sm">
                      {data?.locations?.length - 1} other locations
                    </span>
                  </p>
                </div>
              ) : data?.locations?.length === 1 ? (
                <div className="flex gap-1 my-2 md: text-xs md:text-[13px]">
                  <p className="font-normal text-[10px] md:text-sm text-[#919191]">
                    {data?.locations[0].label}
                  </p>
                </div>
              ) : null
            ) : (
              <p className="text-black text-[10px] md:text-sm ">
                {data?.location}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center space-y-5">
            <button
              className="text-[10px] md:text-sm font-bold text-blue"
              onClick={() =>
                navigate(`/${type}/view-${data.userType}/${data._id}`)
              }
            >
              View Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
