import React, { useEffect, useState } from "react";

import SearchList from "../../components/Search/SearchList";
import Sidebar from "../../components/Sidebar/Sidebar";
import SortBy from "./SortBy";
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useParams,
  NavLink,
} from "react-router-dom";
import queryString from "query-string";

import axios from "axios";
import { data } from "autoprefixer";
import { useSelector } from "react-redux";
import get_current_user from "../../aws_cognito/get_user";
import { AiOutlineClose } from "react-icons/ai";

export default function Search({ typeUser }) {
  const [searchItem, setSearchItem] = useState("");
  const [active, setActive] = useState("1");
  const [searchData, setSearchData] = useState([]);
  const [user, setUser] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);

  //   const [locationToggled, setLocationToggled] = useState(false);
  //   const [causesToggled, setCausesToggled] = useState(false);
  //   const [skillsToggled, setSkillsToggled] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(search);
    searchParams.set("name", searchItem);
    const newParamString = searchParams.toString();
    navigate(location.pathname + "?" + newParamString, { replace: true });
  };

  const location = useLocation();
  const search = location.search;
  const name = new URLSearchParams(search).get("name");

  useEffect(() => {
    setSearchItem(name)
  }, [])

  const userType = new URLSearchParams(search).get("userType");
  const query = queryString.parse(search);
  const localId = localStorage.getItem("id");
  const isAdmin = typeUser === "admin";

  const searchAPI = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/search${location.search}`
      );
      const result = await res.data;
      setLoading(false);
      if (result.status === "true") {
        setSearchData(result.data);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchId();
  }, []);

  const fetchId = async () => {
    const user = await get_current_user(typeUser);
    setUser(user.data.db_id);
    setType(user.data.type);
  };

  useEffect(() => {
    searchAPI();
  }, [name, userType, search]);

  const handleUserType = (userType, e) => {
    setActive(e.target.id);
    const modifiedQuery = {
      ...query,
      userType,
    };
    const options = {
      pathname: location.pathname,
      search: queryString.stringify(modifiedQuery),
    };
    navigate(options, { replace: true });
  };

  const handleAll = (e) => {
    setActive(e.target.id);
    let params = new URLSearchParams(search);
    console.log(params.get("userType"));
    params.delete("userType");
    const url = params.toString();
    navigate(location.pathname + "?" + url, { replace: true });
  };

  return (
    <div
      className={`bg-[#F4F8FB] min-h-screen flex gap-5 ${
        isAdmin ? "w-full h-screen overflow-scroll" : ""
      }`}
    >
      {!isAdmin && (
        <div className="hidden md:block ">
          <Sidebar pageName='search' userType={typeUser} />
        </div>
      )}

      <div
        className={`flex flex-col w-full md:p-10 ${
          isAdmin ? "md:w-full max-w-[80rem]" : "md:w-[70%] w-full h-screen overflow-scroll"
        }`}
      >
        <div
          className={`justify-between hidden ${
            isAdmin ? "xl:flex" : "md:flex"
          }`}
        >
          <p>
            Showing results for{" "}
            <span className="italic font-semibold text-blue">{name}</span>
          </p>
          {isAdmin ? (
            <NavLink
              reloadDocument={true}
              to={`/admin/community-wall`}
              className="text-[#919191] font-bold text-xl cursor-pointer"
            >
              <AiOutlineClose color="#919191" className="text-2xl" />
            </NavLink>
          ) : (
            <NavLink
              reloadDocument={true}
              to={`/${type}/community-wall`}
              className="text-[#919191] font-bold text-xl cursor-pointer"
            >
              X
            </NavLink>
          )}
        </div>
        <form
          onSubmit={handleSubmit}
          class={` flex justify-between p-5 gap-5 shadow-lg items-center  bg-white ${
            isAdmin ? "xl:hidden" : "md:hidden"
          }`}
        >
          <label for="simple-search" class="sr-only">
            Search
          </label>
          <div class="relative w-full">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white pl-10 p-2.5"
              onChange={(e) => setSearchItem(e.target.value)}
              value={searchItem}
              placeholder="Search"
            />
            <input type="submit" hidden />
          </div>

          {isAdmin ? (
            <NavLink
              reloadDocument={true}
              to={`/admin/community-wall`}
              className="text-[#919191] font-bold text-xl cursor-pointer"
            >
              <AiOutlineClose color="#919191" className="text-2xl" />
            </NavLink>
          ) : (
            <NavLink
              reloadDocument={true}
              to={`/${type}/community-wall`}
              className="text-[#919191] font-bold text-xl cursor-pointer"
            >
              X
            </NavLink>
          )}
        </form>
        {/* User Type */}
        <div className="p-5 space-y-5 md:p-0">
          <div className="flex flex-wrap justify-center gap-3 mt-3 text-xs md:gap-5 md:text-sm md:justify-start">
            <button
              className={
                active === "1"
                  ? "p-1 px-3 md:p-2 md:px-7  bg-[#6FCD6D] text-white font-bold rounded-full shadow-sm"
                  : "p-1 px-3 md:p-2 md:px-7  bg-white rounded-full shadow-sm"
              }
              id={"1"}
              onClick={(e) => handleAll(e)}
            >
              All
            </button>
            <button
              className={
                active === "2"
                  ? "p-1 px-3 md:p-2 md:px-7  bg-[#6FCD6D] text-white font-bold rounded-full shadow-sm"
                  : "p-1 px-3 md:p-2 md:px-7  bg-white rounded-full shadow-sm"
              }
              id={"2"}
              onClick={(e) => handleUserType("corporate", e)}
            >
              Corporate
            </button>

            <button
              className={
                active === "3"
                  ? "p-1 px-3 md:p-2 md:px-7  bg-[#6FCD6D] text-white font-bold rounded-full shadow-sm"
                  : "p-1 px-3 md:p-2 md:px-7  bg-white rounded-full shadow-sm"
              }
              id={"3"}
              onClick={(e) => handleUserType("organization", e)}
            >
              Impact Organisation
            </button>

            <button
              className={
                active === "4"
                  ? "p-1 px-3 md:p-2 md:px-7  bg-[#6FCD6D] text-white font-bold rounded-full shadow-sm"
                  : "p-1 px-3 md:p-2 md:px-7  bg-white rounded-full shadow-sm"
              }
              id={"4"}
              onClick={(e) => handleUserType("volunteer", e)}
            >
              Volunteer
            </button>
          </div>
          {/* Sort By */}
          <div className="flex flex-col flex-wrap gap-3 text-xs md:flex-row md:text-base md:gap-8">
            <p className="my-2 ">Sort By: </p>
            <SortBy type="locations" />
            <SortBy type="causes" />
            <SortBy type="skills" />
          </div>

          {/* Search List */}
          {loading ? (
            <div role="status" className="flex justify-center w-full mt-10">
              <svg
                aria-hidden="true"
                class="mr-2 w-8 h-8 fill-blue text-lightGray animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {searchData?.length === 0 ? (
                <p>No users found</p>
              ) : (
                <>
                  {searchData?.map((data, index) => {
                    return (
                      <SearchList type={typeUser} data={data} key={index} />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
