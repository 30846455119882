import { ClockLoader } from 'react-spinners'

export default function MessageStatus({ seen, className }) {
    return (
        <svg className={`${className}`} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L7.5 7.5L13.5 1" stroke={ seen ? "#22A5FF" : "#949494" } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

            { seen &&
                <path d="M1 5L3.5 7.5L1 5ZM7 3.5L9.5 1L7 3.5Z" stroke="#22A5FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            }
        </svg>
    )
}