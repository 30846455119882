import React, { useEffect, useState } from 'react'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'
import { causesData } from '../../dummyData/graphData'
import Loading from '../Loading/Loading'

const CausesGraph = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` })

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setTimeout(() => {
      setData(causesData)
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return (
      <div className="grid w-full h-full place-items-center">
        <Loading />
      </div>
    )
  }

  return (
    <div className={`grid mt-8 place-items-center`}>
      <div>
        <BarChart
          width={isMobile ? 450 : 800}
          height={isMobile ? 350 : 400}
          data={data}
          margin={{ top: 15, right: 5, left: 5, bottom: 5 }}
          barSize={80}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{ value: 'Causes', position: 'insideBottom', offset: -4 }}
            tick={{ fontSize: '0.75rem', fontWeight: 'bold' }}
          ></XAxis>
          <YAxis
            label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
          />
          <Tooltip
            wrapperStyle={{
              width: 170,
              backgroundColor: '#ccc',
              borderRadius: '0.5rem',
              border: 'none',
            }}
          />
          <Bar dataKey="count" fill="#8884d8"></Bar>
        </BarChart>
      </div>
    </div>
  )
}

export default CausesGraph
