import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

export default function ViewAll({ showModal, setShowModal, type }) {
  const profile = useSelector((state) => state[type][type]);
  //   const data = profile.data;
  return (
    <>
      {showModal ? ( 
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-5 md:p-0">
            <div className="relative w-[90vw] sm:w-[80vw] my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-3  rounded-t ">
                  <h1 className="font-bold md:text-lg p-4">
                    Volunteering Hours
                  </h1>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium "
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-8 w-12 text-lg block ">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative pb-4  md:py-4 px-6 flex-auto text-sm space-y-4">
                  <div className="">
                    <p className="text-center md:text-[#919191]">
                      <span className="text-3xl md:text-4xl font-bold text-black">
                      {
                        type === "NGOProfile"
                        ? profile?.creditData?.totalHours
                        : profile?.data?.hoursVolunteered
                      }
                      {
                        type === "corpProfile" 
                        ? profile?.data?.employeeHoursRecieved
                        : ''
                      }
                      </span>
                      &nbsp; hours
                    </p>
                  </div>
                  {profile?.creditData?.length !== 0 ? (
                    <div className="text-[14px] md:text-sm md:px-0 px-3 space-y-3 md:space-y-5 flex flex-col max-h-[75vh] overflow-y-scroll scrollable">
                      {type === "corpProfile" || type === "NGOProfile"
                        ? profile?.creditData?.causes?.map((data, index) => {
                            return (
                              <div
                                className="flex  bg-[#D4EDFE] text-blue justify-between py-1 md:py-3 md:text-base px-2 md:px-5 rounded-xl"
                                key={index}
                              >
                                <p>{data.cause}</p>
                                <p className="font-medium">{data.hours} hrs</p>
                              </div>
                            );
                          })
                        : profile?.creditData?.map((data, index) => {
                            return (
                              <div
                                className="flex  bg-[#D4EDFE] text-blue justify-between py-1 md:py-3 md:text-base px-2 md:px-5 rounded-xl"
                                key={index}
                              >
                                <p>{data.cause}</p>
                                <p className="font-medium">{data.hours} hrs</p>
                              </div>
                            );
                          })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
