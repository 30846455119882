import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import logout from "../../aws_cognito/logout";

export const getCorpProfile = createAsyncThunk(
  "corporate/getCorpProfile",
  async (state) => {
    try {
      console.log(state);
      const user = await get_current_user(state.type);
      const res =
        state.type === "admin"
          ? await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/admin/corporate/${
                state.me === true ? user.data.db_id : state.id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${user.data.idToken}`,
                  "content-type": "application/json",
                },
              }
            )
          : await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/corporate/${
                state.me === true ? user.data.db_id : state.id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${user.data.idToken}`,
                  "content-type": "application/json",
                },
              }
            );
      const data = await res.data;
      const credit = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/credits/corporate/${
          state.me === true ? user.data.db_id : state.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      const result = await credit.data;
      return {
        ...data,
        user_id: user.data.db_id || "",
        loggedInType: user.data.type || [],
        creditData: result.data,
      };
    } catch (error) {
      // console.error(err);
      // alert(err.response.data.message);
      if(error.response.data.data==='verification-pending'){ // warning: don't change the text
        alert(error.response.data.message);
        logout("corporate", false)
      }
      else window.location.href='../corporate/profileSetup'
    }
  }
);

const fetchCorpProfile = createSlice({
  name: "corpProfile",
  initialState: {
    corpProfile: {},
    loading: false,
  },

  extraReducers: {
    [getCorpProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [getCorpProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.corpProfile = action.payload;
    },
    [getCorpProfile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default fetchCorpProfile.reducer;
