import React, { useCallback, useState, useRef } from "react";
import logo from "../../../src/images/Logo.png";
import { ReactTags } from "react-tag-autocomplete";
import { v4 as uuidv4 } from "uuid";

import {
  interestOptions,
  skillsOptions,
  causesOptions,
  bloodGroupOptions,
  locationOptions,
} from "./data/data.js";
import { useForm } from "react-hook-form";
import "./Preferences.css";
import { Router, useNavigate } from "react-router-dom";
import { bloodTypes } from "../Profile/data";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import get_skills_token from "../../api/get_skills_token";

export default function Preferences() {
  const [interests, setInterests] = useState([]);
  // const [skills, setSkills] = useState([]);
  const [bloodType, setBloodType] = useState("");
  const [loading, setLoading] = useState("");
  const [location, setLocation] = useState([]);
  const [skills, setSkills] = useState({
    isBusy: false,
    selected: [],
    suggestions: [],
  });

  const inputRef = useRef(null);
  const handleBlur = useCallback(() => {
    if (
      inputRef.current &&
      !inputRef.current.value &&
      !skills.selected.length
    ) {
      setSkills({
        ...skills,
        showHelperText: true,
      });
    }
  }, [skills]);

  const isDisabled =
    !interests.length ||
    !bloodType ||
    !location.length ||
    !skills.selected.length;

  const navigate = useNavigate();

  const onAddInterest = useCallback(
    (newTag) => {
      if (interests.length < 5) {
        setInterests([...interests, newTag]);
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [interests]
  );
  const onDeleteInterest = useCallback(
    (tagIndex) => {
      setInterests(interests.filter((_, i) => i !== tagIndex));
    },
    [interests]
  );

  // helper text for volunteer skills input
  const [selectedSuggestion, setSelectedSuggestion] = useState(true);

  const onAddSkills = useCallback(
    (newTag) => {
      if (skills.selected.length < 5) {
        // const newTagWithId = { ...newTag, id: uuidv4() }; // Generate unique id for new tag
        // const newSuggestions = [...skills.suggestions];
        // if (!newSuggestions.some((tag) => tag.id === newTagWithId.id)) {
        //   newSuggestions.push(newTagWithId);
        // }
        const newSuggestions = [...skills.suggestions];
        if (!newSuggestions.some((tag) => tag.id === newTag.id)) {
          newSuggestions.push(newTag);
        }

        console.log("new tag: ", newTag);
        setSelectedSuggestion(true);

        setSkills({
          ...skills,
          selected: [...skills.selected, newTag],
          // selected: [...skills.selected, newTagWithId],
          // suggestions: [],
          suggestions: newSuggestions,
        });
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [skills]
  );

  const OnDeleteSkills = useCallback(
    (tagIndex) => {
      setSkills({
        ...skills,
        selected: skills.selected.filter((_, i) => i !== tagIndex),
      });
    },
    [skills]
  );

  // const onAddedBloodGroup = useCallback(
  //   (newTag) => {
  //     setBloodGroup([...bloodGroup, newTag]);
  //   },
  //   [bloodGroup]
  // );

  // const onDeleteBloodGroup = useCallback(
  //   (tagIndex) => {
  //     setBloodGroup(bloodGroup.filter((_, i) => i !== tagIndex));
  //   },
  //   [bloodGroup]
  // );
  const onAddedLocation = useCallback(
    (newTag) => {
      console.log(location);
      if (location.length == 3) {
        return;
      }

      setLocation([...location, newTag]);
    },
    [location]
  );

  const onDeleteLocation = useCallback(
    (tagIndex) => {
      setLocation(location.filter((_, i) => i !== tagIndex));
    },
    [location]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = {
      interests,
      skills: skills.selected,
      bloodType,
      availableLocations: location,
    };
    try {
      const user = await get_current_user("volunteer");
      const data = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/volunteer`,
        form,
        {
          headers: {
            Authorization: `Bearer ${user.data.idToken}`,
          },
        }
      );
      const response = await data;
      setLoading(false);

      if (response.data.status === "success") {
        navigate(`/volunteer/profile`);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  function debounce(fn, delay = 100) {
    let timeoutID;

    return function (...args) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }

  function wait(delay = 100) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async function fetchData(value) {
    if (value.length < 3) {
      return;
    }
    setSkills({ ...skills, suggestions: [], isBusy: true });
    try {
      const query = encodeURIComponent(value);
      await wait();

      const token = await get_skills_token();

      const response = await fetch(
        `https://emsiservices.com/skills/versions/latest/skills?q=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // if (response.ok) {
      const json = await response.json();
      console.log(json.data);

      return json.data.map((item, index) => ({
        value: Math.random(),
        label: item.name,
      }));
      // } else {
      //   throw Error(`The API returned a ${response.status}`);
      // }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const onInput = useCallback(
    debounce(async (value) => {
      console.log("skills isbusy:", skills.isBusy);
      console.log("value: ", value);
      if (value == "") setSelectedSuggestion(true);
      else setSelectedSuggestion(false);
      if (skills.isBusy) return;

      try {
        const suggestions = await fetchData(value);

        if (suggestions) {
          setSkills({ ...skills, suggestions, isBusy: false });
        }
      } catch (error) {
        console.error(error);
        setSkills({ isBusy: false });
      }
    }),
    [skills]
  );

  function isValid(value) {
    return /^[a-z]{4,20}$/i.test(value);
  }
  const onValidate = useCallback((value) => isValid(value), []);

  return (
    <>
      <div className="w-full md:w-[47%] flex flex-col z-10 md:h-screen bg-white pb-10 md:p-8 overflow-y-scroll -mt-5 md:mt-0">
        <div className="flex flex-start px-8 pb-4 md:p-4 underline decoration-blue underline-offset-5 mb-10 md:mb-0 z-10">
          <a
            href="profileSetup"
            className="text-blue font-bold underline w-max cursor-pointer"
          >
            {"<"} Back
          </a>
        </div>
        <div className="hidden md:flex flex-row justify-center -mt-10">
          <img src={logo} alt="logo" className="w-36" />
        </div>
        <h1 className="text-center font-bold md:text-3xl text-lg md:mt-5">
          Select Preferences<span className="text-blue">.</span>
        </h1>
        <p className="text-center text-xs font-medium text-black leading-5 mt-1 mb-4 md:mb-0">
          Don't worry you can edit this later.
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center mt-5 space-y-5 w-full"
        >
          <div className="relative  w-[80%] flex mx-auto flex-col preferences">
            <ReactTags
              placeholderText={interests.length === 0 ? "Interests *" : ""}
              selected={interests}
              suggestions={causesOptions}
              onAdd={onAddInterest}
              onDelete={onDeleteInterest}
              noOptionsText="No matching interests"
            />
          </div>
          <div className="relative w-[80%] flex mx-auto flex-col preferences">
            {/* <ReactTags
              placeholderText={skills.length === 0 ? "Skills" : ""}
              selected={skills}
              suggestions={skillsOptions}
              onAdd={onAddSkills}
              onDelete={OnDeleteSkills}
              noOptionsText="No matching interests"
            /> 
          </div> */}
            <ReactTags
              allowNew={true}
              ariaDescribedBy="description-4"
              id="demo-4"
              noOptionsText={
                skills.isBusy === true ? "Loading" : "No skills found"
              }
              onAdd={onAddSkills}
              onDelete={OnDeleteSkills}
              onValidate={onValidate}
              onInput={onInput}
              placeholderText={skills.selected.length === 0 ? "Skills *" : ""}
              selected={skills.selected}
              suggestions={skills.suggestions}
              inputRef={inputRef}
              onBlur={handleBlur}
            />
            {!selectedSuggestion && (
              <span
                style={{
                  color: "red",
                  fontSize: "11px",
                  fontWeight: "500",
                }}
              >
                Please select from the suggestions or add a new skill before
                leaving.
              </span>
            )}

            <p className="italic text-xs text-lightGray">
              Skills must be between 4 and 20 characters in length and only
              contain the letters A-Z
            </p>
          </div>
          <div className="relative w-[80%] flex mx-auto flex-col preferences">
            <select
              id="bloodType"
              className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 bg-[#F3F4F6]"
              name="bloodType"
              onChange={(e) => setBloodType(e.target.value)}
            >
              <option value="" selected={true} disabled={true}>
                Blood Group *
              </option>
              {bloodTypes.map((data, index) => {
                return (
                  <option value={data} key={index}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="relative w-[80%] flex mx-auto flex-col preferences">
            <ReactTags
              placeholderText={
                location.length === 0
                  ? "Locations available for blood donation *"
                  : ""
              }
              selected={location}
              suggestions={locationOptions}
              onAdd={onAddedLocation}
              onDelete={onDeleteLocation}
              noOptionsText="No matching interests"
            />
          </div>
          <div className="flex justify-center items-center preferences">
            {loading ? (
              <button
                disabled
                type="button"
                className="bg-sky-300 mt-5 hover:bg-blue-500 text-white font-bold py-2 text-sm md:text-base md:py-2 px-5 rounded-lg cursor-pointer"
              >
                <svg
                  role="status"
                  class="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Finishing Up...
              </button>
            ) : (
              <input
                style={{ opacity: isDisabled ? 0.6 : 1 }}
                disabled={isDisabled}
                type="submit"
                value="Complete"
                className="bg-blue mt-5 hover:bg-blue-500 text-white font-bold py-2 text-sm md:text-base md:py-2 px-5 rounded-lg cursor-pointer"
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
}
