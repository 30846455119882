import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { timestampOf, compareDates } from '../../../utils/utils'
import { chatTypes } from '../../../utils/enum'
import { setSelectedUserFromConversation, setAddingConversation, getConversations, clearSelectedUser, getBroadcastConversation } from '../../../redux/slices/chatSlice'
import SectionSelect from '../SectionSelect/SectionSelect'
import ChatInput from '../ChatInput/ChatInput'
import MessageList from '../MessageList/MessageList'
import AddConversation from '../AddConversation/AddConversation'
import MessageStatus from '../Message/MessageStatus'
import './Messages.css'

export default function Messages({ type, className, openNavRef }) {
    const dispatch = useDispatch()
    const { status, conversations, userStatuses, selectedUser, addingConversation, selectedConversation } = useSelector(state => state.chat)
    const widthSm = useMediaQuery({ query: '(min-width: 640px)' })
    const widthMd = useMediaQuery({ query: '(min-width: 900px)' })
    const selectedUserStatus = selectedUser && (userStatuses[selectedUser.type + selectedUser._id] ?? 'offline')
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const [search, setSearch] = useState('')

    const sortedConversations = conversations && [...conversations]
        .filter(x => x.other.name.toLowerCase().startsWith(search.toLowerCase()))
        .sort((a, b) => compareDates(b.lastMessage?.createdAt, a.lastMessage?.createdAt))

    useEffect(() => {
        if (!status.conversations) {
            dispatch(getBroadcastConversation({ type }))
            dispatch(getConversations({ type }))
        }
    }, [])

    const onConversationSelect = (conversation) => {
        if (conversation._id != selectedConversation?._id) {
            dispatch(setSelectedUserFromConversation(conversation))
        }
    }

    return (
        <div className={`bg-white flex shadow rounded w-full overflow-hidden ${className}`}>
            <AddConversation type={type} style={{ marginLeft: addingConversation ? 0 : '-100%' }} className='min-w-full transition-all duration-500' />

            <div style={{ marginLeft: (!widthSm && !addingConversation && selectedUser) ? '-100%' : 0 }} className='flex ml-0 w-full h-full min-w-full transition-all duration-500'>
                <div className='flex flex-col border-r-[1px] mt-4 sm:mt-2 border-solid border-[#EAEAEA] h-screen md:h-auto min-w-[100vw] sm:min-w-0 sm:basis-1/3 xl:basis-1/4'>
                    <div className='p-3'>
                        <div className='flex items-center'>
                            { !widthMd &&                       
                                <button className='translate-y-[-2px] ml-2' onClick={() => openNavRef.current(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 448 512">
                                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                                    </svg>
                                </button>
                            }

                            <h1 className='font-semibold text-[1.25rem] sm:text-[1.5rem] ml-2 translate-y-[-2px]'>Messages</h1>

                            <button className='ml-auto mr-2' onClick={() => dispatch(setAddingConversation(true))}>
                                <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.101 0.852403L5.92502 12.0284C5.49817 12.4529 5.15977 12.9579 4.92942 13.5142C4.69906 14.0704 4.58133 14.6668 4.58303 15.2688V16.4999C4.58303 16.743 4.6796 16.9762 4.85151 17.1481C5.02342 17.32 5.25658 17.4166 5.49969 17.4166H6.73077C7.33281 17.4183 7.9292 17.3005 8.48542 17.0702C9.04164 16.8398 9.54664 16.5014 9.97119 16.0746L21.1472 4.89856C21.6829 4.36155 21.9837 3.634 21.9837 2.87548C21.9837 2.11697 21.6829 1.38941 21.1472 0.852403C20.6024 0.331639 19.8778 0.0410156 19.1241 0.0410156C18.3704 0.0410156 17.6458 0.331639 17.101 0.852403V0.852403ZM19.851 3.6024L8.67502 14.7784C8.15814 15.2921 7.45953 15.5813 6.73077 15.5832H6.41636V15.2688C6.41827 14.54 6.70746 13.8414 7.22119 13.3246L18.3972 2.14857C18.593 1.96153 18.8533 1.85715 19.1241 1.85715C19.3949 1.85715 19.6552 1.96153 19.851 2.14857C20.0435 2.34154 20.1515 2.60295 20.1515 2.87548C20.1515 3.14802 20.0435 3.40943 19.851 3.6024V3.6024Z" fill="#22A5FF"/>
                                    <path d="M21.0833 8.23074C20.8402 8.23074 20.607 8.32732 20.4351 8.49923C20.2632 8.67114 20.1666 8.90429 20.1666 9.14741V13.75H16.5C15.7706 13.75 15.0712 14.0397 14.5554 14.5554C14.0397 15.0712 13.75 15.7706 13.75 16.5V20.1666H4.58333C3.85398 20.1666 3.15451 19.8769 2.63879 19.3612C2.12306 18.8455 1.83333 18.146 1.83333 17.4167V4.58333C1.83333 3.85398 2.12306 3.15451 2.63879 2.63879C3.15451 2.12306 3.85398 1.83333 4.58333 1.83333H12.8718C13.1149 1.83333 13.3481 1.73675 13.52 1.56485C13.6919 1.39294 13.7885 1.15978 13.7885 0.916666C13.7885 0.673551 13.6919 0.440393 13.52 0.268485C13.3481 0.096577 13.1149 0 12.8718 0L4.58333 0C3.3682 0.00145553 2.20326 0.484808 1.34403 1.34403C0.484808 2.20326 0.00145553 3.3682 0 4.58333L0 17.4167C0.00145553 18.6318 0.484808 19.7967 1.34403 20.6559C2.20326 21.5152 3.3682 21.9985 4.58333 22H14.9811C15.5832 22.0017 16.1798 21.884 16.7361 21.6536C17.2925 21.4233 17.7977 21.0849 18.2224 20.658L20.6571 18.2215C21.084 17.7969 21.4225 17.292 21.653 16.7358C21.8835 16.1795 22.0015 15.5832 22 14.9811V9.14741C22 8.90429 21.9034 8.67114 21.7315 8.49923C21.5596 8.32732 21.3264 8.23074 21.0833 8.23074ZM16.9262 19.3618C16.5577 19.7294 16.0918 19.9839 15.5833 20.0951V16.5C15.5833 16.2569 15.6799 16.0237 15.8518 15.8518C16.0237 15.6799 16.2569 15.5833 16.5 15.5833H20.0979C19.9845 16.0907 19.7303 16.5558 19.3646 16.9253L16.9262 19.3618Z" fill="#22A5FF"/>
                                </svg>
                            </button>
                        </div>

                        <div className='flex items-center bg-[#F4F4F4] rounded-md my-5'>
                            <svg className='ml-4' stroke="#919191" width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.5 29.5L22.771 22.759L29.5 29.5ZM26.5 13.75C26.5 17.1315 25.1567 20.3745 22.7656 22.7656C20.3745 25.1567 17.1315 26.5 13.75 26.5C10.3685 26.5 7.12548 25.1567 4.73439 22.7656C2.3433 20.3745 1 17.1315 1 13.75C1 10.3685 2.3433 7.12548 4.73439 4.73439C7.12548 2.3433 10.3685 1 13.75 1C17.1315 1 20.3745 2.3433 22.7656 4.73439C25.1567 7.12548 26.5 10.3685 26.5 13.75V13.75Z" stroke="#919191" strokeWidth="2" strokeLinecap="round"/>
                            </svg>

                            <input className='p-3 text-[0.78rem] text-[#919191] bg-transparent outline-none w-full' placeholder="Search in messages" onInput={e => setSearch(e.target.value)} />
                        </div>

                        <SectionSelect list={['Users', 'Cause Circles']} disabled={[ false, true ]} />
                    </div>

                    <div className='chat-scrollable flex flex-col overflow-y-auto max-h-full' style={{ overflowY: status.conversations == 'pending' ? 'hidden' : 'auto' }}>
                        { status.conversations == 'pending' && [...Array(10)].map((x, i) => (
                            <div key={i} className='flex items-center p-2 px-3 mr-1'>
                                <div className='h-11 w-11 rounded-full bg-animated' />

                                <div className='grow h-10 ml-2 flex flex-col'>
                                    <div className='w-full h-[40%] rounded-md bg-animated mt-1' />
                                    <div className='w-[75%] h-[28%] rounded bg-animated mt-1' />
                                </div>
                            </div>
                        ))}

                        {sortedConversations.map((x, i) => {
                            const isLastSender = x.lastMessage && (x.lastMessage.self || (x.lastMessage.author == userProfile.data?._id && x.lastMessage.authorType == userProfile.data?.type))

                            return (
                                <div onClick={() => onConversationSelect(x)} key={x._id} className={`flex items-center p-2 px-3 cursor-pointer hover:bg-[#f4f4f4] ${x._id == selectedConversation?._id ? 'bg-[#f4f4f4]' : ''}`}>
                                    <div className='flex justify-center w-[3.6rem]'>
                                        <img className='h-11 w-11 rounded-full' src={x.other.avatar} />
                                    </div>

                                    <div className='flex flex-col min-w-0 w-full ml-2'>
                                        <div className='flex min-w-0'>
                                            <span className='font-semibold text-[0.82rem]'>{x.other.name}</span>

                                            { x.lastMessage &&
                                                <span className='text-[0.68rem] font-medium text-[#949494] ml-auto mr-2 translate-y-[1px]'>{timestampOf(x.lastMessage.createdAt)}</span>
                                            }
                                        </div>

                                        <div className='flex relative'>
                                            { x.otherIsTyping
                                                ? <span className='text-[#22A5FF] text-[0.75rem] font-medium'>Typing...</span>
                                                : <>
                                                    { x.lastMessage &&
                                                        <span className='w-[80%] whitespace-nowrap overflow-hidden text-ellipsis text-[0.75rem] text-[#949494] font-regular mr-6 translate-y-[1px] break-all'>{(isLastSender ? 'You' : x.other.name.split(' ')[0]) + ': ' + x.lastMessage.text + (x.lastMessage.attachments && x.lastMessage.attachments.length  ? ' (attachment)' : '')}</span>
                                                    }
                                                </>
                                            }

                                            { x.lastMessage && isLastSender &&
                                                <MessageStatus className='mt-1 translate-y-[3px] absolute right-2' seen={compareDates(x.otherLastSawMessages, x.lastMessage.createdAt) >= 0} />
                                            }

                                            { x.lastMessage && !isLastSender && x.newMessagesCount > 0 &&
                                                <span className='h-[0.9rem] w-[0.9rem] absolute text-[0.6rem] bg-[#22A5FF] rounded-full inline-flex items-center justify-center text-white right-2 translate-y-[3px]'>{x.newMessagesCount}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='flex flex-col p-[2px] sm:p-1 min-w-[100vw] sm:min-w-0 sm:basis-2/3 xl:basis-3/4 h-full'>
                    <div className='h-20 border-b-[1px] border-solid border-[#EAEAEA] flex items-center'>
                        <svg fill={'#333'} onClick={() => dispatch(clearSelectedUser())} className='w-4 ml-4 sm:w-5 cursor-pointer sm:hidden' viewBox="0 0 15 8" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.547826 5.23612L2.96427 7.67753C3.02231 7.73606 3.09137 7.78251 3.16746 7.81421C3.24355 7.84591 3.32516 7.86223 3.40759 7.86223C3.49002 7.86223 3.57163 7.84591 3.64772 7.81421C3.72381 7.78251 3.79287 7.73606 3.85092 7.67753C3.90944 7.61949 3.95589 7.55043 3.98759 7.47434C4.0193 7.39825 4.03562 7.31664 4.03562 7.23421C4.03562 7.15178 4.0193 7.07017 3.98759 6.99408C3.95589 6.91799 3.90944 6.84893 3.85092 6.79088L1.62804 4.55552H14.3596C14.5252 4.55552 14.684 4.48973 14.8011 4.37264C14.9182 4.25554 14.984 4.09672 14.984 3.93112C14.984 3.76551 14.9182 3.60669 14.8011 3.4896C14.684 3.3725 14.5252 3.30671 14.3596 3.30671H1.59058L3.85092 1.04637C3.96162 0.93025 4.02337 0.77597 4.02337 0.615536C4.02337 0.455101 3.96162 0.300822 3.85092 0.184697C3.79287 0.126173 3.72381 0.0797209 3.64772 0.0480208C3.57163 0.0163207 3.49002 0 3.40759 0C3.32516 0 3.24355 0.0163207 3.16746 0.0480208C3.09137 0.0797209 3.02231 0.126173 2.96427 0.184697L0.547826 2.58865C0.197036 2.93988 0 3.41598 0 3.91238C0 4.40879 0.197036 4.88489 0.547826 5.23612Z" />
                        </svg>

                        { selectedUser &&
                            <div className='ml-3 flex items-center'>
                                <img className='h-10 w-10 sm:h-12 sm:w-12 rounded-full' src={selectedUser.avatar} />

                                <div className='flex flex-col ml-3'>
                                    <span className='font-semibold text-[0.8rem] sm:text-[0.9rem] translate-y-[2px]'>{selectedUser.name}</span>

                                    { selectedConversation.otherIsTyping
                                        ? <span className='text-[#22A5FF] text-[0.65rem] sm:text-[0.75rem] font-medium'>Typing...</span>
                                        : <span className={`text-[0.7rem] sm:text-[0.8rem] ${selectedUserStatus == 'online' ? 'text-[#6FCD6D]' : ''}`}>{selectedUserStatus}</span>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div className='messages-div flex flex-col'>
                        <MessageList chatType={chatTypes.PRIVATE} type={type} className='mt-auto' />

                        { selectedUser && selectedUser._id != 'admin' &&
                            <div className='justify-self-end mt-1 mb-2 scale-90 sm:scale-100 sm:mb-0'>
                                <ChatInput chatType={chatTypes.PRIVATE} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}