import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import defaultAvatar from "../../images/default.jpg";

export default function EmployeesModal({ showModal, setShowModal, type }) {
  const [employeeData, setEmployeeData] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const fetchAllEmployees = async () => {
        try {
          const user = await get_current_user(type);
          const res = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/employees/${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.data.idToken}`,
              },
            }
          );

          const data = await res.json();
          if (data.status === "success") {
            setAllEmployees(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchAllEmployees();
    }
  }, []);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none my-5 p-5 md:p-0 rounded-lg">
            <div className="relative w-[800px] my-6 mx-auto  h-screen">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none my-10">
                {/*header*/}
                <div className="flex items-center justify-between p-5 mt-5  rounded-t ">
                  <h3 className="font-bold md:text-xl md:font-semibold ">
                    All Employees ({allEmployees?.length})
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-lightGray  font-bold float-right text-xl leading-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-lightGray h-8 w-12 text-xl block ">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}

                {allEmployees.length !== 0 ? (
                  <span className="relative pb-20  py-4 px-4 flex-auto text-sm overflow-y-scroll max-h-[400px] ">
                    <div className="flex flex-col space-y-5">
                      {allEmployees.map((data, index) => {
                        return (
                          <div className="flex flex-col w-full space-y-6">
                            <div className="flex gap-2 items-center md:gap-3 px-3 ">
                              <img
                                src={
                                  data.profilePic !== ""
                                    ? data?.profilePic
                                    : defaultAvatar
                                }
                                alt=""
                                className="w-14 h-14 md:w-20 md:h-20 rounded-full"
                              />
                              <div className="flex justify-between grow flex-wrap">
                                <div className="flex flex-col space-y-1 ">
                                  <h1 className="text-sm md:text-base font-bold ">
                                    {data.name}
                                  </h1>
                                  <p className="text-lightGray text-xs">
                                    {data.currentCareer}
                                  </p>
                                </div>
                                <button
                                  className="text-[10px] md:text-sm font-bold text-blue"
                                  onClick={() =>
                                    navigate(
                                      `/${type}/view-volunteer/${data._id}`
                                    )
                                  }
                                >
                                  View Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </span>
                ) : (
                  <p className="mx-auto pb-10">
                    This corporate has currently 0 employees.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
