import React from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useMediaQuery } from "react-responsive";
import OtherLeaderboards from "../../OtherLeaderboard/OtherLeaderboards";

export default function LeaderboardCorporate() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <>
      {isMobile ? (
        <>
          <div className="flex flex-row w-full">
            <Sidebar pageName="Leaderboard" userType="volunteer" />
          </div>
          <div className="w-full h-full flex flex-col bg-[#F4F8FB] overflow-x-hidden">
            <div className="pt-16">
              <OtherLeaderboards typeUser="volunteer" />
            </div>
          </div>
        </>
      ) : (
        <div className="flex">
          <div className="height-screen">
            <Sidebar pageName="Leaderboard" userType="volunteer" />
          </div>
          <div className="w-full h-screen overflow-scroll flex flex-col bg-[#F4F8FB]">
            <OtherLeaderboards typeUser="volunteer" />
          </div>
        </div>
      )}
    </>
  );
}
