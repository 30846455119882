import React from "react";
import { useNavigate } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import logout from "../../aws_cognito/logout";

export default function Deactivate({ type }) {
  const navigate = useNavigate();
  let userType = type;

  const handleDeactivate = async () => {
    let ans = window.confirm(
      "Are you sure you want to deactivate your account?"
    );
    if (ans) {
      const user = await get_current_user(type);
      if (type === "organization") {
        userType = "impactOrg";
      }
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/${userType}/deactivate`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.data.idToken}`,
          },
          // body: JSON.stringify({
          //   active: false,
          // }),
        }
      );
      const data = await res.json();
      console.log(data);
      if (data.status === "success") {
        logout(type);
      }
    }
  };

  return (
    <>
      <div className="p-10 flex flex-col space-y-10">
        <h1 className="font-bold text-3xl mt-10 ">Settings</h1>
        <h2 className="font-semibold text-lg">Account Settings</h2>
        <div>
          <h1>Deactivate account</h1>
          <div className="flex flex-col md:flex-row gap-5 justify-between">
            <p className="text-lightGray text-sm w-2/3">
              Deactivate is temporary. Your profile, photos, and all the things
              that you posted will be unavailable. You may reactivate it after a
              while.
            </p>
            <button
              className="p-2 px-2 my-auto rounded-xl font-bold bg-blue text-white w-max"
              onClick={handleDeactivate}
            >
              Deactivate
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
