import React, { useCallback, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addObjectToArray,
  addWorkExp,
  deleteWorkExp,
  updateDetails,
  updateWorkExp,
} from "../../redux/slices/profileSlice";
import { employmentLevel } from "./data";

export default function WorkExpereience() {
  const [inputList, setInputList] = useState([
    { designation: null, companyName: null, yearOfExp: null },
  ]);

  const workExp = useSelector((state) => state.profileForm.career);
  const profile = useSelector((state) => state.profileForm);

  const dispatch = useDispatch();

  const handleInputChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      // const list = [...inputList];
      // list[index][name] = value;
      // setInputList(list);
      console.log(name, value);
      dispatch(updateWorkExp({ id: index, name: name, value: value }));
    },
    [workExp]
  );

  const handleRemoveClick = useCallback(
    (index) => {
      if (window.confirm("Are you sure you want to delete this?")) {
        // const list = [...inputList];
        // list.splice(index, 1);
        // setInputList(list);
        dispatch(deleteWorkExp({ id: index }));
      }
    },
    [workExp]
  );

  const handleAddClick = useCallback(() => {
    // setInputList([
    //   ...inputList,
    //   { designation: "", companyName: "", yearOfExp: "" },
    // ]);
    const obj = { designation: "", companyName: "", yearOfExp: "" };
    dispatch(addObjectToArray({ type: "career", obj }));
  }, [workExp]);

  const handleValues = (e) => {
    e.preventDefault();
    // const { name, value } = e.target;
    // console.log(profile);
    // const { name, value } = e.target;
    // setValues({ ...values, [name]: value });
    dispatch(updateDetails({ name: e.target.name, value: e.target.value }));
  };

  // console.log(workExp);

  return (
    <>
      <div className="flex flex-col border-b border-[#DDDDDD] pb-10">
        <div className="flex justify-between">
          <p className="font-medium">Career</p>
          <div className="bg-blue cursor-pointer" onClick={handleAddClick}>
            <AiOutlinePlus className="text-3xl text-white" />
          </div>
        </div>
        <div>
          <form>
            <div class="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="employmentLevel"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  Employment Level
                </label>
                <select
                  id="employmentLevel"
                  name="employmentLevel"
                  onChange={handleValues}
                  value={
                    employmentLevel.includes(profile.employmentLevel)
                      ? profile.employmentLevel
                      : "Other"
                  }
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {employmentLevel.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              {!employmentLevel.includes(profile.employmentLevel) ||
              profile.employmentLevel === "Other" ? (
                <div>
                  <label
                    for="employmentLevel"
                    class="block mb-2 text-xs font-normal text-lightGray"
                  >
                    Other
                  </label>
                  <input
                    type="text"
                    name="employmentLevel"
                    id="employmentLevel"
                    class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                    required
                    defaultValue={profile.employmentLevel}
                    placeholder="Describe"
                    onChange={handleValues}
                  />
                </div>
              ) : null}
            </div>
            {workExp?.map((x, i) => {
              return (
                <div className="flex gap-5" key={i}>
                  <p className="flex items-center mt-6">{i + 1}.</p>
                  <div class="grid gap-6 my-6 md:grid-cols-4 w-full">
                    <div>
                      <label
                        for="designation"
                        class="block mb-2 text-xs font-normal text-lightGray"
                      >
                        Designation
                      </label>
                      <input
                        type="text"
                        name="designation"
                        id="designation"
                        class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                        required
                        value={x.designation}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div>
                      <label
                        for="companyName"
                        class="block mb-2 text-xs font-normal text-lightGray"
                      >
                        Company name
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                        required
                        value={x.companyName}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div>
                      <label
                        for="yearOfExp"
                        class="block mb-2 text-xs font-normal text-lightGray"
                      >
                        Years of experience
                      </label>
                      <input
                        type="number"
                        name="yearOfExp"
                        id="yearOfExp"
                        class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                        required
                        value={x.yearOfExp}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div className="mt-8">
                      {workExp?.length !== 0 && (
                        <button
                          className="flex justify-center items-center  text-sm font-medium text-[#F38585]"
                          onClick={() => handleRemoveClick(i)}
                        >
                          Delete Experience
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {workExp?.length === 0 ? (
              <p className="text-sm">No work experience</p>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
}
