import React from "react";
import exp from "../../images/volunteer-exp.png";
import ReactStars from "react-rating-stars-component"
import { useDispatch, useSelector } from "react-redux";
import * as postsApi from "../../api/posts";
import { useEffect,useRef,useState } from "react";
import get_current_user from "../../aws_cognito/get_user";
import { getUser } from "../../redux/slices/userSlice";
import defaultAvatar from "../../images/default.jpg"
import { Link } from 'react-router-dom'
import axios from "axios";
import Modal from "../Modal/Modal";
import EditCreditAccept from "../CreditAccept/EditCreditAccept";





export default function VolunteeringExperience({type,profile}) {
  const dispatch = useDispatch();
  const menuRef = useRef()
  const toggleRef = useRef()
  const deletingRef = useRef(false)
  const [showPostMenu, setShowPostMenu] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [postId,setPostId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const { loading, userProfile } = useSelector((state) => state.currentProfile);
  const [info,setInfo]=useState([]);

  useEffect(()=>{
    getdata();
  },[])
  
  const getdata= async ()=>{
    try {
      const user = await get_current_user(type);
      //console.log(userProfile);
      console.log(profile);
      const res=await postsApi.getcreditrequest(profile._id,userProfile.data.idToken);
      console.log(res);
      const result=res.data;
      const infoFetched = result.data
      console.log(...info, infoFetched);
      setInfo(...info, infoFetched)
      console.log(info);
    } catch (error) {
      console.log(error);
    }finally{
    }
  }
 
  const deleteVolExp = async (id,token)=>{
    try {
      const  res = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/credit-request/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
          } 
        });
        console.log(res.data);
      }catch (error) {
      console.log(error)
    }finally{
      setInfo(
        info.filter((item)=>item._id!=id))
      console.log(info);
      alert("Deleted Successfully..")
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        <div
          className="sm:rounded-lg bg-[#F4F8FB] p-6 shadow-xl overflow-scroll w-full md:max-w-[65rem]"
          onClick={(e) => e.stopPropagation()}
        >
          {modalIsOpen && (
            <EditCreditAccept
              request={selectedRequest}
              setModalIsOpen={setModalIsOpen}
            />
          )}
        </div>
      </Modal>
    {
      info.length==0 ?<div className="my-2 text-center">No Experience available</div>:

      info.map((item)=>(
          <div className="flex flex-col md:mt-5 md:border-b border-[#C4C4C4] py-4 w-full">
            <div ref={menuRef} className='relative'>
                    {(userProfile.data._id == item.impactOrgId) &&
                        <div ref={toggleRef} className='post-menu-toggle absolute h-8 w-8 pr-1 right-1 top-[-2px] flex justify-center items-center cursor-pointer' onClick={(e) => {
                            e.target.classList.toggle('active')
                            console.log(e);
                            setShowPostMenu(!showPostMenu)
                            setShowDeleteConfirmation(false)
                            setPostId(item._id)
                        }}>
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                        </div>
                    }

                    {  showPostMenu && (item._id==postId) &&
                        <div className='post-menu bg-white absolute top-8 right-4 w-[120px] rounded overflow-hidden'>
                            <div>
                                <div className={`post-edit-button cursor-pointer duration-300 flex items-center hover:bg-[#f3f3f3] h-[30px] w-full pl-4`} onClick={()=>{
                                setSelectedRequest(item);
                                setModalIsOpen(true);
                                }}>
                                    <svg className='h-[0.85rem] w-[0.85rem] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#111]'>EDIT</span>
                                </div>
                            </div>
                            
                            <div className='post-delete max-h-[30px] overflow-hidden'>
                                <div className={`post-delete-button ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''} cursor-pointer duration-300 flex items-center  hover:bg-[#ff3b3b] h-[30px] w-full pl-4`} onClick={(e) => setShowDeleteConfirmation(true)}>
                                    <svg className='h-[0.8rem] w-[0.8rem] fill-[#f55858] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#f55858]'>DELETE</span>
                                </div>
                                
                                <div className={`post-delete-confirmation flex items-center justify-center h-[30px] bg-[#ff3b3b] duration-300 ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''}`}>
                                    <svg onClick={() => setShowDeleteConfirmation(false)} className='translate-y-[-1px] h-[0.8rem] w-[0.8rem] hover:rotate-90 duration-300 cursor-pointer fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"/>
                                    </svg>

                                    <span className='text-[0.7rem] mx-2 font-bold text-white cursor-default translate-y-[-1px]'>You sure?</span>


                                        <svg className='h-[0.9rem] w-[0.9rem] fill-white cursor-pointer hover:translate-y-[-2px] duration-300' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={() => {
                                            deletingRef.current = true
                                            console.log("deleting");
                                            deleteVolExp(item._id,userProfile.data.idToken);
                                        }}>
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                                        </svg>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            <div className="flex justify-between md:gap-3">
              <img src={item.org.profilePic=="" ? defaultAvatar: item.org.profilePic} alt="ProfilePic" className="rounded-full w-12 h-12 md:w-24 md:h-24" />
              <div className="flex justify-between grow">
                <div className="flex-col p-1 md:p-2 space-y-1">
                  <div className="flex items-start ">
                    <div>
                      <Link to={`/${type}/view-organization/${item.org._id}`}>
                          <span className='text-sm md:text-base font-bold'>
                            {item.org.name}
                          </span>
                      </Link>
                      <h1 className="text-sm md:text-base font-bold">
                      </h1>
                    </div>
                    <div className="ml-3 bg-orange-200 rounded-lg p-1 px-2" >
                      <h3 className=" text-xs text-amber-500 md:text-sm ">{item.tags.map((tag)=>(tag))}</h3>
                    </div>
                  </div>
                  <div className="mt-0">
                    <ReactStars 
                    count={5}
                    value={item.qualityOfWork}
                    edit={false}
                    size={24}
                    activeColor="#ffd700"></ReactStars>
                  </div>

                    <p className=" mt-0 text-slate-500 text-[10px] md:text-sm">
                      {item.feedback}
                    </p>
                </div>
                <div className="flex-col text-center  p-1 px-2 md:mr-7 w-[25%] ">
                    <h1 className=" text-center text-md md:text-2xl font-bold">
                      {item.credit}
                    </h1>
                    <h1 className="text-center text-sm md:text-base font-bold">
                      credits
                    </h1>
                    {
                      item.certificateUrl==""? <></>: 
                      <a href={item.certificateUrl} className="text-xs text-center font-bold text-blue" target="_blank">View Certificate</a>
                    }
                </div>
              </div>
            </div>
          </div>
      ))
    }
    </>
  );
}
