import cog_pool from "./user_pool";

const logout = async (type, confirmation=true) => {
  if (!confirmation || window.confirm("Are you sure you want to logout?")) {
    console.log("type from logout", type);
    const pool = cog_pool(type);
    console.log("pool", pool);
    const cognitoUser = pool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut((test) => console.log(test));
      localStorage.clear();
      window.location = `${
        process.env.REACT_APP_COGNITO_DOMAIN
      }/logout?client_id=${pool.getClientId()}&logout_uri=https://thesocialtown.in/`;
      // * replace with env var for signout url
    } else {
      console.log("Something else");
    }
  }
};
export default logout;
