import React from "react";
import SignupComponent from "../../../components/Signup/Signup";
import corporate from "../../../images/corporate.png";
import logo from "../../../images/Logo.png";
export default function Signup() {
  return (
    <>
      <div className="md:h-screen md:flex justify-center corporate-img">
        <img
          src={corporate}
          alt="volunteer"
          className="w-full h-[250px] md:hidden"
        />
        <div className="flex bg-white md:hidden  justify-center  -mt-20 z-10 ">
          <img
            src={logo}
            alt="logo"
            className="rounded-[50%] w-40 bg-white p-5 my-5"
          />
        </div>
        <SignupComponent type = "corporate" user="corporate" />
      </div>
    </>
  );
}
