import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
export default function VolunteeringHours({ showModal, setShowModal}) {
  const profile = useSelector((state) => state.userProfile.userProfile);
  const data = profile.data;
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-5 md:p-0">
            <div className="relative w-96 my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-3 pb-1  rounded-t ">
                  <p className="font-bold">Available Hours</p>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium "
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-8 w-12 text-lg block ">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative py-4 px-6 flex-auto text-sm">
                  
                  <div className="flex flex-col space-y-5">
                    {data?.availabilityTime?.map((data, index) => {
                      return data.startTime && data.endTime !== "" ? (
                        <div className="flex" key={index}>
                          <p className="">{data.day}: </p>
                          <p className="text-lightGray font-medium">
                            &nbsp;
                            {dayjs(data.startTime).format("h:mm A")}-
                            {dayjs(data.endTime).format("h:mm A")}
                          </p>
                        </div>
                      ) : (
                          <div className="flex" key={index}>
                            <p className="">{data.day}: </p>
                            <p className="text-lightGray font-medium">
                              &nbsp;
                              No volunteering hours
                            </p>
                          </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
