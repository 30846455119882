import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as notificationsApi from '../../api/notifications'
import get_current_user from '../../aws_cognito/get_user'

const getNotifications = createAsyncThunk('notifications/get', async ({ params, userType }) => {
    try {
        const user = await get_current_user(userType)
        const token = user.data.idToken

        const res = await notificationsApi.fetch(params, token)
        return res.data
    }

    catch (error) {
        throw error
    }
})

const getUnreadCount = createAsyncThunk('notifications/getUnreadCount', async ({ userType }) => {
    try {
        const user = await get_current_user(userType)
        const token = user.data.idToken

        const res = await notificationsApi.fetchUnreadCount(token)
        return res.data
    }

    catch (error) {
        throw error
    }
})

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        show: false,
        notifications: [],
        loading: false,
        initial: true,
        unreadCount: null,
        end: false
    },
    reducers: {
        showNotifications: (state, action) => {
            state.show = true 
        },

        hideNotifications: (state, action) => {
            state.show = false 
        }
    },
    extraReducers: {
        [getNotifications.pending]: (state, action) => {
            state.loading = true
        },

        [getNotifications.fulfilled]: (state, action) => {
            state.loading = false 
            state.initial = false
            state.unreadCount = 0
            state.notifications = [...state.notifications, ...action.payload]

            if (action.payload.length < process.env.REACT_APP_NOTIFICATIONS_FETCH_LIMIT) {
                state.end = true
            }
        },

        [getNotifications.rejected]: (state, action) => {
            state.loading = false
        },

        [getUnreadCount.pending]: (state, action) => {
        
        },

        [getUnreadCount.fulfilled]: (state, action) => {
            state.unreadCount = action.payload
        },

        [getUnreadCount.rejected]: (state, action) => {

        }
    }
})

export const { showNotifications, hideNotifications } = notificationsSlice.actions
export { getNotifications, getUnreadCount }
export default notificationsSlice.reducer