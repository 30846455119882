import React from 'react';
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useMediaQuery } from "react-responsive";
import OpportunitiesList from "./OpportunitiesList.js";

export default function Opportunities({ type }) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    return (
        <>
        {isMobile ? (
            <>
            <div className="flex flex-row w-full">
                <Sidebar pageName="Opportunities" userType={type} />
            </div>
            <div className="w-full h-full flex flex-col bg-[#F4F8FB] overflow-x-hidden">
                <div className="pt-16">
                <OpportunitiesList typeUser={type} />
                </div>
            </div>
            </>
        ) : (
            <div className="flex">
                <div className="height-screen">
                    <Sidebar pageName="Opportunities" userType={type} />
                </div>
                <div className="w-full h-screen overflow-scroll flex flex-col bg-[#F4F8FB]">
                    <OpportunitiesList typeUser={type} />
                </div>
            </div>
        )}
        </>
    );
}