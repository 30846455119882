import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import get_current_user from '../../aws_cognito/get_user'
import * as postsApi from '../../api/posts'

const initialState = {
    opportunityActiveTab:1,
    loading: false,
    opportunityPosts: [],
    createdOpportunityPosts:[],
    registeredOpportunityPosts:[],
    opportunityStatuses: {},
    error: null,
    end: false,
    cleared: true
}
const fetchOpportunities = createAsyncThunk('opportunities/fetch', async ({ params, userType}) => {
    const user = await get_current_user(userType)
    try {
        const res = await postsApi.getAllOpportunities(params, user.data.idToken)
        return res.data
    }
    catch (error) {
        throw error
    }
})
const fetchCreatedOpportunities=createAsyncThunk('opportunities/fetchCreated', async ({ params, userType}) => {
    const user = await get_current_user(userType)
    try {
        const res = await postsApi.getCreatedOpportunities(params, user.data.idToken)
        return res.data
    }
    catch (error) {
        throw error
    }
})
const fetchRegisteredOpportunities=createAsyncThunk('opportunities/fetchRegistered', async ({ params, userType}) => {
    const user = await get_current_user(userType)
    try {
        const res = await postsApi.getRegisteredOpportunities(params, user.data.idToken)
        return res.data
    }
    catch (error) {
        throw error
    }
})
const opportunitiesSlice=createSlice({
    name: 'opportunities',
    initialState,
    reducers: {
        clearOpportunities: (state, action) => {
            state.opportunityPosts = []
            state.createdOpportunityPosts = []
            state.registeredOpportunityPosts=[]
            state.opportunityStatuses = {}
            state.end = false
            state.cleared = true
        },
        setOpportunityActiveTab:(state,action)=>{
            state.opportunityActiveTab=action.payload
        }
    },
    extraReducers: {
        [fetchOpportunities.pending]: (state, { meta }) => {
            state.loading = true 
        },
        [fetchOpportunities.fulfilled]: (state, { payload, meta}) => {
            if (meta.arg.clear) {
                state.opportunityPosts = []
                state.createdOpportunityPosts = []
                state.registeredOpportunityPosts=[]
                state.opportunityStatuses = {}
                state.end = false
            }

            state.loading = false 
            state.opportunityPosts = [...state.opportunityPosts, ...payload]
            state.createdOpportunityPosts = []
            state.registeredOpportunityPosts=[]
            state.cleared = false

            state.end = payload.length==0?true:payload.length

            payload.forEach(post => {
                state.opportunityStatuses[post._id] = {}
            })
        },
        [fetchOpportunities.rejected]: (state, { error }) => {
            state.loading = false 
            state.error = error.message
        },


        
        [fetchCreatedOpportunities.pending]: (state, { meta }) => {
            state.loading = true 
        },
        [fetchCreatedOpportunities.fulfilled]: (state, { payload, meta}) => {
            if (meta.arg.clear) {
                state.opportunityPosts = []
                state.createdOpportunityPosts = []
                state.registeredOpportunityPosts=[]
                state.opportunityStatuses = {}
                state.end = false
            }

            state.loading = false 
            state.opportunityPosts = []
            state.registeredOpportunityPosts=[]
            state.createdOpportunityPosts = [...state.createdOpportunityPosts, ...payload]
            state.cleared = false

            state.end = payload.length==0?true:payload.length

            payload.forEach(post => {
                state.opportunityStatuses[post._id] = {}
            })
        },
        [fetchCreatedOpportunities.rejected]: (state, { error }) => {
            state.loading = false 
            state.error = error.message
        },

        [fetchRegisteredOpportunities.pending]: (state, { meta }) => {
            state.loading = true 
        },
        [fetchRegisteredOpportunities.fulfilled]: (state, { payload, meta}) => {
            if (meta.arg.clear) {
                state.opportunityPosts = []
                state.createdOpportunityPosts = []
                state.registeredOpportunityPosts=[]
                state.opportunityStatuses = {}
                state.end = false
            }

            state.loading = false 
            state.opportunityPosts = []
            state.registeredOpportunityPosts=[...state.registeredOpportunityPosts, ...payload]
            state.createdOpportunityPosts = []
            state.cleared = false

            state.end = payload.length==0?true:payload.length 

            payload.forEach(post => {
                state.opportunityStatuses[post._id] = {}
            })
        },
        [fetchRegisteredOpportunities.rejected]: (state, { error }) => {
            state.loading = false 
            state.error = error.message
        },
    }
})
export const {clearOpportunities,setOpportunityActiveTab}=opportunitiesSlice.actions
export { fetchOpportunities,fetchCreatedOpportunities ,fetchRegisteredOpportunities}

export default opportunitiesSlice.reducer