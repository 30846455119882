import React, { useEffect } from "react";
import { get } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { create_session } from "./aws_cognito/create_session";
import { get_token } from "./aws_cognito/get_token";
import get_current_user from "./aws_cognito/get_user";
import axios from "axios";
import logout from "./aws_cognito/logout";
import { refresh_tokens } from "./aws_cognito/refresh_tokens";
function Test() {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("code") !== null) {
      const fetchToken = async () => {
        const code = searchParams.get("code");
        // pass the type of user as well
        // so that we can redirect them to the correct page
        const res = await get_token(code, "volunteer");
        if (res !== null) {
          console.log("aftercall ", res);
          const sess = await create_session(
            res?.data.id_token,
            res?.data.access_token,
            res?.data.refresh_token
          );
          console.log(sess);
        }
      };
      fetchToken();
    }
  }, []);

  return (
    <>
      <button
        style={{ background: "red", color: "white" }}
        onClick={async () => {
          try {
            console.log("running");
            // const res = await get_token("6284c57e-4976-4621-8834-55194c1c0a89");
            const user = await get_current_user();
            // const ref = refresh_tokens();
            // logout();
            // // const res = await get_token()
            // console.log('user reslt', user.data.idToken)
            // const res = await axios.get('http://localhost:5000/', {
            //     headers: {
            //         // 'application/json' is the modern content-type for JSON, but some
            //         // older servers may use 'text/json'.
            //         // See: http://bit.ly/text-json,
            //         'Authorization': `Bearer ${user.data.idToken}`,
            //         'access': user.data.accessToken,
            //         'content-type': 'application/json'
            //     }
            // });
            // console.log(res)
            // const res = await forgotPassword("lidoc20499@5k2u.com");
            // const reset = await confirmPassword("lidoc20499@5k2u.com","169189","HelloWorld@1412")                console.info(reset);
            // logout();
          } catch (error) {
            console.log(error);
          }
        }}
      >
        click mee
      </button>
    </>
  );
}

export default Test;
