import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ViewAll from "./ViewAll";

export default function Effort({ type }) {
  const [showModal, setShowModal] = useState(false);
  const profile = useSelector((state) => state[type][type]);

  return (
    <>
      <div className="bg-white md:mt-5 rounded-2xl pt-3 py-3 md:px-5 shadow-md w-full">
        <ViewAll
          type={type}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <div className="flex-col space-y-5">
          <div className="flex justify-center md:justify-between text-base">
            <h1 className="text-center font-bold text-lightGray md:text-black">
              Hours of Effort
            </h1>
            <button
              className="hidden md:flex text-[#919191] font-semibold"
              onClick={() => setShowModal(true)}
            >
              View All
            </button>
          </div>

          <div className="">
            <p className="text-center md:text-[#919191]">
              <span className="text-xl md:text-4xl font-bold text-black">
                {
                type === "NGOProfile"
                  ? profile?.creditData?.totalHours
                  : profile?.data?.hoursVolunteered
                }
                {
                type === "corpProfile" 
                  ? profile?.data?.employeeHoursRecieved
                  : ''
                }
              </span>
              &nbsp; hours
            </p>
          </div>
          {profile?.creditData?.length !== 0 ? (
            <div className="text-[10px] md:text-sm md:px-0 px-3 space-y-3 md:space-y-5 flex justify-center flex-col">
              {type === "corpProfile" || type === "NGOProfile"
                ? profile?.creditData?.causes?.slice(0, 3).map((data, index) => {
                    return (
                      <div
                        className="flex  bg-[#D4EDFE] text-blue justify-between py-1 md:py-3 md:text-base px-2 md:px-5 rounded-xl"
                        key={index}
                      >
                        <p>{data.cause}</p>
                        <p className="font-medium">{data.hours} hrs</p>
                      </div>
                    );
                  })
                : profile?.creditData?.slice(0, 3).map((data, index) => {
                    return (
                      <div
                        className="flex  bg-[#D4EDFE] text-blue justify-between py-1 md:py-3 md:text-base px-2 md:px-5 rounded-xl"
                        key={index}
                      >
                        <p>{data.cause}</p>
                        <p className="font-medium">{data.hours} hrs</p>
                      </div>
                    );
                  })}
            </div>
          ) : null}
        </div>
        {profile?.creditData?.length !== 0 ? (
          <p
            className="md:hidden mt-2 block text-center font-semibold text-sm text-blue"
            onClick={() => setShowModal(true)}
          >
            view all
          </p>
        ) : null}
      </div>
    </>
  );
}
