import React from 'react';
import LoginComponent from '../../../components/Login/Login';
import adminBg from '../../../images/admin-bg.png';
import logo from '../../../images/Logo.png';
export default function Login() {
  return (
    <>
      <div className='justify-center md:h-screen md:flex bg-img'>
        <img
          src={adminBg}
          alt='volunteer'
          className='w-full h-[250px] md:hidden'
        />
        <div className='z-10 flex justify-center -mt-20 bg-white md:hidden '>
          <img
            src={logo}
            alt='logo'
            className='rounded-[50%] w-40 bg-white p-5 my-5'
          />
        </div>
        <LoginComponent type='admin' user='admin' />
      </div>
    </>
  );
}
