import categories from './categories'
import moment from 'moment'

export const formatDate = (date) => {
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    const dateObj = new Date(date)
    return `at ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')} ${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

export const formatDateWithoutTime = (date) => {
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    const dateObj = new Date(date)
    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

export const roleColorDark = {
    admin: '#e32b4a',
    corporate: '#4257fc',
    organization: '#3ddb5f',
    volunteer: '#f7b257'
}

export const generateCategoryColors = () => {
    const categoryColors = {}

    for (let category of categories) {
        const r = Math.random() * 105 + 150
        const g = Math.random() * 105 + 150
        const b = Math.random() * 105 + 150
    
        categoryColors[category] = {}
        categoryColors[category].dark = `rgba(${r * 0.57}, ${g * 0.57}, ${b * 0.57})`
        categoryColors[category].light = `rgba(${r}, ${g}, ${b})`
    }

    console.log(categoryColors)
}

export const getTypePath = (type) => {
    switch(type) {
        case 'volunteer': 
            return 'volunteer'
        case 'admin':
            return 'admin'
        case 'corporate':
            return 'corporate'
        case 'organization':
            return 'impactOrg'
    }
}

export const timestampOf = (date) => {
    date = new Date(date) 

    const days = moment().diff(date, 'day')

    if (days < 1) {
        return moment(date).format('HH:mm A')
    }

    else if (days < 2) {
        return 'Yesterday, ' + moment(date).format('HH:mm A')
    }

    else {
        return moment(date).format('DD/MM/YYYY')
    }
}

export const compareDates = (date1, date2) => {
    return new Date(date1).getTime() - new Date(date2).getTime()
}