export default {
    TOP_10_RANK: 'top_10_rank',
    TOP_3_RANK: 'top_3_rank',
    CREDIT_REQUEST_UPDATED: 'credit_request_updated',
    CREDIT_REQUEST_RECEIVED: 'credit_request_received',
    CREDIT_REQUEST_APPROVED: 'credit_request_approved',
    CREDIT_REQUEST_REJECTED: 'credit_request_rejected',
    USER_INTERESTED: 'user_interested',
    USER_COMMENTED: 'user_commented',
    USER_LIKED: 'user_liked',
    USER_PARTICIPATED: 'user_participated',
    WORK_REQUEST_RECEIVED: 'work_request_received',
    WORK_REQUEST_ACCEPTED: 'work_request_accepted',
    WORK_REQUEST_REJECTED: 'work_request_rejected',
    BLOOD_REQUIREMENT: 'blood_requirement',
    OPPORTUNITY: 'opportunity'
}