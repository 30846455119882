import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const HoursVolunteering = ({ modalOpen, data }) => {
  return (
    <div className="relative z-40 w-full h-screen overflow-hidden overflow-y-auto">
      <div className="bg-[#F4F8FB] h-screen p-4 w-full absolute xl:right-0">
        <div className="flex items-center gap-4 ">
          <button onClick={() => modalOpen(false)}>
            <MdOutlineKeyboardBackspace size={35} />
          </button>
          <p className="text-lg font-bold">Hours of Volunteering</p>
        </div>
        <div className="flex flex-col items-center my-12">
          <p>Total hours</p>
          <strong className="text-4xl ">{data?.totalHours}</strong>
        </div>
        <div className="flex flex-col gap-8 mt-20 max-w-[20rem] mx-auto">
          {data &&
            Object.keys(data).map((key) => {
              return (
                <div
                  className="flex items-center justify-between gap-6"
                  key={key}
                >
                  <p>{key}</p>
                  <p className="font-semibold min-w-[3rem]">{data[key]} hrs</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HoursVolunteering;
