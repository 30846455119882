import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import cog_pool from "./user_pool";

const login = (email, password, type) => {
  const pool = cog_pool(type);
  return new Promise((resolve, reject) => {
    let cognitoUser = new CognitoUser({
      Username: email,
      Pool: pool,
    });

    let authDetails = new AuthenticationDetails({
      Username: email.toLowerCase(),
      Password: password,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve({ data: data });
        console.log(data, "in login");
      },
      onFailure: (error) => {
        if (error.toString().endsWith('UserNotVerified.')) {
          return reject({ code: 'UserNotVerified' })
        }

        reject({ code: error.code, message: error.message });
      },
      newPasswordRequired: (data) => {
        console.log("new password required", data);
      },
    });
  });
};

export default login;
