import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import { useParams } from "react-router-dom";
import logout from "../../aws_cognito/logout";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (state) => {
    try {
      const user = await get_current_user(state.type);
      console.log(user.data);
      const res =
        state.type === "admin"
          ? await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/admin/volunteer/${
                state.me === true ? user.data.db_id : state.id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${user.data.idToken}`,
                  "content-type": "application/json",
                },
              }
            )
          : await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/volunteer/${
                state.me === true ? user.data.db_id : state.id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${user.data.idToken}`,
                  "content-type": "application/json",
                },
              }
            );
      console.log(res.data);

      const data = await res.data;
      const credit = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/credits/volunteer/${
          state.me === true ? user.data.db_id : state.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      const result = await credit.data;
      return {
        ...data,
        user_id: user.data.db_id,
        loggedInType: user.data.type,
        creditData: result.data,
      };
    } catch (error) {
      // console.error(error);
      if(error.response.data.data==='verification-pending'){ // warning: don't change the text
        alert(error.response.data.message);
        logout("volunteer", false)
      }
      else window.location.href='../volunteer/profileSetup'
    }
  }
);

const fetchProfile = createSlice({
  name: "userProfile",
  initialState: {
    userProfile: {},
    loading: false,
  },

  extraReducers: {
    [getProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.userProfile = action.payload;
    },
    [getProfile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default fetchProfile.reducer;
