import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios';
import gsap from 'gsap'
import { useParams } from "react-router-dom";
import get_current_user from '../../aws_cognito/get_user'
import Post from '../../components/CommunityWall/Post/Post'
import BloodRequirementPost from '../../components/CommunityWall/BloodRequirementPost/BloodRequirementPost'
import OpportunityCard from '../../pages/Volunteers/Opportunities/OpportunitiesCard'
import CommunityWallContext from '../CommunityWall/CommunityWallContext';
import { postTypeEnum } from '../../utils/enum'
import { fetchPosts,clearPosts } from '../../redux/slices/postsSlice'
import { useDispatch } from 'react-redux'

const CompanyPosts = ({ typeUser, profile }) => {
    const { id } = useParams();
    const notify = (message) => {
    const ele = document.querySelector('#notify')
    ele.innerHTML = message
    
    gsap.killTweensOf(ele)
    const t = gsap.timeline()
    
    t.to(ele, { bottom: 20 })
        .to(ele, { bottom: -100, delay: 3 })
    }
    let controller = useRef(new AbortController())
    const postsState = useSelector((state) => state.posts)
    const postStatuses = postsState.postStatuses;
    const dispatch = useDispatch()

    useEffect(() => {
        const params = {
            limit: process.env.REACT_APP_POSTS_FETCH_LIMIT,
            userid: profile._id
        }
        dispatch(clearPosts());
        dispatch(fetchPosts({ params, userType: typeUser, controller }))
    }, [])

    return (
        <CommunityWallContext.Provider value={{ notify }}>
            <>
            {postsState.posts.length==0?<div className="my-2 text-center">No Posts available</div>:(postsState.posts.map(x => {
                switch (x.type) {
                case postTypeEnum.GENERAL:
                    return <Post key={x._id} data={x} status={postStatuses[x._id]} />
                case postTypeEnum.BLOOD_REQUIREMENT:
                    return <BloodRequirementPost key={x._id} data={x} status={postStatuses[x._id]} />
                
                // Placeholder for opportunity posts
                case postTypeEnum.OPPORTUNITY:
                    return <OpportunityCard key={x._id} type={typeUser} opportunityData={x}/>
                }
            }))
        }
            <div id='notify' className='fixed right-2 bottom-[-100px]'></div>
            </>
        </CommunityWallContext.Provider>
    )
}

export default CompanyPosts