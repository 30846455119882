import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as postsApi from '../../api/posts'
import { incComments } from '../../redux/slices/postsSlice'
import get_current_user from '../../aws_cognito/get_user'
import defaultAvatar from '../../images/default.jpg'

export default function PostCommentInput({ postId, parentId, pushComment, closable, close, defaultFieldValue }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const profilePic = userProfile?.data?.profilePic
    const dispatch = useDispatch()
    const [comment, setComment] = useState(defaultFieldValue ?? '')
    const type = userProfile?.data?.type

    const send = async () => {
        if (comment.trim() == '') {
            return 
        }

        setComment('')

        const user = await get_current_user(type)
        const res = await postsApi.createComment(comment, postId, parentId, user.data.idToken)

        if (!parentId) {
            dispatch(incComments({ postId }))
        }

        pushComment(res.data)
    }

    const onKeyDown = async (e) => {
        if (e.keyCode ===13) {
            send()
        }
    }

    return (
        <div className='flex mx-2 mt-1'>
            <img className='rounded-full w-10 h-10' src={(loading || !profilePic || profilePic.trim() == '') ? defaultAvatar : profilePic} />

            <input type='text' 
                rows={1}
                maxLength={2000} 
                className='post-comment-input scrollable ml-1 bg-[#F4F4F4] px-4 py-[0.6rem] text-[0.8rem] w-full outline-none rounded-[20px] pr-10' 
                placeholder='Put a comment...'
                onKeyDown={onKeyDown}
                onChange={(e) => setComment(e.target.value)} value={comment} />
            
            <div className='relative right-[2rem] flex'>
                <button onClick={send}>
                    <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L277.3 424.9l-40.1 74.5c-5.2 9.7-16.3 14.6-27 11.9S192 499 192 488V392c0-5.3 1.8-10.5 5.1-14.7L362.4 164.7c2.5-7.1-6.5-14.3-13-8.4L170.4 318.2l-32 28.9 0 0c-9.2 8.3-22.3 10.6-33.8 5.8l-85-35.4C8.4 312.8 .8 302.2 .1 290s5.5-23.7 16.1-29.8l448-256c10.7-6.1 23.9-5.5 34 1.4z"/>
                    </svg>
                </button>

                { closable && 
                    <svg className='w-4 h-4 ml-5 cursor-pointer self-center' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={close}>
                        <path fill='#555' d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                    </svg>
                }
            </div>
        </div>
    )
}