import { io } from 'socket.io-client'

class Socket {
    constructor(url) {
        this.instance = io(url, {
            autoConnect: false,
            auth: {},
            withCredentials: true
        })
    }

    connect(token) {
        return new Promise((resolve, reject) => {
            this.instance.auth.token = token
            this.instance.connect()

            resolve()
        })
    }

    emit(event, data) {
        return new Promise((resolve, reject) => 
            this.instance.emit(event, data, (response, error) => {
                if (error) {
                    return reject(error)
                }
                
                resolve(response)
            })
        )
    }

    on(event, fn) {
        return new Promise((resolve, reject) => {
            this.instance.on(event, fn)
            resolve()
        })
    }
}

export default Socket