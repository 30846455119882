import React, { useState, useRef, useCallback } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  experienceOptions,
  opportunityModeOptions,
  causesOptions,
} from "../../../components/Profile/data";
import {
  languagesOptions,
  locationOptions,
} from "../../../components/Preferences/data/data.js";
import "./OpportunityModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOpportunities,
  fetchCreatedOpportunities,
} from "../../../redux/slices/opportunitiesSlice";
import { fetchPosts, clearPosts } from "../../../redux/slices/postsSlice";
import get_current_user from "../../../aws_cognito/get_user";
import * as postsApi from "../../../api/posts";
import get_skills_token from "../../../api/get_skills_token";
import { ReactTags } from "react-tag-autocomplete";
const OpportunityModal = ({
  setOpportunityModalOpen,
  typeUser,
  opportunityType,
  setNonCorpModalOpen,
}) => {
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const oppState = useSelector((state) => state.opportunities);
  const currProfile = useSelector((state) => state.currentProfile);
  const oppActiveTab = oppState.opportunityActiveTab;
  const oppPostingRef = useRef(false);
  const pathName = window.location.pathname;
  const [oppPosting, setOppPosting] = useState(false);
  let controller = useRef(new AbortController());
  const [imageUpload, setImageUpload] = useState(null);
  const [experienceCertificateIP, setExperienceCertificateIP] = useState(false);
  const [impactReport, setImpactReport] = useState(false);
  const [requirementsIP, setRequirementsIP] = useState(false);
  const [details, setDetails] = useState({
    title: "",
    role: "",
    sector: "",
    startDate: "",
    completionDate: "",
    applyBy: "",
    location: "",
    email: "",
    contactNumber: "",
    website: "",
    coordinatorName: "",
    volunteersRequired: "",
    minimumHours: "",
    mode: "",
    experienceRequired: "",
    stipend: "",
    description: "",
    preferedTraits: "",
    hasOrientation: false,
    hasTravellingAllowances: false,
  });

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setwebsiteError] = useState("");
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "email") {
      const emailRegex = RegExp(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      );
      if (value.length == 0) {
        setEmailError("");
      } else if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email.");
      } else {
        setEmailError("");
      }
    } else if (name == "contactNumber") {
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      if (value.length < 10 && value.length > 0)
        setPhoneError("Enter valid contact number");
      else setPhoneError("");
    } else if (name == "website") {
      const websiteLinkRegex = RegExp(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
      );
      if (value.length == 0) {
        setwebsiteError("");
      } else if (!websiteLinkRegex.test(value)) {
        setwebsiteError("Please enter a valid website URL.");
      } else {
        setwebsiteError("");
      }
    }
    setDetails({
      ...details,
      [name]: value,
    });
  };
  const handleImpactChange = (e) => {
    if (e.target.value === "yes") {
      setImpactReport(true);
    } else {
      setImpactReport(false);
    }
  };
  const handleExperienceCertfChange = (e) => {
    if (e.target.value === "yes") {
      setExperienceCertificateIP(true);
    } else {
      setExperienceCertificateIP(false);
    }
  };
  const handleRequirementsChange = (e) => {
    if (e.target.value === "yes") {
      setRequirementsIP(true);
    } else {
      setRequirementsIP(false);
    }
  };
  const handleFileChange = (e) => {
    setImageUpload(e.target.files[0]);
  };
  const [skillsIp, setSkillsIp] = useState({
    isBusy: false,
    selected: [],
    suggestions: [],
  });
  const [preferedLanguages, setPreferedLanguages] = useState([]);
  const onAddSkills = useCallback(
    (newTag) => {
      if (skillsIp.selected.length < 5) {
        setSkillsIp({
          ...skillsIp,
          selected: [...skillsIp.selected, newTag],
          suggestions: [],
        });
        //console.log(skillsIp.selected)
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [skillsIp]
  );

  const OnDeleteSkills = useCallback(
    (tagIndex) => {
      setSkillsIp({
        ...skillsIp,
        selected: skillsIp.selected.filter((_, i) => i !== tagIndex),
      });
    },
    [skillsIp]
  );
  function debounce(fn, delay = 100) {
    let timeoutID;

    return function (...args) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }

  function wait(delay = 100) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }
  async function fetchData(value) {
    if (value.length < 3) {
      return;
    }
    setSkillsIp({ ...skillsIp, suggestions: [], isBusy: true });
    try {
      const query = encodeURIComponent(value);
      await wait();

      const token = await get_skills_token();

      const response = await fetch(
        `https://emsiservices.com/skills/versions/latest/skills?q=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // if (response.ok) {
      const json = await response.json();

      return json.data.map((item, index) => ({
        value: Math.random(),
        label: item.name,
      }));
      // } else {
      //   throw Error(`The API returned a ${response.status}`);
      // }
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  const onInput = useCallback(
    debounce(async (value) => {
      if (skillsIp.isBusy) return;

      try {
        const suggestions = await fetchData(value);

        if (suggestions) {
          setSkillsIp({ ...skillsIp, suggestions, isBusy: false });
        }
      } catch (error) {
        console.error(error);
        setSkillsIp({ isBusy: false });
      }
    }),
    [skillsIp]
  );
  function isValid(value) {
    return /^[a-z]{4,20}$/i.test(value);
  }
  const onValidate = useCallback((value) => isValid(value), []);

  const onAddedLanguages = useCallback(
    (newTag) => {
      setPreferedLanguages([...preferedLanguages, newTag]);
    },
    [preferedLanguages]
  );

  const onDeleteLanguages = useCallback(
    (tagIndex) => {
      setPreferedLanguages(preferedLanguages.filter((_, i) => i !== tagIndex));
    },
    [preferedLanguages]
  );
  const contactNumberRegex = RegExp(/^(\+\d{1,3})?\d{10}$/);
  const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  const websiteLinkRegex = RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  );
  var generalForm = !details.stipend || !experienceCertificateIP;
  var corpForm = !impactReport || !requirementsIP;
  const isDisabled = (x) =>
    !details.startDate ||
    !details.applyBy ||
    !details.completionDate ||
    details.startDate > details.completionDate ||
    details.applyBy > details.completionDate ||
    !details.title ||
    !details.role ||
    !details.sector ||
    !details.location ||
    !emailRegex.test(details.email) ||
    !contactNumberRegex.test(x.contactNumber) ||
    !websiteLinkRegex.test("https://" + details.website) ||
    !details.coordinatorName ||
    !details.volunteersRequired ||
    !details.minimumHours ||
    !details.mode ||
    !details.experienceRequired ||
    skillsIp.selected.length == 0 ||
    preferedLanguages.length == 0 ||
    !details.preferedTraits ||
    !details.description ||
    details.minimumHours > 24;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setOppPosting(true);

    if (oppPostingRef.current) return;

    oppPostingRef.current = true;
    try {
      const formData = new FormData();
      for (let key in details) {
        formData.append(key, details[key]);
      }
      formData.append("skills", JSON.stringify(skillsIp.selected));
      formData.append("preferedLanguages", JSON.stringify(preferedLanguages));
      if (imageUpload != null) {
        formData.append("picture", imageUpload);
      }
      console.log(typeof skillsIp.selected);
      console.log(typeof skillsIp.suggestions);
      const user = await get_current_user(typeUser);
      if (
        (currProfile?.userProfile?.data?.type === "volunteer" ||
          currProfile?.userProfile?.data?.type == "admin" ||
          currProfile?.userProfile?.data?.type == "organization") &&
        opportunityType == "corpEmpEng"
      ) {
        formData.delete("stipend");
        formData.append("hasImpactReport", impactReport);
        formData.append("hasRequirements", requirementsIP);
        const res = await postsApi.createCorporateOpportunity(
          formData,
          user.data.idToken
        );
        const post = res.data;
        setDetails({
          title: "",
          role: "",
          sector: "",
          startDate: "",
          completionDate: "",
          applyBy: "",
          location: "",
          email: "",
          contactNumber: "",
          website: "",
          coordinatorName: "",
          volunteersRequired: "",
          minimumHours: "",
          mode: "",
          experienceRequired: "",
          stipend: "",
          description: "",
          preferedTraits: "",
          hasOrientation: false,
          hasTravellingAllowances: false,
        });
        window.location.reload();
        setPreferedLanguages([]);
        setImageUpload(null);
        setOpportunityModalOpen(false);
        setNonCorpModalOpen(false);
        setSubmitLoading(false);
      } else {
        formData.append("hasExperienceCertificate", experienceCertificateIP);
        const res = await postsApi.createVolunteeringOpportunity(
          formData,
          user.data.idToken
        );
        const post = res.data;
        console.log(post);
        setDetails({
          title: "",
          role: "",
          sector: "",
          startDate: "",
          completionDate: "",
          applyBy: "",
          location: "",
          email: "",
          contactNumber: "",
          website: "",
          coordinatorName: "",
          volunteersRequired: "",
          minimumHours: "",
          mode: "",
          experienceRequired: "",
          stipend: "",
          description: "",
          preferedTraits: "",
          hasOrientation: false,
          hasTravellingAllowances: false,
        });
        window.location.reload();
        setPreferedLanguages([]);
        setImageUpload(null);
        setOpportunityModalOpen(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      alert(error.response.data.message);
      console.log(error);
      setSubmitLoading(false);
    } finally {
      oppPostingRef.current = false;
      setOppPosting(false);
    }
  };
  const formRef = useRef();
  const handleClose = (e) => {
    setOpportunityModalOpen(false);
  };
  return (
    <div className="opportunityModalBackground">
      <form
        ref={formRef}
        className="opportunityModalContainer"
        action=""
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="form-header">
          <div className="form-title">
            {typeUser == "corporate" && <h3>Create new opportunity</h3>}
            {typeUser != "corporate" && opportunityType == "corpEmpEng" && (
              <h3>Corporate Employee Engagement </h3>
            )}
            {typeUser != "corporate" && opportunityType == "genVolOpp" && (
              <h3>General Volunteering Opportunity </h3>
            )}
          </div>
          <div className="form-closed">
            <button onClick={handleClose}>
              <GrFormClose />
            </button>
          </div>
        </div>

        <div className="form-flex-display">
          <div className="form-left-display">
            <div>
              <label htmlFor="title">
                Title<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <input
                required
                className="input-field"
                type="text"
                autoComplete="off"
                onChange={handleInput}
                value={details.title}
                name="title"
                id="title"
                placeholder="Give a Title to your act"
              />
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="role">
                  Role<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.role}
                  name="role"
                  id="role"
                  placeholder="Role you're looking for"
                />
              </div>
              <div className="half-portion">
                <label htmlFor="sector">
                  Sector<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <select
                  id="sector"
                  className="input-field"
                  name="sector"
                  onChange={handleInput}
                  value={details.sector}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {causesOptions.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="startDate">
                  Start Date<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.startDate}
                  name="startDate"
                  id="startDate"
                  min={new Date().toISOString().substring(0, 10)}
                />
              </div>
              <div className="half-portion">
                <label htmlFor="completionDate">
                  Completion Date<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.completionDate}
                  name="completionDate"
                  id="completionDate"
                  min={new Date().toISOString().substring(0, 10)}
                />
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="applyBy">
                  Last date to apply<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.applyBy}
                  min={new Date().toISOString().substring(0, 10)}
                  name="applyBy"
                  id="applyBy"
                />
              </div>
              <div className="half-portion">
                <label htmlFor="location">
                  Location<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <select
                  id="location"
                  className="input-field"
                  name="location"
                  onChange={handleInput}
                  value={details.location}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {locationOptions.map((data, index) => {
                    return (
                      <option value={data.label} key={index}>
                        {data.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="email">
                  Email<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="email"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.email}
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                />
                {emailError && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {emailError}
                  </div>
                )}
              </div>
              <div className="half-portion">
                <label htmlFor="contactNumber">
                  Contact Number<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.contactNumber}
                  name="contactNumber"
                  id="contactNumber"
                  minLength={10}
                  maxlength={10}
                  placeholder="Enter your contact"
                />
                {phoneError && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {phoneError}
                  </div>
                )}
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="website">
                  Website<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="url"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.website}
                  name="website"
                  id="website"
                  placeholder="Enter your Website url"
                />
                {websiteError && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {websiteError}
                  </div>
                )}
              </div>
              <div className="half-portion">
                <label htmlFor="coordinatorName">
                  Coordinator Name<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.coordinatorName}
                  name="coordinatorName"
                  id="coordinatorName"
                  placeholder="Coordinator's name here"
                />
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="volunteersRequired">
                  No of volunteers required
                  <span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.volunteersRequired}
                  name="volunteersRequired"
                  id="volunteersRequired"
                  placeholder="Give the required no."
                />
              </div>
              <div className="half-portion">
                <label htmlFor="mode">
                  Mode<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <select
                  id="mode"
                  className="input-field"
                  name="mode"
                  onChange={handleInput}
                  value={details.mode}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {opportunityModeOptions.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="minimumHours">
                  Min hours required<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.minimumHours}
                  name="minimumHours"
                  id="minimumHours"
                  placeholder="No. of hours you need"
                />
              </div>
              <div className="half-portion">
                <label htmlFor="experienceRequired">
                  Experience Required<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <select
                  id="experienceRequired"
                  className="input-field"
                  name="experienceRequired"
                  onChange={handleInput}
                  value={details.experienceRequired}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {experienceOptions.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="skills">
                Skills<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <ReactTags
                allowNew={true}
                ariaDescribedBy="description-4"
                id="input-field-skills"
                noOptionsText={
                  skillsIp.isBusy === true ? "Loading" : "No skills found"
                }
                onAdd={onAddSkills}
                onDelete={OnDeleteSkills}
                onValidate={onValidate}
                onInput={onInput}
                placeholderText={
                  skillsIp.selected.length === 0
                    ? "Enter the skills you require"
                    : ""
                }
                selected={skillsIp.selected}
                suggestions={skillsIp.suggestions}
              />
            </div>
            {opportunityType != "corpEmpEng" && (
              <div>
                <label htmlFor="stipend">
                  Stipend<span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <input
                  required
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  onChange={handleInput}
                  value={details.stipend}
                  name="stipend"
                  id="stipend"
                  placeholder="Stipend you're offering"
                />
              </div>
            )}
          </div>
          <div className="form-right-display">
            <div>
              <label htmlFor="preferedLanguages">
                Preferred Languages<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <ReactTags
                placeholderText={
                  preferedLanguages.length === 0 ? "Add a language" : ""
                }
                id="input-field-skills"
                selected={preferedLanguages}
                suggestions={languagesOptions}
                onAdd={onAddedLanguages}
                onDelete={onDeleteLanguages}
                noOptionsText="No matching languages"
              />
            </div>

            <div>
              <label htmlFor="description">
                Description<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <textarea
                className="input-field"
                autoComplete="off"
                onChange={handleInput}
                value={details.description}
                name="description"
                id="description"
                rows="5"
                placeholder="Add some description about the act"
              ></textarea>
            </div>

            <div>
              <label htmlFor="preferedTraits">
                Preferred Traits<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <textarea
                className="input-field"
                autoComplete="off"
                onChange={handleInput}
                value={details.preferedTraits}
                name="preferedTraits"
                id="preferedTraits"
                rows="5"
                placeholder="Add your preferred traits"
              ></textarea>
            </div>

            <div>
              <label htmlFor="picture">Upload image (optional)</label>
              <br />
              <input
                required
                className="input-field-file"
                type="file"
                autoComplete="off"
                onChange={handleFileChange}
                value={details.picture}
                name="picture"
                id="picture"
              />
            </div>
            {(typeUser == "volunteer" ||
              typeUser == "admin" ||
              typeUser == "organization") &&
              opportunityType == "corpEmpEng" && (
                <div>
                  <label htmlFor="hasImpactReport">
                    Impact Report<span className="text-[#ff0000]"> *</span>
                  </label>
                  <br />
                  <div className="hasImpactReport">
                    <div className="eCert">
                      <input
                        required
                        type="radio"
                        name="hasImpactReport"
                        value="yes"
                        onClick={handleImpactChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="eCert">
                      <input
                        required
                        type="radio"
                        defaultChecked
                        name="hasImpactReport"
                        value="no"
                        onClick={handleImpactChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
              )}
            {(typeUser == "corporate" || opportunityType == "genVolOpp") && (
              <div>
                <label htmlFor="hasExperienceCertificate">
                  Experience Certificate
                  <span className="text-[#ff0000]"> *</span>
                </label>
                <br />
                <div className="hasExperienceCertificate">
                  <div className="eCert">
                    <input
                      required
                      type="radio"
                      name="hasExperienceCertificate"
                      value="yes"
                      onClick={handleExperienceCertfChange}
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="eCert">
                    <input
                      required
                      defaultChecked
                      type="radio"
                      name="hasExperienceCertificate"
                      value="no"
                      onClick={handleExperienceCertfChange}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>
            )}
            <div>
              <label htmlFor="hasOrientation">
                Orientation/Training<span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <div className="hasOrientation">
                <div className="eCert">
                  <input
                    required
                    type="radio"
                    name="hasOrientation"
                    value="yes"
                    onClick={() =>
                      setDetails({ ...details, hasOrientation: true })
                    }
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="eCert">
                  <input
                    required
                    defaultChecked
                    type="radio"
                    name="hasOrientation"
                    value="no"
                    onClick={() =>
                      setDetails({ ...details, hasOrientation: false })
                    }
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="hasTravellingAllowances">
                Travelling and lodging allowances
                <span className="text-[#ff0000]"> *</span>
              </label>
              <br />
              <div className="hasTravellingAllowances">
                <div className="eCert">
                  <input
                    required
                    type="radio"
                    name="hasTravellingAllowances"
                    value="yes"
                    onClick={() =>
                      setDetails({ ...details, hasTravellingAllowances: true })
                    }
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="eCert">
                  <input
                    required
                    defaultChecked
                    type="radio"
                    name="hasTravellingAllowances"
                    value="no"
                    onClick={() =>
                      setDetails({ ...details, hasTravellingAllowances: false })
                    }
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>
            {(typeUser == "volunteer" ||
              typeUser == "admin" ||
              typeUser == "organization") &&
              opportunityType == "corpEmpEng" && (
                <div>
                  <label htmlFor="hasRequirements">
                    Resources/Requirements for the activity
                    <span className="text-[#ff0000]"> *</span>
                  </label>
                  <br />
                  <div className="hasRequirements">
                    <div className="eCert">
                      <input
                        required
                        type="radio"
                        name="hasRequirements"
                        value="yes"
                        onClick={handleRequirementsChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="eCert">
                      <input
                        required
                        defaultChecked
                        type="radio"
                        name="hasRequirements"
                        value="no"
                        onClick={handleRequirementsChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="submit-button">
          {submitLoading ? (
            <button
              disabled
              className="post-btn px-8 py-2 mb-3 font-bold text-white rounded-lg cursor-pointer bg-blue hover:bg-blue-500 md:py-3 md:mb-0 flex items-center"
            >
              <svg
                role="status"
                class="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Posting
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              type="submit"
              style={{ opacity: isDisabled(details) ? 0.5 : 1 }}
              disabled={isDisabled(details)}
              className="post-btn px-8 py-2 mb-3 font-bold text-white rounded-lg cursor-pointer bg-blue hover:bg-blue-500 md:py-3 md:mb-0 flex items-center"
            >
              Post
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default OpportunityModal;
