import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";

const Admin = () => {
  const location = useLocation()
  const path = location.pathname.split('/')
  const [pageName, setPageName] = useState("");
  return (
    <>
      <div className="bg-[#F4F8FB]  xl:flex">
        { (path[path.length-1] != 'community-wall' && path[path.length-1] != 'chat' && path[path.length-1] != 'opportunities') &&
          <div className="xl:w-full xl:max-w-[18rem]">
            <Sidebar pageName={pageName} userType="admin" />
          </div>
        }
        <Outlet context={[setPageName]} />
      </div>
    </>
  );
};

export default Admin;
