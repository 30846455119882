import React from 'react';

const Container = ({ children, className, bg }) => {
  return (
    <div
      className={`px-4 py-4 w-[100%] mx-auto bg-white ${bg ? `bg-[${bg}]` : ''} rounded-lg h-min ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
