import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  addObjectToArray,
  deleteObject,
  updateAvailability,
  updateChecked,
} from "../../redux/slices/profileSlice";

export default function AvailableTime({ day, index }) {
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const valueRef = useRef();

  const profile = useSelector((state) => state.profileForm);
  const startTime = profile.availabilityTime[index]?.startTime;
  const endTime = profile.availabilityTime[index]?.endTime;
  const toggled = profile.availabilityTime[index]?.checked;

  const handleChange = (name, date) => {
    const time = new Date(date).toISOString();
    console.log("TIME: ", time);

    const obj = {
      day,
      startTime: name == "startTime" ? time : startTime,
      endTime: name == "endTime" ? time : endTime,
      checked: toggled,
    };

    dispatch(updateAvailability({ id: index, obj }));
  };

  // useEffect(() => {
  //   if (checked === false && startTime === "" && endTime === "") {
  //     console.log(index);
  //     dispatch(deleteObject({ id: index, day: day }));
  //     setStartDate("");
  //     setEndDate("");
  //   }
  // }, [checked]);

  useEffect(() => {
    if (startDate !== "") {
      handleChange("startTime", startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== "") {
      handleChange("endTime", endDate);
    }
  }, [endDate]);

  // const handleAdd = useCallback(() => {
  //   console.log(checked);

  //   // setInputList([
  //   //   ...inputList,
  //   //   { designation: "", companyName: "", yearOfExp: "" },
  //   // ]);
  //   const obj = {
  //     day: day,
  //     startTime: startDate ? startDate : "",
  //     endTime: endDate ? endDate : "",
  //   };
  //   if (checked === false) {
  //     dispatch(addObjectToArray({ type: "availabilityTime", obj }));
  //   } else {
  //     dispatch(deleteObject({ id: index }));
  //   }
  // }, [checked, dispatch]);

  return (
    <>
      <div className="flex flex-col space-y-3">
        <div className="flex items-center md:gap-0">
          <label
            for="male"
            class="block w-10  text-xs md:text-sm font-normal text-black"
          >
            {day}
          </label>
          <div class="flex justify-center items-center w-full">
            <label for={day} class="flex items-center cursor-pointer">
              <div class="relative">
                <input
                  id={day}
                  type="checkbox"
                  defaultChecked={toggled}
                  class="sr-only"
                  onClick={(e) => {
                    let { id } = e.target;
                    // if (e.target.checked == undefined)
                    console.log("index", day);
                    console.log("checked or not :", e.target.checked);
                    if (e.target.checked == true) {
                      dispatch(
                        deleteObject({
                          id: index,
                          day: day,
                          checked: e.target.checked,
                        })
                      );
                    } else {
                      dispatch(
                        updateChecked({
                          id: index,
                          value: e.target.checked,
                          event: e,
                        })
                      );
                    }
                    setChecked(!checked);
                  }}
                />
                <div
                  class={
                    startTime && endTime != null
                      ? "dot-bg w-6 md:w-10 h-3 md:h-4 bg-gray-300 rounded-full shadow-inner"
                      : "w-8 md:w-10 h-3 md:h-4 bg-gray-300 rounded-full shadow-inner"
                  }
                ></div>
                <div class="dot absolute w-4 h-4 md:w-6 md:h-6 bg-lightGray rounded-full shadow md:-left-1 m,md:-top-1 -top-0.5 transition"></div>
              </div>
            </label>
          </div>
          <div className="flex gap-3">
            <ReactDatePicker
              className={toggled === true ? "bg-time" : null}
              selected={startTime ? new Date(startTime) : startDate}
              name="startTime"
              onChange={(date) => {
                setStartDate(date);
                // handleChange("startTime", date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              autoComplete="off"
              disabled={!toggled}
              dateFormat="h:mm aa"
            />
            <div>-</div>
            <ReactDatePicker
              className={toggled === true ? "bg-time" : null}
              selected={endTime ? new Date(endTime) : endDate}
              name="endTime"
              onChange={(date) => {
                setEndDate(date);
                // handleChange("endTime", date);
              }}
              showTimeSelect
              showTimeSelectOnly
              filterTime={(t) =>
                startDate && t.getTime() >= startDate.getTime()
              }
              timeIntervals={15}
              autoComplete="off"
              disabled={!toggled}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
        </div>
      </div>
    </>
  );
}
