import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";

const NGOslice = createSlice({
  name: "organisation",
  initialState: {
    name: "",
    // email: "",
    // profilePic: "",
    phone_number: "",
    mission: "",
    vision: "",
    coreValues: "",
    thematicAreas: [],
    causes: [],
    locations: [],
    website: "",
    foundedYear: "",
  },
  reducers: {
    updateCompanyDetails: (state, action) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },

    updateInitialState: (state, action) => {
      const { profile } = action.payload;
      for (const key in profile) if (key in state) state[key] = profile[key];

      return state;
    },

    updateArray: (state, action) => {
      const { type, newTag } = action.payload;
      console.log(type, newTag);
      state[type].push(newTag);
    },
    deleteArray: (state, action) => {
      const { type, tagIndex } = action.payload;
      console.log(tagIndex);
      state[type].splice(tagIndex, 1);
    },
  },
});

export default NGOslice.reducer;

export const {
  updateCompanyDetails,
  updateInitialState,
  updateArray,
  deleteArray,
} = NGOslice.actions;
