import React, { useRef, useEffect, useState, createContext } from 'react' 
import gsap from 'gsap'
import './Focusable.css'

export const FocusContext = createContext(false) 

export default function Focusable({ children }) {
    const [focus, setFocus] = useState(false)
    const eleRef = useRef()
    const focusRef = useRef(false)
    const timeoutRef = useRef(null)

    const focusElement = () => {
        if (focusRef.current) return 

        setFocus(true)
        focusRef.current = true

        const ele = eleRef.current
        const eleBB = ele.getBoundingClientRect()
        const centerX = window.innerWidth * 0.5 - eleBB.width * 0.5
        
        const toX = window.innerWidth >= 900 ? centerX - eleBB.left : 0
        const toY = window.innerWidth >= 900 ? 0.125 * window.innerHeight : 0

        gsap.to(ele, { x: toX, y: toY })

        document.querySelector('#community-wall-inner').classList.remove('overflow-scroll')
    }

    const defocusElement = () => {
        if (!focusRef.current) return 

        clearTimeout(timeoutRef.current)

        setFocus(false)
        focusRef.current = false 

        const ele = eleRef.current

        gsap.to(ele, { 
            x: 0, 
            y: 0,  
            onComplete() {
                ele.style.transform = ''
            }
        })

        document.querySelector('#community-wall-inner').classList.add('overflow-scroll')
    }

    useEffect(() => {
        const onResize = (e) => {
            if (!focusRef.current) return

            const ele = eleRef.current
            const eleBB = ele.getBoundingClientRect()            
            const centerX = window.innerWidth * 0.5 - eleBB.width * 0.5
            const toX = window.innerWidth >= 900 ? centerX - eleBB.left : 0
            const toY = window.innerWidth >= 900 ? 0.125 * window.innerHeight - eleBB.top : 0

            const regex = RegExp(/translate.?.?\((.+?)px, (.*?)px.*/)
            const matches = regex.exec(ele.style.transform)
            const baseX = window.innerWidth < 900 ? 0 : Number(matches[1])
            const baseY = Number(matches[2])

            ele.style.transform = `translate3D(${baseX + toX}px, ${baseY + toY}px, 0)`
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <FocusContext.Provider value={{ focus, focusElement, defocusElement }}>
            { focus &&
                <div className={focus ? 'background z-10 top-0 left-0 fixed w-screen h-screen bg-black/[0.6]' : ''} onClick={defocusElement}></div>
            }

            <div className={`focus-element ${focus ? 'z-20' : ''}`} ref={eleRef}>
                <div className='relative top-0 left-0 bg-white shadow-sm md:shadow-md rounded-md'>
                    {children}
                </div>
            </div>
        </FocusContext.Provider>
    )
}