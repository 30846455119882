import React, { useEffect, useState,useRef } from "react";
import Loading from "../../Admin/components/Loading/Loading";
import OpportunitiesCard from './OpportunitiesCard';
import axios from 'axios'
import { MdOutlineAdd } from "react-icons/md";
import CommunityWallContext from '../../../components/CommunityWall/CommunityWallContext';
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../redux/slices/userSlice'
import { fetchOpportunities, clearOpportunities,fetchCreatedOpportunities,fetchRegisteredOpportunities,setOpportunityActiveTab } from '../../../redux/slices/opportunitiesSlice' 
import get_current_user from '../../../aws_cognito/get_user'
import CreateOppNonCorpModal from "./CreateOppNonCorpModal";
import OpportunityModal from "./OppportunityModal";
import gsap from 'gsap'
const OpportunitiesList = ({ typeUser }) => {
    const dispatch = useDispatch()

    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const [currentUserProfile, setCurrentUserProfile] = useState({});
    const [oppLoading, setOppLoading] = useState(false);
    // const [activeTab, setActiveTab] = useState(1);
    const [nonCorpModalOpen, setNonCorpModalOpen] = useState(false);
    const [genModalOpen, setGenModalOpen] = useState(false);
    const [genModalValue, setGenModalValue] = useState("");

    const activeTabClasses = "font-bold";
    const oppState = useSelector((state) => state.opportunities);
    const oppActiveTab = oppState.opportunityActiveTab;
    const opportunities = oppState.opportunityPosts;
    const createdOpportunityPosts = oppState.createdOpportunityPosts;
    const registeredOpportunityPosts = oppState.registeredOpportunityPosts;
    const opportunityStatuses = oppState.opportunityStatuses;
    const fetchingOpp = oppState.loading;
    const fetchingOppRef = useRef(true)
    const oppRef = useRef([])
    const fetchingCreatedOppRef = useRef(true)
    const createdOppRef = useRef([])
    const fetchingRegisteredOppRef = useRef(true)
    const registeredOppRef = useRef([])
    const endRef = useRef(false)
    useEffect(() => {
        if (!userProfile.data) {
            dispatch(getUser({ type:typeUser }))
        }
        
    }, [])
    useEffect(() => {
        fetchingOppRef.current = fetchingOpp
    }, [fetchingOpp])

    useEffect(() => {
        fetchingCreatedOppRef.current = fetchingOpp
    }, [fetchingOpp])

    useEffect(() => {
        fetchingRegisteredOppRef.current = fetchingOpp
    }, [fetchingOpp])

    useEffect(() => {
        endRef.current = oppState.end
    }, [oppState.end])

    useEffect(() => {
        oppRef.current = opportunities
    }, [opportunities])

    useEffect(() => {
        createdOppRef.current = createdOpportunityPosts
    }, [createdOpportunityPosts])

    useEffect(() => {
        registeredOppRef.current = registeredOpportunityPosts
    }, [registeredOpportunityPosts])
    
    //show all opportunities fetch
    useEffect(() => {
        if (oppActiveTab == 1) {
            dispatch(clearOpportunities())
            const paramsAll = {
                limit: 9999,
                type:'opportunity'
            }
            dispatch(fetchOpportunities({ userType: typeUser, params:paramsAll }))
        }
        if (oppActiveTab == 2) {
            dispatch(clearOpportunities())
            const params = {
                limit: 9999
            }
            dispatch(fetchCreatedOpportunities({ userType: typeUser, params}))
        }
        if (oppActiveTab == 3) {
            dispatch(clearOpportunities())
            const params = {
                limit: 9999
            }
            dispatch(fetchRegisteredOpportunities({ params,userType: typeUser}))
        
        }
    }, [oppActiveTab])

    const notify = (message) => {
    const ele = document.querySelector('#notify')
    ele.innerHTML = message
    
    gsap.killTweensOf(ele)
    const t = gsap.timeline()
    
    t.to(ele, { bottom: 20 })
        .to(ele, { bottom: -100, delay: 3 })
    }
    if (oppLoading) {
        return (
            <div className="grid w-full h-screen place-items-center">
                <Loading />
            </div>
        );
    }
    return (
        <CommunityWallContext.Provider value={{ notify }}>
        <div className="px-8 py-12" >
            {/* Tabs */}
            <div className="flex items-center gap-10 mx-1 border-b-2 lg:border-b-transparent border-b-[#CFEBFF] md:justify-start md:gap-24" >
                <button
                className={`pb-2 ${oppActiveTab === 1 && activeTabClasses}`}
                onClick={() => dispatch(setOpportunityActiveTab (1))}
                >
                All
                </button>
                <button
                className={`pb-2 ${oppActiveTab === 2 && activeTabClasses}`}
                onClick={() => dispatch(setOpportunityActiveTab (2))}
                >
                Created
                </button>
                {typeUser!='admin' && <button
                className={`pb-2 ${oppActiveTab === 3 && activeTabClasses}`}
                onClick={() => dispatch(setOpportunityActiveTab (3))}
                >
                Registered
                </button>
                }
                {oppActiveTab == 2 &&
                    <button className=" text-[0.7rem] sm:text-[1rem] hidden xsm:block sm:absolute sm:right-10" style={{ color: '#fff', backgroundColor: '#22a5ff', borderRadius: '5px', padding: '0.2rem 1rem', fontWeight: '600' }} onClick={() => {
                        if (typeUser === "corporate") {
                            setGenModalOpen(true);
                        } else {
                            setNonCorpModalOpen(true);
                        }
                    }}>Create opportunity
                        </button>
                }
                {oppActiveTab == 2 &&
                    <button className="xsm:hidden fixed bottom-0 right-0 m-2 p-3 bg-[#22a5ff] text-[#fff] rounded-full z-20" onClick={() => {
                        if (typeUser === "corporate") {
                            setGenModalOpen(true);
                        } else {
                            setNonCorpModalOpen(true);
                        }
                    }}>
                        <MdOutlineAdd size={25} />
                    </button>
                }
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 overflow-hidden rounded-lg " >
                    {oppActiveTab==1 && opportunities.map((opportunity) => {
                        return <OpportunitiesCard key={opportunity._id} type={typeUser} opportunityData={opportunity} registeredOpportunity={false} createdOpportunity={false} />
                    })}
                    {oppActiveTab==2 && createdOpportunityPosts.length==0 && <div className="m-4 text-[1.2rem]">No posts created.Create a post now!</div>}
                    {oppActiveTab==2 && createdOpportunityPosts.length>0 && createdOpportunityPosts.map((opportunity) => {
                        return <OpportunitiesCard key={opportunity._id} type={typeUser} opportunityData={opportunity} createdOpportunity={true} registeredOpportunity={false} />
                    
                    })}
                    {oppActiveTab==3 && registeredOpportunityPosts.map((opportunity) => {
                        return <OpportunitiesCard key={opportunity._id} type={typeUser} opportunityData={opportunity} registeredOpportunity={true} />
                    })}
                    {oppActiveTab==3 && registeredOpportunityPosts.length==0 && <div className="m-4 text-[1.2rem]">You haven't participated in any opportunity yet!</div>}
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 overflow-hidden rounded-lg ">
                {!oppState.end &&
                <div id='post-loading' className='bg-white shadow-md rounded-md p-4 mt-5'>
                    <div className='flex items-center'>
                    <span className='animate-bg w-6 h-6 md:w-10 md:h-10 rounded-full'></span>
                    <span className='animate-bg h-6 md:h-10 w-[90%] ml-4 rounded-full'></span>
                    </div>

                    <div className='mt-2'>
                    <span className='animate-bg inline-block mx-2 w-[100px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[200px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[50px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[100px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[120px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[60px] h-6 md:h-10 mt-2 rounded-full' />
                    <span className='animate-bg inline-block mx-2 w-[80px] h-6 md:h-10 mt-2 rounded-full' />
                    </div>

                    <div className='animate-bg inline-block mx-2 w-full h-[200px] mt-2 rounded-xl' />
                </div>
                }
            </div>
            <div>

                {nonCorpModalOpen && 
                    <CreateOppNonCorpModal setNonCorpModalOpen={setNonCorpModalOpen} setGenModalOpen={setGenModalOpen} setGenModalValue={setGenModalValue} />
                }

                { genModalOpen &&
                    <OpportunityModal typeUser={typeUser} setOpportunityModalOpen={setGenModalOpen} opportunityType={genModalValue} setNonCorpModalOpen={setNonCorpModalOpen} />
                }
            </div>
        </div>
        <div id='notify' className='fixed right-2 bottom-[-100px] z-100'></div>
    </CommunityWallContext.Provider>
    )
}
export default OpportunitiesList;