import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../../images/Logo.png";
import { useMediaQuery } from "react-responsive";
import { label } from "aws-amplify";
import { ReactTags } from "react-tag-autocomplete";
import { languagesOptions } from "../Preferences/data/data";
import { languages, modeOptions } from "../Profile/data";

export default function ExtendedProfileSetup({ formData, setFormData }) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [language, setLanguage] = useState([]);

  const onAddedLanguage = useCallback(
    (newTag) => {
      setLanguage([...language, newTag]);
      setFormData({
        ...formData,
        // lang: [e.target.value],
        languages: language,
      });
    },
    [language]
  );

  const onDeleteLanguage = useCallback(
    (tagIndex) => {
      setLanguage(language.filter((_, i) => i !== tagIndex));
      setFormData({
        ...formData,
        // lang: [e.target.value],
        languages: language,
      });
    },
    [language]
  );
  return (
    <>
      {/* <div className="w-[95%] lg:w-[70%] xl:w-[65%] flex flex-col z-10 h-screen bg-white p-8 overflow-y-scroll"> */}

      {/* <div className="flex flex-start p-4 underline decoration-blue underline-offset-5 items-start">
          <a
            href="/profileSetup"
            className={`text-blue ${isMobile ? "-mx-10 -mt-8" : "p-4"
              } font-bold underline underline-offset-5 w-max cursor-pointer`}
          >
            {"<"} Back
          </a>
        </div> */}
      {/* <div>
          {isMobile ? (
            <></>
          ) : (
            <div className="flex flex-row justify-center -mt-10">
              <img src={logo} alt="logo" className="w-36" />
            </div>
          )}
        </div> */}
      {/* <div className="flex flex-col justify-center mt-8 text-3xl font-inter font-bold">
          <h1 className="flex justify-center">
            Set up a profile<span className="text-blue">.</span>
          </h1>
        </div> */}
      {/* <p className="flex justify-center text-md font-inter mt-6">
          Don’t worry, you can edit this later.
        </p> */}
      <div className="flex flex-col sm:flex-row items-center justify-center mb-5 sm:gap-2 gap-8 ">
        <div className="flex flex-col items-center justify-center gap-8 w-[100%]">
          <div
            style={{ zIndex: "100" }} // * use??
            className="flex w-[100%] flex-row ml-[2rem]"
          >
            <div className="relative z-0 w-full flex mx-auto">
              <select
                type="text"
                id="language"
                className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lang: e.target.value,
                  });
                }}
                value={formData.lang}
                required
              >
                <option value="" selected={true} disabled={true}>
                  -- select an option --{" "}
                </option>

                {languages.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor="language"
                className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                  isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
              >
                Preferred Language <span className=''>*</span>
              </label>
            </div>
          </div>

          <div
            className={`flex w-[100%]
              }flex-row  ml-[2rem]`}
          >
            <div className="relative z-0 w-full flex mx-auto">
              <select
                type="text"
                id="employ_level"
                className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    employ_level: e.target.value,
                  });
                }}
                value={formData.employ_level}
                required
              >
                <option value="" selected={true} disabled={true}>
                  -- select an option --{" "}
                </option>

                <option value="Student">Student</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Freelancer">Freelancer</option>
                <option value="Working professional (junior-level)">
                  Working professional (junior-level)
                </option>
                <option value="Working professional (mid-level)">
                  Working professional (mid-level)
                </option>
                <option value="Working professional (senior-level)">
                  Working professional (senior-level)
                </option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Retired">Retired</option>
                <option value="I’m not working now">I’m not working now</option>
                <option value="Other">Other</option>
              </select>
              <label
                htmlFor="employ_level"
                className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 
                    peer-focus:text-md
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
              >
                Employment Level <span className=''>*</span>
              </label>
            </div>
          </div>
          {formData.employ_level === "Other" ? (
            <div className="flex w-[100%] flex-row justify-between ml-[2rem]">
              <div className="relative z-0 w-full flex mx-auto">
                <input
                  type="text"
                  id="other"
                  className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      other: e.target.value,
                    });
                  }}
                  required
                />
                <label
                  htmlFor="other"
                  className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1 text-sm scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                    isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
                >
                  Describe
                </label>
              </div>
            </div>
          ) : null}

          <div className="flex w-[100%] flex-row justify-between ml-[2rem]">
            <div className="relative z-0 w-full flex mx-auto">
              <select
                type="text"
                id="volunteerMode"
                className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    volunteerMode: e.target.value,
                  });
                }}
                value={formData.volunteerMode}
                required
              >
                <option value="" selected={true} disabled={true}>
                  -- select an option --{" "}
                </option>

                {modeOptions.map((data, index) => {
                  return (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor="volunteerMode"
                className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                  isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
              >
                Mode of volunteering <span className=''>*</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex w-[100%] flex-row justify-between ml-[2rem]">
          <div className="relative z-0 w-full flex ">
            <textarea
              type="text"
              id="why"
              className="py-2 px-3 bg-gray-100 w-[90%] h-[13em] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
              placeholder=" "
              onChange={(e) => {
                setFormData({
                  ...formData,
                  why: e.target.value,
                });
              }}
              value={formData.why}
              required
            />
            <label
              htmlFor="why"
              className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 
                      peer-focus:text-md
                    }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
            >
              Why you are here? <span className=''>*</span>
            </label>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
