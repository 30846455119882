import cog_pool from "./user_pool";
import get_current_user from "./get_user";
export const refresh_tokens = (type) => {
    return new Promise(async (resolve, reject) => {
        const cognitoUser = cog_pool(type).getCurrentUser();
        const tokens = await get_current_user(type);
        // console.log(tokens.data.refToken)
        cognitoUser.refreshSession(tokens.data.refToken, (err, session) => {
            if (err) {
                console.log('not ref')
                console.log(err);
                reject();
            } else {
                // console.log(session.getCurrentUser())
                console.log('refresh ses', session)
                resolve(session.getIdToken());
            }
        });
    });
}