import React from "react";
import volunteer from "../../../images/volunteer.png";
import logo from "../../../images/Logo.png";

import SignupComponent from "../../../components/Signup/Signup";
import "./Signup.css";

export default function Signup() {
  return (
    <>
      {/* <div className="h-screen bg-[url('images/volunteer-bg.png')] flex justify-center bg-img">
        <SignupComponent />
      </div> */}
      <div className="md:h-screen md:flex justify-center bg-img">
        <img
          src={volunteer}
          alt="volunteer"
          className="w-full h-[250px] md:hidden"
        />
        <div className="flex bg-white md:hidden  justify-center  -mt-20 z-10 ">
          <img
            src={logo}
            alt="logo"
            className="rounded-[50%] w-40 bg-white p-5 my-5"
          />
        </div>
        <SignupComponent type = "volunteer" user="volunteer" />
      </div>
    </>
  );
}
