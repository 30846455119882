import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";

export default function WorkReq({ showModal, setShowModal }) {
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [myReq, setMyReq] = useState([]);
  const { id } = useParams();
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const user = await get_current_user("volunteer");
      const postRole = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/work-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
          body: JSON.stringify({
            corporateId: id,
            role,
          }),
        }
      );

      const data = await postRole.json();
      if (data.status === "success") {
        setLoading(false);

        alert("Your request has been sent successfully");
        window.location.reload();
      } else {
        alert(data.error);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchMyReq = async () => {
      try {
        const user = await get_current_user("volunteer");
        const fetchReq = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/work-request/me?corporateId=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.data.idToken}`,
            },
          }
        );

        const data = await fetchReq.json();
        if (data.status === "success") {
          setMyReq(data.data);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchMyReq();
  }, []);
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-5">
            <div className="relative w-[500px] my-6 mx-auto max-w-lg">
              {/*content*/}

              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-10 px-5">
                <div className="flex items-center justify-between px-5  rounded-t ">
                  {myReq.length === 0 || myReq[0]?.status === "Rejected" ? (
                    <h3 className="text-xl font-semibold">
                      Let them know that you work here
                    </h3>
                  ) : null}

                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium flex justify-end"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-8 w-12 text-lg block ">
                      x
                    </span>
                  </button>
                </div>
                {myReq.length === 0 || myReq[0]?.status === "Rejected" ? (
                  <form
                    className="px-5 flex-col space-y-5"
                    onSubmit={handleSubmit}
                  >
                    <p className="text-lightGray text-sm">Enter Role</p>
                    <div className="relative z-0 w-full flex ">
                      <input
                        type="text"
                        id="text"
                        className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                        placeholder="Type here"
                        required
                        onChange={(e) => setRole(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-between">
                      <button
                        className="text-blue border border-blue py-1 w-[40%] font-bold rounded-lg"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loading}
                        type="submit"
                        className={`${loading ? 'opacity-60' : 'cursor-pointer'} flex items-center justify-center bg-blue text-white border border-blue py-1 w-[40%] font-bold rounded-lg`}
                      >
                        { loading &&
                          <svg
                          role="status"
                          class="inline mr-1 w-4 h-4 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        }

                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  <p
                    className="mx-auto text-sm 
                  p-3"
                  >
                    You already have a pending request to this corporate
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
