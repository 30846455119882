import React, { useCallback, useRef, useState } from "react";

import { CgArrowLongLeft } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import { ReactTags } from "react-tag-autocomplete";
import { location, sectors } from "../Profile/data";
import {
  causesOptions,
  interestOptions,
  locationOptions,
} from "../Preferences/data/data";
import { useForm } from "react-hook-form";
import {
  deleteArray,
  updateArray,
  updateCompanyDetails,
} from "../../redux/slices/corporateSlice";

export default function EditProfile({ showModal, setShowModal }) {
  // const submitRef = useRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const corpProfile = useSelector((state) => state.corpProfile.corpProfile);
  const data = corpProfile.data;
  const corpForm = useSelector((state) => state.corpForm);
  // console.log(profile);

  const finalData = {};
  for (const key in data) {
    if (data[key] !== corpForm[key]) finalData[key] = corpForm[key];
  }

  const ignoreInvalid = (e) => {
    if (e.key == "e" || e.key == "-" || e.key == ".") {
      e.preventDefault();
    }
  };

  // console.log(finalData);

  delete finalData.coverPic;
  delete finalData.credits;
  delete finalData.email;
  delete finalData.profilePic;
  delete finalData._id;
  delete finalData.__v;
  delete finalData.bio;
  const [selectedImage, setSelectedImage] = useState();
  const [idCard, setIdCard] = useState();

  const uploadIdCard = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIdCard(e.target.files[0]);
    }
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  console.log(finalData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const user = await get_current_user("corporate");
      if (selectedImage) {
        const formData = new FormData();
        formData.append("file", selectedImage, selectedImage.name);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/corporate/upload-letter-head        `,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${user.data.idToken}`,
            },
            body: formData,
          }
        );

        const data = await response.json();
        console.log(data);
      }

      if (idCard) {
        const formData = new FormData();
        formData.append("file", idCard, idCard.name);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/corporate/upload-employee-id        `,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${user.data.idToken}`,
            },
            body: formData,
          }
        );

        const data = await response.json();
        console.log(data);
      }

      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/corporate`,
        finalData,
        {
          headers: {
            Authorization: `Bearer ${user.data.idToken}`,
          },
        }
      );
      const result = await res.data;
      setLoading(false);

      if (result.status === "success") {
        window.location.reload();
      } else {
        setLoading(false);
      }
    } catch (error) {
      alert(error.response.data.message.details[0].message);
      setLoading(false);
    }
  };

  const onAddCauses = useCallback(
    (newTag) => {
      if (corpForm?.causes.length < 5) {
        // setSkills([...skills, newTag]);
        dispatch(updateArray({ type: "causes", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [corpForm?.causes]
  );

  const onDeleteCauses = useCallback(
    (tagIndex) => {
      // setSkills(skills.filter((_,i) => i !== tagIndex));
      dispatch(deleteArray({ type: "causes", tagIndex }));
    },
    [corpForm?.causes]
  );

  const [year, setYear] = useState("");
  const [yearError, setYearError] = useState(``);
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");

  const handleValues = (e) => {
    // e.preventDefault();
    // const { name, value } = e.target;
    // console.log(profile);
    // const { name, value } = e.target;
    // setValues({ ...values, [name]: value });

    if (e.target.name == "foundedYear") {
      if (e.target.value.length > 4) {
        e.target.value = e.target.value.slice(0, 4);
      }
      setYear(e.target.value);
      if (e.target.value < 1900 || e.target.value > new Date().getFullYear())
        setYearError(
          `Year should be in between 1900 and ${new Date().getFullYear()}`
        );
      else setYearError("");
    } else if (e.target.name == "phone_number") {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 10);
      }
      if (e.target.value.length < 10)
        setPhoneError("Enter valid contact number");
      else setPhoneError("");
    } else if (e.target.name == "website") {
      const websiteRegex =
        /^((http|https):\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      if (!websiteRegex.test(e.target.value)) {
        setWebsiteError("Please enter a valid website URL.");
      } else {
        setWebsiteError("");
      }
    }
    dispatch(
      updateCompanyDetails({ name: e.target.name, value: e.target.value })
    );
  };

  const onAddLocation = useCallback(
    (newTag) => {
      if (corpForm?.locations.length < 3) {
        // setInterests([...interests, newTag]);
        dispatch(updateArray({ type: "locations", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [corpForm?.locations]
  );
  const onDeleteLocation = useCallback(
    (tagIndex) => {
      // setInterests(interests.filter((_, i) => i !== tagIndex));
      dispatch(deleteArray({ type: "locations", tagIndex }));
    },
    [corpForm?.locations]
  );

  // const onAddSector = useCallback(
  //   (newTag) => {
  //     if (corpForm?.sector.length < 3) {
  //       // setInterests([...interests, newTag]);
  //       dispatch(updateArray({ type: "sector", newTag }));
  //     } else {
  //       alert("Maximum tags limit reached");
  //     }
  //   },
  //   [corpForm?.sector]
  // );
  // const onDeleteSector = useCallback(
  //   (tagIndex) => {
  //     // setInterests(interests.filter((_, i) => i !== tagIndex));
  //     dispatch(deleteArray({ type: "sector", tagIndex }));
  //   },
  //   [corpForm?.sector]
  // );

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex fixed overflow-y-scroll lg:py-10 inset-0 z-50 outline-none focus:outline-none rounded-lg ">
            <div className="w-full lg:w-3/5  h-full my-6  m-auto">
              {/*content*/}
              <div className="border-0 w-full rounded-lg shadow-lg  flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 md:border-b border-solid border-slate-200 rounded-t fixed bg-white z-10 w-full md:relative">
                  <div className="flex md:hidden justify-center items-center gap-5">
                    <CgArrowLongLeft
                      className="w-8 text-xl"
                      onClick={() => setShowModal(false)}
                    />
                    <h3 className="font-bold text-sm md:text-xl md:font-semibold">
                      Edit profile
                    </h3>
                  </div>
                  <h3 className="font-bold md:text-xl md:font-semibold hidden md:flex">
                    Edit profile
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium hidden md:flex"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-8 w-12">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative md:p-6 pt-14 md:pt-6 flex-auto">
                  <div className="p-6 md:p-0 flex flex-col ">
                    <p className="font-medium">About us</p>
                    <div>
                      <form onSubmit={handleSubmit}>
                        <div className="grid gap-6 my-6 md:grid-cols-4">
                          <div>
                            <label
                              for="name"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Company Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              onChange={handleValues}
                              defaultValue={data?.name}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                          </div>
                          <div>
                            <label
                              for="email"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Company Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              disabled
                              //   {...register("email")}
                              onChange={handleValues}
                              defaultValue={data?.email}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                          </div>
                          <div>
                            <label
                              for="foundedYear"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Founded Year
                            </label>
                            <input
                              type="number"
                              onChange={handleValues}
                              // defaultValue={data?.foundedYear}
                              value={year}
                              onKeyDown={ignoreInvalid}
                              id="foundedYear"
                              name="foundedYear"
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                            {yearError && (
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  fontSize: "11px",
                                }}
                              >
                                {yearError}
                              </div>
                            )}
                          </div>
                          <div>
                            <label
                              for="phone_number"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Company Phone Number
                            </label>
                            <input
                              type="number"
                              onChange={handleValues}
                              name="phone_number"
                              defaultValue={data?.phone_number}
                              id="phone_number"
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                            {phoneError && (
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  fontSize: "11px",
                                }}
                              >
                                {phoneError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="grid gap-3 my-6 md:grid-cols-1">
                          <div>
                            <label
                              for="mission"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Mission
                            </label>
                            <input
                              type="text"
                              name="mission"
                              onChange={handleValues}
                              id="mission"
                              defaultValue={data?.mission}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                          </div>
                          <div>
                            <label
                              for="vision"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              CSR Vision
                            </label>
                            <input
                              type="text"
                              name="vision"
                              onChange={handleValues}
                              id="vision"
                              defaultValue={data?.vision}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                          </div>
                          <div>
                            <label
                              for="coreValues"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Core Values
                            </label>
                            <input
                              type="text"
                              name="coreValues"
                              onChange={handleValues}
                              id="coreValues"
                              defaultValue={data?.coreValues}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                          </div>
                        </div>
                        <div className="grid gap-6 my-6 md:grid-cols-4">
                          <div>
                            <label
                              for="number"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Causes supported
                            </label>
                            <ReactTags
                              placeholderText={
                                corpForm?.causes?.length === 0
                                  ? "Select..."
                                  : ""
                              }
                              selected={corpForm?.causes}
                              suggestions={causesOptions}
                              //   {...register("causes", [{}])}
                              onAdd={onAddCauses}
                              onDelete={onDeleteCauses}
                              noOptionsText="No matching causes"
                            />
                          </div>
                          {/* <div>
                            <label
                              for="sector"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Sector
                            </label>
                            <ReactTags
                              placeholderText={
                                corpForm?.sector?.length === 0
                                  ? "Select..."
                                  : ""
                              }
                              selected={corpForm?.sector}
                              suggestions={interestOptions}
                              onAdd={onAddSector}
                              onDelete={onDeleteSector}
                              noOptionsText="No matching sector"
                            />
                          </div> */}
                          <div>
                            <label
                              for="sector"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Sector
                            </label>
                            <select
                              id="dropdown"
                              className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                              onChange={handleValues}
                              value={corpForm?.sector}
                              name="sector"
                            >
                              <option value="" selected={true} disabled={true}>
                                Select..
                              </option>
                              {sectors?.map((data, index) => {
                                return (
                                  <>
                                    <option key={index} value={data}>
                                      {data}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div>
                            <label
                              for="location"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Location
                            </label>
                            <ReactTags
                              placeholderText={
                                corpForm?.locations?.length === 0
                                  ? "Select..."
                                  : ""
                              }
                              selected={corpForm?.locations}
                              suggestions={locationOptions}
                              onAdd={onAddLocation}
                              onDelete={onDeleteLocation}
                              noOptionsText="No matching location"
                            />
                          </div>
                          <div>
                            <label
                              for="website"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Company Website
                            </label>
                            <input
                              type="url"
                              id="website"
                              name="website"
                              onChange={handleValues}
                              defaultValue={data?.website}
                              className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                            />
                            {websiteError && (
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  fontSize: "11px",
                                }}
                              >
                                {websiteError}
                              </div>
                            )}
                          </div>
                          <div>
                            <label
                              for="letterhead"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Company letterhead
                            </label>
                            {selectedImage || !data?.letterhead ? (
                              <div className="bg-[#F0F9FF] border border-dashed border-blue py-1 px-3 justify-center flex items-center rounded-xl">
                                {selectedImage ? (
                                  <>
                                    <input
                                      type="file"
                                      id="img"
                                      accept="application/pdf"
                                      onChange={imageChange}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="img"
                                      className="text-blue p-2 rounded-md text-sm cursor-pointer truncate"
                                    >
                                      {selectedImage.name}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      id="img"
                                      accept="application/pdf"
                                      onChange={imageChange}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="img"
                                      className="text-blue p-2 rounded-md text-sm cursor-pointer"
                                    >
                                      Upload
                                    </label>
                                  </>
                                )}
                              </div>
                            ) : (
                              <>
                                <div className="bg-[#d6ffd6] border border-dashed border-white py-1 px-3 justify-center flex items-center rounded-xl">
                                  <>
                                    <input
                                      type="file"
                                      id="img"
                                      accept="application/pdf"
                                      disabled={true}
                                      onChange={imageChange}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="img"
                                      className="text-green p-2 rounded-md text-sm "
                                    >
                                      ✅ Uploaded
                                    </label>
                                  </>
                                </div>
                                <input
                                  type="file"
                                  id="reupload"
                                  accept="application/pdf"
                                  onChange={imageChange}
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="reupload"
                                  className="text-black p-2 rounded-md text-xs underline flex justify-end cursor-pointer"
                                >
                                  Reupload letterhead?
                                </label>
                              </>
                            )}
                          </div>
                          <div className="pb-10 md:pb-0">
                            <label
                              for="employeeid"
                              className="block mb-2 text-xs font-normal text-lightGray"
                            >
                              Employee ID card
                            </label>
                            {idCard || !data?.employeeId ? (
                              <div className="bg-[#F0F9FF] border border-dashed border-blue py-1 px-3 justify-center flex items-center rounded-xl">
                                {idCard ? (
                                  <>
                                    <input
                                      type="file"
                                      id="idCard"
                                      accept="application/pdf"
                                      onChange={uploadIdCard}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="idCard"
                                      className="text-blue p-2 rounded-md text-sm cursor-pointer truncate"
                                    >
                                      {idCard.name}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      id="idCard"
                                      accept="application/pdf"
                                      onChange={uploadIdCard}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="idCard"
                                      className="text-blue p-2 rounded-md text-sm cursor-pointer"
                                    >
                                      Upload
                                    </label>
                                  </>
                                )}
                              </div>
                            ) : (
                              <>
                                <div className="bg-[#d6ffd6] border border-dashed border-white py-1 px-3 justify-center flex items-center rounded-xl">
                                  <>
                                    <input
                                      type="file"
                                      id="idCard"
                                      accept="application/pdf"
                                      disabled={true}
                                      onChange={uploadIdCard}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      htmlFor="idCard"
                                      className="text-green p-2 rounded-md text-sm "
                                    >
                                      ✅ Uploaded
                                    </label>
                                  </>
                                </div>
                                <input
                                  type="file"
                                  id="reId"
                                  accept="application/pdf"
                                  onChange={uploadIdCard}
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="reId"
                                  className="text-black p-2 rounded-md text-xs underline flex justify-end cursor-pointer"
                                >
                                  Reupload ID Card?
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex fixed md:relative bg-white z-10 w-full bottom-0  justify-end -ml-6 md:-ml-0 md:z-0">
                          {loading ? (
                            <button
                              disabled
                              type="button"
                              className="bg-sky-300 w-full  md:w-max text-white p-2 md:p-3 rounded-lg font-bold"
                            >
                              <svg
                                role="status"
                                class="inline mr-3 w-4 h-4 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Confirming changes...
                            </button>
                          ) : (
                            <button
                              onClick={handleSubmit}
                              className="bg-blue w-full  md:w-max text-white p-2 md:p-3 md:rounded-lg font-bold"
                            >
                              Confirm changes
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
