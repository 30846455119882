import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    name: "",
    // email: "",
    DOB: "",
    phone_number: "",
    reason: "",
    gender: "",
    volunteerMode: "",
    skills: [],
    motivation: "",
    availability: "",
    location: "",
    volunteeringLocation: [],
    interests: [],
    availableHrs: "",
    linkedIn: "",
    availabilityTime: [
      {
        day: "Monday",
        startTime: "",
        endTime: "",
        checked: false,
      },
      {
        day: "Tuesday",
        startTime: "",
        endTime: "",
        checked: false,
      },
      {
        day: "Wednesday",
        startTime: "",
        endTime: "",
        checked: false,
      },
      {
        day: "Thursday",
        startTime: "",
        endTime: "",
        checked: false,
      },
      {
        day: "Friday",
        startTime: "",
        endTime: "",
        checked: false,
      },
      {
        day: "Saturday",
        startTime: "",
        endTime: "",
        checked: false,
      },

      {
        day: "Sunday",
        startTime: "",
        endTime: "",
        checked: false,
      },
    ],
    languages: [],
    edu_level: "",
    school: "",
    hoursVolunteered: "",
    employmentLevel: "",
    career: [],
    donationType: "",
    bloodType: "",
    availableLocations: [],
    _id: "",
  },
  reducers: {
    updateDetails: (state, action) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },

    updateInitialState: (state, action) => {
      const { profile } = action.payload;
      for (const key in profile) if (key in state) state[key] = profile[key];

      return state;
    },
    updateAvailability: (state, action) => {
      const { id } = action.payload;
      // const updateDay = ;
      // updateDay[name] = date;
      // console.log(current(updateDay));
      state.availabilityTime[id] = action.payload.obj;
    },
    deleteObject: (state, action) => {
      const { id, day, checked } = action.payload;
      console.log("deleted");
      console.log("current avail time: ", current(state.availabilityTime));
      console.log(current);

      state.availabilityTime[id] = {
        day,
        startTime: "",
        endTime: "",
        checked: checked,
      };
    },

    updateWorkExp: (state, action) => {
      const { id, name, value } = action.payload;
      // console.log(action.payload);
      // const updateDay = ;
      // updateDay[name] = date;
      // console.log(current(updateDay));
      const existingCareer = state.career[id];

      if (existingCareer) {
        existingCareer[name] = value;
      } else {
        state.career.push({ [name]: value });
      }
      // state.career[id].name = value;
    },

    addObjectToArray: (state, action) => {
      state[action.payload.type].push(action.payload.obj);
    },

    deleteWorkExp: (state, action) => {
      const { id } = action.payload;
      state.career.splice(
        state.career.findIndex((arrow) => arrow.id === id),
        1
      );
    },

    updateArray: (state, action) => {
      const { type, newTag } = action.payload;
      console.log("TYPE", type, "NEWTAG", newTag);

      state[type].push(newTag);
    },
    deleteArray: (state, action) => {
      const { type, tagIndex } = action.payload;
      console.log(tagIndex);
      state[type].splice(tagIndex, 1);
    },

    updateLanguageType: (state, action) => {
      const { id, type, value } = action.payload;
      const changeValue = state.languages[id];
      changeValue[type] = value;
    },

    updateChecked: (state, action) => {
      const { value, id, event } = action.payload;
      state.availabilityTime[id].checked = value;
      console.log("event :", value);
      // if (state.availabilityTime[id]) {
      //   state.availabilityTime[id].checked = value;
      // }
    },
  },
});

export default profileSlice.reducer;

export const {
  updateBio,
  updateTime,
  updateDetails,
  updateAvailability,
  deleteObject,
  updateArray,
  deleteArray,
  updateWorkExp,
  deleteWorkExp,
  addWorkExp,
  addObjectToArray,
  updateInitialState,
  updateChecked,
  updateLanguageType,
} = profileSlice.actions;
