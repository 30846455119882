import React, { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import VolunteeringExperience from "./VolunteeringExperience";
import { useDispatch, useSelector } from "react-redux";
import CompanyPosts from "../ProfileCorporate/CompanyPosts";


export default function VolunteerInfo({type,profileData,me}) {
  const [tabClicked, setTabClicked] = useState(1);
  return (
    <>
      <div className="bg-white shadow-lg md:shadow-none px-5 md:px-8 md:py-5 flex flex-col rounded-2xl md:rounded-lg">
        <div className="text-xs md:text-base  flex gap-5 md:gap-10 py-4 border-b border-[#CFEBFF]">
          <button
            className={
              tabClicked === 1
                ? "font-bold pb-px-2 border-b-4 border-blue border-solid"
                : ""
            }
            onClick={() => {
              setTabClicked(1);
            }}
          >
            Personal Info
          </button>
          <button
            className={
              tabClicked === 2
                ? "font-bold pb-px-2 border-b-4 border-blue border-solid"
                : ""
            }
            onClick={() => {
              setTabClicked(2);
              
            }}
          >
            Volunteering Experience
          </button>
          <button
            className={
              tabClicked === 3
                ? "font-bold pb-px-2 border-b-4 border-blue border-solid"
                : ""
            }
            onClick={() => {
              setTabClicked(3);
              
            }}
          >
            Posts
          </button>
        </div>
        {tabClicked == 1 ? <PersonalInfo /> : tabClicked == 2 ? <VolunteeringExperience type={type} profile={profileData.data} /> : <CompanyPosts typeUser={type} profile={profileData.data} />}
      </div>
    </>
  );
}
