import React from "react";
import ProfileSetupOrg from "../../../components/ProfileSetupOrg/ProfileSetupOrg";
import { useMediaQuery } from "react-responsive";
import NGO from "../../../images/NGO.png";
import logo from "../../../images/Logo.png";

export default function ProfileSetup() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <>
      {/* <div className="h-screen w-screen bg-no-repeat bg-[url('images/volunteer-bg.png')] flex justify-center bg-img">
        <ProfileSetupComponent />
      </div> */}
      {isMobile ? (
        <div className="h-screen flex flex-col items-center">
          <img src={NGO} alt="NGO" className="w-full h-[250px]" />
          <div className="flex flex-row justify-center items-center -mt-14 bg-white w-[9rem] h-[15rem] rounded-full">
            <img src={logo} alt="logo" className=" " />
          </div>
          <ProfileSetupOrg className="flex justify-center" />
        </div>
      ) : (
        <div className="h-screen flex justify-center w-screen bg-no-repeat bg-[url('images/NGO.png')] bg-cover">
          <ProfileSetupOrg />
        </div>
      )}
    </>
  );
}
