
import React from 'react'
import {useNavigate} from 'react-router-dom'
import ErrorPic from '../../images/404error.svg'
const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div id="wrapper">
            <div className="flex flex-col items-center">
                <img className="w-[60vw] h-[70vh] object-contain" src={ErrorPic} />
                <h2>This page could not be found</h2>
                <button className="btn text-[#fff] bg-[#22A5FF] rounded-lg px-3 py-2 m-1" onClick={() => navigate(-1)}>Go Back</button>
            </div>
        </div >
    )
}

export default PageNotFound