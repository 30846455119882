import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import React from "react";

function ProtectedRoute({ type }) {
  const [auth, setAuth] = useState(true);
  useEffect(() => {
    get_current_user(type)
      .then((user) => {
        setAuth(true);
      })
      .catch((err) => {
        setAuth(false);
      });
  }, []);

  if (type === "admin") {
    return <div>{auth ? <Outlet /> : <Navigate to="/admin/login" />}</div>;
  }

  return auth !== false ? <Outlet /> : <Navigate to={"/"} />;
  // (auth !== false) ? <Outlet /> : <Outlet />
}

export default ProtectedRoute;
