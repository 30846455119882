import React,{useRef,useEffect} from "react";
import "./CreateOppNonCorpModal.css";
import { GrFormClose } from "react-icons/gr";
function CreateOppNonCorpModal({ setNonCorpModalOpen, setGenModalOpen, setGenModalValue }) { 
    const wrapperRef = useRef();
    useEffect(() => {
        const onWindowClick = (e) => {
        if (e.target == wrapperRef.current) {
            setNonCorpModalOpen(false);
        }
        };

        window.addEventListener("click", onWindowClick);

        return () => {
        window.removeEventListener("click", onWindowClick);
        };
    }, []);
    return (
        <div ref={wrapperRef}  className="CreateOppNonCorpModalWrapper">
            <div className="CreateOppNonCorpModalBackground">
                <div className="CreateOppNonCorpModalContainer">
                    <div className="CreateOppNonCorpModalTitle">
                        <h1>Create new opportunity</h1>
                        <button
                            onClick={() => {
                            setNonCorpModalOpen(false);
                            }}
                        >
                            <GrFormClose/>
                        </button>
                    </div>
                    <div className="CreateOppNonCorpModalBody">
                        <button onClick={() => {
                            setGenModalOpen(true);
                            
                            setGenModalValue("genVolOpp");
                        }}>Generate Volunteering Opportunity</button>
                        <button onClick={() => {
                            setGenModalOpen(true);
                            
                            setGenModalValue("corpEmpEng");
                        }}>Corporate Employee Engagement</button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreateOppNonCorpModal;