import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteArray,
  updateArray,
  updateDetails,
} from "../../redux/slices/profileSlice";
import { bloodTypes, donationTypes } from "./data";
import { ReactTags } from "react-tag-autocomplete";
import { locationOptions } from "../Preferences/data/data";

export default function BloodDonation() {
  const dispatch = useDispatch();
  const handleValues = (e) => {
    e.preventDefault();
    // const { name, value } = e.target;
    // console.log(profile);
    // const { name, value } = e.target;
    // setValues({ ...values, [name]: value });
    dispatch(updateDetails({ name: e.target.name, value: e.target.value }));
  };
  const profile = useSelector((state) => state.profileForm);

  const OnAddBloodLocation = useCallback(
    (newTag) => {
      if (profile.availableLocations.length < 3) {
        // setSkills([...skills, newTag]);
        dispatch(updateArray({ type: "availableLocations", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [profile.availableLocations]
  );

  const OnDeleteBloodLocation = useCallback(
    (tagIndex) => {
      // setSkills(skills.filter((_,i) => i !== tagIndex));
      dispatch(deleteArray({ type: "availableLocations", tagIndex }));
    },
    [profile.availableLocations]
  );
  return (
    <>
      <div className="flex flex-col pb-5">
        <p className="font-medium">Blood Donation Details</p>
        <div>
          <form>
            <div class="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="donationType"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  Donation type
                </label>
                <select
                  id="donationType"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                  onChange={handleValues}
                  name="donationType"
                  value={profile.donationType}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {donationTypes.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  for="bloodType"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  Blood group
                </label>
                <select
                  id="bloodType"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                  name="bloodType"
                  onChange={handleValues}
                  value={profile.bloodType}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {bloodTypes.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="pb-10 md:pb-0">
                <label
                  for="availableLocations"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  Available locations
                </label>
                <ReactTags
                  placeholderText={
                    profile.availableLocations.length === 0 ? "Select..." : ""
                  }
                  selected={profile.availableLocations}
                  suggestions={locationOptions}
                  onAdd={OnAddBloodLocation}
                  onDelete={OnDeleteBloodLocation}
                  noOptionsText="No matching locations"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
