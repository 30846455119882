import { ClipLoader } from 'react-spinners'
import { timestampOf } from '../../../utils/utils'
import MessageAttachments from './MessageAttachments'
import MessageStatus from './MessageStatus'

export default function UserMessage({ data, seen, className }) {
    return (
        <div data-id={data._id} className={`flex flex-col bg-[#DBF0FF] rounded-lg rounded-tr-none py-2 px-3 mr-2 min-w-[6.2rem] ${className}`}>
            <p className='text-[0.8rem] break-all mb-1'>
                {data.text}
            </p>

            { data.attachments != null && data.attachments.length > 0 &&
                <MessageAttachments attachments={data.attachments} className='self-end' />
            }

            <div className='flex items-center my-[-0.1rem] self-end translate-y-[1px]'>
                { data._id != null 
                    ? <span className='text-[0.66rem] font-medium text-[#949494]'>{timestampOf(data.createdAt)}</span> 
                    : <span className='text-[0.66rem] font-medium text-[#949494]'>sending</span>
                }
            
                { data._id != null 
                    ? <MessageStatus seen={seen} className='ml-2' />
                    : <ClipLoader className='ml-2' size={12} color={'#b8b8b8'}  />
                }
            </div>
        </div>
    )
}