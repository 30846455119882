import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as postsApi from '../../api/posts'
import get_current_user from '../../aws_cognito/get_user'
import defaultAvatar from '../../images/default.jpg'
import { Link } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'

export default function PostLikes({ post, status, close }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    let [likes, setLikes] = useState([])
    let fetching = useRef(false)
    const type = userProfile?.data?.type

    const likesFetchLimit = process.env.REACT_APP_LIKES_FETCH_LIMIT

    useEffect(() => {
        loadMoreLikes()
    }, [])

    const loadMoreLikes = async () => {
        if (fetching.current)
            return

        fetching.current = true

        try {
            const user = await get_current_user(type)
            const params = { limit: likesFetchLimit }
    
            if (likes.length) {
                params.before = likes[likes.length - 1]._id
            }
    
            const res = await postsApi.getLikes(post._id, params, user.data.idToken)

            likes = [...likes, ...res.data]
            setLikes(likes)
        }

        catch (error) { }

        finally {
            fetching.current = false
        }
    }

    const onScroll = (e) => {
        const ele = e.target
        const bottom = ele.scrollHeight - (ele.scrollTop + ele.clientHeight)
  
        if (bottom < 50 && !fetching.current && likes.length < status.likes) {
            loadMoreLikes()
        }
    }

    return (
        <div className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-30'>
            <div className='bg-black/[0.5] fixed top-0 left-0 w-screen h-screen z-30' onClick={close}></div>

            <div className='bg-white shadow-md rounded-md mt-4 h-[600px] w-full max-w-[600px] relative pt-7 m-3 z-40 px-2'>
                <span className='ml-6 text-[1.05rem]'>
                    Users who 

                    <svg className='inline w-5 h-5 mx-2 fill-rose-500 translate-y-[-2px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"/>
                    </svg>

                    liked post
                </span>
            
                <span className='inline-block h-3 w-[12px] m-1 mb-2 cursor-pointer text-[0.7rem] self-end absolute right-3 top-4' onClick={close}>
                    <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-45'></span>
                    <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-[-45deg]'></span>
                </span>

                <div className='w-full h-[1px] bg-[#e2e2e2] mt-6'></div>

                <div className='scrollable flex flex-col overflow-y-scroll h-[500px] mt-2 px-4' onScroll={onScroll}>
                    {likes.map(x => (
                        <div key={x._id} className='flex items-center py-1'>
                            <img src={(!x.user.profilePic || x.user.profilePic.trim() == '') ? defaultAvatar : x.user.profilePic} className='w-12 h-12 rounded-full my-1' />
                            
                            <Link to={userProfile.data?.db_id == x.user._id ? `/${type}/profile` : `/${type}/view-${x.userType}/${x.user._id}`}>
                                <span className='ml-3 text-[0.8rem]'>{x.user.name}</span>
                            </Link>
                        </div>
                    ))}

                    {likes.length < status.likes &&
                        <div className='w-full flex items-center justify-center'>
                            <PulseLoader size={6} color={'#222'} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}