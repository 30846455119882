import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import get_current_user from '../../aws_cognito/get_user'
import { getTypePath } from '../../utils/utils'

export const getUser = createAsyncThunk('user/get', async ({ type }, { rejectWithValue }) => {
    try {
        const user = await get_current_user(type)

        if (!user) return

        if (type != 'admin') {
            if (!user.data?.db_id) {
                return rejectWithValue({ profileNotSetup: true })
            }

            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${getTypePath(type)}/${user.data?.db_id}`, {
                    headers: {
                        Authorization: `Bearer ${user.data?.idToken}`,
                        "content-type": "application/json",
                    },
                })

                var userData = res.data.data
            }

            catch (error) {
                const res = await axios.get(`${process.env.REACT_APP_CHAT_API}/user/info`, {
                    headers: {
                        Authorization: `Bearer ${user.data?.idToken}`,
                        "content-type": "application/json",
                    },
                })

                var userData = res.data
            }

            user.data = { idToken: user.data.idToken, ...userData }
            user.data.db_id = user.data._id
        }
  
        else {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/`, {
                headers: {
                    Authorization: `Bearer ${user.data?.idToken}`,
                    "content-type": "application/json",
                },
            })
  
            user.data = { idToken: user.data.idToken, ...res.data.data }
            user.data.db_id = res.data.data._id
            user.data.type = 'admin'
        }

        return user 
    }

    catch (error) {
        throw error
    }
})

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        userProfile: {
            data: null
        },
        loading: false,
        profileNotSetup: false,
        processing: 0,
        start: false
    },
    extraReducers: {
        [getUser.pending]: (state, action) => {
            state.loading = true
            state.processing++
            state.start = true
        },

        [getUser.fulfilled]: (state, action) => {
            state.loading = false
            state.userProfile = action.payload
            state.fetched = true
            state.processing--
        },

        [getUser.rejected]: (state, { payload }) => {
            if (payload && payload.profileNotSetup) {
                state.profileNotSetup = payload.profileNotSetup
            }
            
            state.loading = false
            state.processing--
        },
    },
})

export default userSlice.reducer