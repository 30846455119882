import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import logo from "../../../src/images/Logo.png";
import login from "../../aws_cognito/login";
import get_current_user from "../../aws_cognito/get_user";
import { useNavigate } from "react-router-dom";
import { resend_code } from "../../aws_cognito/verify";
import { useSearchParams } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/slices/userSlice";

import { get_token } from "../../aws_cognito/get_token";
import { create_session } from "../../aws_cognito/create_session";
import clearCache from "../../aws_cognito/clear_cache";

export default function Login({ type }) {
  const dispatch = useDispatch();
  const { start, userProfile, processing } = useSelector(
    (state) => state.currentProfile
  );
  const [clientId, setClientId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  let [authenticating, setAuthenticating] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  useEffect(() => {
    if (!userProfile.data) {
      for (const currentType of [
        "admin",
        "volunteer",
        "corporate",
        "organization",
      ])
        dispatch(getUser({ type: currentType }));
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get("error_description")) {
      alert("User already exists");
    }
  }, [window.location.search]);

  useEffect(() => {
    const currentType = userProfile.data?.type;

    if (userProfile?.data) {
      navigate(`/${currentType}/community-wall`);
    }

    if (userProfile.profileNotSetup) {
      navigate(`/${currentType}/profileSetup`);
    }
  }, [userProfile.data]);

  useEffect(() => {
    if (searchParams.get("code") !== null) {
      const fetchToken = async () => {
        const code = searchParams.get("code");
        const res = await get_token(code, type, false);
        if (res !== null) {
          console.log("aftercall ", res);
          try {
            const sess = await create_session(
              res?.data.id_token,
              res?.data.access_token,
              res?.data.refresh_token,
              type
            );
            console.log(sess, "session details");
            // const user = await get_current_user(type);
            navigate(`/${type}/profile`);
          } catch (error) {
            alert("Something went wrong, please try again or contact us.");
          }
        }
      };
      fetchToken();
    }
  }, []);

  const handleToggle = (type) => {
    setOpen(!open);
  };
  // const id = localStorage.getItem("id");
  useEffect(() => {
    if (type === "volunteer")
      setClientId(process.env.REACT_APP_AWS_COGNITO_VOL_CLIENT_ID);
    if (type === "corporate")
      setClientId(process.env.REACT_APP_AWS_COGNITO_CORP_CLIENT_ID);
    if (type === "organization")
      setClientId(process.env.REACT_APP_AWS_COGNITO_ORG_CLIENT_ID);
  }, [type]);
  const isAdmin = type === "admin";

  const onSubmit = async (data) => {
    if (authenticating) {
      return;
    }

    authenticating = true;
    setAuthenticating(authenticating);

    try {
      console.log(data);
      const res = await login(data.Email, data.Password, type);
      const user = await get_current_user(type); // not needed i think

      if (isAdmin) {
        navigate("/admin/analytics");
      } else navigate(`/${type}/profile`);
      console.log(res);
    } catch (error) {
      if (error.code == "UserNotVerified") {
        alert("account was not setup successfully, please sign up again");
      }
      // catch error for usernotconfirmed and direct user to verification page
      // after resending the verification code
      else if (error.code === "UserNotConfirmedException" && type !== "admin") {
        const result = await resend_code(data.Email);
        navigate(`/${type}/verify`);
      } else if (error.code === "NotAuthorizedException") {
        // handle for users with incorrect email/password
        alert(error.message);
      } else {
        alert(`User does not exist`);
      }
    } finally {
      authenticating = false;
      setAuthenticating(authenticating);
    }
  };

  return (
    <>
      {(!start || processing > 0) && (
        <div className="h-screen w-screen flex items-center justify-center bg-[#F4F8FB] absolute top-0">
          <svg
            role="status"
            class="inline mr-1 w-12 h-12 text-[#888] animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}

      {start && processing == 0 && (
        <div className="w-full md:w-[47%] flex flex-col z-10 md:h-screen bg-white pb-8 md:p-8 overflow-y-scroll">
          <div className="flex-row justify-center hidden mt-4 md:flex">
            <img src={logo} alt="logo" className="w-36" />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-center w-full mt-10 space-y-8 md:mt-20"
          >
            <div className="relative z-0 flex w-3/4 mx-auto">
              <input
                type="email"
                id="email"
                className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                required
                {...register("Email", {})}
              />
              <label
                htmlFor="email"
                className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
              >
                E-mail ID
              </label>
            </div>
            <div className="relative z-0 w-3/4 flex mx-auto gap-3">
              <input
                type={open === false ? "password" : "text"}
                {...register("Password", {})}
                id="password"
                className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                required
              />
              <label
                htmlFor="password"
                className="absolute  text-black duration-300 transform -translate-y-9 -translate-x-1 scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
              >
                Password
              </label>
              <div className="absolute right-2 top-1.5 text-2xl flex items-center text-blue cursor-pointer">
                {open === false ? (
                  <AiOutlineEye onClick={() => handleToggle()} />
                ) : (
                  <AiOutlineEyeInvisible onClick={() => handleToggle()} />
                )}
              </div>
            </div>
            <div className="flex justify-end w-3/4 mx-auto">
              {/* <div className="flex items-center justify-center gap-2 text-sm md:text-base">
                <label htmlFor="checkbox" className="font-normal ">
                  Remember me
                </label>
                <input
                  id="checkbox"
                  type="checkbox"
                  className="w-3 bg-blue md:w-4"
                />
              </div> */}
              <a
                href={`/${type}/password-reset`}
                className="text-sm font-medium text-blue md:text-sm"
              >
                Forgot Password?
              </a>
            </div>
            <div className="flex flex-col items-center justify-center space-y-3">
              <button
                type="submit"
                value="Login"
                disabled={authenticating}
                className={`${
                  !authenticating ? "px-8" : "px-4 opacity-60"
                } py-2 mb-5 font-bold text-white rounded-lg cursor-pointer bg-blue hover:bg-blue-500 md:py-3 md:mb-0 flex items-center`}
              >
                {authenticating && (
                  <svg
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}

                {authenticating ? "Logging in" : "Login"}
              </button>
              {!isAdmin && (
                <p className="text-sm text-[#141414] font-medium">
                  Don't have an account?{" "}
                  <a href={`/${type}/signup`} className="font-bold text-blue">
                    Signup.
                  </a>
                </p>
              )}
            </div>
            {!isAdmin && (
              <div className="flex justify-center space-x-4">
                <span className="flex items-center">
                  <hr className="border-[1px] w-28 md:w-48 border-[#D9D9D9]" />
                </span>
                <span className="font-bold">OR</span>
                <span className="flex items-center">
                  <hr className="border-[1px] w-28 md:w-48 border-[#D9D9D9]" />
                </span>
              </div>
            )}
          </form>

          {!isAdmin && (
            <div className="flex justify-center mt-3">
              <button
                // href={`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_DOMAIN}/${type}/login/&response_type=CODE&client_id=${clientId}&scope=email openid profile`}
                className="flex gap-3 shadow-md py-3 px-2 rounded-lg items-center font-bold pr-3"
                onClick={() => clearCache(type, clientId)}
              >
                <FcGoogle className="text-3xl" />
                Login with Google
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
