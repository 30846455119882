import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import defaultAvatar from "../../images/default.jpg";
import { updateCompanyDetails } from "../../redux/slices/corporateSlice";
import axios from "axios";
export default function EmpRequests({ showModal, setShowModal }) {
  const [allEmployees, setAllEmployees] = useState([]);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const corpProfile = useSelector((state) => state.corpForm);
  const employeeData = corpProfile.employeeData;
  const { id } = useParams();
  const fetchRequests = async () => {
    try {
      const user = await get_current_user("corporate");
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/work-request?status=Pending`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
        }
      );

      const data = await res.json();
      if (data.status === "success") {
        dispatch(
          updateCompanyDetails({ name: "employeeData", value: data.data })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllEmployees = async () => {    
    try {
      const user = await get_current_user("corporate");
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/work-request?status=Approved`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
        }
      );

      const data = await res.json();
      if (data.status === "success") {
        setAllEmployees(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [change]);

  useEffect(() => {
    fetchAllEmployees();
  }, []);

  const handleAccept = async (status, e) => {
    setChange(true);

    try {
      const user = await get_current_user("corporate");
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/work-request/${e.target.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
          body: JSON.stringify({
            status: status,
          }),
        }
      );

      const data = await res.json();
      // console.log(data.data);
      if (data?.data?.status === "Approved") {
        fetchRequests() // to update employee requests list ( useEffeact() didn't work )
        const arr = [...allEmployees, data.data];
        setAllEmployees(arr);
        // alert(`Employee Request ${data?.data?.status}`);
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleRemove = async (e) => {
    console.log(e.target.id)
    setChange(true);
    try {
      const user = await get_current_user("corporate");
      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/work-request/${e.target.id}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res)=>{
        console.log(res.data);
        window.location.reload();
      })
    } catch (error) {
      alert(error);
    }
  }
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center p-5 my-5 overflow-hidden rounded-lg outline-none focus:outline-none md:p-0">
            <div className="relative w-[800px] my-6 mx-auto  h-screen">
              {/*content*/}
              <div className="relative flex flex-col w-full my-10 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 mt-5 rounded-t ">
                  <h3 className="font-bold md:text-xl md:font-semibold ">
                    Employee Requests ({employeeData?.length})
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-xl font-bold leading-none bg-transparent border-0 text-lightGray"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-12 h-8 text-xl bg-transparent text-lightGray ">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                {employeeData ? (
                  <span className="relative pb-10 py-4 px-4 flex-auto text-sm overflow-y-scroll max-h-[250px]">
                    {employeeData
                      ?.slice()
                      .reverse()
                      .map((data, index) => {
                        return (
                          <div className="flex flex-col space-y-5">
                            <div className="flex flex-col w-full space-y-6">
                              <div className="flex items-center gap-2 px-3 md:gap-3 ">
                                <img
                                  src={
                                    data.volunteerProfilePic !== ""
                                      ? data?.volunteerProfilePic
                                      : defaultAvatar
                                  }
                                  alt=""
                                  className="rounded-full w-14 h-14 md:w-20 md:h-20"
                                />
                                <div className="flex flex-wrap justify-between grow">
                                  <div className="flex flex-col pt-3 space-y-1">
                                    <h1 className="text-sm font-bold md:text-base ">
                                      {data.volunteerName}
                                    </h1>
                                    <p className="text-xs text-lightGray">
                                      {data.role}
                                    </p>
                                  </div>

                                  <div className="flex flex-col">
                                    <button
                                      className="text-[10px] bg-blue md:text-sm font-bold p-1 px-3 rounded-lg text-white"
                                      onClick={(e) =>
                                        handleAccept("Approved", e)
                                      }
                                      id={data._id}
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className="text-[10px] p-2 px-5 md:text-sm  text-red-500"
                                      onClick={(e) =>
                                        handleAccept("Rejected", e)
                                      }
                                      id={data._id}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                ) : (
                  <p className="mx-auto">No pending requests</p>
                )}

                <div className="flex items-center justify-between p-5 rounded-t ">
                  <h3 className="font-bold md:text-xl md:font-semibold ">
                    All Employees ({allEmployees?.length})
                  </h3>
                </div>
                {allEmployees.length !== 0 ? (
                  <span className="relative pb-20  py-4 px-4 flex-auto text-sm overflow-y-scroll max-h-[400px] ">
                    <div className="flex flex-col space-y-5">
                      {allEmployees
                        ?.slice()
                        .reverse()
                        ?.map((data, index) => {
                          return (
                            <div className="flex flex-col w-full space-y-6">
                              <div className="flex items-center gap-2 px-3 md:gap-3 ">
                                <img
                                  src={
                                    data.volunteerProfilePic !== ""
                                      ? data?.volunteerProfilePic
                                      : defaultAvatar
                                  }
                                  alt=""
                                  className="rounded-full w-14 h-14 md:w-20 md:h-20"
                                />
                                <div className="flex flex-wrap justify-between grow">
                                  <div className="flex flex-col space-y-1 ">
                                    <h1 className="text-sm font-bold md:text-base ">
                                      {data.volunteerName}
                                    </h1>
                                    <p className="text-xs text-lightGray">
                                      {data.role}
                                    </p>
                                  </div>
                                  <div className="flex flex-col">
                                    <button
                                    className="text-[10px] bg-blue md:text-sm font-bold p-1 px-3 rounded-lg text-white"
                                    onClick={() =>
                                      navigate(
                                        `/corporate/view-volunteer/${data.volunteerId}`
                                      )
                                    }
                                  >
                                    View Profile
                                  </button>
                                  <button className="text-[10px] p-2 px-5 md:text-sm  text-red-500" onClick={(e)=>{
                                    handleRemove(e)
                                  }} id={data._id}>Remove</button>
                                </div>

                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </span>
                ) : (
                  <p className="pb-10 mx-auto">
                    Unfortunately, You don't have any employees yet
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
