export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const modeOptions = ["Offline", "Online", "Hybrid"];
export const opportunityModeOptions = ["Offline", "Remote","Hybrid"];
export const location = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const availableHrs = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
];

export const educationLevel = [
  "High School",
  "Undergraduate",
  "Graduate",
  "Doctoral Studies",
  "Post-doctoral Studies",
];

export const bloodTypes = ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"];

export const employmentLevel = [
  "Student",
  "Homemaker",
  "Freelancer",
  "Working professional (junior-level)",
  "Working professional (mid-level)",
  "Working professional (senior-level)",
  "Retired",
  "I’m not working now",
  "Other",
];

export const donationTypes = [
  "Whole Blood",
  "Plasma",
  "Platelets",
  "Red Cells",
];

export const languages = [
  "Amharic",
  "Arabic",
  "Basque",
  "Bengali",
  "English (UK)",
  "Portuguese (Brazil)",
  "Bulgarian",
  "Catalan",
  "Cherokee",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English (US)",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kannada",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Marathi",
  "Norwegian",
  "Polish",
  "Portuguese (Portugal)",
  "Romanian",
  "Russian",
  "Serbian",
  "Chinese (PRC)",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tamil",
  "Telugu",
  "Thai",
  "Chinese (Taiwan)",
  "Turkish",
  "Urdu",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
];

export const sectors = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",
];
export const experienceOptions = [
  "0 year",
  "0-1 year",
  "1-2 years",
  "2-3 years",
  "3-4 years",
  "5-6 years",
  "6-7 years",
  "7-8 years",
  "8-9 years",
  "9-10 years",
  "10+ years"
]
export const skills = [
  "CSR Consulting",
  "Fund Raising",
  "Cause Marketing",
  "Proposal Writing",
  "Capacity building",
  "People Management",
  "Coaching and Counselling",
  "Impact Business Modelling",
  "Sustainable Development Consulting",
  "Community Investments",
  "Contracts and Agreements",
  "Investment Banking",
  "Strategizing and planning",
  "Legal consulting",
  "Design Thinking",
  "App development",
  "Videography",
  "Audio production",
  "Editing",
  "Coordinate events",
  "Photoshop",
  "Photography",
  "Law",
  "Journalism",
  "Animation",
  "Data scientist",
  "Sales leadership",
  "UX design",
  "SEO/SEM marketing",
  "Content writing",
  "CAD",
  "Lean manufacturing",
  "Multivariate analysis",
  "Linear regression",
  "Prototyping",
  "Workflow development",
  "STEM skills",
  "Web: HTML, CSS, Javascript",
  "Payment processing ",
  "Automated Billing Systems",
  "CRM Platforms",
  "Research",
  "Troubleshooting",
  "Google Drive",
  "Spreadsheets",
  "Email",
  "Presentations/Slideshows",
  "Database Management",
  "Quickbooks",
  "Social media",
  "Web",
  "Typing",
  "Graphics",
  "Enterprise systems",
  "WPM",
  "Research",
  "Forecasting",
  "Data mining",
  "Data presentation",
  "Resource management",
  "Data engineering",
  "Database management",
  "Data and metrics interpreting",
  "Reporting",
  "Diagnostics",
  "Creativity",
  "Theorizing",
  "SEO/SEM: Ahrefs, SEMRush, SEO Power Suite, Majestic",
  "PPC",
  "Social media marketing and paid social media advertising",
  "Writing",
  "CRO and A/B testing",
  "Email marketing and automation",
  "HubSpot, Aritic PinPoint, ONTRAPORT, Infusionsoft",
  "Funnel management",
  "UX Design",
  "Data visualization",
  "Google Analytics and Google Search Console",
  "AdWords, Facebook Paid Ads",
  "Visual communication",
  "Slideshows",
  "Research",
  "Data analysis",
  "Reporting",
  "Persuasion",
  "Graphic design",
  "Business knowledge",
  "Budgeting",
  "Project management",
  "Hiring",
  "Finance",
  "Office management skills",
  "Logistics",
  "New business development",
  "Negotiating",
  "Planning",
  "Project scheduling",
  "Strategic planning",
  "Project lifecycle management",
  "Agile software",
  "Scrum management",
  "Financial modeling",
  "Kanban",
  "Forecasting",
  "Performance tracking",
  "Budgeting",
  "Basic writing: grammar, punctuation, spelling, vocabulary",
  "Note-taking",
  "Letter writing",
  "Email writing",
  "Business writing reports, press releases, content management",
  "Data visualization",
  "Creative writing: plotting, worldbuilding, dialogue",
  "Academic writing",
  "Storytelling",
  "Copywriting",
  "SEO",
  "Editing",
  "Journalism",
  "Social media",
  "Presentation writing",
  "Growth Strategist",
  "Legal services",
  "Teaching / Tutoring ",
  "Virtual Asisstant / Admin support",
  "Translation",
  "Community Manager",
  "Audio / Video production",
  "Human Resource Management ",
  "Curriculam developer",
  "Mobile App development",
  "Management Consulting ",
  "Financial / Accounting Services",
  "Technology Consulting ",
  "Customer Service",
  "SROI",
  "Social Impact  Assessment ",
  "Market Research / Validation",
  "Surveys/Polls",
  "Data Analysis",
];

export const volunteerMotivation = [
  "Make a Difference",
  "Give back to Community",
  "Personal Growth",
  "Career Growth",
  "Gain Professional Experience",
  "Social Networking",
  "Learn New Skills",
  "Contribute to Employers CSR",
  "Exposure to Social Development Sector",
  "To be a Social Entrepreneur",
  "Other",
];

export const causesOptions = [
  "Rural Development",
  "Children's education",
  "Women’s health",
  "Women empowerment",
  "Wildlife conservation",
  "Marine conservation",
  "Waste management",
  "Community development",
  "Water Conservation",
  "Air quality conservation",
  "Animal Care",
  "Sports",
  "Volunteer for orphanage",
  "Volunteer for old age home",
  "Medical Volunteer",
  "Disaster Management",
  "SDG 1: No Poverty",
  "SDG 2: Zero Hunger",
  "SDG 3: Good Health and Well-being",
  "SDG 4: Quality Education",
  "SDG 5: Gender Equality",
  "SDG 6: Clean Water and Sanitation",
  "SDG 7: Affordable and Clean Energy",
  "SDG 8: Decent Work and Economic Growth",
  "SDG 9: Industry, Innovation and Infrastructure",
  "SDG 10: Reduced Inequality",
  "SDG 11: Sustainable Cities and Communities",
  "SDG 12: Responsible Consumption and Production",
  "SDG 13: Climate Action",
  "SDG 14: Life Below Water",
  "SDG 15: Life on Land",
  "SDG 16: Peace and Justice Strong Institutions",
  "SDG 17: Partnerships to achieve the Goal",
  "Child rights",
  "Poverty",
  "Social Injustice",
  "Environment Conservation",
  "Human Rights",
  "Care for elderly people",
  "Animal Rights",
  "Sanitation and Hygiene",
  "Humanitarian Relief",
  "Health and Nutrition",
  "Literacy and Education",
  "Refugee Crisis",
  "Disease Control",
  "Welfare for differently abled",
  "Tribal welfare",
  "HIV/AIDS",
  "LGBTQ Rights and Welfare",
  "Unemployment",
  "Art & Culture",
  "Menstrual Health & Hygiene",
  "Migrants & Refugee Rights",
];
