import React, { useEffect } from "react";
import ProfileSetupComponent from "../../../components/ProfileSetup/ProfileSetup";
import { useMediaQuery } from "react-responsive";
import volunteer from "../../../images/volunteer.png";
import logo from "../../../images/Logo.png";
import ProfileMultiStep from "../../../components/ProfileSetup/ProfileMultiStep";
import { useSearchParams } from "react-router-dom";
import { get_token } from "../../../aws_cognito/get_token";
import { create_session } from "../../../aws_cognito/create_session";

export default function ProfileSetup() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [searchParams, setSearchParams] = useSearchParams();

  // ! remove this only one is needed
  useEffect(() => {
    try {
      if (searchParams.get('code') !== null) {
        const fetchToken = async () => {
          const code = searchParams.get('code');
          const res = await get_token(searchParams.get('code'));
          if (res !== null) {
            console.log('aftercall ', res)
            const sess = await create_session(res?.data.id_token, res?.data.access_token, res?.data.refresh_token);
            console.log(sess)
          }
        }
        fetchToken();
      }
    } catch (error) {
      // handle error in creating session tokens here 
      console.log(error)
    }

  }, [])
  return (
    <>
      {/* <div className="h-screen w-screen bg-no-repeat bg-[url('images/volunteer-bg.png')] flex justify-center bg-img">
        <ProfileSetupComponent />
      </div> */}
      {isMobile ? (
        <div className="h-screen flex flex-col items-center">
          <img src={volunteer} alt="volunteer" className="w-full h-[250px]" />
          <div className="flex flex-row justify-center items-center -mt-14 bg-white w-[9rem] h-[18rem] rounded-full">
            <img src={logo} alt="logo" className="w-[6em] h-[4.8em]" />
          </div>
          <ProfileMultiStep type="volunteer" className="flex justify-center" />
        </div>
      ) : (
        <div className="h-screen flex justify-center bg-img">
          <ProfileMultiStep type="volunteer" />
        </div>
      )}
    </>
  );
}
