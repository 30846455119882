import React from "react";
import axios from 'axios'
import get_current_user from '../../aws_cognito/get_user'
import defaultAvatar from '../../images/default.jpg'
import ReactStars from 'react-rating-stars-component'
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const Modal = ({ isOpen, onClose, pendingCorporateTestimonials, pendingImpactorgTestimonials, type, setPendingCorporateTestimonials, setPendingImpactOrgTestimonials }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = React.useState(false);
  const acceptCorporateTestimonial = async (id)=>{
    try {
      const user = await get_current_user(type);
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/corporate/accept/${id}`,{},
        {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
        }
      }) 
      setPendingCorporateTestimonials(
        pendingCorporateTestimonials.filter((item)=>item._id!=id)
      )
      alert("Accepted successfully..")
    } catch (error) {
      console.log(error);
    }
  } 
// testimonial fixes
  const acceptImpactOrgTestimonial = async (id)=>{
    try {
      const user = await get_current_user(type);
      console.log(user.data);
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/impactorg/accept/${id}`,{}, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
          }
      })
      setPendingImpactOrgTestimonials(
        pendingImpactorgTestimonials.filter((item)=>item._id!=id)
      )
      alert("Accepted successfully..")
    } catch (error) {
      console.log(error);
    }
  }

  const RejectCorporateTestimonial = async (id)=>{
    try {
      const user = await get_current_user(type);
      console.log(user);
      console.log(user.data);
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/corporate/reject/${id}`,{}, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
        }
      })
      setPendingCorporateTestimonials(
        pendingCorporateTestimonials.filter((item)=>item._id!=id)
      )
      alert("Rejected successfully..")
    } catch (error) {
      console.log(error);
    }
  }

  const rejectImpactOrgTestimonial = async (id)=>{
    try {
      const user = await get_current_user(type);
      console.log(user);
      console.log(user.data);
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/impactorg/reject/${id}`,{}, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
        }
      })
      setPendingImpactOrgTestimonials(
        pendingImpactorgTestimonials.filter((item)=>item._id!=id)
      )
      alert("Rejected successfully..")
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 min-h-screen  z-50 overflow-y-auto ${
        isVisible ? "opacity-100" : "opacity-0 hidden"
      }`}
      onClick={onClose}
    >
      <div className="absolute inset-0 min-h-auto bg-gray-900 opacity-50"></div>
      <div className="absolute inset-x-4  flex items-center justify-center">
        <div className="px-8 py-6 mx-2 bg-white w-full max-w-[45em] rounded-lg" onClick={(e) => e.stopPropagation()}>
            <div className='flex justify-between items-center' >
            <span className='inline-block font-semibold text-l text-[#222]'>Testimonials</span>
            <AiOutlineClose className="cursor-pointer" onClick={onClose} />
            </div>
            <div className="max-h-[40vh] overflow-auto">
            {pendingImpactorgTestimonials&&
            pendingImpactorgTestimonials.map((item)=>(
                <div key={item._id} className='p-2 my-2 items-center sm:flex sm:justify-between'>
                <div className='flex'>
                  <div >
                      <img src={item.corporate.profilePic==""?defaultAvatar:item.corporate.profilePic} alt="ProfilePic" className="rounded-full w-16 h-16 md:w-20 md:h-20" />
                    </div>
                    <div className='flex-col w-full px-4'>
                      <div className='flex'>
                          <div className='flex-1'>
                          <p className="text-[0.8rem] sm:text-[1rem]">From</p>
                          <p onClick={() =>navigate(`/admin/view-corporate/${item.corporate._id}`)} className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.corporate.name}</p>
                          </div>
                          <div className='flex-1'>
                          <p className="text-[0.8rem] sm:text-[1rem]">To</p>
                          <p onClick={() =>navigate(`/admin/view-organization/${item.org._id}`)} className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.org.name}</p>
                          </div>
                      </div>
                      <div>
                          <ReactStars 
                          count={5}
                          value={item.updatefeilds.newrating}
                          edit={false}
                          size={22}
                          activeColor="#ffd700">
                          </ReactStars>
                      </div>
                      <div>
                          <p>{item.updatefeilds.text}</p>
                      </div>
                    </div>
                </div>
                <div className='flex justify-center sm:justify-end'>
                    <button className='m-1 mb-3 md:w-[7rem] w-[5rem] py-2 md:py-3 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>{
                    acceptImpactOrgTestimonial(item._id)
                    }}>Accept</button>
                    <button className='m-1 mb-3 md:w-[7rem] w-[5rem] py-2 md:py-3 border-2 border-blue  text-blue rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>{
                    rejectImpactOrgTestimonial(item._id)
                    }}>Reject</button>
                </div>
                </div>
            ))
            }
            { pendingCorporateTestimonials &&
            pendingCorporateTestimonials.map((item)=>(
                <div key={item._id} className='p-2 my-2 items-center sm:flex sm:justify-between'>
                <div className='flex '>
                    <div >
                      <img src={item.org.profilePic==""?defaultAvatar:item.org.profilePic} alt="ProfilePic" className="rounded-full w-16 h-16 md:w-20 md:h-20" />
                    </div>
                    <div className='flex-col w-full px-4'>
                      <div className='flex'>
                          <div className='flex-1'>
                          <p className="text-[0.8rem] sm:text-[1rem]">From</p>
                          <p onClick={() =>navigate(`/admin/view-organization/${item.org._id}`)} className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.org.name}</p>
                          </div>
                          <div className='flex-1'>
                          <p className="text-[0.8rem] sm:text-[1rem]">To</p>
                          <p onClick={() =>navigate(`/admin/view-corporate/${item.corporate._id}`)} className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.corporate.name}</p>
                          </div>
                      </div>
                      <div>
                          <ReactStars 
                          count={5}
                          value={item.updatefeilds.newrating}
                          edit={false}
                          size={22}
                          activeColor="#ffd700">
                          </ReactStars>
                      </div>
                      <div>
                          <p>{item.updatefeilds.text}</p>
                      </div>
                    </div>
                </div>
                <div className='flex justify-center sm:justify-end'>
                    <button className='m-1 mb-3 md:w-[7rem] w-[5rem] py-2 md:py-3 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>{
                    acceptCorporateTestimonial(item._id)
                    }}>Accept</button>
                    <button className='m-1 mb-3 md:w-[7rem] w-[5rem] py-2 md:py-3 border-2 border-blue  text-blue rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>{
                    RejectCorporateTestimonial(item._id)
                    }}>Reject</button>
                </div>
                </div>
            ))
            }
            </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
