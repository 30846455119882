// function to get tokens from auth code
import axios from "axios";
export const get_token = async (auth_code, type, isSignup) => {
  let redirect_uri;
  if (isSignup)
    redirect_uri = `${process.env.REACT_APP_BASE_DOMAIN}/${type}/signup/`;
  else
    redirect_uri = `${process.env.REACT_APP_BASE_DOMAIN}/${type}/login/`;
  let client_id = process.env.REACT_APP_AWS_COGNITO_VOL_CLIENT_ID;
  if (type === 'corporate')
    client_id = process.env.REACT_APP_AWS_COGNITO_CORP_CLIENT_ID;
  if (type === 'organization')
    client_id = process.env.REACT_APP_AWS_COGNITO_ORG_CLIENT_ID;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`,
      `grant_type=authorization_code&client_id=${client_id}&code=${auth_code}&redirect_uri=${redirect_uri}`,
      // JSON.stringify({
      //     grant_type: 'authorization_code',
      //     client_id: process.env.REACT_APP_AWS_CLIENT_ID,
      //     code: auth_code,
      //     redirect_uri: redirect_uri,
      // }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
