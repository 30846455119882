import React,{useRef,useEffect} from "react";
import "./ApproveRejectConnRequest.css";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
function ApproveRejectConnRequest({ setApproveRejConnModal,userID,  typeUser }) { 
    const wrapperRef = useRef();
    useEffect(() => {
        const onWindowClick = (e) => {
        if (e.target == wrapperRef.current) {
            setApproveRejConnModal(false);
        }
        };
        window.addEventListener("click", onWindowClick);
        return () => {
        window.removeEventListener("click", onWindowClick);
        };
    }, []);
    const handleApproveConnectionRequest = async () => {
        const user = await get_current_user(typeUser);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/connections/${userID}?connectionStatus=Approved`, {},{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    const handleRevokeConnectionRequest = async () => {
        const user = await get_current_user(typeUser);
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${userID}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    return (
        <div ref={wrapperRef}  className="ApproveRejectConnRequestWrapper">
            <div className="ApproveRejectConnRequestBackground">
                <div className="ApproveRejectConnRequestContainer">
                    <div className="ApproveRejectConnRequestTitle">
                        <p className="text-[1.1rem]">Approve / Reject Connection Request</p>
                        <button
                            onClick={() => {
                            setApproveRejConnModal(false);
                            }}
                        > 
                            <GrFormClose/>
                        </button>
                    </div>
                    <p className="my-1 text-[0.9rem]" >If you are interested to approve the connection request,please click on Approve else click on Reject below.</p>
                    <div className="ApproveRejectConnRequestBody">
                        <button className="approveBtn" onClick={() => {
                            handleApproveConnectionRequest()
                        }}>Approve</button>
                        <button className="rejectReqBtn" onClick={() => {handleRevokeConnectionRequest()}}>Reject</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ApproveRejectConnRequest;