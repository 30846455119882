import React from "react";
import Deactivate from "../../components/Settings/Deactivate";
import Sidebar from "../../components/Sidebar/Sidebar";

export default function Settings({ typeUser }) {
  return (
    <>
      <div className="bg-[#F4F8FB] min-h-screen flex md:gap-5">
        <Sidebar userType={typeUser} />
        <Deactivate type={typeUser} />
      </div>
    </>
  );
}
