export default function chatSocketMiddleware(socket) {
    return store => next => action => {
        if (action.type != 'socket') {
            return next(action)
        }
        
        let { promise, resType, meta } = action 

        next({ type: `${resType}/pending`, meta })

        return promise(socket)
            .then(res => next({ type: `${resType}/fulfilled`, payload: res, meta }))
            .catch(err => next({ type: `${resType}/rejected`, payload: err, meta }))
    }
}