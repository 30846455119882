import { useState } from 'react'

const SectionSelect = ({ list, className, disabled, onSelect }) => {
    const [selected, setSelected] = useState(0)
    
    const basis = 100 / list.length
    const left = selected * basis

    const onClick = (i) => {
        if (onSelect) {
            onSelect(list[i])
        }
        
        setSelected(i)
    }

    return (
        <>
            <div className={`flex justify-center ${className}`}>
                {list.map((x, i) => (
                    <button key={i} disabled={disabled[i]} style={{ flexBasis: `${basis}%` }} className={`text-[0.72rem] sm:text-[0.78rem] py-2 transition-color duration-300 disabled:text-[#bbb] ${selected == i ? 'text-[#22A5FF]' : 'text-[#999]'}`} onClick={() => onClick(i)}>
                        {x}
                    </button>
                ))}
            </div>

            <div className='flex items-center relative'>
                <span className='absolute inline-block bg-[#CECECE] w-full h-[1px] rounded-full'/>    
                <span style={{ width: `${basis}%`, marginLeft: `${left}%` }} className='absolute inline-block bg-[#22A5FF] h-[2px] rounded-full transition-all duration-300' />    
            </div>
        </>
    )
}

export default SectionSelect