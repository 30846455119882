import { configureStore } from "@reduxjs/toolkit";
import reducer from "./slices/profileSlice";
import corpReducer from "./slices/corporateSlice";
import ProfileReducer from "./slices/fetchProfile";
import CorporateReducer from "./slices/fetchCorpProfile";
import NGOReducer from "./slices/fetchNGO";
import NGOslice from "./slices/NGOslice";
import search from "./slices/searchSlice";
import postsReducer from "./slices/postsSlice";
import opportunityReducer from "./slices/opportunitiesSlice"
import createPostReducer from "./slices/createPostSlice";
import userSliceReducer from './slices/userSlice'
import notificationsSlice from "./slices/notificationsSlice";
import chatReducer from './slices/chatSlice';
import chatSocketMiddleware from "./middlewares/chatSocketMiddleware";
import Socket from "../utils/Socket";

const chatSocket = new Socket(process.env.REACT_APP_CHAT_API)

export const store = configureStore({
  reducer: {
    profileForm: reducer,
    userProfile: ProfileReducer,
    corpProfile: CorporateReducer,
    corpForm: corpReducer,
    NGOProfile: NGOReducer,
    NGOform: NGOslice,
    filterSearch: search,
    posts: postsReducer,
    opportunities:opportunityReducer,
    createPost: createPostReducer,
    currentProfile: userSliceReducer,
    notifications: notificationsSlice,
    chat: chatReducer
  },

  middleware: (getDefaultMiddleware) => [ ...getDefaultMiddleware({ serializableCheck: false }), chatSocketMiddleware(chatSocket) ]
});
