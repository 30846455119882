import React from "react";
import avatarImg from "../dummyData/images/placeholder-avatar.jpg";
import { useNavigate } from "react-router-dom";
const OtherLeaderCard = ({ leader, rank, credits ,typeUser,
  rankUserType }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-6">
        <p>{rank}</p>
        <img src="" alt="" />
        <img
          className="w-10 h-10 rounded-full"
          src={leader.profilePic || avatarImg}
          alt="Avatar"
        />
        <p className="cursor-pointer" onClick={() =>
          navigate(`/${typeUser}/view-${rankUserType}/${leader._id}`)
      }>{leader.name}</p>
      </div>
      <div>
        <p>{credits}</p>
      </div>
    </div>
  );
};

export default OtherLeaderCard;
