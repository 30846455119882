import React from "react";
import ThankYouComponent from "../../../components/ThankYou/ThankYou";
import organisation from "../../../images/NGO.png";
import logo from "../../../images/Logo.png";

export default function ThankYou() {
  return (
    <>
      <div className="md:h-screen md:flex justify-center ngo-img">
        <img
          src={organisation}
          alt="volunteer"
          className="w-full h-[250px] md:hidden"
        />
        <div className="flex bg-white md:hidden  justify-center  -mt-20 z-10 ">
          <img
            src={logo}
            alt="logo"
            className="rounded-[50%] w-40 bg-white p-5 my-5"
          />
        </div>
        <ThankYouComponent type = "organization" user="Social Impact Organisation" />
      </div>
    </>
  );
}
