import React from "react";
import crownImg from "../dummyData/images/crown.png";
import { useNavigate } from "react-router-dom";
const FirstLeaderCard = ({
  classes,
  first,
  second,
  third,
  name,
  credits,
  image,
  typeUser,
  userId,
  rankUserType
}) => {
  console.log(name!= undefined ? name.split(" ")[0] : name );

  // console.log(name.split(" "));
  // const firstName = name.split(" ");
  // console.log(firstName);
  const rank = first ? "1st" : second ? "2nd" : third ? "3rd" : "";
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col items-center text-white gap-14 hover:scale-105 transition-all  ${classes} ${
        first && "gap-24"
      }`}
    >
      <p className="text-2xl font-bold">{rank}</p>
      {/* Card */}
      <div className="flex flex-col items-center w-full px-2 py-4 bg-white rounded-md h-[12em]">
        <div
          className={`flex items-center flex-col -mt-[3.5rem] ${
            first && "-mt-[7rem]"
          }`}
        >
          {first && (
            <img className="z-[2] w-16 h-16 -mb-5" src={crownImg} alt="crown" />
          )}
          <img
            className="w-[5em] h-[5em] overflow-hidden rounded-full"
            src={image}
            alt="First"
          />
        </div>
        <div className="flex flex-col items-center gap-[1.5em] text-black">
          <strong  className="cursor-pointer truncate text-[1.2em] mt-[1.5em]" onClick={() => navigate(`/${typeUser}/view-${rankUserType}/${userId}`)}>
            {name!= undefined ? name.split(" ")[0] : name }
            </strong>
          <div className="flex flex-col items-center">
            <strong className="text-[1.1em]">{credits}</strong>
            <p className="text-[1.1em]">Credits</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstLeaderCard;
