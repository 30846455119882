import { React, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import logo from "../../images/Logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
import { location } from "../Profile/data";
import { locationOptions } from "../Preferences/data/data";

export default function ProfileSetup({ formData, setFormData }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const regex = new RegExp(/^(\+\d{1,3})?\d{10}$/);

  // const onInputChange = (e) => {
  //   if (regex.test(e.target.value)) {
  //     setFormData({ ...formData, contact: e.target.value });
  //   } else {
  //     alert("Please enter a valid contact number");
  //   }
  // };

  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");

  const handleChangeContact = (e) => {
    // const newValue = e.target.value.replace(/[^0-9]/g, "");
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
    }

    if (e.target.value.length != 10) {
      setPhoneError("Please enter a valid contact number.");
    } else {
      setPhoneError("");
    }

    // if (newValue.length <= 10) {
    setFormData({
      ...formData,
      contact: e.target.value,
    });
    // }
  };

  const handleChangeLinkedinURL = (e) => {
    const urlRegex = RegExp(
      /((?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,}))/
    );
    const { value } = e.target;
    if (!urlRegex.test(value)) {
      setWebsiteError("Please enter a valid Linkedin URL.");
    } else {
      setWebsiteError("");
    }

    setFormData({
      ...formData,
      linkedIn: e.target.value,
    });
  };

  // For limiting input date field to avoid selecting present year
  const currentYear = new Date().getFullYear();
  const maxDate = `${currentYear - 1}-12-31`;

  return (
    <>
      <div
        className={`flex w-[100%] xl:w-[60%] lg:w-[60%] md:w-[60%] flex-row justify-between ml-[2rem]`}
      >
        <div className="relative z-0 w-1/2 flex mx-auto">
          <input
            id="name"
            className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            placeholder=" "
            onChange={(e) => {
              setFormData({
                ...formData,
                name: e.target.value,
              });
            }}
            value={formData.name}
            required
          />
          <label
            htmlFor="name"
            className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
              isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
            }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
          >
            Name <span className="">*</span>
          </label>
        </div>
        <div className="relative z-0 w-1/2 flex mx-auto">
          <select
            type="text"
            id="gender"
            className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            placeholder=" "
            onChange={(e) => {
              setFormData({
                ...formData,
                gender: e.target.value,
              });
            }}
            value={formData.gender}
            required
          >
            <option value="" selected={true} disabled={true}>
              -- select an option --{" "}
            </option>

            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
          </select>
          <label
            htmlFor="gender"
            className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
              isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
            }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
          >
            Gender <span className="">*</span>
          </label>
        </div>
      </div>

      <div
        className={`flex w-[100%] xl:w-[60%] lg:w-[60%] md:w-[60%] flex-row justify-between ml-[2rem]`}
      >
        <div className="relative z-1000 w-1/2 flex ">
          <input
            type="date"
            id="DOB"
            className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            placeholder=""
            // max={new Date().toISOString().substring(0, 10)}
            max={maxDate}
            onChange={(e) => {
              setFormData({
                ...formData,
                DOB: e.target.value,
              });
            }}
            value={formData.DOB}
            required
          />
          <label
            htmlFor="DOB"
            className={`absolute  text-black duration-300 transform -translate-y-9 -translate-x-1 scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
              isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
            }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
          >
            Date of Birth <span className="">*</span>
          </label>
        </div>
        <div className="relative z-0 w-1/2 flex mx-auto items-center">
          <span className="absolute text-[0.88rem] ml-2 md:ml-3 text-[#444] md:text-[0.96rem]">
            +91
          </span>

          <span className="absolute ml-[2.5rem] bg-[#bbb] w-[1px] md:ml-[3.2rem] h-[60%] md:w-[2px] md:bg-[#c8c8c8] rounded inline-block"></span>

          <input
            type="number"
            maxlength="10"
            id="contact"
            className="py-2 px-3 pl-[2rem] pl-[2.8rem] md:pl-[3.8rem] bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            // onChange={(e) => {
            //   setFormData({
            //     ...formData,
            //     contact: e.target.value,
            //   });
            // }}
            onChange={handleChangeContact}
            // onInput={onInputChange}
            pattern="/^(\+\d{1,3})?\d{10}$/"
            value={formData.contact}
            required
          />
          {phoneError && (
            <div
              style={{
                color: "red",
                display: "flex",
                fontSize: "10px",
                position: "absolute",
                marginTop: "52px",
              }}
            >
              {phoneError}
            </div>
          )}
          <label
            htmlFor="contact"
            className={`absolute  text-black duration-300  left-1 -top-6  scale-90  z-10 origin-[0]   text-md
            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 `}
          >
            Contact Number <span className="">*</span>
          </label>
        </div>
      </div>

      <div
        className={`flex w-[100%] xl:w-[60%] lg:w-[60%] md:w-[60%] flex-row justify-between ml-[2rem]`}
      >
        <div className="relative z-0 w-1/2 flex ">
          <select
            id="dropdown"
            className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            name="location"
            onChange={(e) => {
              setFormData({
                ...formData,
                location: e.target.value,
              });
            }}
            value={formData.location}
            required
          >
            <option value="" selected={true} disabled={true}>
              Primary Location <span className="">*</span>
            </option>
            {location.map((data, index) => {
              return (
                <>
                  <option key={index} value={data}>
                    {data}
                  </option>
                </>
              );
            })}
          </select>
        </div>
        <div className="relative z-0 w-1/2 flex mx-auto">
          <input
            type="url"
            // pattern="/^(https?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/"
            id="linkedIn"
            className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
            placeholder=" "
            // onChange={(e) => {
            //   setFormData({
            //     ...formData,
            //     linkedIn: e.target.value,
            //   });
            // }}
            onChange={handleChangeLinkedinURL}
            // value={formData.linkedIn ? formData.linkedIn : "https://"}
            value={formData.linkedIn}
            required
          />
          {websiteError && (
            <div
              style={{
                color: "red",
                display: "flex",
                fontSize: "10px",
                position: "absolute",
                marginTop: "40px",
              }}
            >
              {websiteError}
            </div>
          )}
          <label
            htmlFor="linkedIn"
            className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
              isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
            }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
          >
            LinkedIn Profile <span className="">*</span>
          </label>
        </div>
      </div>
    </>
  );
}
