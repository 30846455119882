import React, { useEffect, useState } from "react";
import ExtendedProfileSetup from "./ExtendedProfileSetup";
import ProfileSetup from "./ProfileSetup";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { get_token } from "../../aws_cognito/get_token";
import get_current_user from "../../aws_cognito/get_user";
import { useSearchParams } from "react-router-dom";
import { create_session } from "../../aws_cognito/create_session";
import { label } from "aws-amplify";
import logo from "../../images/Logo.png";
import { refresh_tokens } from "../../aws_cognito/refresh_tokens";
import logout from "../../aws_cognito/logout";

export default function ProfileMultiStep({ type }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    DOB: "",
    contact: "",
    location: "",
    linkedIn: "",
    index: 0,
    availability: "",
    lang: "",
    why: "",
    employ_level: "",
    other: "",
    volunteerMode: "",
  });

  let firstLang = formData.lang;
  let language = [
    { value: 1, label: firstLang, read: true, write: true, speak: true },
  ];
  // todo: remove after testing google oauth signup
  // useEffect(() => {
  //   if (searchParams.get("code") !== null) {
  //     const fetchToken = async () => {
  //       const code = searchParams.get("code");
  //       const res = await get_token(code, 'volunteer');
  //       if (res !== null) {
  //         console.log("aftercall ", res);
  //         const sess = await create_session(
  //           res?.data.id_token,
  //           res?.data.access_token,
  //           res?.data.refresh_token,
  //           type
  //         );
  //         console.log(sess, "session details");
  //       }
  //     };
  //     fetchToken();
  //   }
  //   // console.log(formData.lang[0].label, "label");
  // }, []);

  function handleSubmit() {
    if (page === 0) {
      // do form validation
      setPage(page + 1);

      console.log(formData);
    } else if (page === 1) {
      setPage(page + 1);
      navigate("/Profile", { replace: true });

      console.log(formData);
    } else setPage(page + 1);
    // setPage(page + 1);

    console.log(formData, "formData");
  }
  // const handleFinalSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let res = await fetch("http:/localhost:5000/api/volunteer", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         name: formData.name,
  //         phone_number: formData.contact,
  //         employmentLevel: formData.employ_level,
  //         DOB: formData.dob,
  //         languages: formData.lang,
  //         locations: formData.location,
  //         availablity: formData.availability,
  //         linkedIn: formData.linkedIn,
  //         reason: formData.why,
  //         edu_level: formData.edu_level,
  //       }),
  //     });
  //     let resJson = await res.json();
  //     if (res.status === 200) {
  //       console.log(resJson);
  //       // navigate("/Profile", { replace: true });
  //     } else {
  //       // setMessage("Some error occured");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleFinalSubmit = async (e) => {
    setLoading(true);
    console.log(formData);
    e.preventDefault();
    try {
      console.log("clicked");
      const user = await get_current_user("volunteer");
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/volunteer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
          body: JSON.stringify({
            name: formData.name,
            gender: formData.gender,
            phone_number: formData.contact,
            employmentLevel:
              formData.other !== "" ? formData.other : formData.employ_level,
            DOB: formData.DOB,
            languages: language,
            location: formData.location,
            volunteerMode: formData.volunteerMode,
            linkedIn: formData.linkedIn,
            reason: formData.why,
            // edu_level: formData.edu_level,t
          }),
        }
      );
      const response = await data.json();
      setLoading(false);

      if (response.status === "success") {
        console.log(response, "success");
        await refresh_tokens("volunteer");
        navigate(`/volunteer/preferences`);
      } else {
        // alert(response.data);
        console.log(response, "error");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const [page, setPage] = useState(0);
  useEffect(() => {
    console.log(page, "page");
  }, [page]);

  // const urlRegex = RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)
  //linkedin url validation
  const urlRegex = RegExp(
    /((?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,}))/
  );

  const phoneRegex = RegExp(/^(\+\d{1,3})?\d{10}$/);
  const isDisabled1 = (x) =>
    !x.name ||
    !x.gender ||
    !x.DOB ||
    !phoneRegex.test(x.contact) ||
    !x.location ||
    !urlRegex.test(x.linkedIn);
  const isDisabled2 = (x) =>
    !x.lang ||
    !x.why ||
    !x.employ_level ||
    (x.employ_level == "Other" && !x.other) ||
    !x.volunteerMode;
  const isDisabled = page == 0 ? isDisabled1(formData) : isDisabled2(formData);

  return (
    <>
      <div className="w-[95%] lg:w-[70%] xl:w-[65%] flex flex-col z-10 h-screen bg-white p-8 overflow-y-scroll">
        {/* for the img in mobile view */}
        {isMobile ? (
          <></>
        ) : (
          <div className="flex flex-row justify-center">
            <img src={logo} alt="logo" className="w-36" />
          </div>
        )}
        <div className="flex flex-col justify-center text-3xl font-inter font-bold">
          <h1 className="flex justify-center">
            Set up a profile<span className="text-blue">.</span>
          </h1>
        </div>
        <p className="flex justify-center text-md font-inter mt-6">
          Don’t worry, you can edit this later.
        </p>
        <div className="flex flex-col items-center w-[100%] h-[100%] mt-12">
          <form className="flex flex-col items-center justify-center mb-5 space-y-8 w-[100%] ">
            {page === 0 ? (
              <>
                <ProfileSetup formData={formData} setFormData={setFormData} />
                <div className="flex flex-row justify-center items-center"></div>
              </>
            ) : (
              <>
                <ExtendedProfileSetup
                  formData={formData}
                  setFormData={setFormData}
                />
              </>
            )}
            <div className="flex flex-row justify-between w-fit mt-16">
              {page == 0 && (
                <button
                  className={` w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md mb-2 mr-2 z-10 bottom-[1rem] left-[56%] sm:bottom-[4rem] sm:left-[52%]"
                      }`}
                  onClick={() => logout(type, false)}
                >
                  Cancel
                </button>
              )}

              {page > 0 && (
                <button
                  className={` w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md mb-2 mr-2 z-10 bottom-[1rem] left-[56%] sm:bottom-[4rem] sm:left-[52%]"
                      }`}
                  onClick={() => setPage(page - 1)}
                >
                  Back
                </button>
              )}
              {loading ? (
                <button
                  disabled
                  type="button"
                  className="w-28 h-10 bg-sky-300  text-white font-semibold text-sm rounded-md mb-2 z-10"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Creating Profile...
                </button>
              ) : (
                <button
                  style={{ opacity: isDisabled ? 0.6 : 1 }}
                  disabled={isDisabled}
                  className={` w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md mb-2 z-10 "
                      }`}
                  onClick={(e) =>
                    page === 1 ? handleFinalSubmit(e) : handleSubmit()
                  }
                >
                  {page === 0 || page === 1 ? "Continue" : "Submit"}
                </button>
              )}
            </div>
            {/* {page > 0 && (
              <button
                className={` w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md  z-10  ${isMobile
                  ? "bottom-[1rem] left-[23%]"
                  : "bottom-[4rem] left-[41%]"
                  }`}
                onClick={() => setPage(page - 1)}
              >
                Back
              </button>
            )} */}
          </form>
        </div>
      </div>
    </>
  );
}
