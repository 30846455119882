import { useSelector } from 'react-redux'
import defaultAvatar from '../../images/default.jpg'
import notificationTypes from './notificationTypes'

export default function Notification({ data }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)

    switch (data.type) {
        case notificationTypes.BLOOD_REQUIREMENT:
            var icon = (
                <svg className='ml-1' width="20" height="32" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.3462 1.78109C13.1723 2.94588 10.4302 5.68397 7.88299 9.1927L14.3462 1.78109ZM14.3462 1.78109C15.4515 2.87737 18.1713 5.60065 20.7225 9.10643L20.7226 9.10662C23.5718 13.0206 26.1586 17.8372 26.1586 22.4312C26.1586 27.083 24.7829 30.3093 22.6557 32.3738L22.6553 32.3742C20.5246 34.4434 17.5823 35.4 14.3461 35.4C11.1093 35.4 8.16769 34.4427 6.03666 32.374L6.0365 32.3738C3.91015 30.3102 2.53359 27.0837 2.53359 22.4312C2.53359 17.8364 5.06427 13.0762 7.88291 9.1928L14.3462 1.78109Z" fill="#E46666" stroke="#E46666" strokeWidth="3.2"/>
                </svg>
            )

            var color = '#FFB0B0'
            var message = `${data.metaData.user.name} has posted a blood donation request which you might be a fit for. Please help out!`
            var href = `/post/${data.metaData.post}`
            break     

        case notificationTypes.OPPORTUNITY:
            var icon = (
                <svg className='ml-1' fill='#7a6931' width={28} height={28} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"/>
                </svg>
            )

            var message = `${data.metaData.user.name} has shared a volunteering opportunity which you might be interested in`
            var color = '#FFE68F'
            
            if (data.metaData.post) {
                var href = `/post/${data.metaData.post}`
            }

            break

        case notificationTypes.TOP_10_RANK: 
            var icon = (
                <svg height={31} width={31} fill='#fac152' className='ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"/>
                </svg>
            )

            var message = `Congrats! You've broken into top 10 of the leaderboard! Keep going!`
            var color = '#fcefd4'

            break

        case notificationTypes.TOP_3_RANK: 
            var icon = (
                <svg height={31} width={31} fill='#fac152' className='ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"/>
                </svg>
            )

            var message = `Congrats! You're now ranked ${data.metaData.rank} in the leaderboard! Keep going!`
            var color = '#fcefd4'

            break

        default:
            var profilePic = data.metaData.user.profilePic

            var icon = (
                <img className='w-10 h-10 rounded-full' src={(!profilePic || profilePic == '') ? defaultAvatar : profilePic}></img>
            )

            switch (data.type) {
                case notificationTypes.CREDIT_REQUEST_UPDATED:
                    var message = `Your request for volunteering credits have been updated by ${data.metaData.user.name}`
                    break

                case notificationTypes.CREDIT_REQUEST_APPROVED:
                    var message = `Your request for volunteering credits have been approved by ${data.metaData.user.name}`
                    break

                case notificationTypes.CREDIT_REQUEST_REJECTED:
                    var message = `Your request for volunteering credits have been rejected by ${data.metaData.user.name}`
                    break
     
               case notificationTypes.CREDIT_REQUEST_RECEIVED:
                    var message = `${data.metaData.user.name} has requested for volunteering credits, Check it out now!`
                    var href = `/${userProfile.data?.type}/profile`
                    break
    
                case notificationTypes.USER_INTERESTED:
                    var message = `${data.metaData.user.name} has expressed interest on your blood donation request`
                    break

                case notificationTypes.USER_COMMENTED:
                    var message = `${data.metaData.user.name} has commented on your post`
                    break

                case notificationTypes.USER_LIKED:
                    var message = `${data.metaData.user.name}${data.metaData.likes > 0 ? ` and ${data.metaData.likes} other people` : ' '} liked your post`
                    break

                case notificationTypes.USER_PARTICIPATED:
                    var message = `${data.metaData.user.name} is participating on your opportunity post`
                    break

                case notificationTypes.WORK_REQUEST_RECEIVED:
                    var message = `${data.metaData.user.name} says they work at your company, Please confirm!`
                    var href = `/${userProfile.data?.type}/profile`
                    break
                
                case notificationTypes.WORK_REQUEST_ACCEPTED:
                    var message = `Yay! ${data.metaData.user.name} has recognized you as a part of their team!`
                    break
                
                case notificationTypes.WORK_REQUEST_ACCEPTED:
                    var message = `Oh no! ${data.metaData.user.name} didn't recognize you as a part of their team.`
                    break
            }

            var color = '#f8f8f8'
            
            if (data.metaData.post) {
                var href = `/post/${data.metaData.post}`
            }

            break
    
    }
    
    return (
        <a style={{ backgroundColor: color }} href={href} className={`p-4 rounded-lg flex items-center mt-3 shadow`}>
            {icon}

            <p className='ml-5 text-[0.8rem]'>
                {message}
            </p>
        </a>
    )
}