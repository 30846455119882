const postTypeEnum = {
    GENERAL: 'general',
    BLOOD_REQUIREMENT: 'bloodrequirement',
    OPPORTUNITY: 'opportunity'
  }
  
  const opportunityModeEnum = {
    OFFLINE: 'Offline',
    REMOTE: 'Remote'
  }
  
  const opportunityTypeEnum = {
    VOLUNTEERING: 'volunteering',
    CORPORATE: 'corporate'
  }

  const socketEventEnum = {
    CONNECT: 'connect',
    CREATE_PRIVATE_MESSAGE: 'create_private_message',
    CREATE_CAUSE_CIRCLE_MESSAGE: 'create_cause_circle_message',
    PRIVATE_MESSAGE_CREATED: 'private_message_created',
    CAUSE_CIRCLE_MESSAGE_CREATED: 'cause_circle_message_created',
    USER_STATUS_CHANGE: 'user_status_change',
    USERS_ONLINE: 'users_online',
    USER_SAW_MESSAGES: 'user_saw_messages',
    OTHER_SAW_MESSAGES: 'other_saw_messages',
    USER_TYPING: 'user_typing',
    OTHER_TYPING: 'other_typing',
    CREATE_BROADCAST_MESSAGE: 'create_broadcast_message',
    BROADCAST_MESSAGE_CREATED: 'broadcast_message_created'
  }

  const broadcastTypes = {
    ALL: 'all',
    VOLUNTEER: 'volunteer',
    CORPORATE: 'corporate',
    IMPACT_ORG: 'organization'
  }

  const chatTypes = {
    PRIVATE: 'private',
    BROADCAST: 'broadcast'
  }

  module.exports = {
    postTypeEnum,
    opportunityModeEnum,
    opportunityTypeEnum,
    socketEventEnum,
    broadcastTypes,
    chatTypes
  }