import cog_pool from "./user_pool";

const get_current_user = async (type) => {
    return new Promise((resolve, reject) => {
        const pool = cog_pool(type)
        const cognitoUser = pool.getCurrentUser();
        if (cognitoUser !== null) {
            cognitoUser.getSession(function (error, session) {
                if (error) {
                    // handle errror
                    console.log('something wrong')
                    reject({ code: error.code, message: error.message });
                }
                console.log(cognitoUser);
                console.log('session validity: ' + session.isValid()); // remove in production
                // console.log(session.getRefreshToken().token)
                const payload = session.getIdToken().payload;
                let type = null;
                let db_id = null;
                if (payload['custom:db_id'] !== undefined) {
                    // console.log(payload['custom:db_id']);
                    // const type = payload['cognito:groups'].at(-1);                    
                    db_id = payload['custom:db_id'];
                } else {
                    // console.log(payload['custom:db_id']);
                }
                const cog_groups = payload['cognito:groups'];
                // console.log(cog_groups)
                type = cog_groups.filter((group) => ['volunteer', 'organization', 'corporate'].includes(group));
                // console.log('type', type[0])
                resolve({
                    data: {
                        accessToken: session.getAccessToken().getJwtToken(),
                        idToken: session.getIdToken().getJwtToken(),
                        refToken: session.getRefreshToken(),
                        db_id: db_id,
                        type: type[0],
                        user: cognitoUser
                    }
                });
            });
        } else {
            console.log('something went wrong')
            // ! can change to any other value
            reject({ code: null, message: "User not logged in" });
        }
    });
};

export default get_current_user;
