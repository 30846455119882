import React, { useState, useRef, useContext } from 'react'
import { FocusContext } from '../Focusable/Focusable'
import categories from '../../../utils/categories'
import './Categories.css'

export default function Categories({ onSelect, onDeselect, windowWidth, currentCategories, clearCategories, disabled }) {
    const focusContext = useContext(FocusContext)
    const selectedRef = useRef({})

    currentCategories.forEach(x => selectedRef.current[x] = true)

    const check = (e) => {
        if (disabled)
            return

        const node = e.target
        const category = node.getAttribute('data-category')

        node.classList.toggle('active')
        
        if (selectedRef.current[category]) {
            selectedRef.current[category] = false
            onDeselect(category)
        }

        else {
            selectedRef.current[category] = true
            onSelect(category)
        }
    }

    return (
        <>
        { windowWidth >= 900 &&
            <div id='categories-select' className={`p-4 flex flex-col ${focusContext.focus ? 'focus' : ''} ${disabled && 'opacity-40 pointer-events-none'}`}>
                { focusContext.focus &&
                    <span className='inline-block h-3 w-[12px] m-1 mb-2 cursor-pointer text-[0.7rem] self-end absolute right-3 top-3' onClick={focusContext.defocusElement}>
                        <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-45'></span>
                        <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-[-45deg]'></span>
                    </span>
                }
                
                <div className={`relative ${focusContext.focus ? 'mt-5' : ''}`}>
                    <span className='inline-block font-semibold text-l text-[#222]'>Categories</span>
                    <span className='absolute inline-block font-semibold right-2 top-1 text-[0.7rem] cursor-pointer'
                        onClick={() => {
                            clearCategories()
                            selectedRef.current = {}
                        }}>
                        Clear Filter
                    </span>
                </div>

                <div id='categories' className='scrollable mt-8 mb-4'>
                    {categories.slice(0, (focusContext.focus ? -1 : 9)).map((x, i) => (
                        <span key={i} 
                            className={`category inline-block rounded-full py-[0.34rem] px-2 p-0 text-[0.6rem] mr-3 mb-4 lg:py-2 lg:px-3 lg:text-[0.65rem] ${!disabled && 'cursor-pointer'} ${currentCategories.includes(x) ? 'active' : ''}`}
                            onClick={(e) => {
                                if (disabled)
                                    return

                                const category = e.target.innerHTML 

                                if (e.target.classList.contains('active')) {
                                    onDeselect(category)
                                }

                                else {
                                    onSelect(category)
                                }

                                e.target.classList.toggle('active')
                            }}>
                            {x}
                        </span>
                    ))}
                </div>

                { !focusContext.focus &&
                    <div className='text-[0.75rem] ml-auto mr-2 cursor-pointer'>
                        <span className='cursor-pointer' onClick={focusContext.focusElement}>View all</span>
                    </div>
                }
            </div>
        }

        { windowWidth < 900 &&
            <div className='flex flex-col bg-white shadow-sm md:shadow-md rounded-md px-4 py-6 mt-4'>
                <span className='inline-block font-medium self-end text-[0.74rem] cursor-pointer'
                    onClick={() => {
                        clearCategories()
                        selectedRef.current = {}
                    }}>
                    Clear Filter
                </span>

                <div className='mt-6 scrollable max-h-[500px] overflow-y-scroll'>
                    { categories.map((x, i) => 
                        <div key={i} className='flex items-center'>
                            <div className={`${selectedRef.current[x] ? 'active' : ''} checkbox w-6 h-6 m-3 ml-2 relative border-[2px] rounded border-[#22A5FF] ${!disabled && 'cursor-pointer'}`} data-category={x} onClick={check}>
                                <svg class='checkbox-svg' width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 13L9 17L19 7" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <span className='text-[0.76rem] ml-2 font-medium text-[#222]'>{x}</span>
                        </div>
                    )}
                </div>
            </div>
        }
        </>
    )
}