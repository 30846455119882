import axios from "axios";
import React, { useEffect, useState } from "react";
import get_current_user from "../../aws_cognito/get_user";
import Loading from "../Admin/components/Loading/Loading";
import FirstLeaderCard from "../Admin/Leaderboard/FirstLeaderCard";

import styles from "../Admin/Leaderboard/LeaderBoard.module.css";
import OtherLeaderCard from "../Admin/Leaderboard/OtherLeaderCard";
import avatarImg from "../Admin/dummyData/images/placeholder-avatar.jpg";
import { useSelector } from "react-redux";

const LeaderBoard = ({ typeUser }) => {
  // const [setPageName] = useOutletContext();
 
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [rankUserType,setRankUserType]=useState("volunteer");
  const userProfile = useSelector(state => state.currentProfile.userProfile.data);

  const [leaderVolunteers, setLeaderVolunteers] = useState([]);
  const [leaderOrganizations, setLeaderOrganizations] = useState([]);
  const [leaderCorporates, setLeaderCorporates] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [myData, setMyData] = useState(null);

  const activeTabClasses = "font-bold border-b-[0.35rem] border-b-[#22A5FF]";

  useEffect(() => {
    const fetchAndSetLeaders = async () => {
      const user = await get_current_user(typeUser);
      const token = user.data.idToken;
      const {
        data: { data: resVolunteers },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/volunteer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      const {
        data: { data: resOrganizations },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/impact-org`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      const {
        data: { data: resCorporates },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/leaderboard/corporate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
        );
      //console.log(resVolunteers);
      console.log(resOrganizations);
      //console.log(resCorporates)
      setLeaderVolunteers(resVolunteers);
      setLeaderOrganizations(resOrganizations);
      setLeaderCorporates(resCorporates);
      setLoading(false);
    };
    fetchAndSetLeaders();
  }, [typeUser]);

  useEffect(() => {
    setLeaders(
      activeTab === 1
        ? leaderVolunteers
        : activeTab === 2
        ? leaderCorporates
        : leaderOrganizations
    );
  }, [activeTab, leaderVolunteers, leaderCorporates, leaderOrganizations]);

  useEffect(() => {
    let data, idx;
    switch (typeUser) {
      case "volunteer":
        idx = leaderVolunteers?.findIndex(
          (item) => item._id === userProfile._id
        );
        data = {
          credits: leaderVolunteers[idx]?.creditsRecieved,
          rank: idx + 1,
        };
        break;
      case "organization":
        idx = leaderOrganizations?.findIndex(
          (item) => item._id === userProfile._id
        );
        data = {
          credits: leaderOrganizations[idx]?.creditsGranted,
          rank: idx + 1,
        };
        break;
      case "corporate":
        idx = leaderCorporates?.findIndex(
          (item) => item._id === userProfile._id
        );
        data = {
          credits: leaderCorporates[idx]?.employeeCreditsRecieved,
          rank: idx + 1,
        };
        break;
      default:
        break;
    }
    setMyData(data);
  }, [
    leaderCorporates,
    leaderOrganizations,
    leaderVolunteers,
    typeUser
  ]);


  if (loading)
    return (
      <div className="grid w-full h-screen place-items-center">
        <Loading />
      </div>
    );

  return (
    <div className="px-8 py-12">
      {/* Tabs */}
      <div className="flex items-center justify-between border-b-2 lg:border-b-transparent border-b-[#CFEBFF] md:justify-start md:gap-24">
        <button
          className={`pb-2 ${activeTab === 1 && activeTabClasses}`}
          onClick={() => { setActiveTab(1); setRankUserType("volunteer"); }}
        >
          Volunteer
        </button>
        <button
          className={`pb-2 ${activeTab === 2 && activeTabClasses}`}
          onClick={() => { setActiveTab(2); setRankUserType("corporate"); }}
        >
          Corporate
        </button>
        <button
          className={`pb-2 ${activeTab === 3 && activeTabClasses}`}
          onClick={() => { setActiveTab(3); setRankUserType("organization"); }}
        >
          Impact Organization
        </button>
      </div>

      {/* Title */}
      <div className="mt-8 mb-6 font-medium">
        <h4 className="text-2xl font-bold">
          Most Credits {activeTab === 3 ? "Granted" : "Received"}
        </h4>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg lg:flex-row lg:w-min">
        {/* Spotlight */}
        <div className={` px-4 py-8 lg:w-[35rem] ${styles.gradient}`}>
          <h2 className="mt-2 mb-6 text-2xl font-bold text-center text-white">
            Impact Maker Spotlight
          </h2>
          <div className="flex items-end justify-between w-full gap-2 md:gap-4 lg:gap-5">
            <div className="flex-grow-[6]">
              <FirstLeaderCard
                name={leaders[1]?.name}
                image={leaders[1]?.profilePic || avatarImg}
                second
                classes={styles.second}
                credits={
                  activeTab === 1
                    ? leaders[1]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[1]?.employeeCreditsRecieved
                    : leaders[1]?.creditsGranted
                }
                userId={leaders[1]?._id}
                typeUser={typeUser}
                rankUserType={rankUserType}
              />
            </div>
            <div className="flex-grow-[7]">
              <FirstLeaderCard
                first
                name={leaders[0]?.name}
                image={leaders[0]?.profilePic || avatarImg}
                credits={
                  activeTab === 1
                    ? leaders[0]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[0]?.employeeCreditsRecieved
                    : leaders[0]?.creditsGranted
                }
                userId={leaders[0]?._id}
                typeUser={typeUser}
                rankUserType={rankUserType}
              />
            </div>
            <div className="flex-grow-[5]">
              <FirstLeaderCard
                third
                classes={styles.third}
                name={leaders[2]?.name}
                image={leaders[2]?.profilePic || avatarImg}
                credits={
                  activeTab === 1
                    ? leaders[2]?.creditsRecieved
                    : activeTab === 2
                    ? leaders[2]?.employeeCreditsRecieved
                    : leaders[2]?.creditsGranted
                }
                userId={leaders[2]?._id}
                typeUser={typeUser}
                rankUserType={rankUserType}
              />
            </div>
          </div>
          {myData &&
            ((activeTab === 1 && typeUser === "volunteer") ||
              (activeTab === 2 && typeUser === "corporate") ||
              (activeTab === 3 && typeUser === "organization")) && (
              <div className="max-w-full px-8 py-5 mx-auto mt-4 bg-white rounded-lg sm:px-12 w-96">
                <div className="flex items-center justify-between">
                  <p className="text-[#C82AA1] font-bold text-xl">
                    Your rank: {myData.rank}
                  </p>
                  <p className="">
                    <span className="text-xl font-bold">{myData.credits}</span>{" "}
                    credits
                  </p>
                </div>
              </div>
            )}
        </div>

        {/* Rank List */}
        <div className="px-6 py-8 font-semibold bg-white lg:w-[35rem] shadow-sm">
          <div className="flex flex-col gap-4  overflow-scroll max-h-[30rem]">
            {leaders.map((leader, rank) => {
              if (rank < 3) return null;
              return (
                <React.Fragment key={rank + 1}>
                  <OtherLeaderCard
                    leader={leader}
                    rank={rank + 1}
                    credits={
                      activeTab === 1
                        ? leader.creditsRecieved
                        : activeTab === 2
                        ? leader.employeeCreditsRecieved
                        : leader.creditsGranted
                    }
                    typeUser={typeUser}
                    rankUserType={rankUserType}
                  />
                  {rank !== leaders.length - 1 && <hr />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
