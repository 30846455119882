import { CognitoUser } from "amazon-cognito-identity-js";
import cog_pool from "./user_pool";
export const verify_email = (code, email, type) => {
    return new Promise((resolve, reject) => {
        if (email === null) {
            console.log('Invalid Email');
            return;
        }
        let userData = {
            Username: email,
            Pool: cog_pool(type),
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(code, true, (err, data) => { // pass the code enterred by the user here
            if (err) {
                if (err.name === '')
                    reject({ error: err.message.split(':')[1], data: null });
                reject({ message: err.message, code: err.code });
            }
            console.log('call result: ' + data); // * remove in production
            resolve({ data: data, error: null });
        });
    })
}
export const resend_code = (email, type) => {
    return new Promise((resolve, reject) => {
        if (email === null || email === '' || email === undefined) {
            console.log('Invalid Email')
            reject("Invalid Email");
        }
        let userData = {
            Username: email,
            Pool: cog_pool(type),
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function (error, result) {
            if (error) {
                // change this to appropriate resp
                alert(error.message || JSON.stringify(error));
                reject({ code: error.code, message: error.message });
            }
            console.log('call result: ' + JSON.stringify(result));
            resolve({ data: result });
        });
    })
}



