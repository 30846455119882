import axios from "axios";
import { useEffect, useState } from "react";
import avatar from "../../pages/Admin/dummyData/images/placeholder-avatar.jpg";
import { useNavigate } from "react-router-dom";
import ViewAllConnectionRequests from './ViewAllConnectionRequests';
import get_current_user from "../../aws_cognito/get_user";
import './ConnectionRequests.css'
export default function ConnectionRequests({type}) {
    const [showModal,setShowModal] = useState(false);
    const [connectionsList, setConnectionsList] = useState({});
    const [connectionRequestCount, setConnectionRequestCount] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        fetchAllConnectionRequests();
    },[connectionRequestCount])
    const fetchAllConnectionRequests = async () => {
        const user = await get_current_user(type)
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/connections/invitations`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            setConnectionsList(res.data.data.connections);
            setConnectionRequestCount(res.data.data.noOfInvitations);
        })
    }
    const handleConnectionConnectRequest = async (reqedUserId) => {
        const user = await get_current_user(type);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/connections/${reqedUserId}?connectionStatus=Approved`, {},{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    const handleRevokeConnection = async (reqedUserId) => {
        const user = await get_current_user(type);
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${reqedUserId}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    return (
        <>
        <ViewAllConnectionRequests
            showModal={showModal}
            setShowModal={setShowModal}
            connectionsList={connectionsList}
            type={type}
        />
            {connectionRequestCount > 0 && <div className="connectionRequestsContainer w-full py-3 bg-white shadow-md md:mt-5 rounded-2xl md:px-5 h-max">
                <div className="flex-col w-full space-y-5">
                    <div className="connectionRequestHeading flex justify-between w-full">
                        <h1 className="font-bold text-center text-lightGray md:text-black">
                            Connection Requests
                        </h1>
                        <button className="flex text-[#919191] font-semibold" onClick={() => setShowModal(true)}>
                            View All
                        </button>
                    </div>
                    <div className="connectionRequestList flex flex-row gap-5 overflow-auto md:flex-col">
                        {Object.entries(connectionsList).map((typeArr) => {
                            return (typeArr[1].map((connectionItem) => {
                                return (
                                    <div key={connectionItem._id} className="flex flex-col items-center justify-between md:flex-row min-w-[7.5rem] gap-4 mb-1">
                                        <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
                                            <img
                                                src={connectionItem.profilePic == "" ? avatar : connectionItem.profilePic}
                                                alt="pfp"
                                                className="w-14 h-14 rounded-full"
                                            />
                                            <p className="font-semibold cursor-pointer" onClick={() =>
                                                navigate(`/${type}/view-${typeArr[0]}/${connectionItem._id}`)
                                            }>
                                                {connectionItem.name}
                                            </p>
                                        </div>
                                        <div className="flex xl:flex-row flex-col"
                                        >
                                            <button style={{ color: '#30AAFF', padding: '5px 15px', borderRadius: '5px', border: '1px solid #30AAFF', fontSize: '0.9rem', fontWeight: '600' }} onClick={() => {
                                                handleConnectionConnectRequest(connectionItem._id)
                                            }}>
                                                Connect
                                            </button>
                                            <p className="mt-2 ml-2 text-center font-semibold text-sm text-[#30AAFF] cursor-pointer" onClick={() => {
                                                handleRevokeConnection(connectionItem._id)
                                            }} >
                                                Delete
                                            </p>
                                        
                                        </div>
                                    </div>
                                )
                            }))
                        })}
                        
                    </div>
                </div>
            </div>
            }
        </>
    );
}
