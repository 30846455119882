import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const API = axios.create({
  baseURL: baseUrl,
});

// to save user in db
// export const create_user = (formData) => {
//     API.post('/volunteer', formData);
// }

export const update_volunteer = (formData, token) => {
  API.put('/volunteer', formData, {
    headers: {

    }
  })
}