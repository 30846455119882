import React, { useState } from "react";
import logo from "../../images/Logo.png";
import { useForm } from "react-hook-form";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import verify from "../../../src/images/Vector.png";
import { CgArrowLongLeft } from "react-icons/cg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { confirmPassword } from "../../aws_cognito/forgot_password";
export default function ResetComponent({ type }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { email, code } = useLocation().state;
  const [resetSuccess, setResetSuccess] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const handleToggle = (type) => {
    if (type === "password") {
      setOpen(!open);
    } else {
      setConfirmPass(!confirmPass);
    }
  };
  const onSubmit = async (data) => {
    if (data) {
      // setResetSuccess(true);
      try {
        console.log(email, code, data.password)
        const res = await confirmPassword(email, code, data.password, type);
        if (res.error === null) {
          setResetSuccess(true)
        }
      } catch (error) {
        alert('Password reset failed');
      }
    }
  };
  return (
    <>
      <div className="w-full md:w-[47%] flex flex-col z-10 md:h-screen bg-white p-8 overflow-y-scroll">
        <div className="hidden md:flex flex-row justify-center mt-4">
          <img src={logo} alt="logo" className="w-36" />
        </div>
        {resetSuccess === true ? (
          <>
            <h1 className="text-center font-bold md:text-3xl md:mt-10 text-lg">
              Password reset successfully<span className="text-blue">!</span>
            </h1>
            <div className="flex justify-center items-center mt-10">
              <img
                className=""
                style={{ width: "80px", height: "80px" }}
                src={verify}
                alt="verify"
              />
            </div>
            <div className="flex justify-center mt-10 items-center gap-2">
              <div className="text-3xl flex items-center text-blue cursor-pointer">
                <Link to="/login">
                  <CgArrowLongLeft />
                </Link>
              </div>
              <a
                href="login"
                className="text-blue  font-bold underline underline-offset-4 w-max cursor-pointer text-center"
              >
                Back to login
              </a>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-center font-bold text-lg md:text-3xl md:mt-10">
              Reset your password<span className="text-blue">.</span>
            </h1>
            <p className="text-center text-xs font-medium text-black leading-5 mt-5 md:w-[40%] mx-auto">
              Enter a new password that was different from the ones you used
              previously
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col justify-center mt-20 space-y-10 w-full"
            >
              <div className="relative z-0 w-full md:w-3/4 flex mx-auto gap-3">
                <input
                  type={open === false ? "password" : "text"}
                  id="password"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("password", {})}
                />
                <label
                  htmlFor="password"
                  className="absolute  text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  Enter Password
                </label>
                <div className="text-2xl flex items-center text-blue cursor-pointer">
                  {open === false ? (
                    <AiOutlineEye onClick={() => handleToggle("password")} />
                  ) : (
                    <AiOutlineEyeInvisible
                      onClick={() => handleToggle("password")}
                    />
                  )}
                </div>
              </div>
              <div className="relative z-0 w-full md:w-3/4 flex mx-auto gap-3">
                <input
                  type={confirmPass === false ? "password" : "text"}
                  id="confirmPass"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("confPassword", {})}
                />
                <label
                  htmlFor="confirmPass"
                  className="absolute  text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  Confirm Password
                </label>
                <div className="text-2xl flex items-center text-blue cursor-pointer">
                  {confirmPass === false ? (
                    <AiOutlineEye onClick={() => handleToggle("confirmPass")} />
                  ) : (
                    <AiOutlineEyeInvisible
                      onClick={() => handleToggle("confirmPass")}
                    />
                  )}
                </div>
              </div>

              <div className="flex justify-center flex-col items-center ">
                <input
                  type="submit"
                  value="Reset Password"
                  className="bg-blue hover:bg-blue-500 text-white font-bold py-2 px-5 text-sm md:text-base md:py-3 md:px-8 rounded-lg cursor-pointer"
                />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}
