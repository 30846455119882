import React, { useEffect, useState } from "react";
import cover from "../../images/default-cover.png";
// import pfp from "../../images/pfp.png";
import { TiCamera } from "react-icons/ti";
import ContactInfo from "./ContactInfo";
import EditProfile from "./EditProfile";
import { useSelector } from "react-redux";
import PhotoModal from "./PhotoModal";
import defaultAvatar from "../../images/default.jpg";
import { data } from "autoprefixer";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import get_current_user from "../../aws_cognito/get_user";
import ViewAllConnections from "./ViewAllConnections"
import WithdrawConnRequest from "./WithdrawConnRequest";
import ApproveRejectConnRequest from "./ApproveRejectConnRequest";
export default function CoverPhotoComponent({me,admin,editModalOpen,setEditModalOpen,typeUser}) {
  const [showModal, setShowModal] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [showAllConnectionsModal, setShowAllConnectionsModal] = useState(false);
  const [totalConnections, setTotalConnections] = useState(0);
  const [edit, setEdit] = useState(true);
  const [withdrawConnModal, setWithdrawConnModal] = useState(false);
  const [approveRejConnModal, setApproveRejConnModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [connectionsList, setConnectionsList] = useState({});
  const profile = useSelector((state) => state.profileForm);
  const userProfile = useSelector((state) => state.userProfile.userProfile); 
  const [connStatus, setConnStatus] = useState(userProfile?.data?.btnStatus);
  useEffect(() => {
    if (id === userProfile?.user_id) {
      navigate(`/volunteer/profile`);
    } 
    
    fetchAllConnections();
  }, []);
  
  const fetchAllConnections = async () => {
    const user = await get_current_user(typeUser)
    if (me == true) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/connections`, {
        headers: {
          Authorization: `Bearer ${user.data.idToken}`
        }
      }).then((res) => {
        setConnectionsList(res.data.data.connections);
        setTotalConnections(res.data.data.count);
      })
    } else {
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/connections?userType=${userProfile.data.type}&userId=${userProfile.data._id}`, {
        headers: {
          Authorization: `Bearer ${user.data.idToken}`
        }
      }).then((res) => {
        setConnectionsList(res.data.data.connections);
        setTotalConnections(res.data.data.count);
      })
    }
  }
  const handleCreateConnect = async (btnVal=null) => {
    if (connStatus == "Connect") {
      const connectData = {
          invitedUserType: userProfile.data.type,
          invitedUserId: userProfile.data._id,
          
      }
      const user = await get_current_user(typeUser)
      
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/connections`, connectData, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
        }
      }).then((res) => {
        console.log(res);
        setConnStatus("Pending Request")
      }).catch(err => console.log(err))
      
    }
    if (connStatus === "Pending Request") {
      setWithdrawConnModal(true);
    }
    if (connStatus === "Remove" || btnVal==="Delete") {
      const user = await get_current_user(typeUser)
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${userProfile.data._id}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
          console.log(res);
          window.location.reload();
        })
    }
    if(btnVal=="Connect"){
      const user = await get_current_user(typeUser);
      axios.put(`${process.env.REACT_APP_BASE_URL}/api/connections/${userProfile.data._id}?connectionStatus=Approved`, {},{
      headers: {
          Authorization: `Bearer ${user.data.idToken}`
          }
      }).then((res) => {
          console.log(res);
          window.location.reload();
      })
    }
    // if(connStatus==="Approve or Reject"){
    //   setApproveRejConnModal(true);
    // }
  }
  const handleAdminVerify = async () => {
    if (!window.confirm("Are you sure you want to verify this user?")) {
      return;
    }

    const { data: adminData } = await get_current_user("admin");
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/volunteer/${id}`,
        {
          adminVerified: true,
        },
        {
          headers: {
            Authorization: `Bearer ${adminData.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      window.location.reload(false);
    } catch (error) {
      console.error(error.response);
    }
  };

  const handleBlockUser = async () => {
    if (!window.confirm("Are you sure you want to block this user?")) {
      return;
    }
    const { data: adminData } = await get_current_user("admin");
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/volunteer/${id}`,
        {
          adminVerified: false,
        },
        {
          headers: {
            Authorization: `Bearer ${adminData.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      window.location.reload(false);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <>
      {me === true ? (
        <div className="flex justify-end pt-5 md:hidden">
          <button
            className="text-sm font-bold text-black "
            onClick={() => setShowEditProfile(true)}
          >
            Edit Profile
          </button>
        </div>
      ) : null}
 
      <div
        className={`bg-white shadow-md md:shadow-none md:mx-0 w-full  pb-5 md:w-full ${
          me === true ? `mt-6` : `mt-16`
        } md:mt-5 rounded-xl `}
      >
        <ViewAllConnections showAllConnectionsModal={showAllConnectionsModal} setShowAllConnectionsModal={setShowAllConnectionsModal} connectionsList={connectionsList} type={typeUser} me={me} />
        <EditProfile
          showModal={showEditProfile || editModalOpen}
          setShowModal={(val) => {
            setShowEditProfile(val);
            setEditModalOpen(val);
          }}
        />
        <PhotoModal
          showModal={showPhotoModal}
          type="Profile"
          user="volunteer"
          setShowModal={setShowPhotoModal}
        />
        <PhotoModal
          showModal={showCoverPhoto}
          type="Cover"
          user="volunteer"
          setShowModal={setShowCoverPhoto}
        />
        <ContactInfo showModal={showModal} setShowModal={setShowModal} />
        <div className="flex flex-col ">
          <div className="relative">
            {me === true ? (
              <div className="absolute right-0 p-2 m-3 text-white rounded-full bg-blue ">
                <label htmlFor="coverImg" className="">
                  <TiCamera
                    className="text-sm cursor-pointer md:text-2xl"
                    onClick={() => setShowCoverPhoto(true)}
                  />
                </label>
              </div>
            ) : null}
            <img
              src={
                userProfile?.data?.coverPic === ""
                  ? cover
                  : userProfile?.data?.coverPic
              }
              alt="cover_pic"
              className="rounded-t-xl rounded-tr-xl object-fit h-28 sm:h-max md:h-[240px] w-full"
            />
          </div>
          <div className="z-10 flex">
            <div className="relative ml-5 -mt-10 md:-mt-16 md:ml-10">
              <img
                src={
                  userProfile?.data?.profilePic === ""
                    ? defaultAvatar
                    : userProfile?.data?.profilePic
                }
                alt="profile_pic"
                className="w-28 h-20 rounded-full object-cover  md:w-[208px] md:h-40"
              />
              {me === true ? (
                <div className="absolute p-2 m-3 text-white rounded-full bg-blue top-8 left-10 md:left-24 md:top-24">
                  <label htmlFor="image">
                    <TiCamera
                      className="text-sm cursor-pointer md:text-2xl"
                      onClick={() => setShowPhotoModal(true)}
                    />
                  </label>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col w-full px-3 py-2 md:py-3 md:px-5">
              <div className="flex justify-between">
                <p className="text-sm font-semibold text-black md:text-base">
                  {profile.name}
                </p>
                {me === true ? (
                  <button
                    className="hidden text-sm font-semibold text-black md:block"
                    onClick={() => setShowEditProfile(true)}
                  >
                    Edit Profile
                  </button>
                ) : null}
              </div>
              {userProfile?.data?.currentCareer !== "" ? (
                <p className="mt-1 text-xs font-light md:text-sm md:w-6/12">
                  {userProfile?.data?.currentCareer}
                </p>
              ) : userProfile?.data?.career?.length === 0 &&
                userProfile?.data?.school === "" ? (
                <p className="mt-1 text-xs font-light md:text-sm md:w-6/12">
                  {userProfile?.data?.employmentLevel}
                </p>
              ) : userProfile?.data?.career?.length !== 0 ? (
                <p className="mt-1 text-xs font-light md:text-sm md:w-6/12">
                  {userProfile?.data?.career[(userProfile?.data?.career?.length)-1]?.designation} at{" "}
                  {userProfile?.data?.career[(userProfile?.data?.career?.length)-1]?.companyName}
                </p>
              ) : (
                <p className="mt-1 text-xs font-light md:text-sm md:w-6/12">
                  {userProfile?.data?.edu_level} at {userProfile?.data?.school}
                </p>
              )}

              <div className="flex gap-1 my-2 md:my-3 text-xs md:text-[13px]">
                <p className="font-normal text-[#919191]">
                  {userProfile?.data?.location}
                </p>
                {userProfile.hide===false && <button
                  className="font-medium cursor-pointer text-blue"
                  onClick={() => setShowModal(true)}
                >
                  Contact Info.
                </button>}
              </div> 
              {/* abc */}
              <div className="my-1 flex flex-col xs:flex-row xs:items-center text-[0.8rem] md:text-[0.9rem]">
                { me==false && (typeUser=="volunteer" || typeUser=="organization")&& connStatus!=="Approve or Reject" && <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={handleCreateConnect}>{connStatus}</button> }
                { me==false && (typeUser=="volunteer" || typeUser=="organization")&& connStatus==="Approve or Reject" && <div> <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={()=>handleCreateConnect("Connect")}>Connect</button> <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={()=>handleCreateConnect("Delete")}>Delete</button> </div> }
                <p onClick={() => { setShowAllConnectionsModal(true) }} className={"text-[#22A5FF] font-semibold cursor-pointer"+(me===true?"":" ml-2")}>{totalConnections==0?` ${totalConnections} connections`:totalConnections==1?` ${totalConnections} connection`:` ${totalConnections} connections`}</p>
              </div>
              {admin &&
                (!userProfile?.data?.adminVerified ? (
                  <div className="h-12 mt-2">
                    <button
                      onClick={handleAdminVerify}
                      className="rounded-md bg-[#22A5FF] text-white font-semibold px-4 py-2"
                    >
                      Give Access
                    </button>
                  </div>
                ) : (
                  <div className="h-12 mt-2">
                    <button
                      onClick={handleBlockUser}
                      className="rounded-md text-[#FF8181] border-[1px] border-[#FF8181] font-semibold px-4 py-2 hover:border-[2px] transition-all"
                    >
                      Block User
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {me === true ? (
            <div className="flex w-full px-5 md:justify-end md:-mt-4">
              <div className="bg-[#F4F4F4] w-full md:w-max p-3 md:p-4 rounded-2xl flex space-x-4 justify-around">
                <div className="flex flex-col">
                  <p className=" text-center text-[#716B6B] text-sm">
                    <span className="text-lg font-bold text-black md:text-2xl">
                      #{userProfile?.data?.rank}{" "}
                    </span>
                    in
                  </p>
                  <p className="text-[#716B6B] text-xs">
                    “most credits recieved”
                  </p>
                </div>
                <div className="border-[1px] border-solid border-[#B9B9B9]"></div>
                <div className="flex flex-col">
                  <p className="text-lg font-bold text-center md:text-2xl">
                    {userProfile?.data?.creditsRecieved || 0}
                  </p>
                  <p className="text-[#716B6B] text-xs">Credits</p>
                </div>
              </div>
            </div>
          ) : null} 
          
          
          <div className="flex-col justify-center hidden mt-3 md:flex">
            <fieldset className="flex items-center justify-center mx-5">
              <legend className="px-5 text-xs text-center text-[#919191]">
                Why am I here
              </legend>
              <p className="font-bold text-center">{profile.reason}</p>
            </fieldset>
          </div>
          <div className="flex px-5 mt-5 space-x-2 md:hidden ">
            <p className=" text-xs text-center flex justify-center items-center w-full   text-[#919191]">
              Why am I here
            </p>
            <div className="border-[1px] border-solid border-blue"></div>
            <p className="w-full text-xs font-medium">{profile.reason}</p>
          </div>
        </div>
        {withdrawConnModal && <WithdrawConnRequest setWithdrawConnModal={setWithdrawConnModal} userID={userProfile?.data?._id} typeUser={typeUser} />} 
        {approveRejConnModal && <ApproveRejectConnRequest userID={userProfile?.data?._id} setApproveRejConnModal={ setApproveRejConnModal} typeUser={typeUser} />}
      </div>
    </>
  );
}
