import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Sidebar from '../../components/Sidebar/Sidebar'
import Messages from '../../components/Chat/Messages/Messages'
import ChatDetails from '../../components/Chat/ChatDetails/ChatDetails'
import Broadcast from '../../components/Chat/Broadcast/Broadcast'
import { getUser } from '../../redux/slices/userSlice'
import './Chat.css'

export default function Chat({ type }) {
    const dispatch = useDispatch()
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const widthXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const openNavRef = useRef(null)

    useEffect(() => {
        if (!userProfile.data) {
            dispatch(getUser({ type }))
        }
    }, [])

    return (
        <div className='bg-[#F4F8FB] w-screen h-screen max-h-screen overflow-hidden flex sm:gap-5 text-[#333]'>
            <div className='fixed z-40 flex min-w-[250px] md:relative mr-[-0.7rem] md:block'>
                <Sidebar openNavRef={openNavRef} pageName='chat' userType={type} cancelFetch={true} />
            </div>

            <div className='chat-inner flex w-full mr-0 md:mr-2 md:py-2'>
                { type == 'admin' 
                    ? <Broadcast className='grow' openNavRef={openNavRef} />
                    : <Messages type={type} openNavRef={openNavRef} />
                }
            </div>
        </div>
    )
}