import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserMessage from '../Message/UserMessage'
import OtherMessage from '../Message/OtherMessage'
import { getMessages, getBroadcastMessagesForType, chatSocketAction, getBroadcastMessages, updateLastSeenBroadcast } from '../../../redux/slices/chatSlice'
import { compareDates } from '../../../utils/utils'
import { chatTypes, socketEventEnum } from '../../../utils/enum'
import defaultAvatar from '../../../images/default.jpg'
import './MessageList.css'

export default function MessageList ({ type, chatType, className }) {
    const dispatch = useDispatch()
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const { broadcastType, messages, selectedUser, selectedConversation, events } = useSelector(state => state.chat)
    const isAdmin = selectedConversation?._id == 'admin'

    if (chatType == chatTypes.PRIVATE) {
        var selectedMessages = selectedUser && (isAdmin ? messages[selectedUser._id] : messages[selectedUser.type + selectedUser._id])
    }

    else if (chatType == chatTypes.BROADCAST) {
        var selectedMessages = broadcastType && messages[broadcastType]
    }

    const allFetched = selectedConversation && selectedConversation.allFetched
    const userAvatar = (userProfile.data?.profilePic && userProfile.data?.profilePic != ' ') ? userProfile.data?.profilePic : defaultAvatar
    const listRef = useRef()
    const listInnerRef = useRef()
    const loadingRef = useRef()
    const allFetchedRef = useRef()
    const varsRef = useRef({
        topPrev: null,
        topIntersectionObserver: null,
        selectedUser: null,
        firstMessage: null,
        bottomPrev: null,
        bottomIntersectionObserver: null,
        scrollHeightPrev: null
    })

    const getConversationMessages = (params) => {
        const vars = varsRef.current

        if (chatType == chatTypes.PRIVATE) {
            if (!vars.selectedUser) {
                return
            }

            if (vars.selectedUser._id == 'admin') {
                dispatch(getBroadcastMessages({ query: params, type }))
            }

            else {
                const other = {
                    type: vars.selectedUser.type,
                    _id: vars.selectedUser._id
                }
    
                dispatch(getMessages({ other, query: params, type }))
            }
        }
        
        else if (chatType == chatTypes.BROADCAST) {
            dispatch(getBroadcastMessagesForType({ broadcastType, query: params, type: 'admin' }))
        }
    }

    useEffect(() => {
        allFetchedRef.current = allFetched
    }, [allFetched])

    useEffect(() => {
        varsRef.current.firstMessage = selectedMessages && selectedMessages[0]
    }, [selectedMessages && selectedMessages[0]?._id])

    useEffect(() => {
        let topObserverOptions = {
            root: listRef.current,
            rootMargin: '0px',
            threshold: 0.0
        }

        const vars = varsRef.current

        const onTopIntersection = (entries, observer) => {
            const isIntersecting = entries.every(x => x.isIntersecting)

            if (isIntersecting && vars.firstMessage) {
                getConversationMessages({ 
                    before: vars.firstMessage._id
                })
            }
        }
    
        const topObserver = new IntersectionObserver(onTopIntersection, topObserverOptions)
        vars.topIntersectionObserver = topObserver

        const bottomObserverOptions = {
            root: listRef.current,
            rootMargin: '0px'
        }

        const onBottomIntersection = (entries, observer) => {
            const isIntersecting = entries.every(x => x.isIntersecting)

            if (isIntersecting && vars.selectedUser) {
                if (vars.selectedUser._id == 'admin') {
                    dispatch(updateLastSeenBroadcast({ type }))
                }

                else {
                    const other = {
                        _id: vars.selectedUser._id,
                        type: vars.selectedUser.type
                    }
    
                    dispatch(chatSocketAction(socketEventEnum.USER_SAW_MESSAGES, (socket) => socket.emit(socketEventEnum.USER_SAW_MESSAGES, { other }), { other }))
                }
            }
        }
        
        const bottomObserver = new IntersectionObserver(onBottomIntersection, bottomObserverOptions)
        vars.bottomIntersectionObserver = bottomObserver

        return () => {
            topObserver.disconnect()
            bottomObserver.disconnect()
        }
    }, [])

    useEffect(() => {
        const vars = varsRef.current
        const list = listRef.current
        const top = document.querySelector('#message-list-loading')
        const bottom = document.querySelector('.message:last-child')

        if (selectedUser) {
            vars.selectedUser = selectedUser
            vars.firstMessage = selectedMessages[0]
            vars.scrollHeightPrev = list.scrollHeight
    
            if (top) {
                const topObserver = vars.topIntersectionObserver
    
                if (!allFetchedRef.current && top) {
                    topObserver.observe(top)
                }
            }

            if (bottom) {
                const bottom = document.querySelector('.message:last-child')
                const bottomObserver = vars.bottomIntersectionObserver

                bottomObserver.observe(bottom)
                vars.bottomPrev = bottom
            }
    
            if (!selectedMessages.length) {
                getConversationMessages({})
            }
    
            list.scrollTop = list.scrollHeight
        }

        if (broadcastType) {
            if (top) {
                const topObserver = vars.topIntersectionObserver
                vars.scrollHeightPrev = list.scrollHeight
    
                if (!allFetchedRef.current && top) {
                    topObserver.observe(top)
                }

                if (selectedMessages) {
                    vars.firstMessage = selectedMessages[0]
                }

                else {
                    getConversationMessages({})
                }
            }

            list.scrollTop = list.scrollHeight
        }

    }, [selectedUser, broadcastType])

    useEffect(() => {
        if (events.fetchedOldMessages) {
            const vars = varsRef.current
            const top = document.querySelector('.message:first-child')
            const list = listRef.current

            if (vars.topPrev) {
                list.scrollTop += list.scrollHeight - vars.scrollHeightPrev
            }

            else {
                list.scrollTop = list.scrollHeight
            }

            vars.topPrev = top
            vars.firstMessage = selectedMessages && selectedMessages[0]
            vars.scrollHeightPrev = list.scrollHeight
        }

    }, [events.fetchedOldMessages])

    useEffect(() => {
        if (events.newMessage) {
            const vars = varsRef.current
            const list = listRef.current
            const lastMessage = selectedMessages && selectedMessages[selectedMessages.length - 1]

            if ((lastMessage && lastMessage.self) || (vars.scrollHeightPrev - list.scrollTop - list.clientHeight < 30)) {
                list.scrollTop = list.scrollHeight
            }
        
            if (chatType == chatTypes.PRIVATE) {
                if (lastMessage && !lastMessage.self) {
                    const bottomObserver = vars.bottomIntersectionObserver
            
                    if (vars.bottomPrev) {
                        bottomObserver.unobserve(vars.bottomPrev)
                    }
            
                    const bottom = document.querySelector('.message:last-child')
                    bottomObserver.observe(bottom)
                    
                    vars.bottomPrev = bottom
                    vars.scrollHeightPrev = list.scrollHeight
                }
            }
        }
    }, [events.newMessage])

    useEffect(() => {
        if (events.initialFetchMessages) {
            if (selectedUser && selectedMessages.length) {
                const list = listRef.current
                const firstUnseen = selectedMessages.find(x => !x.self && compareDates(x.createdAt, selectedConversation.lastSawMessages) > 0)

                if (firstUnseen) {
                    const firstUnseenNode = list.querySelector(`div[data-id="${firstUnseen._id}"`)
                    list.scrollTop = firstUnseenNode.offsetTop - 80   
                }

                else {
                    list.scrollTop = list.scrollHeight
                }
            }
        }
    }, [events.initialFetchMessages, selectedUser])

    return (
        <div ref={listRef} className={`flex flex-col overflow-y-auto chat-scrollable px-0 sm:px-2 ${className}`}>
            { !allFetched &&
                <div id='message-list-loading' ref={loadingRef}>
                    {selectedConversation && [...Array(20)].map((x, i) => 
                        i % 2 == 0 
                            ? (
                                <div key={i} className='flex my-2'>
                                    <div className='h-11 w-11 rounded-full bg-animated' />

                                    <div className='grow ml-2 flex flex-col'>
                                        <div className='w-[30%] h-[10px] rounded-md bg-animated mt-1' />
                                        <div className='w-[80%] h-[24px] rounded bg-animated mt-1' />

                                        { i % 4 == 0 &&
                                            <div className='w-[60%] h-[200px] rounded bg-animated mt-1' />
                                        }
                                    </div>
                                </div>
                            )

                            : (
                                <div key={i} className='message-list-loading flex my-2 justify-end'>
                                    <div className='grow mr-2 flex flex-col items-end'>
                                        <div className='w-[30%] h-[10px] rounded-md bg-animated mt-1' />
                                        <div className='w-[80%] h-[24px] rounded bg-animated mt-1' />

                                        { i % 5 == 0 &&
                                            <div className='w-[60%] h-[200px] rounded bg-animated mt-1' />
                                        }
                                    </div>

                                    <div className='h-11 w-11 rounded-full bg-animated' />
                                </div>
                            )
                    )}
                </div>
            }

            <div ref={listInnerRef} id='message-list' className='flex flex-col'>
                {selectedMessages && selectedMessages.map((m, i) => {
                    return (
                        <div key={m._id ?? m.lid} className={`message flex ${!m.self ? 'self-begin' : 'self-end'} my-2`}>
                            {  
                                <div className='w-11 hidden sm:block'>
                                    { !m.self && (i == 0 || selectedMessages[i-1].self) &&
                                        <img className='h-11 w-11 rounded-full' src={selectedUser.avatar} />
                                    }
                                </div>  
                            }

                            {
                                !m.self 
                                    ? <OtherMessage data={m} className='message-inner mr-1 sm:mr-0' /> 
                                    : <UserMessage data={m} seen={compareDates(m.createdAt, selectedConversation.otherLastSawMessages) <= 0} className='message-inner ml-1 sm:ml-0' />
                            }

                            { 
                                <div className='w-11 mt-auto hidden sm:block'>
                                    { m.self && (i == selectedMessages.length-1 || !selectedMessages[i+1].self) &&
                                        <img className='h-11 w-11 rounded-full' src={userAvatar} />
                                    }
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}