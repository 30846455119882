import { React, useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import logo from "../../images/Logo.png";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
import { get_token } from "../../aws_cognito/get_token";
import get_current_user from "../../aws_cognito/get_user";
import { useSearchParams } from "react-router-dom";
import { create_session } from "../../aws_cognito/create_session";
import { ReactTags } from "react-tag-autocomplete";
import { causesOptions, locationOptions } from "../Preferences/data/data";
import { sectors } from "../Profile/data";
import { refresh_tokens } from "../../aws_cognito/refresh_tokens";
import logout from "../../aws_cognito/logout";

export default function ProfileSetupCorp() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(new Date());
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [causes, setCauses] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    sector: [],
    no_employees: "",
    web_link: "",
  });
  const phoneRegex = RegExp(/^(\+\d{1,3})?\d{10}$/);
  const websiteLinkRegex = RegExp(
    /((https?:\/\/(?:www\.|(?!www)))?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  );
  const isDisabled = (x) =>
    !x.name ||
    !location.length ||
    !phoneRegex.test(x.contact) ||
    !x.sector.length ||
    !x.no_employees ||
    !websiteLinkRegex.test("https://" + x.web_link) ||
    !causes.length;

  const onAddLocation = useCallback(
    (newTag) => {
      if (location.length < 3) {
        setLocation([...location, newTag]);
        // dispatch(updateArray({ type: "volunteeringLocation", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [location]
  );
  const onDeleteLocation = useCallback(
    (tagIndex) => {
      setLocation(location.filter((_, i) => i !== tagIndex));
      // dispatch(deleteArray({ type: "volunteeringLocation", tagIndex }));
    },
    [location]
  );
  const onAddCauses = useCallback(
    (newTag) => {
      if (causes.length < 5) {
        setCauses([...causes, newTag]);
        // dispatch(updateArray({ type: "causes", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [causes]
  );
  const onDeleteCauses = useCallback(
    (tagIndex) => {
      setCauses(causes.filter((_, i) => i !== tagIndex));
      // dispatch(deleteArray({ type: "volunteeringLocation", tagIndex }));
    },
    [causes]
  );
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    console.log(formData, "data");
    // await refresh_tokens('corporate');
    setLoading(true);
    try {
      console.log("clicked");
      const user = await get_current_user("corporate");
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/corporate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
          body: JSON.stringify({
            name: formData.name,
            locations: location,
            phone_number: formData.contact,
            sector: formData.sector,
            causes: causes,
            numberOfEmp: formData.no_employees,
            website: formData.web_link,
          }),
        }
      );
      const response = await data.json();
      setLoading(false);

      if (response.status === "success") {
        console.log(response, "success");
        // refreshing tokens to get id info
        console.log("refreshing tokens should have id");
        await refresh_tokens("corporate");
        // localStorage.setItem("id", response.data._id);
        setLoading(false);

        navigate(`/corporate/profile`, {
          state: user.data.type,
        });
      } else {
        alert("Something went wrong");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      alert("Something went wrong");

      console.log(err);
    }
  };

  // new NOTE
  const [websiteError, setWebsiteError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleWeblinkChange = (e) => {
    const { value } = e.target;
    if (!websiteLinkRegex.test(value)) {
      setWebsiteError("Please enter a valid website URL.");
    } else {
      setWebsiteError("");
    }
    setFormData({
      ...formData,
      web_link: e.target.value,
    });
  };

  const handleContactChange = (e) => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
    }

    if (e.target.value.length != 10) {
      setPhoneError("Please enter a valid contact number.");
    } else {
      setPhoneError("");
    }
    setFormData({
      ...formData,
      contact: e.target.value,
    });
  };

  return (
    <>
      <div className="w-[95%] flex flex-col z-10 h-screen bg-white p-8 overflow-y-scroll md:w-[60%]">
        {isMobile ? (
          <></>
        ) : (
          <div className="flex flex-row justify-center">
            <img src={logo} alt="logo" className="w-36 rounded-full" />
          </div>
        )}
        <div className="flex flex-col justify-center mt-8 text-3xl font-inter font-bold">
          <h1 className="flex justify-center">
            Set up a profile<span className="text-blue">.</span>
          </h1>
        </div>
        <p className="flex justify-center text-md font-inter mt-6">
          Don’t worry, you can edit this later.
        </p>
        <div className="flex flex-col items-center w-[100%] h-[100%] mt-12">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center justify-center  space-y-8 w-[100%] "
          >
            <div
              // className="flex w-[60%] flex-row justify-between ml-[2rem]"
              className={`flex ${
                isMobile ? "w-[100%]" : "w-[60%]"
              } flex-row justify-between ml-[2rem]`}
            >
              <div className="relative z-0 w-1/2 flex mx-auto">
                <input
                  id="name"
                  className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("name", { required: true })}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                  value={formData.name}
                />
                <label
                  htmlFor="name"
                  className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                    isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
                >
                  Name <span className="">*</span>
                </label>
              </div>
              <div className="relative z-10 w-1/2 flex mx-auto preferences">
                <ReactTags
                  placeholderText={location?.length === 0 ? "Locations *" : ""}
                  selected={location}
                  suggestions={locationOptions}
                  onAdd={onAddLocation}
                  onDelete={onDeleteLocation}
                  noOptionsText="No matching locations"
                />
              </div>
            </div>
            <div
              className={`flex ${
                isMobile ? "w-[100%]" : "w-[60%]"
              } flex-row justify-between ml-[2rem]`}
            >
              <div className="relative z-0 w-1/2 flex mx-auto">
                <select
                  type="text"
                  id="sector"
                  className="py-2 px-3 bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      sector: e.target.value,
                    });
                  }}
                  value={formData.sector}
                  required
                >
                  <option value="" selected={true} disabled={true}>
                    -- select an option --{" "}
                  </option>

                  {sectors.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor="sector"
                  className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                    isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
                >
                  Sector <span className="">*</span>
                </label>
              </div>

              <div className="relative z-0 w-1/2 flex mx-auto">
                <select
                  type="text"
                  id="no_employees"
                  className="py-[0.3rem] px-3 bg-gray-100 w-[100%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("no_employees", {})}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      no_employees: e.target.value,
                    });
                  }}
                  value={formData.no_employees}
                >
                  <option value="" selected={true} disabled={true}>
                    -- select an option --{" "}
                  </option>

                  <option value="0-1">0-1</option>
                  <option value="2-10">2-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-200">51-200</option>
                  <option value="201-500">201-500</option>
                  <option value="501-1000">501-1000</option>
                  <option value="1000+">1000+</option>
                </select>
                <label
                  htmlFor="no_employees"
                  className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                    isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
                >
                  Number of employees <span className="">*</span>
                </label>
              </div>
            </div>
            <div
              // className="flex w-[60%] flex-row justify-between ml-[2rem]"
              className={`flex ${
                isMobile ? "w-[100%]" : "w-[60%]"
              } flex-row justify-between ml-[2rem]`}
            >
              <div className="relative z-0 w-1/2 flex mx-auto items-center">
                <span className="absolute text-[0.88rem] ml-2 md:ml-3 text-[#444] md:text-[0.96rem]">
                  +91
                </span>

                <span className="absolute ml-[2.5rem] bg-[#bbb] w-[1px] md:ml-[3.2rem] h-[60%] md:w-[2px] md:bg-[#c8c8c8] rounded inline-block"></span>

                <input
                  type="number"
                  id="contact"
                  className="py-2 px-3 pl-[2rem] pl-[2.8rem] md:pl-[3.8rem] bg-gray-100 w-[90%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  {...register("contact", {
                    maxLength: 10,
                    minLength: 10,
                    pattern: /^(\+\d{1,3})?\d{10}$/,
                  })}
                  // onChange={(e) => {
                  //   setFormData({
                  //     ...formData,
                  //     contact: e.target.value,
                  //   });
                  // }}
                  onChange={handleContactChange}
                  // onInput={onInputChange}
                  pattern="/^(\+\d{1,3})?\d{10}$/"
                  value={formData.contact}
                  required
                />
                {phoneError && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      fontSize: "11px",
                      position: "absolute",
                      marginTop: "55px",
                    }}
                  >
                    {phoneError}
                  </div>
                )}
                <label
                  htmlFor="contact"
                  className={`absolute  text-black duration-300  left-1 -top-6  scale-90  z-10 origin-[0]   text-md
                  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 `}
                >
                  Contact Number <span className="">*</span>
                </label>
              </div>
              <div className="relative z-0 w-1/2 flex mx-auto">
                <input
                  type="text"
                  // type="url"
                  pattern="(https?:\/\/(?:www\.|(?!www)))?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}"
                  id="web_link"
                  className="py-2 px-3 bg-gray-100 w-[100%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("web_link", {})}
                  // onChange={(e) => {
                  //   setFormData({
                  //     ...formData,
                  //     web_link: e.target.value,
                  //   });
                  // }}
                  onChange={handleWeblinkChange}
                  value={formData.web_link}
                />

                {websiteError && (
                  <div
                    style={{
                      color: "red",
                      // display: "flex",
                      position: "absolute",
                      marginTop: "40px",
                      fontSize: "11px",
                    }}
                  >
                    {websiteError}
                  </div>
                )}
                <label
                  htmlFor="web_link"
                  className={`absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-2 left-5 z-10 origin-[0] peer-focus:left-2 ${
                    isMobile ? "peer-focus:text-md" : "peer-focus:text-xl "
                  }peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9`}
                >
                  Website Link <span className="">*</span>
                </label>
              </div>
            </div>
            <div
              className={`flex ${
                isMobile ? "w-[100%]" : "w-[60%]"
              } flex-row justify-between ml-[2rem]`}
            >
              <div className="relative z-10 w-full flex-col mx-auto preferences">
                <ReactTags
                  allowNew={true}
                  placeholderText={
                    causes?.length === 0 ? "Causes Supported *" : ""
                  }
                  selected={causes}
                  suggestions={causesOptions}
                  onAdd={onAddCauses}
                  onDelete={onDeleteCauses}
                  noOptionsText="No matching causes"
                />
                <p className="italic text-xs text-lightGray">
                  You can also add causes which you support that are not in the
                  list
                </p>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              {!loading && (
                <button
                  className="bg-blue hover:bg-blue-500 text-white font-bold py-3 px-8 mx-1 rounded-lg cursor-pointer"
                  onClick={() => logout("corporate", false)}
                >
                  Cancel
                </button>
              )}

              {loading ? (
                <button
                  disabled
                  type="button"
                  className="bg-sky-300 text-white font-bold py-3 px-8 rounded-lg cursor-pointer mx-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Creating Profile...
                </button>
              ) : (
                <input
                  style={{ opacity: isDisabled(formData) ? 0.6 : 1 }}
                  disabled={isDisabled(formData)}
                  type="submit"
                  value="Continue"
                  className="bg-blue hover:bg-blue-500 text-white font-bold py-3 px-8 mx-1 rounded-lg cursor-pointer"
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
