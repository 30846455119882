import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Post from '../../../components/CommunityWall/Post/Post'
import BloodRequirementPost from '../../../components/CommunityWall/BloodRequirementPost/BloodRequirementPost'
import CreatePost from '../../../components/CommunityWall/CreatePost/CreatePost'
import Categories from '../../../components/CommunityWall/Categories/Categories'
import Focusable from '../../../components/CommunityWall/Focusable/Focusable'
import { fetchPosts, clearPosts } from '../../../redux/slices/postsSlice'
import CommunityWallContext from '../../../components/CommunityWall/CommunityWallContext'
import { getUser } from '../../../redux/slices/userSlice'
import gsap from 'gsap'
import { postTypeEnum } from '../../../utils/enum'
import './CommunityWall.css'
import ConnectionRequests from '../../../components/Profile/ConnectionRequests'
import OpportunityCard from '../Opportunities/OpportunitiesCard'
import axios from 'axios'
import get_current_user from '../../../aws_cognito/get_user'
import defaultAvatar from "../../../images/default.jpg"
import { useNavigate } from "react-router-dom";
import Modal from '../../../components/Modal/Modal'
import TestimonialModal from "../../../components/TestimonialModal/TestimonialModal"

 
export default function CommunityWall({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const postsState = useSelector((state) => state.posts)
  const [windowWidth, setWindowWidth] = useState()
  const [showCategories, setShowCategories] = useState(false)
  const posts = postsState.posts
  const postStatuses = postsState.postStatuses
  const fetchingPosts = postsState.loading 
  const postsRef = useRef([])
  const timeoutRef = useRef(null)
  const categoriesRef = useRef([])
  const fetchingPostsRef = useRef(true)
  const optionRef = useRef('all')
  const endRef = useRef(false)
  const { loading, userProfile } = useSelector(state => state.currentProfile)
  let controller = useRef(new AbortController())
  const [pendingCorporateTestimonials,setPendingCorporateTestimonials]=useState([]);
  const [pendingImpactorgTestimonials,setPendingImpactOrgTestimonials]=useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(()=>{
    getData();
  },[])
  
  const getData= async ()=>{
    try {
      const user = await get_current_user(type);
      const corpTestimonials = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/corporate/`, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
          } 
        });

        const impactOrgTestimonials = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonial/impactorg/`, {
          headers: {
              Authorization: `Bearer ${user.data.idToken}`
            } 
          });
      console.log(corpTestimonials.data.data)
      console.log(impactOrgTestimonials.data.data)
        if(corpTestimonials.data.data.length>0){
          setPendingCorporateTestimonials(...pendingCorporateTestimonials,corpTestimonials.data.data);
        }
        if(impactOrgTestimonials.data.data.length>0){
          setPendingImpactOrgTestimonials(...pendingImpactorgTestimonials,impactOrgTestimonials.data.data);
        }
    } catch (error) {
      console.log(error);
    }
  }

  

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    dispatch(clearPosts())

    if (!userProfile.data) {
      dispatch(getUser({ type }))
    }

    const params = {
      limit: process.env.REACT_APP_POSTS_FETCH_LIMIT
    }

    dispatch(fetchPosts({ userType: type, params, clear: true, controller: controller.current }))
  }, [])

  useEffect(() => {
    fetchingPostsRef.current = fetchingPosts
  }, [fetchingPosts])

  useEffect(() => {
    endRef.current = postsState.end
  }, [postsState.end])

  useEffect(() => {
      postsRef.current = posts
  }, [posts])

  const onCategorySelect = (category) => {
    dispatch(clearPosts())
    categoriesRef.current.push(category)

    controller.current.abort()
    controller.current = new AbortController()
    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      const params = {
        limit: process.env.REACT_APP_POSTS_FETCH_LIMIT,
        categories: categoriesRef.current
      }

      if (optionRef.current != 'all') params.type = optionRef.current
    
      dispatch(fetchPosts({ userType: type, params, clear: true, controller: controller.current }))
    }, 500)
  }

  const onCategoryDeselect = (category) => {
    dispatch(clearPosts())
    categoriesRef.current = categoriesRef.current.filter(x => x != category)

    controller.current.abort()
    controller.current = new AbortController()
    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      const params = {
        limit: process.env.REACT_APP_POSTS_FETCH_LIMIT,
        categories: categoriesRef.current
      }

      if (optionRef.current != 'all') params.type = optionRef.current

      dispatch(fetchPosts({ userType: type, params, clear: true, controller: controller.current }))
    }, 500)
  }

  useEffect(() => {
  const onResize = (e) => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', onResize)
  
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const onScroll = (e) => {
    const loadingEle = document.querySelector('#post-loading')
    if (!loadingEle) return

    const top = loadingEle.getBoundingClientRect().top

    if (top <= window.innerHeight - 30 && !fetchingPostsRef.current && !endRef.current && postsRef.current.length) {
      fetchingPostsRef.current = true
      const cposts = postsRef.current

      const params = {
        limit: process.env.REACT_APP_POSTS_FETCH_LIMIT,
        before: cposts[cposts.length - 1]._id
      }

      if (optionRef.current != 'all') params.type = optionRef.current
      if (optionRef.current != 'bloodrequirement') params.categories = categoriesRef.current

      dispatch(fetchPosts({ userType: type, params, controller: controller.current }))
    }
  }

  const categories = <Categories 
    disabled={optionRef.current == 'bloodrequirement'}
    onSelect={onCategorySelect} 
    onDeselect={onCategoryDeselect} 
    windowWidth={windowWidth} 
    currentCategories={categoriesRef.current} 
    clearCategories={() => {
      if (!categoriesRef.current.length)
        return 

      categoriesRef.current = []
      dispatch(clearPosts())

      const params = {
        limit: process.env.REACT_APP_POSTS_FETCH_LIMIT,
      }

      if (optionRef.current != 'all') params.type = optionRef.current

      controller.current.abort()
      controller.current = new AbortController()
      dispatch(fetchPosts({ userType: type, params, controller: controller.current }))
      
      const checkBoxes = document.querySelectorAll('.checkbox.active')
      checkBoxes.forEach(x => x.classList.remove('active'))
    }} />

  const notify = (message) => {
    const ele = document.querySelector('#notify')
    ele.innerHTML = message
    
    gsap.killTweensOf(ele)
    const t = gsap.timeline()
    
    t.to(ele, { bottom: 20 })
     .to(ele, { bottom: -100, delay: 3 })
  }

  const switchOption = (option) => {
    optionRef.current = option

    dispatch(clearPosts())

    const params = {
      limit: process.env.REACT_APP_POSTS_FETCH_LIMIT
    }

    if (option != 'all') params.type = option
    if (option != 'bloodrequirement') params.categories = categoriesRef.current

    controller.current.abort()
    controller.current = new AbortController()
    dispatch(fetchPosts({ userType: type, params, controller: controller.current }))
  }

  return (
    <CommunityWallContext.Provider value={{ notify, categoriesRef }}>
      <div className={`bg-[#F4F8FB] max-h-screen overflow-hidden flex md:gap-5`}>
        <div className={`md:block md:min-w-[250px] ${windowWidth < 900 ? 'z-30 absolute top-0' : ''}`}>
          <Sidebar pageName='community-wall' userType={type} />
        </div>
        <div id='community-wall-inner' className={`flex flex-col md:flex-row scrollable overflow-scroll pt-[3rem] md:pt-[20px]`} onScroll={onScroll}>
          
          <div className='flex flex-col mx-2 sm:mx-5 md:ml-0 order-2 md:order-1 md:basis-7/12 pb-4'>
            <Focusable windowWidth={windowWidth}>
              <CreatePost typeUser={type} />
            </Focusable>

            <div className='flex mt-4 items-center'>
              { windowWidth < 900 &&
                <div className='flex items-center rounded shadow-sm md:shadow-md px-2 py-1 bg-white cursor-pointer' onClick={() => setShowCategories(!showCategories)}>
                  <span className='text-[0.7rem] xs:text-[0.78rem] font-medium'>Categories</span>
                  <span className='rounded-full ml-4 bg-[#22A5FF] text-[0.7rem] font-semibold w-4 h-4 xs:w-5 xs:h-5 flex justify-center items-center text-white'>
                    {categoriesRef.current.length}
                  </span>
                </div>
              }

              <div className='ml-auto'>
                <label className='text-[0.7rem] text-[#111] xs:text-[0.8rem]'>Show: </label>
                <select className='text-[0.7rem] font-medium text-[#444] bg-white p-1 px-2 rounded shadow-sm md:shadow-md outline-none w-[100px] xs:w-auto xs:ml-1 xs:text-[0.8rem]' onInput={(e) => switchOption(e.target.value)}>
                  <option value='all'>All posts</option>
                  <option value='bloodrequirement'>Blood requirement</option>
                  <option value='opportunity'>Opportunity</option>
                </select>
              </div>
            </div>

            { windowWidth < 900 && showCategories &&
              <div>
                {categories}
              </div>
            }
            {windowWidth<900 && <ConnectionRequests type={type} />}
            {posts.map(x => {
              switch (x.type) {
                case postTypeEnum.GENERAL:
                  return <Post key={x._id} data={x} status={postStatuses[x._id]} />

                case postTypeEnum.BLOOD_REQUIREMENT:
                  return <BloodRequirementPost key={x._id} data={x} status={postStatuses[x._id]} />

                // Placeholder for opportunity posts
                case postTypeEnum.OPPORTUNITY:
                  return <OpportunityCard key={x._id} type={type} opportunityData={x}/>
              }
            })}

            {!postsState.end &&
              <div id='post-loading' className='bg-white shadow-md rounded-md p-4 mt-5'>
                <div className='flex items-center'>
                  <span className='animate-bg w-6 h-6 md:w-10 md:h-10 rounded-full'></span>
                  <span className='animate-bg h-6 md:h-10 w-[90%] ml-4 rounded-full'></span>
                </div>

                <div className='mt-2'>
                  <span className='animate-bg inline-block mx-2 w-[100px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[200px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[50px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[100px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[120px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[60px] h-6 md:h-10 mt-2 rounded-full' />
                  <span className='animate-bg inline-block mx-2 w-[80px] h-6 md:h-10 mt-2 rounded-full' />
                </div>

                <div className='animate-bg inline-block mx-2 w-full h-[200px] mt-2 rounded-xl' />
              </div>
            }
          </div>
          
          <div className='flex flex-col mr-5 order-1 md:order-2  md:basis-5/12' >
            { windowWidth > 900 &&
                <Focusable>
                  {categories}
                </Focusable>
            }

            { windowWidth > 900 &&
              <ConnectionRequests type={type} />
            }
            
            {
              type=="admin" && 
              <div className='flex-col mt-4 p-4 mb-12 ml-5 md:ml-0 md:mb-0 items-center rounded shadow-sm md:shadow-md bg-white'>
                <div className='flex mb-3'>
                    <span className='inline-block font-semibold text-l text-[#222]'>Testimonials</span>
                    <div className='text-[0.75rem] ml-auto mr-2 cursor-pointer'>
                        <span className='cursor-pointer' onClick={()=>{
                          setModalIsOpen(true)
                        }}>View all</span>
                    </div>
                </div>
                  <div className="max-h-[35vh] overflow-auto">
                    {
                      pendingImpactorgTestimonials.length > 0 &&
                      pendingImpactorgTestimonials.map((item)=>(
                        <div key={item._id} className='flex p-2 items-center'>
                          <div className='w-16 h-16 md:w-20 md:h-20'>
                            <img src={item.corporate.profilePic==""?defaultAvatar:item.corporate.profilePic} alt="ProfilePic" className="rounded-full w-16 h-16 md:w-20 md:h-20" />
                          </div>
                          <div className='w-full flex justify-between px-4 '>
                            <div>
                              <p className="text-[0.8rem] sm:text-[1rem]">From</p>
                              <p onClick={() =>navigate(`/admin/view-corporate/${item.corporate._id}`)}  className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.corporate.name}</p>
                            </div>
                            <div>
                              <p className="text-[0.8rem] sm:text-[1rem]">To</p>
                              <p onClick={() =>navigate(`/admin/view-organization/${item.org._id}`)}  className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.org.name}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    {
                      pendingCorporateTestimonials.length > 0 &&
                      pendingCorporateTestimonials.map((item)=>(
                        <div key={item._id} className='flex p-2 items-center'>
                          <div className='w-16 h-16 md:w-20 md:h-20 '>
                            <img src={item.org.profilePic==""?defaultAvatar:item.org.profilePic} alt="ProfilePic" className="rounded-full w-16 h-16 md:w-20 md:h-20" />
                          </div>
                          <div className='w-full flex justify-between px-4 '>
                            <div>
                              <p className="text-[0.8rem] sm:text-[1rem]">From</p>
                              <p onClick={() =>navigate(`/admin/view-organization/${item.org._id}`)} className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.org.name}</p>
                            </div>
                            <div>
                              <p className="text-[0.8rem] sm:text-[1rem]">To</p>
                              <p onClick={() =>navigate(`/admin/view-corporate/${item.corporate._id}`)}  className='cursor-pointer inline-block font-semibold text-[0.9rem] sm:text-[1rem] text-[#222]'>{item.corporate.name}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  
                    
                    {
                      pendingImpactorgTestimonials.length == 0 && pendingCorporateTestimonials.length == 0 &&
                      <div>
                        <p className='text-center text-sm'>No Testimonial Requests</p> 
                      </div>
                    }
                    {
                      modalIsOpen &&
                      <TestimonialModal 
                      isOpen={modalIsOpen}
                      onClose={() => {
                        setModalIsOpen(false);
                      }}
                      pendingImpactorgTestimonials={pendingImpactorgTestimonials}
                      pendingCorporateTestimonials={pendingCorporateTestimonials}
                      type={type}
                      setPendingCorporateTestimonials={setPendingCorporateTestimonials}
                      setPendingImpactOrgTestimonials={setPendingImpactOrgTestimonials}
                    />
                    }
              </div>
            }
          </div>
        </div>
      </div>

      <div id='notify' className='fixed right-2 bottom-[-100px]'></div>
    </CommunityWallContext.Provider>
  )
}