import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConnections, setSelectedUserFromConversation, createConversation, setAddingConversation } from '../../../redux/slices/chatSlice'
import defaultProfilePic from '../../../images/default.jpg'

export default function AddConversation({ type, style, className }) {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const { status, connections, conversations, selectedConversation } = useSelector(state => state.chat)

    useEffect(() => {
        dispatch(getConnections({ type }))
    }, [])

    const onConnectionClick = (other) => {
        const conversation = conversations.find(x => x.other._id == other._id && x.otherType == other.type)

        if (conversation) {
            if (selectedConversation && selectedConversation._id == conversation._id) {
                dispatch(setAddingConversation(false))
            }

            else {
                dispatch(setSelectedUserFromConversation(conversation))
                dispatch(setAddingConversation(false))
            }
        }

        else {
            if (status.createConversation != 'pending') {
                dispatch(createConversation({ other, type }))
            }
        }
    }

    return (
        <div style={style} className={`${className} p-4 flex flex-col`}>
            <div className='flex items-center'>
                <h1 className='text-[1.2rem] sm:text-[1.4rem] font-semibold ml-2 mt-3 mb-2'>
                    Start a conversation!
                </h1>

                <svg fill={'#333'} onClick={() => dispatch(setAddingConversation(false))} className='rotate-180 translate-y-[2px] w-4 sm:w-5 ml-auto mr-1 cursor-pointer' viewBox="0 0 15 8" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.547826 5.23612L2.96427 7.67753C3.02231 7.73606 3.09137 7.78251 3.16746 7.81421C3.24355 7.84591 3.32516 7.86223 3.40759 7.86223C3.49002 7.86223 3.57163 7.84591 3.64772 7.81421C3.72381 7.78251 3.79287 7.73606 3.85092 7.67753C3.90944 7.61949 3.95589 7.55043 3.98759 7.47434C4.0193 7.39825 4.03562 7.31664 4.03562 7.23421C4.03562 7.15178 4.0193 7.07017 3.98759 6.99408C3.95589 6.91799 3.90944 6.84893 3.85092 6.79088L1.62804 4.55552H14.3596C14.5252 4.55552 14.684 4.48973 14.8011 4.37264C14.9182 4.25554 14.984 4.09672 14.984 3.93112C14.984 3.76551 14.9182 3.60669 14.8011 3.4896C14.684 3.3725 14.5252 3.30671 14.3596 3.30671H1.59058L3.85092 1.04637C3.96162 0.93025 4.02337 0.77597 4.02337 0.615536C4.02337 0.455101 3.96162 0.300822 3.85092 0.184697C3.79287 0.126173 3.72381 0.0797209 3.64772 0.0480208C3.57163 0.0163207 3.49002 0 3.40759 0C3.32516 0 3.24355 0.0163207 3.16746 0.0480208C3.09137 0.0797209 3.02231 0.126173 2.96427 0.184697L0.547826 2.58865C0.197036 2.93988 0 3.41598 0 3.91238C0 4.40879 0.197036 4.88489 0.547826 5.23612Z" />
                </svg>
            </div>

            <div className='flex items-center bg-[#F4F4F4] rounded-md my-4'>
                <svg className='ml-4' stroke="#919191" width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.5 29.5L22.771 22.759L29.5 29.5ZM26.5 13.75C26.5 17.1315 25.1567 20.3745 22.7656 22.7656C20.3745 25.1567 17.1315 26.5 13.75 26.5C10.3685 26.5 7.12548 25.1567 4.73439 22.7656C2.3433 20.3745 1 17.1315 1 13.75C1 10.3685 2.3433 7.12548 4.73439 4.73439C7.12548 2.3433 10.3685 1 13.75 1C17.1315 1 20.3745 2.3433 22.7656 4.73439C25.1567 7.12548 26.5 10.3685 26.5 13.75V13.75Z" stroke="#919191" strokeWidth="2" strokeLinecap="round"/>
                </svg>

                <input className='p-3 text-[0.78rem] text-[#919191] bg-transparent outline-none w-full' placeholder="Search in conversations" onInput={e => setSearch(e.target.value)} />
            </div>

            <div className='chat-scrollable h-full' style={{ overflowY: status.connections == 'pending' ? 'hidden' : 'auto' }}>
                { status.connections == 'pending' && [...Array(10)].map((x, i) => (
                    <div key={i} className='flex items-center my-1 pr-1 py-2 pl-2 rounded cursor-pointer'>
                        <div className='h-11 w-11 sm:h-12 sm:w-12 rounded-full bg-animated' />
                        
                        <div className='grow h-11 ml-2 flex flex-col mr-2'>
                            <div className='w-[60%] h-[35%] rounded bg-animated mt-1' />
                            <div className='w-[100%] h-[55%] rounded-md bg-animated mt-1' />
                        </div>
                    </div>
                ))}

                {connections.filter(x => x.name.toLowerCase().startsWith(search.toLowerCase())).map((x, i) => (
                    <div key={x._id} className='flex items-center my-1 pr-1 py-2 pl-2 hover:bg-[#F4F4F4] rounded cursor-pointer' onClick={() => onConnectionClick(x)}>
                        <img className='h-11 w-11 sm:h-12 sm:w-12 rounded-full' src={!x.profilePic || x.profilePic == '' ? defaultProfilePic : x.profilePic} />
                        
                        <span className='ml-3 text-[0.8rem] sm:text-[0.85rem]'>{x.name}</span>
                        <span className='ml-1 text-[0.55rem] sm:text-[0.6rem]'>•</span>
                        <span className='text-[0.8rem] sm:text-[0.85rem] text-[#555] font-semibold ml-1'>{x.type}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}