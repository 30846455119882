import axios from 'axios'

export const fetch = (params, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/notifications`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const fetchUnreadCount = (token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/notifications/unreadCount`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })