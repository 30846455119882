import React,{useEffect,useState} from "react";
import { useDispatch, useSelector} from "react-redux";
import { updateDetails } from "../../redux/slices/profileSlice";
import { educationLevel } from "./data";
import axios from 'axios';
export default function Career({collegesList}) {
  const dispatch = useDispatch();
  const handleValues = (e) => {
    e.preventDefault();
    // const { name, value } = e.target;
    // console.log(profile);
    // const { name, value } = e.target;
    // setValues({ ...values, [name]: value });
    dispatch(updateDetails({ name: e.target.name, value: e.target.value }));
  };
  
  const profile = useSelector((state) => state.profileForm);

  return (
    <>
      <div className="flex flex-col border-b border-[#DDDDDD]">
        <p className="font-medium">Education</p>
        <div>
          <form>
            <div class="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="edu_level"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  Highest education level
                </label>
                <select
                  id="edu_level"
                  name="edu_level"
                  onChange={handleValues}
                  defaultValue={profile.edu_level}
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {educationLevel.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  for="school"
                  class="block mb-2 text-xs font-normal text-lightGray"
                >
                  School/College
                </label>
                <select
                  id="school"
                  name="school"
                  onChange={handleValues}
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                  defaultValue={
                    collegesList.some(data=>profile.school===data.name)
                      ? profile.school
                      : "Other"
                  }
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {collegesList.map((data, index) => {
                    return (
                      <option value={data.name} key={index}>
                        {data.name}
                      </option>
                    );
                  })}
                  <option value="Other">Other</option>
                </select>
              </div>
              {!collegesList.some(data=>profile.school===data.name) ||
              profile.school === "Other" ? (
                <div>
                  <label
                    for="school"
                    class="block mb-2 text-xs font-normal text-lightGray"
                  >
                    Other
                  </label>
                  <input
                    type="text"
                    name="school"
                    id="school"
                    class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                    required
                    defaultValue={profile.school}
                    placeholder="Describe"
                    onChange={handleValues}
                  />
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
