import React from "react";
import { useSelector } from "react-redux";

export default function About() {
  //   const data = useSelector((state) => state.userProfile.userProfile);
  //   const profile = data.data;
  //   console.log(profile);

  //   const work = profile?.career ?? [];
  const corpProfile = useSelector((state) => state.corpProfile.corpProfile);
  const data = corpProfile.data;
  const creditData = corpProfile.creditData;

  return (
    <div className="flex flex-col w-full my-9 gap-10 ">
      <div className="flex-col space-y-8 w-full text-sm">
        {creditData?.causes?.length !== 0 ? (
          <div className="text-xs md:text-sm ">
            <h1 className="font-medium">Causes supported</h1>
          </div>
        ) : null}

        {creditData?.causes?.map((data, index) => {
          return (
            <div className="md:grid grid-cols-3 items-center" key={index}>
              <p className="text-[#919191] text-xs md:text-sm">{data?.cause}</p>
              <div className="flex w-full gap-2 items-center">
                <div class="w-full border-[1.5px] border-[#B7E2FF] rounded-full h-2.5 md:h-4 flex">
                  <div
                    class="bg-blue h-2.5 md:h-4 rounded-full"
                    style={{ width: `${Math.round(data?.percent)}%` }}
                  ></div>
                </div>
                <span className="text-[#919191] text-sm">
                  {Math.round(data?.percent)}%
                </span>
              </div>
            </div>
          );
        })}

        <div className="text-xs md:text-sm space-y-1">
          <h1 className="font-medium">Website</h1>
          <a
            href={data?.website}
            target="_blank"
            rel="noreferrer"
            className="text-[#658FE1]"
          >
            {data?.website}
          </a>
        </div>

        <div className="text-xs md:text-sm space-y-1">
          <h1 className="font-medium">Mail</h1>
          <a
            href={`mailto:${data?.email}`}
            className="text-[#658FE1]"
            target="_blank"
            rel="noreferrer"
          >
            {data?.email}
          </a>
        </div>
        <div className="text-xs md:text-sm space-y-1">
          <h1 className="font-medium">Contact Number</h1>
          <a href={`tel:${data?.phone_number}`} className="text-[#658FE1]">
            {data?.phone_number}
          </a>
        </div>
      </div>
    </div>
  );
}
