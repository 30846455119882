import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { likePost, unlikePost, deletePost, editGeneralPost } from '../../../redux/slices/postsSlice'
import * as postsApi from '../../../api/posts'
import get_current_user from '../../../aws_cognito/get_user'
import PostComment from '../PostComment'
import PostLikes from '../PostLikes'
import PostCommentInput from '../PostCommentInput'
import defaultAvatar from '../../../images/default.jpg'
import { ClipLoader } from 'react-spinners'
import copy from 'copy-to-clipboard'
import { roleColorDark, formatDate } from '../../../utils/utils'
import categoryColors from '../../../utils/categoryColors.json'
import CommunityWallContext from '../CommunityWallContext'
import { Link } from 'react-router-dom'
import categories from '../../../utils/categories'
import './Post.css'

export default function Post({ data, status }) {
    const { notify } = useContext(CommunityWallContext)
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const dispatch = useDispatch()
    let [comments, setComments] = useState({ show: false, list: [] })
    const fetchingComments = useRef(false)
    const commentsFetchLimit = process.env.REACT_APP_COMMENTS_FETCH_LIMIT
    const inActionRef = useRef(false)
    const [showLikes, setShowLikes] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [fetchingMore, setFetchingMore] = useState(false)
    const [showPostMenu, setShowPostMenu] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editState, setEditState] = useState({ 
        content: data.content, 
        categories: data.categories,
        filesToDelete: [],
        files: []
    })
    const deletingRef = useRef(false)
    const editingRef = useRef(false)
    const initialRef = useRef(true)
    const toggleRef = useRef()
    const type = userProfile?.data?.type
    const menuRef = useRef()
    let commentsCount = useRef(status.comments)

    useEffect(() => {
        const closeMenu = (e) => {
            if (deletingRef.current) {
                return
            }

            if (menuRef.current && menuRef.current.children.length > 1 && !menuRef.current.contains(e.target)) {
                const toggle = menuRef.current.querySelector('.post-menu-toggle') 

                if (toggle) {
                    toggle.classList.remove('active')
                    setShowDeleteConfirmation(false)
                    setShowPostMenu(false)
                }
            }
        }
        
        document.addEventListener('click', closeMenu)

        return () => {
            document.removeEventListener('click', closeMenu)
        }
    }, [])

    useEffect(() => {
        inActionRef.current = status.inAction
    }, [status.inAction])

    useEffect(() => {
        deletingRef.current = status.deleting
    }, [status.deleting])

    useEffect(() => {
        if (!status.editing) {
            setEditMode(false)
        }

        editingRef.current = status.editing
    }, [status.editing])

    const pushComment = (commentData) => {
        comments.list.push(commentData)
        comments = { ...comments }
        commentsCount.current++
        setComments(comments)
    }

    const removeComment = (id) => {
        comments.list = comments.list.filter(x => x._id != id)
        comments = { ...comments }
        commentsCount.current--
        setComments(comments)
    }

    const onLikeClick = () => {
        if (inActionRef.current) 
            return

        inActionRef.current = true
            
        if (status.like)
            return dispatch(unlikePost({ userType: type, id: data._id }))
            
        if (!status.like)
            return dispatch(likePost({ userType: type, id: data._id }))
    }

    const onCommentClick = async () => {
        if (!comments.show) {
            if (initialRef.current) {
                initialRef.current = false
                loadMoreComments()
            }

            else {
                comments = { ...comments, show: true }
                setComments(comments)    
            }
        }

        else {
            comments = { ...comments, show: false }
            setComments(comments)
        }
    }

    const onShareClick = () => {
        copy(`${window.location.host}/post/${data._id}`)
        notify(`
            <div class='flex justify-center items-center bg-sky-100/[0.7] text-sky-500 px-5 py-3 rounded-md text-bold text-[0.9rem]'>
                <svg class='w-5 h-5 mr-2 fill-sky-400' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128C448 181 405 224 352 224C326.1 224 302.6 213.8 285.4 197.1L285.4 197.1z"/>
                </svg>

                Share url has been copied to clipboard!
            </div>
        `)
    }

    const loadMoreComments = async () => {
        if (fetchingComments.current)
            return

        fetchingComments.current = true
        
        if (!comments.list.length) setFetching(true)
        else setFetchingMore(true)
        
        try {
            const user = await get_current_user(type)
            const res = await postsApi.getComments(data._id, user.data.idToken, { 
                limit: commentsFetchLimit, 
                before: comments.list[0]?._id
            })

            comments = { show: true, list: [...res.data, ...comments.list] }
            setComments(comments)
        }

        catch (error) {
        
        }

        finally {
            fetchingComments.current = false
            setFetching(false)
            setFetchingMore(false)
        }
    }

    const onEditStart = () => {
        setEditState({ content: data.content, categories: data.categories, filesToDelete: [], files: [] })
        setShowPostMenu(false)
        setShowDeleteConfirmation(false)
        setEditMode(true)
        toggleRef.current.classList.remove('active')
    }

    const onEditClick = () => {
        if (editingRef.current)
            return 

        editingRef.current = true

        const formData = new FormData() 
        formData.set('content', editState.content)
        formData.set('categories', JSON.stringify(editState.categories))
        formData.set('filesToDelete', JSON.stringify(editState.filesToDelete))

        for (const file of editState.files) {
            formData.append('pictures', file)
        }

        dispatch(editGeneralPost({ id: data._id, edit: formData, userType: type }))
    }

    const categoriesEqual = () => {
        if (data.categories.length != editState.categories.length) 
            return false 

        const exists1 = {}
        const exists2 = {}

        for (const category of data.categories)
            exists1[category.toLowerCase()] = true

        for (const category of editState.categories)
            exists2[category.toLowerCase()] = true

        let inter = 0

        for (const category of categories) {
            if (exists1[category.toLowerCase()] && exists2[category.toLowerCase()]) {
                inter++
            }
        }        

        return (inter == data.categories.length)
    }

    const disableEditButton = () => {
        return (data.content == editState.content.trim() && categoriesEqual() && editState.filesToDelete.length == 0 && editState.files.length == 0)
    }

    return (
        <>
            <div className='post bg-white shadow-md rounded-md mt-4 relative'>
                <div ref={menuRef} className='relative'>
                    { (type == 'admin' || (type == data.authorType && userProfile?.data?._id == data.author._id)) &&
                        <div ref={toggleRef} className='post-menu-toggle absolute h-8 w-8 pr-1 right-1 top-[-2px] flex justify-center items-center cursor-pointer' onClick={(e) => {
                            e.target.classList.toggle('active')
                            setShowPostMenu(!showPostMenu)
                            setShowDeleteConfirmation(false)
                        }}>
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                        </div>
                    }

                    {  showPostMenu && 
                        <div className='post-menu bg-white absolute top-8 right-4 w-[120px] rounded overflow-hidden'>
                            <div>
                                <div className={`post-edit-button cursor-pointer duration-300 flex items-center hover:bg-[#f3f3f3] h-[30px] w-full pl-4`} onClick={onEditStart}>
                                    <svg className='h-[0.85rem] w-[0.85rem] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#111]'>EDIT</span>
                                </div>
                            </div>

                            <div className='post-delete max-h-[30px] overflow-hidden'>
                                <div className={`post-delete-button ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''} cursor-pointer duration-300 flex items-center  hover:bg-[#ff3b3b] h-[30px] w-full pl-4`} onClick={(e) => setShowDeleteConfirmation(true)}>
                                    <svg className='h-[0.8rem] w-[0.8rem] fill-[#f55858] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#f55858]'>DELETE</span>
                                </div>
                                
                                <div className={`post-delete-confirmation flex items-center justify-center h-[30px] bg-[#ff3b3b] duration-300 ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''}`}>
                                    <svg onClick={() => setShowDeleteConfirmation(false)} className='translate-y-[-1px] h-[0.8rem] w-[0.8rem] hover:rotate-90 duration-300 cursor-pointer fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"/>
                                    </svg>

                                    <span className='text-[0.7rem] mx-2 font-bold text-white cursor-default translate-y-[-1px]'>You sure?</span>

                                    { status.deleting &&
                                        <ClipLoader size={15} color='white' />
                                    }

                                    { !status.deleting &&
                                        <svg className='h-[0.9rem] w-[0.9rem] fill-white cursor-pointer hover:translate-y-[-2px] duration-300' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={() => {
                                            deletingRef.current = true
                                            dispatch(deletePost({ userType: type, id: data._id }))
                                        }}>
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                                        </svg>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className='p-4 flex flex-col'>
                    <div className='flex items-center'>
                        <img className='w-12 h-12 rounded-full' src={(!data.author.profilePic || data.author.profilePic == '') ? defaultAvatar : data.author.profilePic} />

                        <div className='flex flex-col ml-3'>
                            <div className='flex items-center'>
                                <Link to={(userProfile.data?.type == data.authorType && userProfile.data?.db_id == data.author._id) ? `/${type}/profile` : `/${type}/view-${data.authorType}/${data.author._id}`}>
                                    <span className='text-[0.75rem] font-semibold'>
                                        {data.author.name}
                                    </span>
                                </Link>

                                { data.authorType == 'admin' &&
                                    <span style={{ backgroundColor: roleColorDark[data.authorType] }} className={`rounded ml-2 px-[7px] pb-[2px] pt-[1px] text-[0.7rem] font-bold text-white`}>{data.authorType}</span>
                                }
                            </div>
                            <span className='text-[0.7rem] text-[#777]'>{formatDate(data.createdAt)}</span>
                        </div>
                    </div>

                    { !editMode &&
                        <div className='mt-4'>
                            {data.categories.map((x, i) => (
                                <span key={i} 
                                    style={{ backgroundColor: categoryColors[x].light, color: categoryColors[x].dark }}
                                    className={`inline-block rounded-full py-[6px] px-[12px] mr-2 text-[0.6rem] xs:mr-3 mb-2 xs:py-2 xs:px-3 xs:text-[0.63rem] font-semibold`}>
                                    {x}
                                </span>
                            ))}
                        </div>
                    }

                    { !editMode &&
                        <p className='text-[0.8rem] mt-1 ml-1 whitespace-pre'>{data.content}</p>
                    }

                    { editMode &&
                        <textarea rows={1}
                            maxLength={2000} 
                            className='scrollable mt-5 bg-[#F4F4F4] rounded-md px-4 py-[0.7rem] text-[0.8rem] w-full outline-none' 
                            onChange={(e) => setEditState({ ...editState, content: e.target.value })}
                            value={editState.content} />  
                    }

                    { editMode &&
                        <div className='scrollable mt-5 h-[115px] overflow-y-auto mb-4'>
                            {categories.map((x, i) => (
                                <span key={i} 
                                    className={`category ${editState.categories.includes(x) ? 'active' : ''} inline-block rounded-full py-2 px-3 mr-2 mb-2 text-[0.65rem] cursor-pointer`}
                                    onClick={(e) => {
                                        const category = e.target.innerHTML

                                        if (editState.categories.includes(category)) 
                                            setEditState({ ...editState, categories: editState.categories.filter(x => x != category) })

                                        else setEditState({ ...editState, categories: [...editState.categories, category] })
                                    }}>
                                    {x}
                                </span>
                            ))}
                        </div>
                    }
                </div>

                { !editMode && 
                    <div className='p-2'>                    
                        {data.images.length == 1 &&
                            <img className='w-full object-fill' src={data.images[0]} />
                        }
        
                        {data.images.length == 2 &&
                            <div className='flex gap-1 justify-center'>
                                <img className='w-[50%] max-h-[400px] object-cover' src={data.images[0]} />
                                <img className='w-[50%] max-h-[400px] object-cover' src={data.images[1]} />
                            </div>
                        }
        
                        {data.images.length == 3 &&
                            <div className='grid grid-rows-2 grid-cols-2 gap-1'>
                                <img className='w-full max-h-[200px] h-full object-cover' src={data.images[0]} />
                                <img className='w-full max-h-[200px] h-full object-cover' src={data.images[1]} />
                                <img className='w-full max-h-[200px] col-span-2 object-cover' src={data.images[2]} />
                            </div>
                        }
        
                        {data.images.length > 3 &&
                            <div className='grid grid-rows-2 grid-cols-2 gap-1'>
                                <img className='w-full h-full max-h-[200px] object-cover' src={data.images[0]} />
                                <img className='w-full h-full max-h-[200px] object-cover' src={data.images[1]} />
                                <img className='w-full h-full max-h-[200px] object-cover' src={data.images[2]} />
        
                                { data.images.length > 4
                                    ? <div className='relative'>
                                        <div className='absolute top-0 left-0 w-full h-full bg-gray-900/[0.75] flex justify-center items-center text-white cursor-pointer hover:bg-gray-900/[0.6] text-[0.9rem]'>
                                            View more
                                        </div>
                                        <img className='w-full h-full max-h-[200px] object-cover' src={data.images[3]} />
                                    </div>
        
                                    : <img className='w-full h-full max-h-[200px] object-cover' src={data.images[3]} />
                                }
                            </div>
                        }
                    </div>
                }

                { editMode &&
                    <div className='overflow-x-auto scrollable flex mx-4 pb-1'>
                        {data.images.map((x, i) => {
                            if (editState.filesToDelete.includes(i))
                                return 

                            return (
                                <div key={x} className='relative'>
                                    <span className='inline-flex justify-center items-center absolute right-0 top-0 h-[17px] w-[17px] bg-[#fc6a6a] rounded-full cursor-pointer'
                                        onClick={() => {
                                            setEditState(state => ({ ...state, filesToDelete: [ ...state.filesToDelete, i ] }))
                                        }}>
                                        <svg width={14} height={14} fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M5.72 5.72a.75.75 0 011.06 0L12 10.94l5.22-5.22a.75.75 0 111.06 1.06L13.06 12l5.22 5.22a.75.75 0 11-1.06 1.06L12 13.06l-5.22 5.22a.75.75 0 01-1.06-1.06L10.94 12 5.72 6.78a.75.75 0 010-1.06z"/>
                                        </svg>
                                    </span>

                                    <img className='w-[70px] h-[70px] md:w-[80px] md:h-[80px] mx-1 object-cover rounded' key={i} src={x} />
                                </div>
                            )
                        })}

                        {editState.files.map((x, i) => {
                            const url = URL.createObjectURL(x)

                            return (
                                <div className='relative' key={url}>
                                    <span className='inline-flex justify-center items-center absolute right-0 top-0 h-[17px] w-[17px] bg-[#fc6a6a] rounded-full cursor-pointer'
                                        onClick={() => {
                                            setEditState(state => ({ ...state, files: state.files.filter(y => y != x) }))
                                        }}>
                                        <svg width={14} height={14} fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M5.72 5.72a.75.75 0 011.06 0L12 10.94l5.22-5.22a.75.75 0 111.06 1.06L13.06 12l5.22 5.22a.75.75 0 11-1.06 1.06L12 13.06l-5.22 5.22a.75.75 0 01-1.06-1.06L10.94 12 5.72 6.78a.75.75 0 010-1.06z"/>
                                        </svg>
                                    </span>

                                    <img className='w-[70px] h-[70px] md:w-[80px] md:h-[80px] mx-1 object-cover rounded' src={url} />
                                </div>
                            )
                        })}

                        {((data.images.length ?? 0) + editState.files.length < 4) &&
                            <>                        
                                <label for='edit-file-upload' className='w-[70px] h-[70px] md:w-[80px] md:h-[80px] bg-[#F2F2F2] rounded mx-1 flex items-center justify-center cursor-pointer'>
                                    <svg width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g id="ic-actions-add-file">
                                            <line className="cls-1" x1="16" y1="13.12766" x2="8" y2="13.12766"/>
                                            <line className="cls-1" x1="12" y1="17.12766" x2="12" y2="9.12766"/>
                                            <path className="cls-2" d="M14.85571,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8.91869a1,1,0,0,0-.18085-.57357L15.67487,2.42643A1,1,0,0,0,14.85571,2Z"/>
                                        </g>
                                    </svg>
                                </label>

                                <input id='edit-file-upload' type="file" className='hidden' multiple
                                    onInput={(e) => setEditState(state => {
                                        return { 
                                            ...state, 
                                            files: [
                                                ...state.files, 
                                                ...Array.from(e.target.files).slice(0, process.env.REACT_APP_MAX_POST_IMAGES - (data.images.length ?? 0) + state.files.length)
                                            ] 
                                        }
                                    })}>    
                                </input>
                            </>
                        }
                    </div>
                }

                { !editMode &&
                    <>
                        <div className='flex justify-start ml-2 translate-y-[-4px]'>
                            <span className='text-[0.7rem] m-1 hover:text-rose-600 cursor-pointer' onClick={() => setShowLikes(true)}>{status.likes} likes</span> 
                            <span className='text-[0.7rem] m-1'>•</span>
                            <span className='text-[0.7rem] m-1'>{commentsCount.current} comments</span>
                        </div>

                        <div className='flex justify-around'>
                            <button onClick={onLikeClick} className={`flex items-center justify-center w-full rounded text-rose-400 p-2 py-3 xs:p-3 xs:text-[0.8rem] font-semibold text-[0.7rem] hover:text-rose-500 hover:bg-rose-100 ${ status.like ? 'bg-rose-100/[0.6] text-rose-400' : '' }`}>
                                { !status.inAction &&
                                    <svg className={`w-4 h-4 xs:w-5 xs:h-5 mx-1 p-[1px] ${status.like ? 'fill-rose-500' : 'fill-rose-400 hover:fill-rose-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"/>
                                    </svg>
                                }

                                { status.inAction &&
                                    <ClipLoader size={15} color='#FB7185' />
                                }

                                <span className='mx-1'>
                                    { status.like ? 'Liked' : 'Like' }
                                </span>
                            </button>
                            
                            <button onClick={onCommentClick} className={`flex items-center justify-center w-full rounded text-emerald-400 p-2 py-3 xs:p-3 xs:text-[0.8rem] font-semibold text-[0.8rem] hover:text-emerald-500 hover:bg-emerald-100 ${ comments.show ? 'text-emerald-400' : '' }`}>
                                {!fetching &&
                                    <svg className={`w-4 h-4 xs:w-5 xs:h-5 mx-1 p-[1px] ${comments.show ? 'fill-emerald-500' : 'fill-emerald-400 hover:fill-emerald-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"/>
                                    </svg>   
                                }

                                { fetching &&
                                    <ClipLoader size={15} color='#34d399' />
                                }
                            
                                <span className='mx-1'>
                                    Comment
                                </span>
                            </button>
                            
                            <button onClick={onShareClick} className={`flex items-center justify-center w-full rounded text-sky-400 p-2 py-3 xs:p-3 xs:text-[0.8rem] font-semibold text-[0.8rem] hover:text-sky-500 hover:bg-sky-100`}>
                                <svg className='w-4 h-4 xs:w-5 xs:h-5 mx-1 p-[1px] fill-sky-400' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128C448 181 405 224 352 224C326.1 224 302.6 213.8 285.4 197.1L285.4 197.1z"/>
                                </svg>

                                <span className='mx-1'>
                                    Share
                                </span>
                            </button>
                        </div>

                        {comments.show &&
                            <div className='h-[1px] bg-[#aaa] mx-[5%] bg-stone-200/[0.8]' />
                        }

                        {comments.show &&
                            <div className='flex flex-col p-2 mt-2'>
                                { fetchingMore &&
                                    <div className='self-center mt-[-0.5rem]'> 
                                        <ClipLoader size={15} color='#34d399' />
                                    </div>
                                }

                                {comments.list.length < commentsCount.current && !fetchingMore &&
                                    <span className='self-center text-[0.8rem] cursor-pointer font-semibold text-slate-700' onClick={loadMoreComments}>load more</span>
                                }

                                {comments.list.map((x, i) => 
                                    <div key={x._id}>
                                        { i > 0 && 
                                            <div className='h-[1px] bg-[#aaa] mx-[5%] bg-stone-200/[0.8] mt-[1px]' />
                                        }

                                        <PostComment removeComment={removeComment} key={x._id} data={x} />
                                    </div>
                                )}

                                <PostCommentInput postId={data._id} pushComment={pushComment} />
                            </div>
                        }
                    </>
                }

                { editMode &&
                    <div className={`${data.images.length ? 'mt-4' : ''} flex items-center justify-end ml-auto`}>
                        <span className='text-[#333] cursor-pointer ml-8 mr-3 text-[0.85rem] translate-y-[-4px]' onClick={() => {
                            if (editingRef.current)
                                return

                            setEditMode(false)
                        }}>cancel</span>

                        <button id='post-edit-button' disabled={disableEditButton()} className={`flex items-center justify-center mx-4 mb-3 md:w-[8rem] w-[5rem] py-2 md:py-3 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem]`} onClick={onEditClick}>
                            { status.editing &&
                                <ClipLoader size={15} color='#eee' />
                            }

                            <span className={`${status.editing ? 'ml-2' : ''}`}>
                                { status.editing ? 'editing' : 'edit' }
                            </span>
                        </button>
                    </div>
                }
            </div>

            {showLikes &&
                <PostLikes post={data} status={status} close={() => setShowLikes(false)} />
            }
        </>
    )
}