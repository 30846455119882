import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import cog_pool from "./user_pool";

const signup = async (email, password, type) => {
    return new Promise((resolve, reject) => {
        console.log('triggering')
        const pool = cog_pool(type);
        pool.signUp(email.toLowerCase(), password, [], null, async (error, data) => {
            console.log('during call');
            if (error) {
                console.log(error.code)

                switch (error.code) {
                    case 'UsernameExistsException':                        
                        reject({ code: error.code, message: error.message });
                        break

                    case 'InvalidParameterException':
                        reject({ code: null, message: 'Invalid Email or Password' });
                        break;
                    case 'InvalidPasswordException':
                        reject({ code: error.code, message: error.message })
                        // reject({ error: error.message.split(':')[1].trim(), data: null })
                        break;
                    case 'UserLambdaValidationException':   // this exception will work, if lamda function in aws restrict login process
                        console.log('here');
                        reject({code: error.code, message: (error.message).split('error')[1]})
                        break
                    // case 'UsernameExistsException':
                    //     break
                    default: 
                        // * may have to change the default response 
                        reject({ code: error.code, message: error.message })
                        break;
                }
            }
            else {
                console.log(data);
                resolve({ error: null, data: data });
            }
        })
    })
}

export default signup;