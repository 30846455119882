import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'

export default function MessageAttachment({ data }) {
    const [loading ,setLoading] = useState(true)
    const canvasRef = useRef()

    const { attachments } = useSelector(state => state.chat)
    const attachmentState = attachments[data.key]

    const typeSplit = data.type.split('/')

    useEffect(() => {
        const canvas = canvasRef.current 

        if (attachmentState && canvas) {
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, 60, 60)

            const progress = attachmentState.uploaded / attachmentState.size

            ctx.lineWidth = 2
            ctx.strokeStyle = "rgba(235, 235, 235, 0.4)"
            ctx.beginPath()
            ctx.arc(30, 30, 10, 0, 2.0 * Math.PI)
            ctx.stroke()
            ctx.closePath()
    
            ctx.strokeStyle = "#22A5FF"
            ctx.beginPath()
            ctx.arc(30, 30, 10, Math.PI, Math.PI + progress * 2.0 * Math.PI)
            ctx.stroke()
            ctx.closePath()
        }
    }, [attachmentState && attachmentState.uploaded])

    return attachmentState != null && attachmentState.status == 'pending'
        ? (
            <div className='relative flex items-center justify-center' key={data.key}>
                <canvas ref={canvasRef} width={60} height={60} className='absolute z-10' />

                {typeSplit[0] == 'image' &&
                    <img style={{ filter: attachmentState?.status == 'pending' ? 'blur(1px)' : '' }} height={275} width={225} className='max-w-[100%] max-h-[275px] mt-1 mb-2 rounded' src={attachmentState.previewUrl} />
                }
            </div>
        )

        : (
            <div className='relative flex flex-col items-center justify-center'>
                { loading &&
                    <ScaleLoader className='absolute z-10' color='#22A5FF' height={16} width={2} margin={1} />
                }

                {typeSplit[0] == 'image' &&
                    <img style={{ opacity: loading ? '0.5' : '' }} onLoad={() => setLoading(false)} height={275} width={225} className='max-w-[100%] max-h-[275px] mt-1 mb-2 rounded' src={attachmentState?.previewUrl ?? `${process.env.REACT_APP_AWS_CLOUDFRONT}/${data.key}`} />
                }
            </div>
        )
}