export const get_skills_token = async () => {
  const response = await fetch(`https://auth.emsicloud.com/connect/token`, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `grant_type=client_credentials&client_id=${process.env.REACT_APP_SKILLS_CLIENT_ID}&client_secret=${process.env.REACT_APP_SKILLS_CLIENT_SECRET}&scope=emsi_open`,
  });
  const data = await response.json();
  return data.access_token;
};
export default get_skills_token;
