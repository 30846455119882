import React, { useState, useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { bloodTypes } from "../../Profile/data";
import { useDispatch, useSelector } from "react-redux";
import { pushPost, updatePost } from "../../../redux/slices/postsSlice";
import get_current_user from "../../../aws_cognito/get_user";
import * as postsApi from "../../../api/posts";
import { locationOptions } from "../../Preferences/data/data";

import "./BloodRequirement.css";

const BloodRequirement = ({ bloodRequirement, setBloodReq, data, edit }) => {
  const dispatch = useDispatch();
  const { loading, userProfile } = useSelector((state) => state.currentProfile);
  const postingRef = useRef(false);
  const [posting, setPosting] = useState(false);
  const type = userProfile?.data?.type;

  const [details, setDetails] = useState({
    verified: false,
  });
  const [brSubmitPosting, setBrSubmitPosting] = useState(false);
  const [unUpdated, setUnpdated] = useState({
    patientName: data.patientName,
    patientAge: data.patientAge,
    bloodType: data.bloodType,
    unitsRequired: data.unitsRequired,
    case: data.case,
    hospital: data.hospital,
    location: data.location,
    bystanderName: data.bystanderName,
    contactNumber: data.contactNumber,
    dateOfRequirement: data.dateOfRequirement,
    verified: data.verified,
  });
  const contactNumberRegex = RegExp(/^(\+\d{1,3})?\d{10}$/);
  const checkEditDisable = () => {
    if (edit === true) {
      return editIsDisabled();
    } else {
      return isDisabled();
    }
  };
  const isDisabled = () =>
    !details.bloodType ||
    !details.hospital ||
    !details.unitsRequired ||
    !contactNumberRegex.test(details.contactNumber) ||
    !details.dateOfRequirement;
  const editIsDisabled = () =>
    !unUpdated.bloodType ||
    !unUpdated.hospital ||
    !unUpdated.unitsRequired ||
    !contactNumberRegex.test(unUpdated.contactNumber) ||
    !unUpdated.dateOfRequirement;

  const [records, setRecords] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [ageError, setAgeError] = useState("");
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "contactNumber") {
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      if (value.length == 0) setPhoneError("");
      else if (value.length < 10) setPhoneError("Enter valid contact number");
      else setPhoneError("");
    } else if (name == "patientAge") {
      if (value.length > 3) {
        value = value.slice(0, 3);
      }
      if (value.length == 0) setAgeError("");
      else if (value < 1 || value > 122) {
        setAgeError("Enter valid age");
      } else setAgeError("");
    }
    if (edit) {
      setUnpdated({
        ...unUpdated,
        [name]: value,
      });
    } else {
      setDetails({
        ...details,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPosting(true);
    setBrSubmitPosting(true);
    if (postingRef.current) return;

    postingRef.current = true;

    try {
      const user = await get_current_user(type);

      if (edit) {
        const resUpdated = await postsApi.editBloodRequirement(
          data._id,
          unUpdated,
          user.data.idToken
        );
        setBrSubmitPosting(false);
        const postUpdated = resUpdated.data;
        dispatch(updatePost({ post: postUpdated }));
      } else {
        const res = await postsApi.createBloodRequirementPost(
          details,
          user.data.idToken
        );
        const post = res.data;
        setBrSubmitPosting(false);
        dispatch(pushPost(post));
      }
      console.log(details);

      setDetails({});
    } catch (error) {
    } finally {
      postingRef.current = false;
      setPosting(false);
    }
    setBloodReq(0);
  };

  const handleClose = (e) => {
    setBloodReq(0);
  };

  const handleVerified = (e) => {
    if (edit) {
      setUnpdated({ ...unUpdated, verified: true });
    } else {
      setDetails({ ...details, verified: true });
    }
  };

  const handleUnerified = (e) => {
    if (edit) {
      setUnpdated({ ...unUpdated, verified: false });
    } else {
      setDetails({ ...details, verified: false });
    }
  };

  const formRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    const onWindowClick = (e) => {
      if (e.target == wrapperRef.current) {
        setBloodReq(false);
      }
    };

    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []);

  return (
    <div className="blood-req-popup flex flex-col">
      <div ref={wrapperRef} className="wrapper p-4">
        <form
          ref={formRef}
          className="form p-2"
          action=""
          onSubmit={handleSubmit}
        >
          <div className="form-header ml-4 my-2">
            <div className="form-title">
              <h3>Blood Requirement</h3>
            </div>
            <div className="form-close">
              <button onClick={handleClose}>
                <AiOutlineClose />
              </button>
            </div>
          </div>

          <div className="p-4 max-h-[75vh] scrollable overflow-y-scroll">
            <div>
              <label htmlFor="patientName">Patient Name</label>
              <br />
              <input
                className="input-field"
                type="text"
                placeholder="Enter the patient's name"
                autoComplete="off"
                value={edit ? unUpdated.patientName : details.patientName}
                onChange={handleInput}
                name="patientName"
                id="patientName"
              />
            </div>

            <div className="full-portion">
              <div className="half-portion">
                <label htmlFor="patientAge">Patient Age</label>
                <br />
                <input
                  className="input-field"
                  placeholder="Enter patient's age"
                  type="number"
                  autoComplete="off"
                  value={edit ? unUpdated.patientAge : details.patientAge}
                  onChange={handleInput}
                  name="patientAge"
                  id="patientAge"
                />
                {ageError && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontWeight: 500,
                      marginTop: "-5px",
                    }}
                  >
                    {ageError}
                  </div>
                )}
              </div>

              <div className="half-portion">
                <label htmlFor="bloodType">
                  Blood Group <span className="text-red-600">*</span>
                </label>
                <br />

                <select
                  id="bloodType"
                  className="input-field"
                  name="bloodType"
                  onChange={handleInput}
                  value={edit ? unUpdated.bloodType : details.bloodType}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {bloodTypes.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion ">
                <label htmlFor="unitsRequired">
                  No. of units required <span className="text-red-600">*</span>
                </label>
                <br />
                <input
                  className="input-field"
                  placeholder="Enter the no. of units required"
                  type="number"
                  autoComplete="off"
                  value={edit ? unUpdated.unitsRequired : details.unitsRequired}
                  onChange={handleInput}
                  name="unitsRequired"
                  id="unitsRequired"
                />
              </div>
              <div className="half-portion">
                <label htmlFor="case">Medical Case</label>
                <br />
                <input
                  className="input-field"
                  placeholder="Specify the medical case"
                  type="text"
                  autoComplete="off"
                  value={edit ? unUpdated.case : details.case}
                  onChange={handleInput}
                  name="case"
                  id="case"
                />
              </div>
            </div>

            <div className="full-portion">
              <div className="half-portion">
                <label htmlFor="hospital">
                  Hospital <span className="text-red-600">*</span>
                </label>
                <br />
                <input
                  className="input-field"
                  placeholder="Enter the hospital name"
                  type="text"
                  autoComplete="off"
                  value={edit ? unUpdated.hospital : details.hospital}
                  onChange={handleInput}
                  name="hospital"
                  id="hospital"
                />
              </div>

              <div className="half-portion">
                <label htmlFor="location">
                  Location <span className="text-red-600">*</span>
                </label>
                <br />
                <select
                  id="location"
                  className="input-field"
                  name="location"
                  onChange={handleInput}
                  value={edit ? unUpdated.location : details.location}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {locationOptions.slice(1).map((data, index) => {
                    return (
                      <option value={data.label} key={index}>
                        {data.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div>
              <label htmlFor="bystanderName">Bystander Name</label>
              <br />
              <input
                className="input-field"
                placeholder="Enter the bystander's name"
                type="text"
                autoComplete="off"
                value={edit ? unUpdated.bystanderName : details.bystanderName}
                onChange={handleInput}
                name="bystanderName"
                id="bystanderName"
              />
            </div>

            <div>
              <label htmlFor="contactNumber">
                Contact Number <span className="text-red-600">*</span>
              </label>
              <br />
              <input
                className="input-field"
                placeholder="Enter the contact number"
                type="number"
                autoComplete="off"
                value={edit ? unUpdated.contactNumber : details.contactNumber}
                onChange={handleInput}
                name="contactNumber"
                id="contactNumber"
              />
              {phoneError && (
                <div
                  style={{
                    color: "red",
                    fontSize: "11px",
                    fontWeight: 500,
                    marginTop: "-5px",
                  }}
                >
                  {phoneError}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="dateOfRequirement">
                Date of Requirement <span className="text-red-600">*</span>
              </label>
              <br />
              <input
                className="input-field"
                type="date"
                autoComplete="off"
                min={new Date().toISOString().substring(0, 10)}
                value={
                  edit
                    ? unUpdated.dateOfRequirement.substring(0, 10)
                    : details.dateOfRequirement
                }
                onChange={handleInput}
                name="dateOfRequirement"
                id="dateOfRequirement"
              />
            </div>

            <div>
              <label htmlFor="verification">
                Verified? <span className="text-red-600">*</span>
              </label>
              <br />
              <div className="verification">
                <div className="verify">
                  <input
                    type="radio"
                    onClick={handleVerified}
                    name="verification"
                    value="yes"
                  />
                  <label htmlFor="yes" onClick={handleVerified}>
                    Yes
                  </label>
                </div>
                <div className="verify">
                  <input
                    type="radio"
                    onClick={handleUnerified}
                    name="verification"
                    value="no"
                    defaultChecked
                  />
                  <label htmlFor="no" onClick={handleUnerified}>
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="submit-button">
            {brSubmitPosting ? (
              <button
                disabled
                className="post-btn px-8 py-2 mb-5 font-bold text-white rounded-lg cursor-pointer bg-blue hover:bg-blue-500 md:py-3 md:mb-0 flex items-center"
              >
                <svg
                  role="status"
                  class="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Posting
              </button>
            ) : (
              <button
                style={{ opacity: checkEditDisable() ? 0.5 : 1 }}
                disabled={checkEditDisable()}
                onClick={handleSubmit}
                type="submit"
                className="post-btn px-8 py-2 mb-5 font-bold text-white rounded-lg cursor-pointer bg-blue hover:bg-blue-500 md:py-3 md:mb-0 flex items-center"
              >
                Post
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BloodRequirement;
