import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    locations: [],
    causes: [],
    skills: [],
  },
  reducers: {
    updateArray: (state, action) => {
      const { type, value } = action.payload;
      console.log(type, value);
      state[type].push(value);
    },
    deleteArray: (state, action) => {
      const { type, value } = action.payload;
      console.log(value);
      state[type].splice(state[type].indexOf(value), 1);
    },

    resetFilter: (state, action) => {
      const { type } = action.payload;
      console.log(type);
      state[type] = [];
    },
  },
});

export default searchSlice.reducer;

export const { updateArray, deleteArray, resetFilter } = searchSlice.actions;
