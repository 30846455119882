import React, { useState } from "react";
import CompanyPosts from "../ProfileCorporate/CompanyPosts";
import NGOAbout from "./NGOAbout";
import NGOTestimonials from "./NGOTestimonials";

export default function NGOInfo({ typeUser, profileData,me }) {
  console.log(profileData)
  const [tabClicked, setTabClicked] = useState(1);
  return (
    <>
      <div className="flex flex-col px-5 bg-white shadow-lg md:shadow-lg md:px-8 md:py-5 rounded-2xl md:rounded-lg">
        <div className="text-xs md:text-base  flex gap-5 md:gap-10 py-4 border-b border-[#CFEBFF]">
          <button
            className={
              tabClicked === 1
                ? "font-bold underline underline-offset-[16px] md:underline-offset-[18px] decoration-blue decoration-4"
                : ""
            }
            onClick={() => {
              setTabClicked(1);
            }}
          >
            About
          </button>
          <button
            className={
              tabClicked === 2
                ? "font-bold underline underline-offset-[16px] md:underline-offset-[18px] decoration-blue decoration-4"
                : ""
            }
            onClick={() => {
              setTabClicked(2);
            }}
          >
            Posts
          </button>
          <button
            className={
              tabClicked === 3
                ? "font-bold underline underline-offset-[16px] md:underline-offset-[18px] decoration-blue decoration-4"
                : ""
            }
            onClick={() => {
              setTabClicked(3);
            }}
          >
            Testimonials
          </button>
        </div>
        {tabClicked == 1 ? <NGOAbout /> : tabClicked == 2 ? <CompanyPosts typeUser={typeUser} profile={profileData.data} />:<NGOTestimonials typeUser={typeUser} profile={profileData.data} />}
      </div>
    </>
  );
}
