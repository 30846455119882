import React from "react";
import { useSelector } from "react-redux";

export default function ContactInfo({ showModal, setShowModal }) {
  const profile = useSelector((state) => state.userProfile.userProfile);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-96 my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5  rounded-t ">
                  <h3 className="text-xl font-semibold">Contact Info</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium "
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-8 w-12 text-lg block ">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-col space-y-5">
                    <div className="flex flex-col">
                      <p className="">Linkedin</p>
                      <a
                        href={profile?.data?.linkedIn}
                        className="text-[#4793D9] text-base"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile?.data?.linkedIn}
                      </a>
                    </div>
                    <div className="flex flex-col">
                      <p className="">Email</p>
                      <a
                        href={`mailto:${profile?.data?.email}`}
                        className="text-[#4793D9] text-base"
                      >
                        {profile?.data?.email}
                      </a>
                    </div>
                    <div className="flex flex-col">
                      <p className="">Contact No.</p>
                      <a
                        href={`tel:${profile?.data?.phone_number}`}
                        className="text-[#4793D9] text-base"
                      >
                        {profile?.data?.phone_number}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
