import React, { useCallback, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

export const UserAnalyticsGraph = ({ data }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
  const isVerySmall = useMediaQuery({ query: `(max-width: 450px)` });

  const [graphData, setGraphData] = useState([]);

  const [loading, setLoading] = useState(true);

  const convertData = useCallback(() => {
    const dataVolunteer = data?.volunteer?.map((item) => {
      return {
        volunteers: item.count,
        date: item.date,
      };
    });
    const dataCorporate = data?.corporate?.map((item) => {
      return {
        corporates: item.count,
        date: item.date,
      };
    });
    const dataImpactOrg = data?.impactOrg?.map((item) => {
      return {
        "impact organizations": item.count,
        date: item.date,
      };
    });

    const newData = [...dataVolunteer, ...dataCorporate, ...dataImpactOrg];

    // Combine if same date
    const newDataCombined = newData.reduce((acc, curr) => {
      const existing = acc.find((item) => item.date === curr.date);
      if (!existing) {
        return acc.concat([curr]);
      } else {
        return acc.map((item) => {
          if (item.date === curr.date) {
            return {
              ...item,
              ...curr,
            };
          } else {
            return item;
          }
        });
      }
    }, []);

    /* Convert all dates in newDataCombined to date format - DD MMM */
    return formatDate(newDataCombined);
  }, [data?.corporate, data?.impactOrg, data?.volunteer]);

  const formatDate = (data) => {
    const newData = data
      .map((item) => {
        const date = new Date(item.date);
        const dateString = date.toDateString().split(" ");
        const dateFormatted = `${dateString[2]} ${dateString[1]}`;
        return {
          ...item,
          date: dateFormatted,
        };
      })
      .sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    return newData;
  };

  useEffect(() => {
    if (data.volunteer === undefined) data.volunteer = [];
    if (data.corporate === undefined) data.corporate = [];
    if (data.impactOrg === undefined) data.impactOrg = [];
    setGraphData(convertData());
  }, [convertData, data]);

  return (
    <div className={`grid mt-8 place-items-center`}>
      <div>
        <LineChart
          width={isVerySmall ? 300 : isMobile ? 400 : 800}
          height={isVerySmall ? 250 : isMobile ? 300 : 400}
          data={graphData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="volunteers"
            stroke="#E7C038"
            strokeWidth={3}
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey="date"
            tick={{ fontSize: "0.75rem", fontWeight: "bold" }}
          />
          <YAxis tick={{ fontSize: "0.75rem", fontWeight: "bold" }} />
          <Tooltip />

          <Line
            type="monotone"
            dataKey="corporates"
            strokeWidth={3}
            stroke="#46B966"
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey="date"
            tick={{ fontSize: "0.75rem", fontWeight: "bold" }}
          />
          <YAxis tick={{ fontSize: "0.75rem", fontWeight: "bold" }} />
          <Tooltip />

          <Line
            type="monotone"
            dataKey="impact organizations"
            stroke="#66E4DC"
            strokeWidth={3}
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey="date"
            tick={{ fontSize: "0.75rem", fontWeight: "bold" }}
          />
          <YAxis tick={{ fontSize: "0.75rem", fontWeight: "bold" }} />
          <Tooltip />
        </LineChart>
      </div>
    </div>
  );
};
