import cog_pool from "./user_pool";
import { CognitoUser } from "amazon-cognito-identity-js";
export const forgotPassword = (email, type) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: cog_pool(type)
    });
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                resolve({ data: result });
            },
            onFailure: (error) => {
                reject({ code: error.code, message: error.message });
            }
        });
    });
}

export function confirmPassword(email, verificationCode, newPassword, type) {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: cog_pool(type)
    });

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure(error) {
                reject({ error: error, data: null });
            },
            onSuccess(result) {
                console.log(result);
                resolve({ error: null, data: "Password reset successfully" });
            },
        });
    });
}