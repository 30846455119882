import React, { useEffect, useState } from 'react'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
  ScatterChart,
  Legend,
  Scatter,
  ResponsiveContainer,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'
import { outreachData } from '../../dummyData/graphData'
import Loading from '../Loading/Loading'

const OutreachGraph = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` })

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setTimeout(() => {
      setData(outreachData)
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return (
      <div className="grid w-full h-full place-items-center">
        <Loading />
      </div>
    )
  }

  return (
    <div className={`grid mt-8 place-items-center`}>
      <ScatterChart
        width={isMobile ? 450 : 800}
        height={isMobile ? 350 : 400}
        margin={{
          top: 15,
          right: 5,
          bottom: 18,
          left: 5,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="novo"
          name="novo"
          label={{
            value: 'No. of volunteers required',
            position: 'insideBottom',
            offset: -15,
          }}
          tick={{ fontSize: '0.9rem' }}
        />
        <YAxis
          type="number"
          dataKey="novr"
          name="novr"
          label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
          tick={{ fontSize: '0.9rem' }}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter name="" data={data} fill="#FF9D9D" />
      </ScatterChart>
    </div>
  )
}

export default OutreachGraph
