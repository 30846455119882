import React,{useRef,useEffect} from "react";
import "./WithdrawConnRequest.css";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
function WithdrawConnRequest({ setWithdrawConnModal,userID,typeUser}) { 
    const wrapperRef = useRef();
    useEffect(() => {
        const onWindowClick = (e) => {
        if (e.target == wrapperRef.current) {
            setWithdrawConnModal(false);
        }
        };
        window.addEventListener("click", onWindowClick);
        return () => {
        window.removeEventListener("click", onWindowClick);
        };
    }, []);
    const handleRevokeConnection = async () => {
        const user = await get_current_user(typeUser);
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${userID}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    return (
        <div ref={wrapperRef}  className="WithdrawConnRequestWrapper">
            <div className="WithdrawConnRequestBackground">
                <div className="WithdrawConnRequestContainer">
                    <div className="WithdrawConnRequestTitle">
                        <p className="text-[1.1rem]">Withdraw Connection Invitation</p>
                        <button
                            onClick={() => {
                            setWithdrawConnModal(false);
                            }}
                        >
                            <GrFormClose/>
                        </button>
                    </div>
                    <p className="my-1 text-[0.9rem]" >Are you sure you want to withdraw connection invitation,please click withdraw button below.</p>
                    <div className="WithdrawConnRequestBody">
                        <button onClick={() => {
                            setWithdrawConnModal(false);
                        }}>Cancel</button>
                        <button className="withdrawBtn" onClick={() => {handleRevokeConnection()}}>Withdraw</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WithdrawConnRequest;