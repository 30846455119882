import { timestampOf } from '../../../utils/utils'
import MessageAttachments from './MessageAttachments'

export default function OtherMessage({ data, className }) {
    return (
        <div data-id={data._id} className={`flex flex-col bg-[#E1E1E1] rounded-lg rounded-tl-none min-w-[6.2rem] py-2 px-3 ml-2 ${className}`}>
            <p className='text-[0.8rem] mb-1 break-all'>
                {data.text}
            </p>
            
            { data.attachments != null && data.attachments.length > 0 &&
                <MessageAttachments attachments={data.attachments} className='self-start' />
            }

            <span className='text-[0.66rem] font-medium text-[#949494] self-end my-[-0.1rem] translate-y-[1px]'>{timestampOf(data.createdAt)}</span>
        </div>
    )   
}