import * as yup from "yup";

export const basicSchema = yup.object().shape({
  year: yup
    .number()
    .required("Year is required")
    .min(1900, "Year must be greater than or equal to 1900")
    .max(
      new Date().getFullYear(),
      `Year must be less than or equal to ${new Date().getFullYear()}`
    ),
});
