import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import get_current_user from '../../aws_cognito/get_user'
import * as chatApi from '../../api/chat'
import { socketEventEnum } from '../../utils/enum'
import { compareDates } from "../../utils/utils"
import defaultAvatar from '../../images/default.jpg'
import adminAvatar from '../../images/Broadcast.png'

export const defaultConversationInput = {
    text: '',
    files: [],
    filePreviews: []
}

const fetchLimit = Number(process.env.REACT_APP_MESSAGES_FETCH_LIMIT) + 1

const getConnections = createAsyncThunk("chat/getConnections", async ({ type }) => {
    try {
        const user = await get_current_user(type)
        const res = await chatApi.getConnections(user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const getConversations = createAsyncThunk("chat/getConversations", async ({ type }) => {
    try {
        const user = await get_current_user(type)
        const res = await chatApi.getConversations(user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const createConversation = createAsyncThunk("chat/createConversation", async ({ other, type }) => {
    try {
        const user = await get_current_user(type)
        const res = await chatApi.createConversation(other, user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const getMessages = createAsyncThunk("chat/getMessages", async ({ other, query, type }) => {
    try {
        query.limit = fetchLimit

        const user = await get_current_user(type)
        const res = await chatApi.getMessages(other, query, user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const getBroadcastMessages = createAsyncThunk("chat/getBroadcastMessages", async ({ query, type }) => {
    try {
        query.limit = fetchLimit

        const user = await get_current_user(type)
        const res = await chatApi.getBroadcastMessages(query, user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const getBroadcastMessagesForType = createAsyncThunk("chat/getBroadcastMessagesForType", async ({ broadcastType, query, type }) => {
    try {
        query.limit = fetchLimit

        const user = await get_current_user(type)
        const res = await chatApi.getBroadcastMessagesForType(broadcastType, query, user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const getBroadcastConversation = createAsyncThunk("chat/getBroadcastConversation", async ({ type }) => {
    try {
        const user = await get_current_user(type)
        const res = await chatApi.getBroadcastConversation(user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const updateLastSeenBroadcast = createAsyncThunk("chat/updateLastSeenBroadcast", async ({ type }) => {
    try {
        const user = await get_current_user(type)
        const res = await chatApi.updateLastSeenBroadcast(user.data.idToken)

        return res.data
    }

    catch (error) {
        throw error
    }
})

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        status: {
            connection: 'pending',
        },
        conversations: [],
        connections: [],
        selectedUser: null,
        userStatuses: {},
        messages: {},
        addingConversation: false,
        events: {
            newMessage: null,
            fetchedOldMessages: null,
            initialFetchMessages: null
        },
        attachments: {},
        conversationInputs: {},
        broadcastType: null
    },

    reducers: {
        [`${socketEventEnum.CONNECT}/pending`]: (state, action) => {
            state.status.connection = 'pending'
        },

        [`${socketEventEnum.CONNECT}/fulfilled`]: (state, action) => {
            state.status.connection = 'success'
        },

        [`${socketEventEnum.CONNECT}/rejected`]: (state, action) => {
            state.status.connection = 'failed'
        },

        [`${socketEventEnum.CREATE_PRIVATE_MESSAGE}/fulfilled`]: (state, { payload, meta }) => {
            const message = state.messages[meta.to.type + meta.to._id].find(x => x.lid == meta.lid)
            message._id = payload._id
            message.createdAt = payload.createdAt

            const conversation = state.conversations.find(x => x.other._id == meta.to._id && x.otherType == meta.to.type)

            if (conversation.lastMessage.lid == meta.lid) {
                conversation.lastMessage = message
            }
        },

        [`${socketEventEnum.CREATE_BROADCAST_MESSAGE}/fulfilled`]: (state, { payload, meta }) => {
            const message = state.messages[meta.type].find(x => x.lid == meta.lid)
            message._id = payload._id
            message.createdAt = payload.createdAt
        },

        [`${socketEventEnum.USER_SAW_MESSAGES}/fulfilled`]: (state, { payload, meta }) => {
            const conversation = state.conversations.find(x => x.otherType == meta.other.type && x.other._id == meta.other._id)
            const selectedUser = state.selectedUser
            conversation.newMessagesCount = 0

            if (selectedUser.type == meta.other.type && selectedUser._id == meta.other._id) {
                state.selectedConversation.lastSawMessages = payload.lastSawMessages
            }
        },

        setUserStatuses: (state, { payload }) => {
            payload.forEach(x => state.userStatuses[x.type + x._id] = 'online')
        },

        changeUserStatus: (state, { payload }) => {
            state.userStatuses[payload.type + payload._id] = payload.status

            if (payload.status == 'offline') {
                const conversation = state.conversations.find(x => x.otherType == payload.type && x.other._id == payload._id)
                const selectedConversation = state.selectedConversation

                if (conversation) {
                    conversation.otherIsTyping = false
                }

                if (selectedConversation && selectedConversation._id == conversation._id) {
                    state.selectedConversation.otherIsTyping = false
                }
            }
        },

        setAddingConversation: (state, { payload }) => {
            state.addingConversation = payload
        },

        setSelectedUserFromConversation: (state, { payload }) => {
            state.selectedUser = { type: payload.otherType, ...payload.other }
            state.selectedConversation = payload
        },

        addMessage: (state, { payload }) => {
            const other = payload.self
                ? { type: payload.recipientType, _id: payload.recipient }
                : { type: payload.authorType, _id: payload.author }

            const selectedUser = state.selectedUser

            if (selectedUser && other.type == selectedUser.type && other._id == selectedUser._id) {
                state.events.newMessage = Date.now()
            }

            if (state.messages[other.type + other._id]) {
                state.messages[other.type + other._id].push(payload)
            }

            else state.messages[other.type + other._id] = [payload]

            let conversation = state.conversations.find(x => x.otherType == other.type && x.other._id == other._id)

            if (!conversation) {
                state.conversations.unshift({
                    _id: payload.conversationId,
                    other: {
                        _id: payload.author,
                        name: payload.authorName,
                        avatar: (!payload.authorProfilePic || payload.authorProfilePic == '') ? defaultAvatar : payload.authorProfilePic
                    },

                    otherType: payload.authorType,
                    newMessagesCount: 0,
                    lastMessage: payload
                })

                state.conversationInputs[payload.conversationId] = defaultConversationInput
                conversation = state.conversations[0]
            }

            if (!payload.self) {
                conversation.newMessagesCount++
            }

            conversation.lastMessage = payload
        },

        addBroadcastMessage: (state, { payload }) => {
            if (state.messages[state.broadcastType]) {
                state.messages[state.broadcastType].push(payload)
            }

            else state.messages[state.broadcastType] = [payload]

            state.events.newMessage = Date.now()
        },

        updateLastSeen: (state, { payload }) => {
            const conversation = state.conversations.find(x => x.otherType == payload.otherType && x.other._id == payload.other)
            const selectedConversation = state.selectedConversation
            conversation.otherLastSawMessages = payload.otherLastSawMessages

            if (selectedConversation && selectedConversation._id == conversation._id) {
                state.selectedConversation.otherLastSawMessages = payload.otherLastSawMessages
            }
        },

        updateUserTyping: (state, { payload }) => {
            const conversation = state.conversations.find(x => x.otherType == payload.other.type && x.other._id == payload.other._id)
            const selectedConversation = state.selectedConversation

            if (conversation) {
                conversation.otherIsTyping = payload.isTyping
            }

            if (selectedConversation && selectedConversation._id == conversation._id) {
                selectedConversation.otherIsTyping = payload.isTyping
            }
        },

        setConversationInput: (state, { payload }) => {
            state.conversationInputs[state.selectedConversation._id] = payload
        },

        clearSelectedUser: (state, { payload }) => {
            state.selectedUser = null
            state.selectedConversation = null
        },

        updateAttachmentState: (state, { payload }) => {
            state.attachments[payload.key] = { ...state.attachments[payload.key], ...payload }
        },

        setBroadcastType: (state, { payload }) => {
            state.broadcastType = payload

            if (!state.conversationInputs[state.broadcastType]) {
                state.conversations = [ ...state.conversations, { _id: state.broadcastType } ]
                state.conversationInputs[state.broadcastType] = defaultConversationInput
            }

            state.selectedConversation = state.conversations.find(x => x._id == payload)
        },

        addAdminMessage: (state, { payload }) => {
            const conversation = state.conversations.find(x => x._id == 'admin')

            if (conversation) {
                state.messages['admin'].push(payload)
                conversation.lastMessage = payload 
                conversation.newMessagesCount++
            }
        }
    },

    extraReducers: {
        [getConnections.pending]: (state, action) => {
            state.status.connections = 'pending'
        },

        [getConnections.fulfilled]: (state, { payload }) => {
            state.status.connections = 'success'
            state.connections = payload
        },

        [getConnections.rejected]: (state, action) => {
            state.status.connections = 'failed'
        },

        [getConversations.pending]: (state, action) => {
            state.status.conversations = 'pending'
        },

        [getConversations.fulfilled]: (state, { payload }) => {
            state.status.conversations = 'success'
            state.conversations = [...state.conversations, ...payload]
            state.conversations.forEach(x => {
                state.messages[x.otherType + x.other._id] = []
                state.conversationInputs[x._id] = defaultConversationInput
                x.other.avatar = (!x.other.profilePic || x.other.profilePic == '') ? defaultAvatar : x.other.profilePic
            })

            state.conversations = state.conversations.sort((a, b) => compareDates(b.lastMessage?.createdAt, a.lastMessage?.createdAt))
        },

        [getConversations.rejected]: (state, action) => {
            state.status.conversations = 'failed'
        },

        [createConversation.pending]: (state, action) => {
            state.status.createConversation = 'pending'
        },

        [createConversation.fulfilled]: (state, { payload }) => {
            payload.other.avatar = (!payload.other.profilePic || payload.other.profilePic == '') ? defaultAvatar : payload.other.profilePic
            state.conversationInputs[payload._id] = defaultConversationInput

            state.status.createConversation = 'success'
            state.conversations.push(payload)
            state.messages[payload.otherType + payload.other._id] = []
            state.selectedUser = { type: payload.otherType, ...payload.other }
            state.selectedConversation = payload
            state.addingConversation = false
        },

        [createConversation.rejected]: (state, action) => {
            state.status.createConversation = 'failed'
        },

        [getMessages.pending]: (state, action) => {
            state.status.getMessages = 'pending'
        },

        [getMessages.fulfilled]: (state, { payload, meta }) => {
            state.status.getMessages = 'success'

            payload.forEach(x => x.self = x.authorType != meta.arg.other.type || x.author != meta.arg.other._id)
            payload.reverse()

            const selectedUser = state.selectedUser
            const isSelectedUser = selectedUser && (selectedUser.type == meta.arg.other.type && selectedUser._id == meta.arg.other._id)
            const conversation = state.conversations.find(x => x.otherType == meta.arg.other.type && x.other._id == meta.arg.other._id)

            if (payload.length < fetchLimit) {
                conversation.allFetched = true

                if (isSelectedUser) {
                    state.selectedConversation.allFetched = true
                }
            }

            else {
                payload.splice(0, 1)
            }

            if (meta.arg.query.before) {
                state.messages[meta.arg.other.type + meta.arg.other._id].unshift(...payload.slice(0, payload.length))

                if (isSelectedUser) {
                    state.events.fetchedOldMessages = Date.now()
                }
            }

            else {
                state.messages[meta.arg.other.type + meta.arg.other._id] = payload.slice(0, payload.length)

                if (isSelectedUser) {
                    state.events.newMessage = Date.now()
                    state.events.fetchedOldMessages = Date.now()
                    state.events.initialFetchMessages = Date.now()
                }
            }
        },

        [getBroadcastMessages.fulfilled]: (state, { meta, payload }) => {
            const selectedUser = state.selectedUser
            state.status.getBroadcastMessages = 'success'
            payload.reverse()

            const conversation = state.conversations.find(x => x._id == 'admin')

            if (payload.length < fetchLimit) {
                conversation.allFetched = true

                if (selectedUser._id == 'admin') {
                    state.selectedConversation.allFetched = true
                }
            }

            else {
                payload.splice(0, 1)
            }

            if (meta.arg.query.before) {
                state.messages['admin'].unshift(...payload.slice(0, payload.length))

                if (selectedUser._id == 'admin') {
                    state.events.fetchedOldMessages = Date.now()
                }
            }

            else {
                state.messages['admin'] = payload.slice(0, payload.length)

                if (selectedUser._id == 'admin') {
                    state.events.newMessage = Date.now()
                    state.events.fetchedOldMessages = Date.now()
                    state.events.initialFetchMessages = Date.now()
                }
            }
        },

        [getBroadcastMessagesForType.fulfilled]: (state, { meta, payload }) => {
            state.status.getBroadcastMessagesForType = 'success'
            payload.forEach(x => x.self = true)
            payload.reverse()

            const conversation = state.conversations.find(x => x._id == meta.arg.broadcastType)

            if (payload.length < fetchLimit) {
                conversation.allFetched = true

                if (meta.arg.broadcastType == state.broadcastType) {
                    state.selectedConversation.allFetched = true
                }
            }

            else {
                payload.splice(0, 1)
            }

            if (meta.arg.query.before) {
                state.messages[meta.arg.broadcastType].unshift(...payload.slice(0, payload.length))

                if (meta.arg.broadcastType == state.broadcastType) {
                    state.events.fetchedOldMessages = Date.now()
                }
            }

            else {
                state.messages[meta.arg.broadcastType] = payload.slice(0, payload.length)

                if (meta.arg.broadcastType == state.broadcastType) {
                    state.events.newMessage = Date.now()
                    state.events.fetchedOldMessages = Date.now()
                    state.events.initialFetchMessages = Date.now()
                }
            }
        },

        [getBroadcastConversation.fulfilled]: (state, { payload }) => {
            state.conversations.unshift({
                _id: 'admin',
                other: {
                    _id: 'admin',
                    name: 'Admin',
                    avatar: adminAvatar
                },
                otherType: 'admin',
                lastMessage: payload.lastMessage,
                newMessagesCount: payload.newMessagesCount
            })

            state.conversations = state.conversations.sort((a, b) => compareDates(b.lastMessage?.createdAt, a.lastMessage?.createdAt))
            state.conversationInputs['admin'] = defaultConversationInput
            state.messages['admin'] = []
        },

        [updateLastSeenBroadcast.fulfilled]: (state, { payload }) => {
            const conversation = state.conversations.find(x => x._id == 'admin')
            conversation.newMessagesCount = 0
        }
    }
})

export const chatSocketAction = (type, promise, meta) => ({
    type: 'socket',
    resType: 'chat/' + type,
    promise,
    meta
})

export const { 
    setUserStatuses,
    changeUserStatus,
    setAddingConversation,
    setSelectedUserFromConversation,
    addMessage,
    updateLastSeen,
    updateUserTyping,
    setConversationInput,
    clearSelectedUser,
    updateAttachmentState,
    setBroadcastType,
    addBroadcastMessage,
    addAdminMessage
} = chatSlice.actions

export { 
    getConnections, 
    getConversations, 
    createConversation, 
    getMessages, 
    getBroadcastMessages, 
    getBroadcastMessagesForType, 
    getBroadcastConversation,
    updateLastSeenBroadcast
}

export default chatSlice.reducer