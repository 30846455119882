import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import get_current_user from '../../aws_cognito/get_user'
import * as postsApi from '../../api/posts'
import { postTypeEnum } from '../../utils/enum'

const initialState = {
    loading: false,
    posts: [],
    postStatuses: {},
    error: null,
    end: false,
    cleared: true
}

const fetchPost = createAsyncThunk('post/fetch', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        const res = await postsApi.getPost(id, user.data.idToken)
        return res.data
    }

    catch (error) {  
        throw error
    }
})

const fetchPosts = createAsyncThunk('posts/fetch', async ({ params, userType, controller }) => {
    const user = await get_current_user(userType)

    try {
        const res = await postsApi.getPosts(params, user.data.idToken, controller)
        return res.data
    }

    catch (error) {
        throw error
    }
})

const likePost = createAsyncThunk('posts/like', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        await postsApi.likePost(id, user.data.idToken)
    }

    catch (error) {
        throw error
    }
})
 
const unlikePost = createAsyncThunk('posts/unlike', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        await postsApi.unlikePost(id, user.data.idToken)
    }

    catch (error) {
        throw error
    }
})

const deletePost = createAsyncThunk('posts/delete', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        await postsApi.deletePost(id, user.data.idToken)
    }

    catch (error) {
        throw error
    }
})

const editGeneralPost = createAsyncThunk('posts/editGeneral', async ({ id, edit, userType }) => {
    const user = await get_current_user(userType)

    try {
        const res = await postsApi.editGeneralPost(id, edit, user.data.idToken)
        return res.data
    }

    catch (error) {
        throw error
    }
})

const addInterest = createAsyncThunk('posts/addInterest', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        await postsApi.addInterest(id, user.data.idToken)
    }

    catch (error) {
        throw error
    }
})

const removeInterest = createAsyncThunk('posts/removeInterest', async ({ id, userType }) => {
    const user = await get_current_user(userType)

    try {
        await postsApi.removeInterest(id, user.data.idToken)
    }

    catch (error) {
        throw error
    }
})

const postsSlice = createSlice({
    name: 'posts',
    initialState,

    reducers: {
        clearPosts: (state, action) => {
            state.posts = []
            state.postStatuses = {}
            state.end = false
            state.cleared = true
        },

        incComments: (state, { payload }) => {
            state.postStatuses[payload.postId].comments++
        },

        pushPost: (state, { payload }) => {
            state.posts = [ payload, ...state.posts ]

            if (payload.type == postTypeEnum.BLOOD_REQUIREMENT) {
                state.postStatuses[payload._id] = {}
            }

            else {
                state.postStatuses[payload._id] = { likes: 0, comments: 0, interests: 0, participants: 0 }
            }
        },

        updatePost: (state, { payload }) => {
            const updated = payload.post 
            const index = state.posts.findIndex(p => p._id == updated._id)
            
            state.posts[index] = updated
        }
    },

    extraReducers: {
        [fetchPost.pending]: (state, { meta }) => {
            state.loading = true 
        },
        
        [fetchPost.fulfilled]: (state, { payload }) => {
            state.loading = false 
            const post = payload 
            state.posts = [ post ]

            state.postStatuses[post._id] = {}

            state.postStatuses[post._id].likes = post.likes
            state.postStatuses[post._id].comments = post.comments
            state.postStatuses[post._id].interests = post.interests
            state.postStatuses[post._id].participants = post.participants

            state.postStatuses[post._id].like = post.userLiked
            state.postStatuses[post._id].interest = post.userInterested
            state.postStatuses[post._id].participate = post.userParticipated
        },

        [fetchPost.rejected]: (state, { error }) => {
            state.loading = false 
            state.error = error.message
        },

        [fetchPosts.pending]: (state, { meta }) => {
            state.loading = true 
        },
        
        [fetchPosts.fulfilled]: (state, { payload, meta}) => {
            if (meta.arg.clear) {
                state.posts = []
                state.postStatuses = {}
                state.end = false
            }

            state.loading = false 
            state.posts = [ ...state.posts, ...payload ]
            state.cleared = false

            state.end = payload.length < process.env.REACT_APP_POSTS_FETCH_LIMIT

            payload.forEach(post => {
                state.postStatuses[post._id] = {}
                
                state.postStatuses[post._id].likes = post.likes
                state.postStatuses[post._id].comments = post.comments
                state.postStatuses[post._id].interests = post.interests
                state.postStatuses[post._id].participants = post.participants
    
                state.postStatuses[post._id].like = post.userLiked
                state.postStatuses[post._id].interest = post.userInterested
                state.postStatuses[post._id].participate = post.userParticipated
            })
        },

        [fetchPosts.rejected]: (state, { error }) => {
            state.loading = false 
            state.error = error.message
        },

        [likePost.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.inAction = true
        },
        
        [likePost.fulfilled]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.like = true
            postStatus.likes++
            postStatus.inAction = false
        },

        [likePost.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]
            
            postStatus.like = false
            postStatus.inAction = false
        },

        [unlikePost.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]
            
            postStatus.inAction = true
        },
        
        [unlikePost.fulfilled]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.like = false
            postStatus.likes--
            postStatus.inAction = false
        },

        [unlikePost.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.inAction = false
        },

        [deletePost.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.deleting = true
        },
        
        [deletePost.fulfilled]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            state.posts = state.posts.filter(x => x._id != postId)
            delete state.postStatuses[postId]
            postStatus.deleting = false
        },

        [deletePost.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.deleting = false
        },

        [editGeneralPost.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.editing = true
        },
        
        [editGeneralPost.fulfilled]: (state, { payload, meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]
            const index = state.posts.findIndex(x => x._id == postId)

            state.posts[index] = payload
            postStatus.editing = false
        },

        [editGeneralPost.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.editing = false
        },

        [addInterest.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.inAction = true
        },
        
        [addInterest.fulfilled]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.interest = true
            postStatus.interests++
            postStatus.inAction = false
        },

        [addInterest.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]
            
            postStatus.interest = false
            postStatus.inAction = false
        },

        [removeInterest.pending]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]
            
            postStatus.inAction = true
        },
        
        [removeInterest.fulfilled]: (state, { meta }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.interest = false
            postStatus.interests--
            postStatus.inAction = false
        },

        [removeInterest.rejected]: (state, { meta, error }) => {
            const postId = meta.arg.id
            const postStatus = state.postStatuses[postId]

            postStatus.inAction = false
        }
    }
})

export const { pushPost, incComments, clearPosts, updatePost } = postsSlice.actions

export { fetchPost, fetchPosts, likePost, unlikePost, deletePost, editGeneralPost, addInterest, removeInterest }

export default postsSlice.reducer