import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { BiTimeFive } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import Container from "../components/Elements/Container";

import HoursVolunteering from "../components/HoursVolunteering/HoursVolunteering";
import { UserAnalyticsGraph } from "../components/Graphs/UserAnalyticsGraph";
import CausesGraph from "../components/Graphs/CausesGraph";
import OutreachGraph from "../components/Graphs/OutreachGraph";
import LocationGraph from "../components/Graphs/LocationGraph";
import Loading from "../components/Loading/Loading";
import Modal from "../components/Modal/Modal";
import VolunteerModal from "../components/Modal/VolunteerModal";
import placeholderAvatar from "../dummyData/images/placeholder-avatar.jpg";
import axios from "axios";
import get_current_user from "../../../aws_cognito/get_user";

const Analytics = () => {
  const [setPageName] = useOutletContext();
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
  const isXl = useMediaQuery({ query: `(max-width: 1280px)` });

  const [loading, setLoading] = useState(true);
  const [graphTab, setGraphTab] = useState(1);
  const [showHoursVolunteering, setShowHoursVolunteering] = useState(false);

  const [showType, setShowType] = useState("all");
  const [showTime, setShowTime] = useState("last-month");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [hourData, setHourData] = useState(null);

  const [volunteerUnverified, setVolunteerUnverified] = useState([]);
  const [organizationUnverified, setOrganizationUnverified] = useState([]);
  const [corporateUnverified, setCorporateUnverified] = useState([]);

  const applyType = useCallback(
    (type) => {
      if (type === "volunteers") {
        return { volunteer: data.volunteer };
      }
      if (type === "corporates") {
        return { corporate: data.corporate };
      }
      if (type === "ngos") {
        return { impactOrg: data.impactOrg };
      }
      return data;
    },
    [data]
  );

  const applyTime = useCallback((typeFilteredData, time) => {
    if (time === "last-3-days") {
      return {
        volunteer: typeFilteredData?.volunteer?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 3;
        }),
        corporate: typeFilteredData?.corporate?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 3;
        }),
        impactOrg: typeFilteredData?.impactOrg?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 3;
        }),
      };
    }
    if (time === "last-week") {
      return {
        volunteer: typeFilteredData?.volunteer?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 7;
        }),
        corporate: typeFilteredData?.corporate?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 7;
        }),
        impactOrg: typeFilteredData?.impactOrg?.filter((item) => {
          const date = new Date(item.date);
          return date.getDate() >= new Date().getDate() - 7;
        }),
      };
    }
    if (time === "last-month") {
      return {
        volunteer: typeFilteredData?.volunteer?.filter((item) => {
          const date = new Date(item.date);
          return date.getMonth() >= new Date().getMonth() - 1;
        }),
        corporate: typeFilteredData?.corporate?.filter((item) => {
          const date = new Date(item.date);
          return date.getMonth() >= new Date().getMonth() - 1;
        }),
        impactOrg: typeFilteredData?.impactOrg?.filter((item) => {
          const date = new Date(item.date);
          return date.getMonth() >= new Date().getMonth() - 1;
        }),
      };
    }
    if (time === "last-year") {
      return {
        volunteer: typeFilteredData?.volunteer?.filter((item) => {
          const date = new Date(item.date);
          return date.getFullYear() >= new Date().getFullYear() - 1;
        }),
        corporate: typeFilteredData?.corporate?.filter((item) => {
          const date = new Date(item.date);
          return date.getFullYear() >= new Date().getFullYear() - 1;
        }),
        impactOrg: typeFilteredData?.impactOrg?.filter((item) => {
          const date = new Date(item.date);
          return date.getFullYear() >= new Date().getFullYear() - 1;
        }),
      };
    }
    return typeFilteredData;
  }, []);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const user = await get_current_user("admin");
      const token = user.data.idToken;

      const {
        data: {
          data: { analysis, signUps, summary },
        },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            contentType: "application/json",
          },
        }
      );

      setData(analysis);

      setHourData(summary);

      setVolunteerUnverified(signUps.volunteers);
      setOrganizationUnverified(signUps.impactOrgs);
      setCorporateUnverified(signUps.corporates);

      setLoading(false);
    };
    fetchAndSetData();
  }, []);

  useEffect(() => {
    const dataTypeFiltered = applyType(showType);
    const dataTimeAndTypeFiltered = applyTime(dataTypeFiltered, showTime);
    setFilteredData(dataTimeAndTypeFiltered);
  }, [applyTime, applyType, showTime, showType]);

  useEffect(() => {
    setPageName("Dashboard");
  }, [setPageName]);

  if (loading) {
    return (
      <div className="grid w-full h-screen place-items-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
          setModalContent("");
        }}
      >
        {modalContent === "volunteers" ? (
          <VolunteerModal
            onBack={() => setModalIsOpen(false)}
            type="Volunteers"
            color="#E7C038"
          />
        ) : modalContent === "companies" ? (
          <VolunteerModal
            onBack={() => setModalIsOpen(false)}
            type="Companies"
            color="#66E4DC"
          />
        ) : modalContent === "ngos" ? (
          <VolunteerModal
            onBack={() => setModalIsOpen(false)}
            type="Impact Organizations"
            color="#46B966"
          />
        ) : (
          <></>
        )}
      </Modal>

      <div
        className={`flex flex-col gap-4 p-4 w-[100%] xl:flex-row-reverse max-h-screen ${
          showHoursVolunteering && isXl ? "overflow-hidden" : "overflow-scroll"
        }`}
      >
        {/* Volunteering Time Container */}
        {showHoursVolunteering ? (
          <Container className="xl:w-[35%] bg-[#F4F8FB] py-0 px-0">
            <HoursVolunteering
              data={hourData}
              modalOpen={setShowHoursVolunteering}
            />
          </Container>
        ) : (
          <Container className="xl:w-[35%]">
            <div className="flex items-center justify-between w-[100%]">
              <div className="flex items-center gap-4">
                <div className="img grid place-items-center bg-[#CAB7FF] w-12 h-12 rounded">
                  <BiTimeFive color="white" size={"80%"} />
                </div>
                <div className="text-xl">
                  <p>
                    <strong>{hourData.totalHours}</strong>
                  </p>
                  <p>
                    <small>hours of volunteering</small>
                  </p>
                </div>
              </div>

              {/* Right Arrow / Details */}
              <button
                onClick={() => setShowHoursVolunteering(true)}
                className="text-2xl"
              >
                <BsChevronRight color="#22A5FF" />
              </button>
            </div>
          </Container>
        )}

        {/* Graph Container */}
        {
          <Container className="pb-12 xl:flex-1">
            {/* Menu Tab */}
            <div className="flex items-center justify-between px-8 text-lg lg:py-5">
              <button
                onClick={() => {
                  setGraphTab(1);
                }}
                className={`pb-1 ${
                  graphTab === 1
                    ? "font-bold border-b-[6px] border-b-[#22A5FF]"
                    : ""
                }`}
              >
                User Analytics
              </button>
              {/* 
              <button
                onClick={() => setGraphTab(2)}
                className={`pb-1 ${
                  graphTab === 2
                    ? 'font-bold border-b-[6px] border-b-[#22A5FF]'
                    : ''
                }`}
              >
                Causes
              </button>
              <button
                onClick={() => setGraphTab(3)}
                className={`pb-1 ${
                  graphTab === 3
                    ? 'font-bold border-b-[6px] border-b-[#22A5FF]'
                    : ''
                }`}
              >
                Outreach
              </button>
              <button
                onClick={() => setGraphTab(4)}
                className={`pb-1 ${
                  graphTab === 4
                    ? 'font-bold border-b-[6px] border-b-[#22A5FF]'
                    : ''
                }`}
              >
                Location
              </button> 
              */}
            </div>

            {/* Select Options for Graph */}
            <div className="flex items-center justify-end gap-4 px-8 my-8 mb-6 md:my-2 md:gap-16">
              <select
                name="show-type"
                id="show-type"
                className="w-32 outline-none cursor-pointer"
                value={showType}
                onChange={(e) => setShowType(e.target.value)}
              >
                <option value="all">Show All</option>
                <option value="volunteers">Show Volunteers</option>
                <option value="corporates">Show Corporates</option>
                <option value="ngos">Show NGOs</option>
              </select>
              <select
                name="show-time"
                id="show-time"
                className="outline-none cursor-pointer"
                value={showTime}
                onChange={(e) => setShowTime(e.target.value)}
              >
                <option value="last-3-days">Last 3 Days</option>
                <option value="last-week">Last Week</option>
                <option value="last-month">Last Month</option>
                <option value="last-year">Last Year</option>
                {/* <option value="custom">Custom</option> */}
              </select>
            </div>

            {/* Graph */}
            <div className="md:h-[400px] h-[350px] w-full">
              {graphTab === 1 ? (
                <UserAnalyticsGraph
                  data={filteredData}
                  type={showType}
                  time={showTime}
                />
              ) : graphTab === 2 ? (
                <CausesGraph />
              ) : graphTab === 3 ? (
                <OutreachGraph />
              ) : (
                <LocationGraph />
              )}
            </div>

            {/* Graph end */}

            {/* Cards */}
            <div className="mt-12">
              {isMobile ? (
                <div className="flex items-center justify-between mt-8 font-bold">
                  <div className="flex flex-col justify-between items-center gap-12 p-6 w-[27vw] h-[13rem] rounded-lg shadow-sm border-[1px] border-gray-100">
                    <div className="flex flex-col items-center">
                      <p className="text-[#E7C038] text-3xl">
                        {volunteerUnverified.totalCount}
                      </p>
                      <p className="text-[#919191]">Volunteers</p>
                    </div>
                    <div>
                      <button
                        className="text-blue"
                        onClick={() => {
                          setModalContent("volunteers");
                          setModalIsOpen(true);
                        }}
                      >
                        Show All
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between items-center gap-12 p-6 w-[27vw] h-[13rem] rounded-lg shadow-sm border-[1px] border-gray-100">
                    <div className="flex flex-col items-center">
                      <p className="text-[#66E4DC] text-3xl">
                        {corporateUnverified.totalCount}
                      </p>
                      <p className="text-[#919191]">Companies</p>
                    </div>
                    <div>
                      <button
                        className="text-blue"
                        onClick={() => {
                          setModalContent("companies");
                          setModalIsOpen(true);
                        }}
                      >
                        Show All
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between items-center gap-12 p-6 w-[27vw] h-[13rem] rounded-lg shadow-sm border-[1px] border-gray-100">
                    <div className="flex flex-col items-center">
                      <p className="text-[#46B966] text-3xl">
                        {organizationUnverified.totalCount}
                      </p>
                      <p className="text-[#919191] text-center">
                        Impact <br /> Organizations
                      </p>
                    </div>
                    <div>
                      <button
                        className="text-blue"
                        onClick={() => {
                          setModalContent("ngos");
                          setModalIsOpen(true);
                        }}
                      >
                        Show All
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between gap-6 mt-5 font-bold">
                  <div className="flex-1 p-5 rounded-lg border-[1px] border-gray-100 shadow-md h-[24rem] card overflow-hidden">
                    {/* Top Div Data */}
                    <div className="flex items-end justify-between">
                      {/* Left num */}
                      <div className="space-y-8">
                        <p className="text-3xl text-[#E7C038]">
                          {volunteerUnverified.totalCount}
                        </p>
                        <p className="text-[#919191]">Volunteers</p>
                      </div>
                      {/* Right Btn */}
                      <div className="min-w-[4.3rem]">
                        <button
                          className="text-blue"
                          onClick={() => {
                            setModalContent("volunteers");
                            setModalIsOpen(true);
                          }}
                        >
                          Show All
                        </button>
                      </div>
                    </div>

                    <hr className="mt-5 mb-8 border-[#B9E2FF]" />

                    {/* Lower Div Recents */}
                    <div className="flex flex-col gap-4">
                      <p>Recent Signups</p>
                      {/* Users Details */}
                      {volunteerUnverified.recent.map((volunteer) => {
                        return (
                          <div
                            key={volunteer._id}
                            className="flex items-center gap-6"
                          >
                            <img
                              className="w-10 border-[1px] border-gray-200 h-10 overflow-hidden rounded-full"
                              src={volunteer.profilePic || placeholderAvatar}
                              alt="placeholder"
                            />
                            <p className="font-medium">{volunteer.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex-1 p-5 rounded-lg border-[1px] border-gray-100 shadow-md h-[24rem] card">
                    {/* Top Div Data */}
                    <div className="flex items-end justify-between">
                      {/* Left num */}
                      <div className="space-y-8">
                        <p className="text-3xl text-[#66E4DC]">
                          {corporateUnverified.totalCount}
                        </p>
                        <p className="text-[#919191]">Companies</p>
                      </div>
                      {/* Right Btn */}
                      <div className="min-w-[4.3rem]">
                        <button
                          className="text-blue"
                          onClick={() => {
                            setModalContent("companies");
                            setModalIsOpen(true);
                          }}
                        >
                          Show All
                        </button>
                      </div>
                    </div>

                    <hr className="mt-5 mb-8 border-[#B9E2FF]" />

                    {/* Lower Div Recents */}
                    <div className="flex flex-col gap-4">
                      <p>Recent Signups</p>
                      {/* Users Details */}
                      {corporateUnverified.recent.map((corporate, idx) => {
                        return (
                          <div
                            key={corporate._id}
                            className="flex items-center gap-6"
                          >
                            <img
                              className="w-10 border-[1px] border-gray-200 h-10 overflow-hidden rounded-full"
                              src={corporate.profilePic || placeholderAvatar}
                              alt="placeholder"
                            />
                            <p className="font-medium">{corporate.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex-1 p-5 rounded-lg border-[1px] border-gray-100 shadow-md h-[24rem] card">
                    {/* Top Div Data */}
                    <div className="flex items-end justify-between">
                      {/* Left num */}
                      <div className="space-y-2">
                        <p className="text-3xl text-[#46B966]">
                          {organizationUnverified.totalCount}
                        </p>
                        <p className="text-[#919191]">Impact Organizations</p>
                      </div>
                      {/* Right Btn */}
                      <div className="min-w-[4.3rem]">
                        <button
                          className="text-blue"
                          onClick={() => {
                            setModalContent("ngos");
                            setModalIsOpen(true);
                          }}
                        >
                          Show All
                        </button>
                      </div>
                    </div>

                    <hr className="mt-5 mb-8 border-[#B9E2FF]" />

                    {/* Lower Div Recents */}
                    <div className="flex flex-col gap-4">
                      <p>Recent Signups</p>
                      {/* Users Details */}
                      {organizationUnverified.recent.map((org, idx) => {
                        return (
                          <div
                            key={org._id}
                            className="flex items-center gap-6"
                          >
                            <img
                              className="w-10 border-[1px] border-gray-200 h-10 overflow-hidden rounded-full"
                              src={org.profilePic || placeholderAvatar}
                              alt="placeholder"
                            />
                            <p className="font-medium">{org.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
        }
      </div>
    </>
  );
};

export default Analytics;
