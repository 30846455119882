import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import badgeBlue from "../../images/badge-blue.png";
import badgeGrey from "../../images/badge-grey.png";
import badgeGolden from "../../images/golden_badge.svg";
import { AiOutlineClose } from "react-icons/ai";

export default function Badges({ badges = [] }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        <div
          className="px-8 py-6 bg-white w-full max-w-[30rem] rounded-lg mx-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between">
            <strong className="text-base md:text-lg">Badges</strong>
            <button onClick={() => setModalIsOpen(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className="flex flex-col gap-6 mt-6 max-h-[80vh] overflow-y-scroll scrollable">
            {badges?.map((badge) => (
              <>
                <div className="flex gap-2 md:gap-5">
                  <img
                    src={
                      badge.type === "level"
                        ? badgeGrey
                        : badge.type === "privilege"
                        ? badgeBlue
                        : badgeGolden
                    }
                    alt=""
                    className="w-8 md:w-12"
                  />
                  <div className="flex flex-col w-full md:space-y-2">
                    <p className="font-bold md:font-medium md:text-base text-[13px] w-full">
                      {badge.title}
                    </p>
                    <p className="text-[9px] md:text-sm font-light">
                      {badge.description}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </Modal>
      <div className="w-full py-3 pt-3 bg-white shadow-md md:mt-5 rounded-2xl md:px-5 h-full md:h-auto">
        <div className="flex-col space-y-3">
          <div className="flex justify-center text-base md:justify-between">
            <h1 className="font-bold text-center text-lightGray md:text-black">
              Badges
            </h1>
            <button
              onClick={() => setModalIsOpen(true)}
              className="hidden md:flex text-[#919191] font-semibold"
            >
              View All
            </button>
          </div>
          <div className="flex flex-col justify-center w-full px-3 space-y-3 md:px-0">
            {badges?.slice(0, 3).map((badge) => (
              <>
                <div className="flex gap-2 md:gap-5">
                  <img
                    src={
                      badge.type === "level"
                        ? badgeGrey
                        : badge.type === "privilege"
                        ? badgeBlue
                        : badgeGolden
                    }
                    alt=""
                    className="w-8 h-8 md:w-max md:h-max"
                  />
                  <div className="flex flex-col w-full md:space-y-2">
                    <p className="font-bold md:font-medium md:text-base text-[13px] w-full">
                      {badge.title}
                    </p>
                    <p className="text-[9px] md:text-sm font-light">
                      {badge.description}
                    </p>
                  </div>
                </div>
              </>
            ))}
            <button
              onClick={() => setModalIsOpen(true)}
              className="block text-sm font-semibold text-center md:hidden text-blue translate-y-2"
            >
              View All
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
