import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import logo from "../../../src/images/Logo.png";
import { forgotPassword } from "../../aws_cognito/forgot_password";
import { data } from "autoprefixer";

export default function ForgotComponent({ type, user }) {
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState(false);
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [sixth, setSixth] = useState();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const num1 = useRef();

  const num2 = useRef();

  const num3 = useRef();

  const num4 = useRef();

  const num5 = useRef();

  const num6 = useRef();

  const handleOtp1Change = (value) => {
    setFirst(value);
    // num2.current.focus();
  };

  const handleOtp2Change = (value) => {
    setSecond(value);
    // num3.current.focus();
  };

  const handleOtp3Change = (value) => {
    setThird(value);
    // num4.current.focus();
  };

  const handleOtp4Change = (value) => {
    setFourth(value);
    //only update the value here
  };

  const handleOtp5Change = (value) => {
    setFifth(value);
  };

  const handleOtp6Change = (value) => {
    setSixth(value);
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      console.log(elmnt.target.tabIndex);
      const next = elmnt.target.tabIndex;
      if (next === 6) {
        num5.current.focus();
      } else if (next === 5) {
        num4.current.focus();
      } else if (next === 4) {
        num3.current.focus();
      } else if (next === 3) {
        num2.current.focus();
      } else if (next === 2) {
        num1.current.focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next === 1) {
        num2.current.focus();
      } else if (next === 2) {
        num3.current.focus();
      } else if (next === 3) {
        num4.current.focus();
      } else if (next === 4) {
        num5.current.focus();
      } else if (next === 5) {
        num6.current.focus();
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data) {
      if (!verifyCode) {
        await forgotPassword(data.email, type);
        setEmail(data.email);
        setVerifyCode(true);
      } else {
        let otp_code = first + second + third + fourth + fifth + sixth;
        console.log(data.email, otp);
        navigate(`/${type}/reset`, { state: { email: data.email, code: otp_code } });
      }
    }
  };

  // console.log('something went wron',errors);
  return (
    <>
      <div className="w-full md:w-[47%] flex flex-col z-10 md:h-screen bg-white p-8 overflow-y-scroll">
        <div className="hidden md:flex flex-row justify-center mt-4">
          <img src={logo} alt="logo" className="w-36" />
        </div>

        {verifyCode === true ? (
          <>
            <h1 className="text-center font-bold text-lg md:text-3xl md:mt-10">
              Check your mail<span className="text-blue">.</span>
            </h1>
            <p className="text-center text-xs font-medium text-black leading-5 mt-3">
              We’ve sent a code to {email}
            </p>
            <h4 className="flex justify-center mt-20 text-black">Enter Code</h4>

            <div className="flex flex-row justify-center mt-6">
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition"
                maxLength={1}
                required
                ref={num1}
                tabIndex="1"
                onChange={(e) => handleOtp1Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num2}
                tabIndex="2"
                onChange={(e) => handleOtp2Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num3}
                tabIndex="3"
                onChange={(e) => handleOtp3Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num4}
                tabIndex="4"
                onChange={(e) => handleOtp4Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num5}
                tabIndex="5"
                onChange={(e) => handleOtp5Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num6}
                tabIndex="6"
                onChange={(e) => handleOtp6Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
            </div>
            <div className="flex justify-center mt-8">
              <button
                onClick={handleSubmit(onSubmit)}
                className="w-20 md:w-24 h-8 md:h-10 bg-blue  text-white font-semibold text-sm rounded-md"
              >
                Verify
              </button>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-center font-bold text-lg md:text-3xl md:mt-10">
              Forgot Password<span className="text-blue">?</span>
            </h1>
            <p className="text-center text-xs font-medium text-black w-4/5 mx-auto leading-5 mt-5 md:w-full">
              That’s alright! We have made it easier for you to get back in.
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col justify-center mt-20 space-y-8 w-full"
            >
              <div className="relative z-0 w-4/5 md:w-3/4 flex mx-auto">
                <input
                  type="email"
                  id="email"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("email", {})}
                />
                <label
                  htmlFor="email"
                  className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  Enter Email
                </label>
              </div>

              <div className="flex justify-center flex-col items-center ">
                <input
                  type="submit"
                  value="Send Verification Code"
                  className="bg-blue hover:bg-blue-500 text-white font-bold md:py-3 py-2 px-5 text-xs md:text-base md:px-8 rounded-lg cursor-pointer mt-10"
                />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}
