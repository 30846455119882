import React, { useEffect } from "react";
import avatar from "../../pages/Admin/dummyData/images/placeholder-avatar.jpg";
import { useNavigate } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";
export default function ViewAllConnections({ showAllConnectionsModal, setShowAllConnectionsModal,connectionsList,type ,me}) {
    const navigate = useNavigate();
    const handleRevokeConnection = async (reqedUserId) => {
        const user = await get_current_user(type);
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${reqedUserId}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
    }
    return ( 
        <>
        {showAllConnectionsModal ? (
            <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-5 md:p-0">
                <div className="relative w-[90vw] sm:w-[80vw] my-6 mx-auto max-w-lg">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-center justify-between p-3  rounded-t ">
                    <h1 className="font-bold md:text-lg p-4">
                        All Connections
                    </h1>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-xl leading-none font-medium "
                        onClick={() => setShowAllConnectionsModal(false)}
                    >
                        <span className="bg-transparent text-black h-8 w-12 text-lg block ">
                        x
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    { Object.entries(connectionsList).reduce((x, y) => x + y[1].length, 0) == 0 &&
                        <span className='text-center'>No connections</span>
                    }
                    <div  className="max-h-[40vh] overflow-auto relative pb-4  md:py-4 px-6 flex-auto space-y-4">
                        {Object.entries(connectionsList).map((typeArr) => {
                            return (typeArr[1].map((connectionItem) => {
                                return (
                                    <div key={connectionItem._id} className="flex flex-row items-center justify-between  min-w-[7.5rem] gap-4">
                                        <div className="flex flex-row items-center justify-center gap-3 ">
                                            <img
                                                src={connectionItem.profilePic==""?avatar:connectionItem.profilePic}
                                                alt="pfp"
                                                className="w-14 h-14 rounded-full"
                                            />
                                            <p className="font-semibold text-[0.8rem] md:text-[0.9rem]">
                                                {connectionItem.name}
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-center sm:flex-row sm:justify-center"
                                        >
                                            <button className="text-[0.8rem] md:text-[0.9rem]" style={{ color: '#30AAFF', padding: '5px 15px', borderRadius: '5px', border: '1px solid #30AAFF',  fontWeight: '600' ,fontSize:'0.8rem'}} onClick={() =>
                                                navigate(`/${type}/view-${typeArr[0]}/${connectionItem._id}`)
                                            } > 
                                                View Profile
                                            </button>
                                            {me===true && <p className="mt-2 sm:mt-0 ml-2 text-center font-semibold text-[#30AAFF] cursor-pointer text-[0.8rem] md:text-[0.9rem]" onClick={() => {
                                                handleRevokeConnection(connectionItem._id)
                                            }} >
                                                Remove
                                            </p>}
                                                
                                        </div>
                                    </div>
                                )
                            }))
                        })}
                                    
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </>
    );
}
