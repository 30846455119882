import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import VerifyComponent from "../../../components/Verify/Verify";
import volunteer from "../../../images/volunteer.png";
import logo from "../../../images/Logo.png";

export default function Verify() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <>
      {isMobile ? (
        <div className="h-screen flex flex-col items-center">
          <img src={volunteer} alt="volunteer" className="w-full h-[250px]" />
          <div className="flex flex-row justify-center items-center -mt-14 bg-white w-[9rem] h-[15rem] rounded-full">
            <img src={logo} alt="logo" className=" " />
          </div>
          <VerifyComponent type={"volunteer"} className="flex justify-center" />
        </div>
      ) : (
        <div className="h-screen flex justify-center bg-img">
          <VerifyComponent type={"volunteer"} />
        </div>
      )}
    </>
  );
}
