import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import defaultAvatar from '../../images/default.jpg'
import { formatDate } from '../../utils/utils'
import { Link } from 'react-router-dom'
import * as postsApi from '../../api/posts'
import get_current_user from '../../aws_cognito/get_user'
import { ClipLoader } from 'react-spinners'

export default function Reply({ data, onReplyClick, removeReply }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const type = userProfile?.data?.type
    const [showPostMenu, setShowPostMenu] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const deletingRef = useRef(false)
    const menuRef = useRef()

    const onDeleteClick = async (e) => {
        if (deletingRef.current) {
            return 
        }

        deletingRef.current = true
        setDeleting(true)

        const user = await get_current_user(type)
        await postsApi.deletePostComment(data._id, user.data.idToken)
        removeReply(data._id)

        deletingRef.current = false
        setDeleting(false)
    }

    useEffect(() => {
        const closeMenu = (e) => {
            if (deletingRef.current) {
                return
            }

            if (menuRef.current && menuRef.current.children.length > 1 && !menuRef.current.contains(e.target)) {
                const toggle = menuRef.current.querySelector('.post-menu-toggle') 

                if (toggle) {
                    toggle.classList.remove('active')
                    setShowDeleteConfirmation(false)
                    setShowPostMenu(false)
                }
            }
        }
        
        document.addEventListener('click', closeMenu)

        return () => {
            document.removeEventListener('click', closeMenu)
        }
    }, [])

    return (
        <div>
            <div ref={menuRef} className='relative'>
                { (type == 'admin' || (type == data.authorType && userProfile?.data?._id == data.author._id)) &&
                    <div className='post-menu-toggle absolute h-8 w-8 pr-1 right-[-1rem] top-[-2px] flex justify-center items-center cursor-pointer' onClick={(e) => {
                        e.target.classList.toggle('active')
                        setShowPostMenu(!showPostMenu)
                        setShowDeleteConfirmation(false)
                    }}>
                        <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                        <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                        <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                    </div>
                }

                {  showPostMenu && 
                    <div className='post-menu shadow-md bg-white absolute top-8 right-[-0.2rem] w-[120px] rounded overflow-hidden'>
                        <div className='post-delete max-h-[30px] overflow-hidden'>
                            <div className={`post-delete-button ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''} cursor-pointer duration-300 flex items-center  hover:bg-[#ff3b3b] h-[30px] w-full pl-4`} onClick={(e) => setShowDeleteConfirmation(true)}>
                                <svg className='h-[0.8rem] w-[0.8rem] fill-[#f55858] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"/>
                                </svg>

                                <span className='text-[0.7rem] ml-2 font-bold text-[#f55858]'>DELETE</span>
                            </div>

                            <div className={`post-delete-confirmation flex items-center justify-center h-[30px] bg-[#ff3b3b] duration-300 ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''}`}>
                                <svg onClick={() => setShowDeleteConfirmation(false)} className='translate-y-[-1px] h-[0.8rem] w-[0.8rem] hover:rotate-90 duration-300 cursor-pointer fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"/>
                                </svg>

                                <span className='text-[0.7rem] mx-2 font-bold text-white cursor-default translate-y-[-1px]'>You sure?</span>

                                { deleting &&
                                    <ClipLoader size={15} color='white' />
                                }

                                { !deleting &&
                                    <svg className='h-[0.9rem] w-[0.9rem] fill-white cursor-pointer hover:translate-y-[-2px] duration-300' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={onDeleteClick}>
                                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                                    </svg>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='flex mx-2 my-2'>
                <img className='rounded-full w-10 h-10' src={(!data.author.profilePic || data.author.profilePic == '') ? defaultAvatar : data.author.profilePic} />

                <div className='flex flex-col ml-2'>
                    <span className='inline-flex items-center'>
                        <Link to={(userProfile.data?.type == data.authorType && userProfile.data?.db_id == data.author._id) ? `/${type}/profile` : `/${type}/view-${data.authorType}/${data.author._id}`}>
                            <span className='font-semibold text-[0.75rem]'>{data.author.name}</span>
                        </Link>
                        <span className='inline-block translate-y-[1px] text-[0.7rem] ml-1 text-[#777]'>{formatDate(data.createdAt)}</span>
                    </span>

                    <p className='scrollable text-[0.8rem] w-full outline-none rounded-[20px]'>
                        {data.content}
                    </p>
                    
                    <div className='flex'>
                        <span className='text-[0.8rem] mt-[2px] mr-5 cursor-pointer text-slate-700' onClick={onReplyClick}>reply</span>
                    </div>
                </div>
            </div>
        </div>
    )
}