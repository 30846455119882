import axios from "axios";
import { useEffect, useState } from "react";
import get_current_user from "../../aws_cognito/get_user";
import pfp from "../../images/pfp.png";
import CreditAccept from "../CreditAccept/CreditAccept";
import Modal from "../Modal/Modal";
import avatar from "../../pages/Admin/dummyData/images/placeholder-avatar.jpg";

export default function CreditReq({ requests, setRequests }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        <div
          className="sm:rounded-lg bg-[#F4F8FB] p-6 shadow-xl overflow-scroll w-full md:max-w-[65rem]"
          onClick={(e) => e.stopPropagation()}
        >
          {modalIsOpen && (
            <CreditAccept
              request={selectedRequest}
              setModalIsOpen={setModalIsOpen}
            />
          )}
        </div>
      </Modal>
      <div className="w-full px-3 py-3 bg-white shadow-md md:mt-5 rounded-2xl md:px-5 h-max">
        <div className="flex-col w-full space-y-5">
          <div className="flex justify-between w-full">
            <h1 className="font-bold text-center text-lightGray md:text-black">
              Credits requests
            </h1>
            {/* TODO */}
            {/* <button className="flex text-[#919191] font-semibold">
              View All
            </button> */}
          </div>
          <div className="flex gap-5 overflow-auto md:flex-col scrollable pb-1">
            {requests.map((request) => (
              <div className="flex flex-col items-center justify-between md:flex-row min-w-[7.5rem] gap-4">
                <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
                  <img
                    src={request?.volunteerProfilePic || avatar}
                    alt="pfp"
                    className="w-16 h-16 rounded-full"
                  />
                  <p className="font-semibold">
                    {request?.volunteerName || "No Name"}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setSelectedRequest(request);
                    setModalIsOpen(true);
                  }}
                >
                  <p className="text-center font-semibold text-sm text-[#30AAFF]">
                    View request
                  </p>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
