import { React, useState, useRef } from "react";
import logo from "../../../src/images/Logo.png";
import verify from "../../images/Vector.png";
// import OtpInput from "react-otp-input";

export default function ThankYou() {
  const [verifyState, setVerifyState] = useState(false);
  const [otp, setOtp] = useState("");
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [sixth, setSixth] = useState();

  const handleOTP = (otp) => {
    setOtp(
      first * 100000 +
        second * 10000 +
        third * 1000 +
        fourth * 100 +
        fifth * 10 +
        sixth
    );
  };

  const num1 = useRef();

  const num2 = useRef();

  const num3 = useRef();

  const num4 = useRef();

  const num5 = useRef();

  const num6 = useRef();

  const handleOtp1Change = (value) => {
    setFirst(value);
    // num2.current.focus();
  };

  const handleOtp2Change = (value) => {
    setSecond(value);
    // num3.current.focus();
  };

  const handleOtp3Change = (value) => {
    setThird(value);
    // num4.current.focus();
  };

  const handleOtp4Change = (value) => {
    setFourth(value);
    //only update the value here
  };

  const handleOtp5Change = (value) => {
    setFifth(value);
  };

  const handleOtp6Change = (value) => {
    setSixth(value);
  };

  const handleOTPChange = () => {
    setOtp(
      first * 100000 +
        second * 10000 +
        third * 1000 +
        fourth * 100 +
        fifth * 10 +
        sixth
    );
    setVerifyState(true);
  };
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      console.log(elmnt.target.tabIndex);
      const next = elmnt.target.tabIndex;
      if (next === 6) {
        num5.current.focus();
      } else if (next === 5) {
        num4.current.focus();
      } else if (next === 4) {
        num3.current.focus();
      } else if (next === 3) {
        num2.current.focus();
      } else if (next === 2) {
        num1.current.focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next === 1) {
        num2.current.focus();
      } else if (next === 2) {
        num3.current.focus();
      } else if (next === 3) {
        num4.current.focus();
      } else if (next === 4) {
        num5.current.focus();
      } else if (next === 5) {
        num6.current.focus();
      }
    }
  };
  return (
    <>
      <div className="w-full md:w-[47%] flex flex-col z-10 md:h-screen bg-white pb-10 md:p-8 overflow-y-scroll -mt-5 md:mt-0">
        <div className="flex flex-col w-full">
          <div className="flex flex-start px-8 pb-4 md:p-4 underline decoration-blue underline-offset-5 mb-10 md:mb-0">
            <a
              href="preferences"
              className="text-blue font-bold underline w-max cursor-pointer z-10"
            >
              {"<"} Back
            </a>
          </div>
          <div className="hidden md:flex justify-center items-center -mt-10 ">
            <img src={logo} alt="" className="w-36" />
          </div>
          <h1 className="text-center font-bold md:text-3xl md:mt-10 text-lg">
            Thank You<span className="text-blue">!</span>
            <p className="text-center text-xs font-medium text-black leading-5 w-1/2 mx-auto mt-5">
              We’ve sent a verification code to your number{" "}
              <strong>+91 9123456789.</strong>
            </p>
            <p className="text-center text-xs font-medium text-black leading-5 mt-1">
              {" "}
              Enter the code to start your journey.
            </p>
          </h1>
          <div className="flex flex-col justify-center py-20">
            {verifyState ? (
              <div className="flex flex-row justify-center">
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={verify}
                  alt="verify"
                />{" "}
                <p className="text-green ml-2"> Verified!</p>
              </div>
            ) : (
              <h4 className="flex justify-center">Enter Code</h4>
            )}
            {/* <div className="flex flex-row justify-center mt-6">
              <OtpInput
                value={otp}
                onChange={handleOTP}
                className="border-2 rounded bg-transparent outline-blue text-center font-semibold text-2xl spin-button-none 
              border-blue  focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4 box"
                numInputs={4}
                isInputNum={true}
                separator={<span></span>}
              />
            </div> */}
            <div className="flex flex-row justify-center mt-6">
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition"
                maxLength={1}
                required
                ref={num1}
                tabIndex="1"
                onChange={(e) => handleOtp1Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num2}
                tabIndex="2"
                onChange={(e) => handleOtp2Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num3}
                tabIndex="3"
                onChange={(e) => handleOtp3Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num4}
                tabIndex="4"
                onChange={(e) => handleOtp4Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num5}
                tabIndex="5"
                onChange={(e) => handleOtp5Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                type="text"
                className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
                maxLength={1}
                required
                ref={num6}
                tabIndex="6"
                onChange={(e) => handleOtp6Change(e.target.value)}
                onKeyUp={(e) => inputfocus(e)}
              />
            </div>
          </div>
          <div className="flex justify-center -mt-8">
            {verifyState ? (
              <>
                <button
                  className=" w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md"
                  onClick={handleOTPChange}
                >
                  Continue
                </button>
              </>
            ) : (
              <button
                className=" w-24 h-10 bg-blue  text-white font-semibold text-sm rounded-md"
                onClick={handleOTPChange}
              >
                Verify
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
