import axios from 'axios'

export const getPost = async (id, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const deletePost = async (id, token) => 
    axios.delete(`${process.env.REACT_APP_BASE_URL}/api/posts/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getPosts = async (params, token, controller) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts`, {
        params,
        signal: controller.signal,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const likePost = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/like`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const unlikePost = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/unlike`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const createGeneralPost = async (data, token) =>
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/createGeneral`, data, {
        headers: {
            type: 'multipart/formdata',
            Authorization: `Bearer ${token}`
        }
    })

export const createBloodRequirementPost = async (data, token) =>
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/createBloodRequirement`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const createVolunteeringOpportunity = async (data, token) =>
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/createVolunteeringOpportunity`, data, {
        headers: {
            type: 'multipart/formdata',
            Authorization: `Bearer ${token}`
        }
    })

export const createCorporateOpportunity = async (data, token) =>
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/createCorporateOpportunity`, data, {
        headers: {
            type: 'multipart/formdata',
            Authorization: `Bearer ${token}`
        }
    })

export const participateVolunteeringOpportunity = async (postId, token) =>
axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/participate`,{} , {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const unParticipateVolunteeringOpportunity = async (postId, token) =>
axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/unparticipate`,{} , {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getAllOpportunities = async (params, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
    }
})
export const getRegisteredOpportunities = async ( params,token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/registeredOpportunities`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getCreatedOpportunities = async (  params,token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/createdOpportunities`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
export const getComments = async (postId, token, params) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/comments`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const createComment = async (comment, postId, parentId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/comments/create`, { content: comment, parentId }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getLikes = async (postId, params, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/likes`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const deletePostComment = async (id, token) => 
    axios.delete(`${process.env.REACT_APP_BASE_URL}/api/posts/comments/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const addInterest = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/addInterest`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const removeInterest = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/removeInterest`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const participate = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/participate`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const unparticipate = async (postId, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/unparticipate`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const editGeneralPost = async (postId, edit, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/editGeneral`, edit, {
        headers: {
            Authorization: `Bearer ${token}`,
            type: 'multipart/formdata'
        }
    })

export const editBloodRequirement = async (postId, edit, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/editBloodRequirement`, edit, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const editVolunteeringOpportunity = async (postId, edit, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/editVolunteeringOpportunity`, edit, {
        headers: {
            Authorization: `Bearer ${token}`,
            type: 'multipart/formdata'
        }
    })

export const editCorporateOpportunity = async (postId, edit, token) => 
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/editCorporateOpportunity`, edit, {
        headers: {
            Authorization: `Bearer ${token}`,
            type: 'multipart/formdata'
        }
    })

export const getInterestedUsers = async (postId, params, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/${postId}/getInterestedUsers`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getcreditrequest = async (id, token) => 
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/credit-request/acceptedrequest/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
})
