import React,{useState,useRef} from 'react'
import defaultAvatar from '../../../images/default.jpg';
import defaultOpportunity from '../../../images/defaultOpportunity.svg'
import {AiOutlineEdit} from "react-icons/ai";
import DetailedOpportunityModal from './DetailedOpportunityModal';
import ParticipantsListModal from './ParticipantsListModal';
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux'
import { fetchOpportunities, clearOpportunities, fetchRegisteredOpportunities } from '../../../redux/slices/opportunitiesSlice' 
import { fetchPosts, clearPosts } from '../../../redux/slices/postsSlice'
import get_current_user from '../../../aws_cognito/get_user'
import { useNavigate } from "react-router-dom";
import * as postsApi from '../../../api/posts'
import EditOpportunityModal from './EditOpportunityModal';
export default function OpportunitiesCard({ type, opportunityData, createdOpportunity, registeredOpportunity }) { 
    const dispatch = useDispatch()
    const oppState = useSelector((state) => state.opportunities);
    const oppActiveTab = oppState.opportunityActiveTab;
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    let controller = useRef(new AbortController())
    const navigate = useNavigate();
    const pathName = window.location.pathname;
    const [detailedOpportunityModalV, setDetailedOpportunityModalV] = useState(false);
    const [editOpportunityModalOpen,setEditOpportunityModalOpen]=useState(false); 
    const [participantsListOpen, setParticipantsListOpen] = useState(false);
    const [participateLoading, setParticipateLoading] = useState(false);
    const participateRef = useRef(false)
    const handleUnParticipateRequest = async (e) => {
        setParticipateLoading(true);
        e.preventDefault();
        if (participateRef.current)
            return

        participateRef.current = true
        try {
            const user = await get_current_user(type)
            const res = await postsApi.unParticipateVolunteeringOpportunity(opportunityData._id, user.data.idToken);
            console.log(res.data);
            setParticipateLoading(false);
                dispatch(clearOpportunities())
                
            if (oppActiveTab === 3) {
                    const params = {
                    limit: 9999
                }
                    dispatch(fetchRegisteredOpportunities({ params,userType: type}))
                }
                if (oppActiveTab === 1) {
                    const paramsAll = {
                        limit: 9999,
                        type:'opportunity'
                    }
                    dispatch(fetchOpportunities({ userType: type, params:paramsAll }))
                }
            
                dispatch(clearPosts())
                const paramsCWall = {
                    limit: process.env.REACT_APP_POSTS_FETCH_LIMIT
                }
                dispatch(fetchPosts({ userType: type, params:paramsCWall, clear: true, controller: controller.current }))
            
        }catch (error) { 
            console.log(error);
        }
        finally {
            participateRef.current = false
        }
    }
    const handleParticipateRequest = async (e) => {
        setParticipateLoading(true);
        e.preventDefault();
        if (participateRef.current)
            return

        participateRef.current = true
        try {
            const user = await get_current_user(type)
            const res = await postsApi.participateVolunteeringOpportunity(opportunityData._id, user.data.idToken);
            console.log(res.data);
            setParticipateLoading(false);
                dispatch(clearOpportunities())
            if (oppActiveTab === 3) {
                    const params = {
                    limit: 9999
                }
                    dispatch(fetchRegisteredOpportunities({ params,userType: type}))
                }
                if (oppActiveTab === 1) {
                    const paramsAll = {
                        limit: 9999,
                        type:'opportunity'
                    }
                    dispatch(fetchOpportunities({ userType: type, params:paramsAll }))
                }
            
                dispatch(clearPosts())
                const paramsCWall = {
                    limit: process.env.REACT_APP_POSTS_FETCH_LIMIT
                }
                dispatch(fetchPosts({ userType: type, params:paramsCWall, clear: true, controller: controller.current }))
            
        }catch (error) { 
            console.log(error);
        }
        finally {
            participateRef.current = false
        }
    }
    return (
        <div style={{ borderRadius: '10px',width:'100%' }} className="mr-3 my-2 px-6 py-9 font-semibold bg-white  shadow-md rounded-md">
            <div className="flex flex-col gap-4">
                <div className='rounded-md p-1'> 
                    <div className="flex flex-col sm:flex-row">
                        <div className="p-1 w-[100%] sm:w-[65%]" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='flex items-center'>
                                <img className='w-12 h-12 rounded-full' src={opportunityData.author.hasOwnProperty('profilePic')?(opportunityData.author.profilePic==""?defaultAvatar:opportunityData.author.profilePic):defaultAvatar} />
                                <div className='flex flex-col ml-3'>
                                    <p className='text-[0.8rem] font-bold'><span className="cursor-pointer"onClick={() => 
                                    {
                                    if (opportunityData.authorType!=="admin") { navigate(`/${type}/view-${opportunityData.authorType}/${opportunityData.author._id}`) }
                                    }
                                    }>{opportunityData.author.name}</span> {(createdOpportunity === true && opportunityData.participants>0) && <span style={{ color: '#22A5FF', fontWeight: '600', cursor: 'pointer' }} onClick={()=>{setParticipantsListOpen(true)}}>{opportunityData.participants} participants</span>}{createdOpportunity && <AiOutlineEdit className="m-1 ml-4 sm:hidden cursor-pointer" style={{ float: 'right' }} onClick={() => { setEditOpportunityModalOpen(true) }} />}</p>
                                    <p className='text-[0.8rem] '>{dayjs(opportunityData.createdAt).format("DD MMM YYYY")}</p>
                                </div>
                            </div>
                                <img className="sm:hidden mt-1 w-60 h-60" style={{ objectFit: 'cover', borderRadius: '5px' }} src={opportunityData.hasOwnProperty('image')?opportunityData.image:defaultOpportunity } />
                            
                            <div style={{fontSize:'0.9rem',fontWeight:'bold',margin:'0.3rem 0'}}>{opportunityData.title}</div>
                            <div style={{ fontSize: '0.9rem', fontWeight: '500', margin: '0.2rem 0' }} >{opportunityData.location} {opportunityData.participants>0 && <span className="text-[#919191]">{opportunityData.participants} participants</span>}
                            </div>
                            <div style={{ width: 'fit-content', padding: '0.3rem 0.5rem', fontSize: '0.9rem', margin: '0.2rem 0', backgroundColor: '#E7BFFF', color: '#C05EFB', borderRadius: '5px' }}>{opportunityData.sector}</div>
                            <div style={{margin: '0.2rem 0'}}>
                                <p style={{fontSize: '0.8rem',color:'#919191'}}>Role</p>
                                <p style={{fontSize: '0.9rem'}}>{opportunityData.role}</p>
                            </div>
                            <div style={{display: 'flex',fontSize: '0.9rem',margin: '0.1rem 0',justifyContent: 'space-between',width:'60%',fontWeight:'500'}}>
                                <div>
                                    <p style={{fontSize: '0.8rem',color:'#919191'}}>Start date</p>
                                    <p>{dayjs(opportunityData.startDate).format("DD MMM YYYY")}</p>
                                </div>
                                <div>
                                    <p style={{fontSize: '0.8rem',color:'#919191'}}>End date</p>
                                    <p>{dayjs(opportunityData.completionDate).format("DD MMM YYYY")}</p>
                                </div>
                            </div>
                            <div style={{margin: '0.2rem 0'}}>
                                <p style={{fontSize: '0.8rem',color:'#919191'}}>Mode</p>
                                <p style={{fontSize: '0.9rem'}}>{opportunityData.mode}</p>
                            </div>
                        </div>
                        <div className="w-[100%] sm:w-[35%]">
                            {createdOpportunity && <AiOutlineEdit className="my-1 hidden sm:block cursor-pointer" style={{ float: 'right' }} onClick={() => { setEditOpportunityModalOpen(true) }} />}
                                <img className="hidden sm:block w-60 h-60" style={{ objectFit: 'cover', borderRadius: '5px' }} src={opportunityData.hasOwnProperty('image')?opportunityData.image:defaultOpportunity } />
                            
                            {(((type != 'admin' && registeredOpportunity===false && createdOpportunity===false && userProfile.data?._id!=opportunityData.author._id ) && (registeredOpportunity===false && createdOpportunity===false && opportunityData.userParticipated===false )) && participateLoading===false)&&
                                <button style={{ width:'100%',backgroundColor: '#22A5FF', color: '#fff', fontWeight: 'bold', fontSize: '0.9rem', padding: '0.3rem 0.5rem', margin: '0.5rem 0',borderRadius:'5px' }} onClick={handleParticipateRequest}>I want to participate</button>
                            }
                            {participateLoading=== true && (
                                <button disabled style={{ width:'100%',backgroundColor: '#22A5FF', color: '#fff', fontWeight: 'bold', fontSize: '0.9rem', padding: '0.3rem 0.5rem', margin: '0.5rem 0',borderRadius:'5px' }}>
                                <svg
                                role="status"
                                class="inline mr-3 w-4 h-4 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                />
                                </svg>
                                Submitting
                            </button>
                            )}
                            {(type != 'admin' && registeredOpportunity === false && createdOpportunity === false && opportunityData.userParticipated === true && participateLoading===false) &&
                                
                                <button style={{ width:'100%',backgroundColor: '#22A5FF', color: '#fff', fontWeight: 'bold', fontSize: '0.9rem', padding: '0.3rem 0.5rem', margin: '0.5rem 0',borderRadius:'5px' }} onClick={handleUnParticipateRequest}>Unparticipate</button>
                            } 
                            {(type != 'admin' && registeredOpportunity===true && participateLoading===false )&&
                                <button style={{ width:'100%',backgroundColor: '#22A5FF', color: '#fff', fontWeight: 'bold', fontSize: '0.9rem', padding: '0.3rem 0.5rem', margin: '0.5rem 0',borderRadius:'5px' }} onClick={handleUnParticipateRequest}>Unparticipate</button>
                            }
                            <p style={{ color: '#22A5FF', fontWeight: 'bold', fontSize: '0.9rem', textAlign: 'center', margin: '0.5rem 0', cursor: "pointer" }} onClick={() => { setDetailedOpportunityModalV(true); }}>View more details</p>
                        </div>
                    </div>
                    {detailedOpportunityModalV && <DetailedOpportunityModal setDetailedOpportunityModalV={setDetailedOpportunityModalV} type={type} opportunityData={opportunityData} setParticipantsListOpen={setParticipantsListOpen} registeredOpportunity={registeredOpportunity} createdOpportunity={createdOpportunity} />}
                    {participantsListOpen && <ParticipantsListModal setParticipantsListOpen={setParticipantsListOpen} opportunityData={opportunityData} type={type} />}
                    {editOpportunityModalOpen && <EditOpportunityModal setEditOpportunityModalOpen={setEditOpportunityModalOpen} opportunityData={opportunityData} typeUser={type} />}
                </div>
            </div>
        </div>
    )
} 

OpportunitiesCard.defaultProps = {
    createdOpportunity: false,
    registeredOpportunity: false
}