import React from "react";
import { useSelector } from "react-redux";

export default function ProfileProgress({ setEditModalOpen }) {
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const data = userProfile.data;

  return (
    <>
      <div className="bg-white border-dashed border-2 border-[#D9D9D9] p-2 md:p-3">
        <div className="flex justify-between text-sm font-bold md:text-base">
          <p className="">Profile Progress</p>
          <p>{data?.profileCompletion}/5 steps completed</p>
        </div>

        <div className="w-full bg-[#E9EBE9] rounded-full h-2.5 my-3">
          <div
            className="bg-[#56C661] h-2.5 rounded-full"
            style={{ width: `${data?.profileCompletion * 20}%` }}
          ></div>
        </div>

        <div className="text-right">
          <button
            onClick={() => setEditModalOpen(true)}
            className="font-bold text-[#625d5d]"
          >
            Complete Profile
          </button>
        </div>
      </div>
    </>
  );
}
