import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import get_current_user from '../../aws_cognito/get_user'
import * as postsApi from '../../api/posts'

const initialState = {
    status: 'none',
    lastUploaded: null
}

const createGeneralPost = createAsyncThunk('posts/createGeneral', async ({ formData, type }) => {
    const user = await get_current_user(type)

    try {
        const post = await postsApi.createGeneralPost(formData, user.data.idToken)
        return post.data
    }

    catch (error) {
        throw error
    }
})

const createPostSlice = createSlice({
    name: 'createPost',
    initialState,
    extraReducers: {
        [createGeneralPost.pending]: (state, { meta }) => {
            state.status = 'uploading'
        },
        
        [createGeneralPost.fulfilled]: (state, { payload }) => {
            state.status = 'uploaded'
            state.lastUploaded = payload
        },

        [createGeneralPost.rejected]: (state, { error }) => {
            state.status = 'failed'
        }
    }
})

export { createGeneralPost }

export default createPostSlice.reducer