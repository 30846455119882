import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  causesOptions,
  locationOptions,
  skillsOptions,
} from "../../components/Preferences/data/data";
import { useDispatch, useSelector } from "react-redux";
import {
  updateArray,
  deleteArray,
  resetFilter,
} from "../../redux/slices/searchSlice";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import get_skills_token from "../../api/get_skills_token";

export default function SortBy({ type }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchLocations, setSearchLocations] = useState("");
  const [skills, setSkills] = useState({
    isBusy: false,
    suggestions: [],
  });

  function debounce(fn, delay = 100) {
    let timeoutID;

    return function (...args) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }

  function wait(delay = 100) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async function fetchData(value) {
    if (value.length < 3) {
      return;
    }
    setSkills({ ...skills, suggestions: [], isBusy: true });
    try {
      const query = encodeURIComponent(value);
      await wait();

      const token = await get_skills_token();

      const response = await fetch(
        `https://emsiservices.com/skills/versions/latest/skills?q=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // if (response.ok) {
      const json = await response.json();
      console.log(json.data);

      return json.data.map((item, index) => ({
        value: Math.random(),
        label: item.name,
      })); // } else {
      //   throw Error(`The API returned a ${response.status}`);
      // }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const onInput = useCallback(
    debounce(async (value) => {
      console.log(skills.isBusy);
      if (skills.isBusy) return;

      // setSkills({ suggestions: [], isBusy: true });

      try {
        const suggestions = await fetchData(value);

        if (suggestions) {
          setSkills({ suggestions, isBusy: false });
        }
      } catch (error) {
        console.error(error);
        setSkills({ isBusy: false });
      }
    }),
    [skills]
  );

  const ref = useRef();
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.filterSearch);
  const location = useLocation();
  const search = location.search;
  const query = queryString.parse(search);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const handleChange = (e, type, index) => {
    if (e.target.checked === true) {
      dispatch(updateArray({ type, value: e.target.name }));
    } else {
      dispatch(deleteArray({ type, value: e.target.name }));
    }
  };

  let ar = searchState[type];
  console.log(ar);

  const advancedFilters = () => {
    const modifiedQuery = {
      ...query,
      [type]: ar,
    };
    const options = {
      pathname: location.pathname,
      search: queryString.stringify(modifiedQuery),
    };
    navigate(options);
  };

  const handleDelete = () => {
    dispatch(resetFilter({ type }));
  };

  useEffect(() => {
    advancedFilters();
  }, [ar]);

  return (
    <>
      <div className="flex flex-col" ref={ref}>
        <button
          className=" relative flex bg-white shadow-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue font-medium rounded-lg text-xs md:text-sm p-1 px-3 md:px-4 md:py-2.5 text-center  items-center  justify-between gap-10 md:gap-20"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <p
            id="dropdownCheckboxButton"
            data-dropdown-toggle="dropdownDefaultCheckbox"
            className="text-black  "
            type="button"
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </p>
          <div className="flex items-center">
            {searchState[type]?.length !== 0 ? (
              <p className="bg-blue text-white text-xs px-2 rounded-full p-1">
                {searchState[type]?.length}
              </p>
            ) : null}

            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
        </button>

        {isMenuOpen === true ? (
          <div
            id="dropdownDefaultCheckbox"
            className="z-10 mt-6 md:mt-10 absolute max-h-full  w-48 bg-white rounded divide-y divide-gray-100 shadow-lg   overflow-y-scroll"
          >
            {type === "skills" ? (
              <div class="p-3">
                <label for="input-group-search" class="sr-only">
                  Search
                </label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      onInput(e.target.value);
                    }}
                    id="input-group-search"
                    class="block p-2 pl-10 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder={`Search ${type}`}
                  />
                </div>
              </div>
            ) : (
              <div class="p-3">
                <label for="input-group-search" class="sr-only">
                  Search
                </label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchLocations(e.target.value);
                    }}
                    id="input-group-search"
                    class="block p-2 pl-10 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder={`Search ${type}`}
                  />
                </div>
              </div>
            )}

            <ul
              className="   text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownCheckboxButton"
            >
              <li className="flex p-3 z-10 w-48 bg-white rounded divide-y divide-gray-100 shadow-lg max-h-[8.5rem]  overflow-y-scroll flex-col gap-3 overflow-x-hidden">
                {type === "locations"
                  ? locationOptions
                      .filter((item) =>
                        item.label.toLowerCase().includes(searchLocations)
                      )
                      .map((data, index) => {
                        return (
                          <div className="flex items-center" key={index}>
                            <input
                              id="checkbox-item-1"
                              name={data.label}
                              type="checkbox"
                              checked={searchState[type].includes(data.label)}
                              onChange={(e) =>
                                handleChange(e, "locations", index)
                              }
                              className="w-4 h-4 text-blue bg-gray-100 rounded border-gray-300 "
                            />
                            <label
                              for="checkbox-item-1"
                              className="ml-2 text-sm font-medium text-gray-900 "
                            >
                              {data.label}
                            </label>
                          </div>
                        );
                      })
                  : type === "causes"
                  ? causesOptions
                      .filter((item) =>
                        item.label.toLowerCase().includes(searchLocations)
                      )
                      .map((data, index) => {
                        return (
                          <div className="flex items-center" key={index}>
                            <input
                              id="checkbox-item-1"
                              name={data.label}
                              type="checkbox"
                              checked={searchState[type].includes(data.label)}
                              onChange={(e) => handleChange(e, "causes", index)}
                              className="w-4 h-4 text-blue bg-gray-100 rounded border-gray-300 "
                            />
                            <label
                              for="checkbox-item-1"
                              className="ml-2 text-sm font-medium text-gray-900 "
                            >
                              {data.label}
                            </label>
                          </div>
                        );
                      })
                  : skills.suggestions.map((data, index) => {
                      return (
                        <div className="flex items-center " key={index}>
                          <input
                            id="checkbox-item-1"
                            name={data.label}
                            type="checkbox"
                            checked={searchState[type].includes(data.label)}
                            onChange={(e) => handleChange(e, "skills", index)}
                            className="w-4 h-4 text-blue bg-gray-100 rounded border-gray-300 "
                          />
                          <label
                            for="checkbox-item-1"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            {data.label}
                          </label>
                        </div>
                      );
                    })}
              </li>
              <button
                className="flex items-center p-3 text-sm font-medium text-red-600 border-t bg-white w-full"
                onClick={handleDelete}
              >
                Reset Filter
              </button>
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
}
