import React from "react";
import exp from "../../images/volunteer-exp.png";
import ReactStars from "react-rating-stars-component"
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import get_current_user from "../../aws_cognito/get_user";
import defaultAvatar from "../../images/default.jpg"
import WriteTestimonial from "../WriteTestimonial/WriteTestimonial";
import { useSelector } from 'react-redux'
import { useRef } from "react";
import Modal from "../Modal/Modal";


export default function NGOTestimonials({typeUser,profile}) {
  const [ngoTestimonials,setNgoTestimonials]=useState([]);
  const {loading,userProfile}=useSelector(state=>state.currentProfile)
  const menuRef =useRef()
  const toggleRef = useRef()
  const deletingRef = useRef(false)
  const [showPostMenu, setShowPostMenu] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [postId,setPostId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rating,setRating]=useState(0);
  const [feedback,setFeedback]=useState("");




  useEffect(()=>{
    getTestimonials();
    console.log(profile);
    console.log(userProfile);
  },[])

  const getTestimonials= async ()=>{
    try {
      const user = await get_current_user(typeUser)
      console.log(typeUser);
      console.log(user);
      console.log(profile);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/testimonial/impactorg/accepted/${profile._id}`, {
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
          } 
        });
        console.log(res.data.data);
        if(res.data.data.length>0){
          setNgoTestimonials(...ngoTestimonials,res.data.data)
        }
    } catch (error) {
      console.log(error);
    }
  }

  const deleteNgoTestimonial = async (id)=>{
    try {
      const  res = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/testimonial/impactorg/${id}`, {
        headers: {
            Authorization: `Bearer ${userProfile.data.idToken}`
          } 
        });
        console.log(res.data);
      }catch (error) {
      console.log(error)
    }finally{
      setNgoTestimonials(
        ngoTestimonials.filter((item)=>item._id!=id))
      console.log(ngoTestimonials);
      alert("Deleted Successfully..")
    }
  }

  const ratingChanged=(newrating)=>{
    console.log(newrating);
    setRating(newrating);
}

  const handleChange=(e)=>{
    setFeedback(e.target.value);
  }

  const handleSubmit= async (id)=>{
    try {
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/testimonial/impactorg/${id}`, { testimonial: feedback, rating: rating }, {
        headers: {
            Authorization: `Bearer ${userProfile.data.idToken}`
        }
    })
    console.log(res.data);
    alert("Edit request send for verification..")
    setModalIsOpen(false)

    } catch (error) {
      console.log(error);
    }
  }

  return (
      <>
      {
        (userProfile.data.type=="corporate" && profile.type=="organization" && userProfile.data._id != profile._id) &&
        <WriteTestimonial profile={profile}></WriteTestimonial>
      }
        {
          ngoTestimonials.length>0 && 
          ngoTestimonials.map((testimonial)=>(
            <div className="flex flex-col md:mb-5 md:border-b border-[#C4C4C4] py-4 w-full">
              <div ref={menuRef} className='relative'>
                    {(userProfile.data._id == testimonial.from) &&
                        <div ref={toggleRef} className='post-menu-toggle absolute h-8 w-8 pr-1 right-1 top-[-2px] flex justify-center items-center cursor-pointer' onClick={(e) => {
                            e.target.classList.toggle('active')
                            console.log(e);
                            setShowPostMenu(!showPostMenu)
                            setShowDeleteConfirmation(false)
                            setPostId(testimonial._id)
                        }}>
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                            <span className='pointer-events-none inline-block bg-[#111] z-10 rounded-full' />
                        </div>
                    }

                    {  showPostMenu && (testimonial._id==postId) &&
                        <div className='post-menu bg-white absolute top-8 right-4 w-[120px] rounded overflow-hidden'>
                            <div>
                                <div className={`post-edit-button cursor-pointer duration-300 flex items-center hover:bg-[#f3f3f3] h-[30px] w-full pl-4`} onClick={()=>{
                                  // implement edit funcionality here
                                  setFeedback(testimonial.testimonial)
                                  setRating(testimonial.rating)
                                  setModalIsOpen(true)
                                }}>
                                    <svg className='h-[0.85rem] w-[0.85rem] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#111]'>EDIT</span>
                                </div>
                            </div>
                            {
                              modalIsOpen &&
                              <Modal
                                isOpen={modalIsOpen}
                                onClose={() => {
                                  setModalIsOpen(false);
                                }}
                              >
                                <div className="px-8 py-6 mx-2 bg-white w-full max-w-[45em] rounded-lg" onClick={(e) => e.stopPropagation()}>
                                  <span className='inline-block font-semibold text-l text-[#222]'>Edit Testimonial</span>
                                  <div className='p-3 mt-3'>
                                      <div className='flex items-center'>
                                          <div className='w-16 h-16 md:w-20 md:h-20'>
                                              <img src={userProfile.data.profilePic==""?defaultAvatar: userProfile.data.profilePic} alt="ProfilePic" className="rounded-full  w-12 h-12 md:w-20 md:h-16" />
                                          </div>
                                          <div className='mx-3 border-b-2 border-slate-100 w-full'>
                                              <div className=' items-center md:flex'>
                                                      <p className='mr-2'><span>{testimonial.org.name}</span></p>
                                                      <ReactStars 
                                                      count={5}
                                                      size={24}
                                                      edit={true}
                                                      onChange={ratingChanged}
                                                      value={rating}
                                                      activeColor="#ffd700"></ReactStars>
                                              </div>
                                              <div >
                                                  <input  className='w-full py-2' type="text" placeholder='Add Testimonial' value={feedback} onChange={handleChange}/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className='flex justify-end mt-2'>
                                          <button className='m-1 mb-3 md:w-[5rem]  py-1 px-1 md:py-1 border-2 border-blue  text-blue rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>setModalIsOpen(false)}>Reject</button>
                                          <button className='m-1 mb-3 md:w-[5rem]  py-1 px-1 md:py-1 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem]' onClick={()=>{handleSubmit(testimonial._id)}}>Submit</button>
                                      </div>
                                  </div>
                                </div>
                              </Modal>
                            }
                            
                            <div className='post-delete max-h-[30px] overflow-hidden'>
                                <div className={`post-delete-button ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''} cursor-pointer duration-300 flex items-center  hover:bg-[#ff3b3b] h-[30px] w-full pl-4`} onClick={(e) => setShowDeleteConfirmation(true)}>
                                    <svg className='h-[0.8rem] w-[0.8rem] fill-[#f55858] ml-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"/>
                                    </svg>

                                    <span className='text-[0.7rem] ml-2 font-bold text-[#f55858]'>DELETE</span>
                                </div>
                                
                                <div className={`post-delete-confirmation flex items-center justify-center h-[30px] bg-[#ff3b3b] duration-300 ${showDeleteConfirmation ? 'translate-y-[-30px]' : ''}`}>
                                    <svg onClick={() => setShowDeleteConfirmation(false)} className='translate-y-[-1px] h-[0.8rem] w-[0.8rem] hover:rotate-90 duration-300 cursor-pointer fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"/>
                                    </svg>

                                    <span className='text-[0.7rem] mx-2 font-bold text-white cursor-default translate-y-[-1px]'>You sure?</span>


                                        <svg className='h-[0.9rem] w-[0.9rem] fill-white cursor-pointer hover:translate-y-[-2px] duration-300' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={() => {
                                            deletingRef.current = true
                                            console.log("deleting");
                                            deleteNgoTestimonial(testimonial._id)
                                        }}>
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                                        </svg>

                                </div>
                            </div>
                        </div>
                    }
                </div>
              <div className="flex md:gap-3">
                <img src={testimonial.org.profilePic==""?defaultAvatar:testimonial.org.profilePic} alt="ProfilePic" className="rounded-full w-12 h-12 md:w-24 md:h-24" />
                <div className="flex justify-between grow">
                  <div className="flex-col p-1 md:p-2 space-y-1">
                    <div className="md:space-y-1">
                      <h1 className="text-sm md:text-base font-bold">
                        {testimonial.org.name}
                      </h1>
                    </div>
                    <div className="mt-0">
                      <ReactStars 
                      count={5}
                      value={testimonial.rating}
                      edit={false}
                      size={24}
                      activeColor="#ffd700"></ReactStars>
                    </div>

                      <p className=" mt-0 text-[10px] md:text-sm">
                        {testimonial.testimonial}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        {
          ngoTestimonials.length==0 &&
          <div>
            <div className="text-center py-2">No Testimonials Available</div>
          </div>
        }
        
      </>
  );
}
