import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import { useSelector } from 'react-redux'
import get_current_user from '../../aws_cognito/get_user'
import defaultAvatar from "../../images/default.jpg"
import axios from 'axios'


const WriteTestimonial = ({profile}) => {
    const {loading,userProfile}=useSelector(state=>state.currentProfile)
    const [rating,setRating]=useState(0);
    const [feedback,setFeedback]=useState("");

    useEffect(()=>{
        console.log(profile);
        // console.log(userProfile);
    },)

    const ratingChanged=(newrating)=>{
        console.log(newrating);
        setRating(newrating);
    }

    const handleChange=(e)=>{
        setFeedback(e.target.value);
    }
    const resetRating=()=>{
        setRating(0);
    }
    const handleSubmit= async ()=>{
        try {
            if(feedback==""){
                alert("Please enter a testimonial ")
            }
            if(profile.type=="corporate"){
                const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/testimonial/corporate`, { to: profile._id, testimonial: feedback,rating: rating }, {
                    headers: {
                        Authorization: `Bearer ${userProfile.data.idToken}`
                    }
                })
                console.log(res.data);
            }else{
                const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/testimonial/impactorg`, { to: profile._id, testimonial: feedback,rating: rating }, {
                    headers: {
                        Authorization: `Bearer ${userProfile.data.idToken}`
                    }
                })
                console.log(res.data);
            }
            setFeedback("")
            alert("Testimonial send for verification")
            resetRating()
            console.log(rating);
        } catch (error) {
            console.log(error);
        }finally{
        }
    }


    
  return (
    <div className='p-3 mt-3'>
        <div className='flex items-center'>
            <div className='w-16 h-16 md:w-20 md:h-20'>
                <img src={userProfile.data.profilePic==""?defaultAvatar: userProfile.data.profilePic} alt="ProfilePic" className="rounded-full  w-12 h-12 md:w-20 md:h-16" />
            </div>
            <div className='mx-3 border-b-2 border-slate-100 w-full'>
                <div className=' items-center md:flex'>
                        <p className='mr-2 '>How would you rate <span className='font-bold text-[#111]'>{profile.name} </span>?</p>
                        <ReactStars 
                        count={5}
                        size={24}
                        onChange={ratingChanged}
                        value={rating}
                        activeColor="#ffd700"></ReactStars>
                </div>
                <div >
                    <input  className='w-full py-2' type="text" placeholder='Add Testimonial' value={feedback} onChange={handleChange}/>
                </div>
            </div>
        </div>
        <div className='flex justify-end mt-2'>
            <button className='m-1 mb-3 md:w-[5rem]  py-1 px-1 md:py-1 border-2 border-blue  text-blue rounded-md font-semibold md:text-sm text-[0.8rem]'>Cancel</button>
            <button className='m-1 mb-3 md:w-[5rem]  py-1 px-1 md:py-1 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem] disabled:opacity-50' disabled={(rating>0)?false:true} onClick={()=>{handleSubmit()}}>Submit</button>
        </div>
    </div>
  )
}

export default WriteTestimonial