import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import logo from "../../images/Logo.png";
import { FcGoogle } from "react-icons/fc";
import signup from "../../aws_cognito/signup";
import login from "../../aws_cognito/login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_token } from "../../aws_cognito/get_token";
import { create_session } from "./../../aws_cognito/create_session";
import clearCache from "../../aws_cognito/clear_cache";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/slices/userSlice'
import "./Signup.css"

export default function Signup({ type, user }) {
  const dispatch = useDispatch()
  const { start, userProfile, processing } = useSelector(state => state.currentProfile)
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('error_description')) {
      alert("User already exists")
    }
  }, [window.location.search])

  const handleToggle = (type) => {
    if (type === "password") {
      setOpen(!open);
    } else {
      setConfirmPass(!confirmPass);
    }
  };
  const [clientId, setClientId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false)
  const [popups, setPopups] = useState({
    password: false
  })

  useEffect(() => {
    if (!userProfile.data) {
        for (const currentType of ['admin', 'volunteer', 'corporate', 'organization'])
            dispatch(getUser({ type: currentType }))

    }
  }, [])

  useEffect(() => {
    const currentType = userProfile.data?.type

    if (userProfile?.data) {
      navigate(`/${currentType}/community-wall`)
    }

    if (userProfile.profileNotSetup) {
      navigate(`/${currentType}/profileSetup`)
    }

  }, [userProfile.data])

  useEffect(() => {
    if (searchParams.get("code") !== null) {
      const fetchToken = async () => {
        const code = searchParams.get("code");
        const res = await get_token(code, type, true);
        if (res !== null) {
          console.log("aftercall ", res);
          const sess = await create_session(
            res?.data.id_token,
            res?.data.access_token,
            res?.data.refresh_token,
            type
          );
          navigate(`/${type}/profileSetup/`);
          console.log(sess, "session details");
        }
      };
      fetchToken();
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const onSubmit = (data) => console.log(data);
  useEffect(() => {
    if (type === "volunteer")
      setClientId(process.env.REACT_APP_AWS_COGNITO_VOL_CLIENT_ID);
    if (type === "corporate")
      setClientId(process.env.REACT_APP_AWS_COGNITO_CORP_CLIENT_ID);
    if (type === "organization")
      setClientId(process.env.REACT_APP_AWS_COGNITO_ORG_CLIENT_ID);
  }, [type]);

  const SignUp = async (data) => {
    try {
      const res = await signup(data.email, data.password, type);
      setLoading(false);

      if (res.data !== null) {
        navigate(`/${type}/verify`, {
          replace: true,
          state: { email: data.email, pw: data.password },
        });
      }
    }
    catch (error) {
      throw error
    }
  }

  const onSubmit = async (data) => {
    if (loadingRef.current) {
      return 
    }

    setLoading(true);
    loadingRef.current = true 

    if (data.password !== data.confpassword) {
      console.log(data.password, data.confpassword);
      alert("Passwords do not match!");
      setLoading(false);
      loadingRef.current = false
      return;
    }

    console.log(data);

    try {
      await SignUp(data)
    }

    catch (error) {
      if (error.code == 'UsernameExistsException') {
        try {
          await login(data.email, '', type)
        }
        catch (error2) {
          if (error2.code == 'UserNotVerified') {
            try {
              await SignUp(data)
            }
            catch (error) {
              alert(error.message);
              setLoading(false)
            }

            return
          }

          else {
            alert('User already exists')
            setLoading(false)
          }
        }
      }

      else {
        alert(error.message);
        setLoading(false);
      }
    }

    finally {
      loadingRef.current = false
    }
  };
  console.log(errors);
  return (
    <>
      { (!start || processing > 0) &&
        <div className='h-screen w-screen flex items-center justify-center bg-[#F4F8FB] absolute top-0'>                    
            <svg
            role="status"
            class="inline mr-1 w-12 h-12 text-[#888] animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
            />
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
            />
            </svg>
        </div>
      }

      { (start && processing == 0) &&
        <div className="w-full md:w-[47%] flex z-10 md:h-screen bg-white p-5 md:p-8 overflow-y-scroll">
          <div className="flex flex-col w-full">
            <a
              href="/"
              className="text-blue px-4 md:p-4 font-bold underline underline-offset-5 w-max cursor-pointer z-10"
            >
              {"<"} Back
            </a>
            <div className="hidden md:flex justify-center items-center -mt-10">
              <img src={logo} alt="" className="w-36" />
            </div>
            <p className="text-center text-xs font-medium leading-5 mt-10 md:mt-0">
              Are you a {user}?
            </p>
            <h1 className="text-center font-bold text-lg md:text-3xl mb-5 md:mb-0">
              Get Started.
            </h1>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col justify-center mt-7 space-y-8 w-full"
            >
              <div className="relative w-3/4 flex mx-auto">
                <input
                  type="email"
                  id="email"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  placeholder=" "
                  required
                  {...register("email", {})}
                />
                <label
                  htmlFor="email"
                  className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1  scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  E-mail ID
                </label>
              </div>
              <div className="relative w-3/4 flex mx-auto">
                <input
                  onFocus={() => setPopups({ ...popups, password: true })}
                  type={open === false ? "password" : "text"}
                  {...register("password", {})}
                  id="password"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  onBlur={() => setPopups({ ...popups, password: false })}
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="password"
                  className="absolute  text-black duration-300 transform -translate-y-9 -translate-x-1 scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2 peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  Password
                </label>
                <div className="absolute right-2 top-1.5 text-2xl flex items-center text-blue cursor-pointer">
                  {open === false ? (
                    <AiOutlineEye onClick={() => handleToggle("password")} />
                  ) : (
                    <AiOutlineEyeInvisible
                      onClick={() => handleToggle("password")}
                    />
                  )}
                </div>
                
                { popups.password &&
                  <div className='signup-popup absolute left-[10%] pointer-events-none top-[40px] w-[80%] shadow-md p-2 z-50 pl-4 rounded-md bg-[#383848]/[0.9] translate-y-[0.7rem] text-[#eaeaea] font-light'>
                    <div className='triangle h-[1rem] w-[1rem] translate-y-[-1rem] rotate-[-45deg] absolute bg-[#383848]/[0.9]'></div>

                    <span className='text-[0.7rem] mb-1'>Password requirements</span>
                    <ul className='list-disc ml-4 text-[0.75rem]'>
                      <li>Minimum 8 characters</li>
                      <li>Contains at least 1 special character</li>
                      <li>Contains at least 1 uppercase letter</li>
                      <li>Contains at least 1 lowercase letter</li>
                    </ul>
                  </div>
                }
              </div>
              <div className="relative w-3/4 flex mx-auto">
                <input
                  type={confirmPass === false ? "password" : "text"}
                  id="confirmPass"
                  className="py-2 px-3 bg-gray-100 w-full text-sm text-[#141414]   border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-[#22A5FF] peer focus:bg-white"
                  required
                  placeholder=" "
                  {...register("confpassword", {})}
                />
                <label
                  htmlFor="confirmPass"
                  className="absolute text-black duration-300 transform -translate-y-9 -translate-x-1 scale-90 top-1.5 left-5 z-10 origin-[0] peer-focus:left-2  peer-focus:text-xl peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9"
                >
                  Confirm Password
                </label>
                <div className="absolute right-2 top-1.5  text-2xl flex items-center text-blue cursor-pointer">
                  {confirmPass === false ? (
                    <AiOutlineEye onClick={() => handleToggle("confirmPass")} />
                  ) : (
                    <AiOutlineEyeInvisible
                      onClick={() => handleToggle("confirmPass")}
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-center flex-col items-center space-y-3">
                {loading ? (
                  <button
                    disabled
                    type="button"
                    className="bg-sky-300 w-full  md:w-max text-white p-2 md:p-3 rounded-lg font-bold"
                  >
                    <svg
                      role="status"
                      class="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Signing Up...
                  </button>
                ) : (
                  <input
                    type="submit"
                    value="Continue"
                    className="bg-blue hover:bg-blue-500 text-white font-bold py-2 md:py-3 px-8 mb-5 md:mb-0 rounded-lg cursor-pointer"
                  />
                )}
                <p className="text-sm text-[#141414] font-medium">
                  Already have an account?{" "}
                  <a href={`/${type}/login`} className="text-blue font-bold">
                    Login.
                  </a>
                </p>
              </div>
              <div className="flex justify-center space-x-4">
                <span className="flex items-center">
                  <hr className="border-[1px] w-28 md:w-48 border-[#D9D9D9]" />
                </span>
                <span className="font-bold">OR</span>
                <span className="flex items-center">
                  <hr className="border-[1px] w-28 md:w-48 border-[#D9D9D9]" />
                </span>
              </div>
            </form>
            <div className="flex justify-center my-3 pb-5">
              <button
                // href={`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_DOMAIN}/${type}/signup/&response_type=CODE&client_id=${clientId}&scope=email openid profile`}
                className="flex gap-3 shadow-md py-3 px-2 rounded-lg items-center font-bold pr-3"
                onClick={()=>clearCache(type, clientId)}
              >
                <FcGoogle className="text-3xl" />
                Sign Up with Google
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}
