import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdWest } from "react-icons/md";
import { Link } from "react-router-dom";
import get_current_user from "../../../../aws_cognito/get_user";

import placeholderAvatar from "../../dummyData/images/placeholder-avatar.jpg";
import Loading from "../Loading/Loading";

const VolunteerModal = ({ onBack, type, color }) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);
  const [unverified, setUnverified] = useState(null);
  const [allVolunteers, setAllVolunteers] = useState(null);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const user = await get_current_user("admin");
      const token = user.data.idToken;

      try {
        const { data: response } =
          type === "Volunteers"
            ? await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/admin/volunteer`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    contentType: "application/json",
                  },
                }
              )
            : type === "Companies"
            ? await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/admin/corporate`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    contentType: "application/json",
                  },
                }
              )
            : type === "Impact Organizations"
            ? await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/admin/impact-org`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    contentType: "application/json",
                  },
                }
              )
            : { data: { data: [] } };

        console.log(response.data);
        setData(response.data);
        setAllVolunteers(response.data);
        setUnverified(response.data.filter((item) => !item.adminVerified));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetData();
  }, [type]);

  const [recentSignupsPage, setRecentSignupsPage] = React.useState(1);
  const [allVolunteersPage, setAllVolunteersPage] = React.useState(1);
  const totalRecentSignupsPages = Math.ceil(unverified?.length / 3);
  const totalAllVolunteersPages = Math.ceil(data?.length / 3);

  const [searchText, setSearchText] = React.useState("");

  const handleRecentSignupsPageChange = (val) => {
    if (recentSignupsPage === 1 && val === -1) return;
    if (recentSignupsPage >= totalRecentSignupsPages && val === 1) return;
    setRecentSignupsPage((prevState) => prevState + val);
  };

  const handleAllVolunteersPageChange = (val) => {
    if (allVolunteersPage === 1 && val === -1) return;
    if (allVolunteersPage >= totalAllVolunteersPages && val === 1) return;
    setAllVolunteersPage((prevState) => prevState + val);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setAllVolunteersPage(1);
    setAllVolunteers(
      data.filter((volunteer) =>
        volunteer.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const LINK_MAP = {
    Volunteers: "volunteer",
    Companies: "corporate",
    "Impact Organizations": "organization",
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading />;
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center xl:h-full">
      <div className="flex flex-col w-full max-w-[25rem]">
        <div className="cursor-pointer disabled lg:hidden" onClick={onBack}>
          <MdWest className="text-2xl" />
        </div>
        <div className="flex flex-col items-center">
          <h2 className={`text-4xl text-[${color}] font-bold`}>
            {data.length}
          </h2>
          <p className="font-semibold text-xl text-[#919191]">{type}</p>
        </div>

        {/* Recent Signups */}
        <div className="mt-8 mb-4">
          <h3 className="font-semibold">Recent Signups</h3>
          <div className="px-2 py-6 space-y-4 h-[11rem]">
            {/* Single Profile Stack Card */}
            {unverified
              .slice(
                Math.max(-3 * recentSignupsPage, -unverified.length),
                -3 * recentSignupsPage + 3 || unverified.length
              )
              .reverse()
              .map((user, idx) => (
                <div className="flex items-center justify-between">
                  {/* Image + Name */}
                  <div className="flex items-center gap-4">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={user.profilePic || placeholderAvatar}
                      alt=""
                    />
                    <p className="font-bold">{user.name}</p>
                  </div>
                  {/* View Profile Button */}
                  <Link to={`/admin/view-${LINK_MAP[type]}/${user._id}`}>
                    <button className="font-medium text-blue">
                      View Profile
                    </button>
                  </Link>
                </div>
              ))}
          </div>
          {/* Change Page Section */}
          {unverified.length === "0" ? (
            <div className="flex items-center justify-center -translate-y-20">
              <p className="font-semibold text-xl text-[#919191]">
                No recent signups
              </p>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-4">
              <button onClick={() => handleRecentSignupsPageChange(-1)}>
                <AiOutlineLeft />
              </button>
              <p className="w-8 text-center">
                {recentSignupsPage}/{totalRecentSignupsPages}
              </p>
              <button onClick={() => handleRecentSignupsPageChange(1)}>
                <AiOutlineRight />
              </button>
            </div>
          )}
        </div>

        {/* All Volunteers */}
        <div className="mt-4">
          <h3 className="font-semibold">All {type}</h3>
          <div className="my-4 rounded-md bg-[#EAEEF0] flex items-center gap-2 p-2 px-4">
            <BiSearch />
            <input
              autoFocus
              className="flex-1 px-2 bg-transparent outline-none"
              type="text"
              name="search volunteer"
              id=""
              placeholder={`Search ${type}`}
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </div>
          <div className="px-2 py-6 space-y-4 h-[11rem]">
            {/* Single Profile Stack Card */}
            {allVolunteers
              ?.slice(
                3 * (allVolunteersPage - 1),
                Math.min(3 * allVolunteersPage, data.length)
              )
              .reverse()
              .map((user, idx) => (
                <div
                  key={user._id}
                  className="flex items-center justify-between"
                >
                  {/* Image + Name */}
                  <div className="flex items-center gap-4">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={user.profilePic || placeholderAvatar}
                      alt=""
                    />
                    <p className="font-bold">{user.name}</p>
                  </div>
                  {/* View Profile Button */}
                  <Link to={`/admin/view-${LINK_MAP[type]}/${user._id}`}>
                    <button className="font-medium text-blue">
                      View Profile
                    </button>
                  </Link>
                </div>
              ))}
          </div>
          {/* Change Page Section */}
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => handleAllVolunteersPageChange(-1)}>
              <AiOutlineLeft />
            </button>
            <p className="w-8 text-center">
              {allVolunteersPage}/{totalAllVolunteersPages}
            </p>
            <button onClick={() => handleAllVolunteersPageChange(1)}>
              <AiOutlineRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerModal;
