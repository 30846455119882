export default [
    'Rural Development',
    'Children\'s education',
    'Women’s health',
    'Animal Care',
    'Sports',
    'Women empowerment',
    'Wildlife conservation',
    'Marine conservation',
    'Waste management',
    'Community development',
    'Water Conservation',
    'Air quality conservation',
    'Volunteer for orphanage',
    'Volunteer for old age home',
    'Medical Volunteer',
    'Disaster Management',
    'SDG 1: No Poverty',
    'SDG 2: Zero Hunger',
    'SDG 3: Good Health and Well-being',
    'SDG 4: Quality Education',
    'SDG 5: Gender Equality',
    'SDG 6: Clean Water and Sanitation',
    'SDG 7: Affordable and Clean Energy',
    'SDG 8: Decent Work and Economic Growth',
    'SDG 9: Industry, Innovation and Infrastructure',
    'SDG 10: Reduced Inequality',
    'SDG 11: Sustainable Cities and Communities',
    'SDG 12: Responsible Consumption and Production',
    'SDG 13: Climate Action',
    'SDG 14: Life Below Water',
    'SDG 15: Life on Land',
    'SDG 16: Peace and Justice Strong Institutions',
    'SDG 17: Partnerships to achieve the Goal',
    'Child rights',
    'Poverty',
    'Social Injustice',
    'Environment Conservation',
    'Human Rights',
    'Care for elderly people',
    'Animal Rights',
    'Sanitation and Hygiene',
    'Humanitarian Relief',
    'Health and Nutrition',
    'Literacy and Education',
    'Refugee Crisis',
    'Disease Control',
    'Welfare for differently abled',
    'Tribal welfare',
    'HIV/AIDS',
    'LGBTQ Rights and Welfare',
    'Unemployment',
    'Art & Culture',
    'Menstural Health & Hyegiene',
    'Migrants & Refugee Rights',
    'News',
    'Funds / Grants',
    'Career Opportunities',
    'Internship',
    'Scholarships & Fellowship',
    'Goverment Announcements',
    'Courses / Training Programs',
    'Events',
    'Sell / Buy',
    'Any'
]