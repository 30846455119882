import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as postsApi from '../../api/posts'
import get_current_user from '../../aws_cognito/get_user'
import defaultAvatar from '../../images/default.jpg'
import { Link } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'

export default function InterestedUsers({ post, status, close }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    let [interests, setInterests] = useState([])
    let fetching = useRef(false)
    const type = userProfile?.data?.type

    const interestsFetchLimit = process.env.REACT_APP_LIKES_FETCH_LIMIT

    useEffect(() => {
        loadMoreInterests()
    }, [])

    const loadMoreInterests = async () => {
        if (fetching.current)
            return

        fetching.current = true

        try {
            const user = await get_current_user(type)
            const params = { limit: interestsFetchLimit }
    
            if (interests.length) {
                params.before = interests[interests.length - 1]._id
            }
    
            const res = await postsApi.getInterestedUsers(post._id, params, user.data.idToken)

            interests = [...interests, ...res.data]
            setInterests(interests)
        }

        catch (error) { }

        finally {
            fetching.current = false
        }
    }

    const onScroll = (e) => {
        const ele = e.target
        const bottom = ele.scrollHeight - (ele.scrollTop + ele.clientHeight)
  
        if (bottom < 50 && !fetching.current && interests.length < status.interests) {
            loadMoreInterests()
        }
    }

    return (
        <div className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-30'>
            <div className='bg-black/[0.5] fixed top-0 left-0 w-screen h-screen z-30' onClick={close}></div>

            <div className='bg-white shadow-md rounded-md mt-4 h-[600px] w-full max-w-[600px] relative pt-7 m-3 z-40 px-2'>
                <span className='ml-6 text-[1.05rem]'>
                    Interested people for blood donation
                </span>
            
                <span className='inline-block h-3 w-[12px] m-1 mb-2 cursor-pointer text-[0.7rem] self-end absolute right-3 top-4' onClick={close}>
                    <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-45'></span>
                    <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-[-45deg]'></span>
                </span>

                <div className='w-full h-[1px] bg-[#e2e2e2] mt-6'></div>

                <div className='scrollable flex flex-col overflow-y-scroll h-[500px] mt-2 px-4' onScroll={onScroll}>
                    {interests.map(x => (
                        <div key={x._id} className='flex items-center py-1'>
                            <img src={(!x.user.profilePic || x.user.profilePic.trim() == '') ? defaultAvatar : x.user.profilePic} className='w-12 h-12 rounded-full my-1' />
                            
                            <Link to={userProfile.data?.db_id == x.user._id ? `/${type}/profile` : `/${type}/view-${x.userType}/${x.user._id}`}>
                                <span className='ml-3 text-[0.8rem]'>{x.user.name}</span>
                            </Link>
                        </div>
                    ))}

                    {interests.length < status.interests &&
                        <div className='w-full flex items-center justify-center'>
                            <PulseLoader size={6} color={'#222'} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}