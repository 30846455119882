import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { getNotifications, hideNotifications } from '../../redux/slices/notificationsSlice'
import { getUser } from '../../redux/slices/userSlice'
import { useMediaQuery } from 'react-responsive'
import Notification from "./Notification"
import './Notifications.css'
import { PulseLoader } from 'react-spinners'

const Notifications = () => {
    const dispatch = useDispatch()
    const wrapperRef = useRef()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const type = userProfile.data?.type
    const notifications = useSelector(state => state.notifications)

    useEffect(() => {
        if (notifications.initial && userProfile.data) {
            console.log(JSON.stringify(userProfile.data))
            const params = {
                limit: process.env.REACT_APP_NOTIFICATIONS_FETCH_LIMIT
            }

            dispatch(getNotifications({ params, userType: type }))
        }
    }, [userProfile.data?._id])

    useEffect(() => {
        if (!userProfile.data) {
            for (const type of ['admin', 'volunteer', 'corporate', 'organization'])
                dispatch(getUser({ type }))
        }

        const onWindowClick = (e) => {
            if (e.target == wrapperRef.current) {
                dispatch(hideNotifications())
            }
        }

        window.addEventListener('click', onWindowClick)

        return () => {  
            window.removeEventListener('click', onWindowClick)
        }
    }, [])

    const onScroll = (e) => {
        if (notifications.end) return

        const ele = e.target
        const bottom = ele.scrollHeight - (ele.scrollTop + ele.clientHeight)
  
        if (bottom < 50 && !notifications.loading) {
            const list = notifications.notifications

            const params = {
                before: list[list.length - 1]._id,
                limit: process.env.REACT_APP_NOTIFICATIONS_FETCH_LIMIT
            }

            dispatch(getNotifications({ params, userType: type }))
        }
    }

    return (
        <div ref={wrapperRef} className="notifications-wrapper">
            <div onScroll={onScroll} className={`notifications absolute right-0 ${isMobile ? 'w-full' : 'w-[400px]'} scrollable overflow-y-scroll h-[100vh] bg-white shadow-md p-4 bg-[#F4F8FB]`}>
                <div className='flex items-center mt-3'>
                    <span className='font-semibold text-[1.25rem] text-[#222]'>Notifications</span>

                    <span className='inline-flex items-center justify-center w-[16px] h-[16px] cursor-pointer text-[0.7rem] relative ml-auto mr-1' onClick={() => dispatch(hideNotifications())}>
                        <span className='inline-block h-[1px] w-full bg-gray-800 absolute rotate-45'></span>
                        <span className='inline-block h-[1px] w-full bg-gray-800 absolute rotate-[-45deg]'></span>
                    </span>
                </div>

                <div className='flex justify-end mt-8'>
                    <div>
                        <label className='text-[0.8rem] mr-1'>Sort by:</label>
                        <select className='font-bold text-[0.8rem] bg-transparent'>
                            <option>Recent</option>
                        </select>
                    </div>
                </div>

                <div className='flex flex-col justify-center mt-1'>
                    { !notifications.loading && notifications.notifications.length == 0 &&
                        <span className='font-semibold mt-5 text-[#333]'>You have no notifications</span>
                    }
                    
                    { notifications.notifications.map(x => (
                        <Notification key={x._id} data={x} />
                    ))}

                    {!notifications.end &&
                        <div className='w-full flex items-center justify-center mt-4'>
                            <PulseLoader size={6} color={'#222'} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifications