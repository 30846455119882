import cog_pool from "./user_pool";

const clearCache = async(type, clientId) => {
  const pool = cog_pool(type);
  const cognitoUser = pool.getCurrentUser();

  if (cognitoUser) {
    cognitoUser.signOut((test) => console.log(test));
    localStorage.clear();
    window.location = `${
      process.env.REACT_APP_COGNITO_DOMAIN
    }/logout?client_id=${pool.getClientId()}&logout_uri=${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_DOMAIN}/${type}/login/&response_type=CODE&client_id=${clientId}&scope=email openid profile`;
    alert()
  }
  window.location.href = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_DOMAIN}/${type}/login/&response_type=CODE&client_id=${clientId}&scope=email openid profile`
}

export default clearCache;