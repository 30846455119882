// Create a full screen modal

import React from "react";

const Modal = ({ children, isOpen, onClose }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        isVisible ? "opacity-100" : "opacity-0 hidden"
      }`}
      onClick={onClose}
    >
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="absolute inset-0 flex items-center justify-center">
        <div
          className="w-screen h-screen max-w-[500px] sm:rounded-lg sm:h-[95%] max-h-[50rem] bg-[#F4F8FB] p-6 shadow-xl overflow-scroll"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
