import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBroadcastType } from '../../../redux/slices/chatSlice'
import SectionSelect from '../SectionSelect/SectionSelect'
import ChatInput from '../ChatInput/ChatInput'
import MessageList from '../MessageList/MessageList'
import { useMediaQuery } from 'react-responsive'
import { broadcastTypes, chatTypes } from '../../../utils/enum'
import './Broadcast.css'

export default function Broadcast({ className, openNavRef }) {
    const dispatch = useDispatch()
    const { broadcastType } = useSelector(state => state.chat)
    const widthMd = useMediaQuery({ query: '(min-width: 900px)' })

    useEffect(() => {
        dispatch(setBroadcastType(broadcastTypes.ALL))
    }, [])

    return (
        <div className={`bg-white shadow rounded p-3 flex flex-col ${className}`}>
            <div className='flex items-center'>
                { !widthMd &&                       
                    <button className='translate-y-[4px] ml-2' onClick={() => openNavRef.current(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 448 512">
                            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                        </svg>
                    </button>
                }

                <h1 className='font-semibold text-[1.3rem] md:text-[1.4rem] ml-2 mt-2'>Broadcast Message</h1>
            </div>

            <SectionSelect 
                className='mt-6' 
                list={[ 'All', 'Volunteer', 'Corporate', 'Organization' ]} 
                disabled={[ false, false, false, false ]} 
                onSelect={(x) => dispatch(setBroadcastType(x.toLowerCase()))} />

            { broadcastType && 
                <div className='broadcast-div flex flex-col'>
                    <MessageList className='mt-auto' chatType={chatTypes.BROADCAST} />

                    <div className='mt-2'>
                        <ChatInput chatType={chatTypes.BROADCAST} />
                    </div>
                </div>
            }
        </div>
    )
}