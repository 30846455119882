import React, { useEffect, useState } from "react";
import axios from "axios";
import cover from "../../images/default-cover.png";
import pfp from "../../images/default.jpg";
import { TiCamera } from "react-icons/ti";
// import ContactInfo from "./ContactInfo";
import EditProfile from "./EditProfile";
import { useSelector } from "react-redux";
import PhotoModal from "../Profile/PhotoModal";
import defaultAvatar from "../../images/default.jpg";
import { useNavigate, useParams } from "react-router-dom";
import get_current_user from "../../aws_cognito/get_user";
import Modal from "../Modal/Modal";
import ReqCredit from "../ReqCredit/ReqCredit";
import * as postsApi from '../../api/posts'
import ViewAllConnections from "../Profile/ViewAllConnections";
import WithdrawConnRequest from "../Profile/WithdrawConnRequest";
import ApproveRejectConnRequest from "../Profile/ApproveRejectConnRequest";
import { getTypePath } from './../../utils/utils';
export default function CoverPhotoNGO({ type, me, admin }) {
  //   const [showModal, setShowModal] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [edit, setEdit] = useState(true);
  const navigate = useNavigate();
  const [totalConnections, setTotalConnections] = useState(0);
  const [connectionsList, setConnectionsList] = useState({});
  const [showAllConnectionsModal, setShowAllConnectionsModal] = useState(false);
  const [withdrawConnModal, setWithdrawConnModal] = useState(false);
  const [approveRejConnModal, setApproveRejConnModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const NGOProfile = useSelector((state) => state.NGOProfile.NGOProfile);
  const data = NGOProfile.data;
  const [connStatus, setConnStatus] = useState(data?.btnStatus);
  const { id } = useParams();
  useEffect(() => {
    if (id === NGOProfile?.user_id) {
      navigate(`/organization/profile`);
    }
    fetchAllConnections();
  }, []);
  const fetchAllConnections = async () => {
    const user = await get_current_user(type)
    if (me === true) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/connections`,{
      headers: {
          Authorization: `Bearer ${user.data.idToken}`
          }
      }).then((res) => {
          setConnectionsList(res.data.data.connections);
          setTotalConnections(res.data.data.count);
      })
    } else {
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/connections?userType=${NGOProfile.data.type}&userId=${NGOProfile.data._id}`,{
      headers: {
          Authorization: `Bearer ${user.data.idToken}`
          }
      }).then((res) => {
          setConnectionsList(res.data.data.connections);
          setTotalConnections(res.data.data.count);
      })
    }
  } 
    const handleCreateConnect = async (btnVal=null) => {
      if (connStatus == "Connect") {
        const connectData = {
          invitedUserType: NGOProfile.data.type,
          invitedUserId: NGOProfile.data._id
        }
        const user = await get_current_user(type)
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/connections`, connectData, {
          headers: {
              Authorization: `Bearer ${user.data.idToken}`
          }
        }).then((res) => {
          console.log(res);
          setConnStatus("Pending Request")
        }).catch(err => console.log(err))
      }
      if (connStatus == "Pending Request") {
      setWithdrawConnModal(true);
      }
      if (connStatus == "Remove" || btnVal=="Delete") {
      const user = await get_current_user(type)
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/connections/${NGOProfile.data._id}`,{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
          console.log(res);
          window.location.reload();
        })
      }
      if(btnVal=="Connect"){
        const user = await get_current_user(type);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/connections/${NGOProfile.data._id}?connectionStatus=Approved`, {},{
        headers: {
            Authorization: `Bearer ${user.data.idToken}`
            }
        }).then((res) => {
            console.log(res);
            window.location.reload();
        })
      }
      // if(connStatus==="Approve or Reject"){
      //   setApproveRejConnModal(true);
      // }
      
    }
  const handleAdminVerify = async () => {
    if (!window.confirm("Are you sure you want to verify this user?")) {
      return;
    }

    const { data: adminData } = await get_current_user("admin");
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/impact-org/${id}`,
        {
          adminVerified: true,
        },
        {
          headers: {
            Authorization: `Bearer ${adminData.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      window.location.reload(false);
    } catch (error) {
      console.error(error.response);
    }
  };

  const handleBlockUser = async () => {
    if (!window.confirm("Are you sure you want to block this user?")) {
      return;
    }
    const { data: adminData } = await get_current_user("admin");
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/impact-org/${id}`,
        {
          adminVerified: false,
        },
        {
          headers: {
            Authorization: `Bearer ${adminData.idToken}`,
            "content-type": "application/json",
          },
        }
      );
      window.location.reload(false);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
          setModalContent("");
        }}
      >
        <div
          className="sm:rounded-lg bg-[#F4F8FB] p-6 shadow-xl overflow-scroll"
          onClick={(e) => e.stopPropagation()}
        >
          <ReqCredit setModalIsOpen={setModalIsOpen} orgId={id} />
        </div>
      </Modal>
      {me === true ? (
        <div className="flex justify-end pt-5 md:hidden">
          <button
            className="text-sm font-bold text-black "
            onClick={() => setShowEditProfile(true)}
          >
            Edit Profile
          </button>
        </div>
      ) : null}
      <div
        className={`bg-white shadow-md md:shadow-none md:mx-0 w-full  pb-5 md:w-full ${
          me === true ? `mt-6` : `mt-16`
        } md:mt-5 rounded-xl `}
      >
        <ViewAllConnections showAllConnectionsModal={showAllConnectionsModal} setShowAllConnectionsModal={setShowAllConnectionsModal} connectionsList={connectionsList} type={type} me={me} />
        <EditProfile
          showModal={showEditProfile}
          setShowModal={setShowEditProfile}
        />
        <PhotoModal
          showModal={showPhotoModal}
          type="Profile"
          user="impactOrg"
          setShowModal={setShowPhotoModal}
        />
        <PhotoModal
          showModal={showCoverPhoto}
          type="Cover"
          user="impactOrg"
          setShowModal={setShowCoverPhoto}
        />
        {/* <ContactInfo showModal={showModal} setShowModal={setShowModal} /> */}
        <div className="flex flex-col ">
          <div className="relative">
            {me === true ? (
              <div className="absolute right-0 p-2 m-3 text-white rounded-full bg-blue ">
                <label htmlFor="coverImg" className="">
                  <TiCamera
                    className="text-sm cursor-pointer md:text-2xl"
                    onClick={() => setShowCoverPhoto(true)}
                  />
                </label>
              </div>
            ) : null}
            <img
              src={data?.coverPic === "" ? cover : data?.coverPic}
              alt="cover_pic"
              className="rounded-t-xl rounded-tr-xl object-cover h-28 sm:h-max md:h-[200px] w-full"
            />
          </div>
          <div className="z-10 flex">
            <div className="relative ml-5 -mt-10 md:-mt-16 md:ml-10">
              <img
                src={data?.profilePic === "" ? defaultAvatar : data?.profilePic}
                alt="profile_pic"
                className="w-28 h-20 rounded-full object-cover  md:w-[208px] md:h-40"
              />
              {me === true ? (
                <div className="absolute p-2 m-3 text-white rounded-full bg-blue top-8 left-10 md:left-24 md:top-24">
                  <label htmlFor="image">
                    <TiCamera
                      className="text-sm cursor-pointer md:text-2xl"
                      onClick={() => setShowPhotoModal(true)}
                    />
                  </label>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col w-full px-3 py-2 md:py-3 md:px-5">
              <div className="flex justify-between">
                <div className="flex items-center gap-3">
                  <p className="text-sm font-semibold text-black md:text-base">
                    {data?.name}
                  </p>
                  {data?.foundedYear !== "" ? (
                    <span className="text-xs font-semibold text-lightGray">
                      Founded in {data?.foundedYear}
                    </span>
                  ) : null}
                </div>
                {me === true ? (
                  <button
                    className="hidden text-sm font-semibold text-black md:block"
                    onClick={() => setShowEditProfile(true)}
                  >
                    Edit Profile
                  </button>
                ) : type === "volunteer" ||
                  NGOProfile?.loggedInType?.[0] === "volunteer" ? (
                  <button
                    className="p-1 text-xs font-semibold border rounded-full text-blue border-blue md:p-2 md:text-sm"
                    onClick={() => setModalIsOpen(true)}
                  >
                    + Request for credit
                  </button>
                ) : null}
              </div>
              <div className="flex flex-wrap gap-1 my-2 md: text-xs md:text-[13px]">
                {data?.thematicAreas.map((data, index) => {
                  return (
                    <p
                      className="font-bold rounded-md p-1 px-2 bg-[#FFE8AD] text-[#C39C37] w-max"
                      key={index}
                    >
                      {data?.label?.length > 10
                        ? data?.label.replace(/ \([\s\S]*?\)/g, "")
                        : data?.label}
                    </p>
                  );
                })}
              </div>
              {data?.locations?.length >= 2 ? (
                <div className="flex gap-1 my-2 md: text-xs md:text-[13px]">
                  <p className="font-normal text-[#919191]">
                    {data?.locations[0]?.label} and{" "}
                    <span className="font-bold">
                      {data?.locations?.length - 1} other locations
                    </span>
                  </p>
                </div>
              ) : data?.locations?.length === 1 ? (
                <div className="flex gap-1 my-2 md: text-xs md:text-[13px]">
                  <p className="font-normal text-[#919191]">
                    {data?.locations[0]?.label}
                  </p>
                </div>
              ) : null}
              {/* abc */}     
              <div className="my-1 flex flex-col xs:flex-row xs:items-center text-[0.8rem] md:text-[0.9rem]">
                {me==false &&(type=="corporate" || type=="organization") && connStatus !=="Approve or Reject" && <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={handleCreateConnect}>{connStatus}</button>}
                {me==false &&(type=="corporate" || type=="organization" || type=="volunteer")  && connStatus==="Approve or Reject" && <div> <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={()=>handleCreateConnect("Connect")}>Connect</button> <button style={{backgroundColor:'#22A5FF',color:'#fff',padding:'5px 10px',borderRadius:'5px'}} onClick={()=>handleCreateConnect("Delete")}>Delete</button> </div>}
                <p onClick={() => { setShowAllConnectionsModal(true) }} className={"text-[#22A5FF] font-semibold cursor-pointer"+(me==true?"":" ml-2")}>{totalConnections==0?` ${totalConnections} connections`:totalConnections==1?` ${totalConnections} connection`:` ${totalConnections} connections`}</p>
              </div>
              {admin &&
                (!data?.adminVerified ? (
                  <div className="h-12 mt-6">
                    <button
                      onClick={handleAdminVerify}
                      className="rounded-md bg-[#22A5FF] text-white font-semibold px-4 py-2"
                    >
                      Give Access
                    </button>
                  </div>
                ) : (
                  <div className="h-12 mt-6">
                    <button
                      onClick={handleBlockUser}
                      className="rounded-md text-[#FF8181] border-[1px] border-[#FF8181] font-semibold px-4 py-2 hover:border-[2px] transition-all"
                    >
                      Block User
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex w-full px-5 md:justify-end ">
            <div className="bg-[#F4F4F4] w-full md:w-max p-3 md:p-4 rounded-2xl flex space-x-4 justify-around">
              <div className="flex flex-col">
                <p className=" text-center text-[#716B6B] text-sm">
                  <span className="text-lg font-bold text-black md:text-2xl">
                    #{data?.rank}{" "}
                  </span>
                  in
                </p>
                <p className="text-[#716B6B] text-xs">“most credits gained"</p>
              </div>
              <div className="border-[1px] border-solid border-[#B9B9B9]"></div>
              <div className="flex flex-col">
                <p className="text-lg font-bold text-center md:text-2xl">
                  {data?.creditsGranted}
                </p>
                <p className="text-[#716B6B] text-xs">Credits Granted</p>
              </div>
            </div>
            
          </div>
          {/* abcd */}
          
          <div className="flex justify-center gap-3 p-3">
            
            {data?.mission !== "" ? (
              <div className="flex-col justify-center hidden w-full mt-3 md:flex">
                <fieldset className="flex items-center justify-center ">
                  <legend className="px-5 text-xs text-center text-[#919191]">
                    Mission
                  </legend>
                  <p className="text-xs font-bold text-center">
                    {data?.mission}
                  </p>
                </fieldset>
              </div>
            ) : null}
            {data?.vision !== "" ? (
              <div className="flex-col justify-center hidden w-full mt-3 md:flex">
                <fieldset className="flex items-center justify-center ">
                  <legend className="px-5 text-xs text-center text-[#919191]">
                    Vision
                  </legend>
                  <p className="text-xs font-bold text-center">
                    {data?.vision}
                  </p>
                </fieldset>
              </div>
            ) : null}
            {data?.coreValues !== "" ? (
              <div className="flex-col justify-center hidden w-full mt-3 md:flex">
                <fieldset className="flex items-center justify-center ">
                  <legend className="px-5 text-xs text-center text-[#919191]">
                    Core Values
                  </legend>
                  <p className="text-xs font-bold text-center">
                    {data?.coreValues}
                  </p>
                </fieldset>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col w-full mx-auto">
            {data?.mission !== "" ? (
              <div className="flex px-5 mt-5 space-x-2 md:hidden ">
                <p className=" text-xs text-center w-1/2 flex justify-center items-center    text-[#919191]">
                  Mission
                </p>
                <div className="border-[1px] border-solid border-blue"></div>
                <p className="w-full text-xs font-medium">{data?.mission}</p>
              </div>
            ) : null}

            {data?.vision !== "" ? (
              <div className="flex px-5 mt-5 space-x-2 md:hidden ">
                <p className=" text-xs text-center w-1/2 flex justify-center items-center    text-[#919191]">
                  Vision
                </p>
                <div className="border-[1px] border-solid border-blue"></div>
                <p className="w-full text-xs font-medium">{data?.vision}</p>
              </div>
            ) : null}

            {data?.coreValues !== "" ? (
              <div className="flex px-5 mt-5 space-x-2 md:hidden ">
                <p className=" text-xs text-center w-1/2 flex justify-center items-center    text-[#919191]">
                  Core Values
                </p>
                <div className="border-[1px] border-solid border-blue"></div>
                <p className="w-full text-xs font-medium">{data?.coreValues}</p>
              </div>
            ) : null}
          </div>
          {withdrawConnModal && <WithdrawConnRequest setWithdrawConnModal={setWithdrawConnModal} userID={NGOProfile.data._id} typeUser={type} />}
          {approveRejConnModal && <ApproveRejectConnRequest  setApproveRejConnModal={ setApproveRejConnModal} userID={NGOProfile.data._id} typeUser={type} />}
        </div>
      </div>
    </>
  );
}
