export const userAnalyticsData = [
  {
    date: '29 Apr',
    volunteers: 1700,
    companies: 500,
    impact_organizations: 400,
  },
  {
    date: '30 Apr',
    volunteers: 1400,
    companies: 400,
    impact_organizations: 500,
  },
  {
    date: '1 May',
    volunteers: 1800,
    companies: 600,
    impact_organizations: 400,
  },
  {
    date: '2 May',
    volunteers: 2100,
    companies: 750,
    impact_organizations: 459,
  },
  {
    date: '3 May',
    volunteers: 1900,
    companies: 650,
    impact_organizations: 512,
  },
  {
    date: '4 May',
    volunteers: 1800,
    companies: 900,
    impact_organizations: 550,
  },
  {
    date: '5 May',
    volunteers: 1750,
    companies: 750,
    impact_organizations: 497,
  },
  {
    date: '6 May',
    volunteers: 1900,
    companies: 1000,
    impact_organizations: 400,
  },
  {
    date: '7 May',
    volunteers: 2500,
    companies: 959,
    impact_organizations: 455,
  },
  {
    date: '8 May',
    volunteers: 2335,
    companies: 500,
    impact_organizations: 611,
  },
  {
    date: '9 May',
    volunteers: 2635,
    companies: 550,
    impact_organizations: 651,
  },
]

export const causesData = [
  {
    name: 'Environmental',
    count: 7,
  },
  {
    name: 'Hunger',
    count: 11,
  },
  {
    name: 'Menstrual Hygiene',
    count: 5,
  },
  {
    name: 'RTE',
    count: 3,
  },
]

export const outreachData = [
  {
    novo: 5,
    novr: 5,
  },
  {
    novo: 8,
    novr: 10,
  },
  {
    novo: 20,
    novr: 4,
  },
  {
    novo: 34,
    novr: 14,
  },
  {
    novo: 48,
    novr: 18,
  },
  {
    novo: 62,
    novr: 10,
  },
  {
    novo: 71,
    novr: 23,
  },
]

export const locationData = [
  {
    name: 'Bangalore',
    count: 7,
  },
  {
    name: 'Kochi',
    count: 11,
  },
  {
    name: 'Chennai',
    count: 5,
  },
  {
    name: 'Pune',
    count: 7,
  },
]
