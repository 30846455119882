import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export default function cog_pool(type) {
    if (type === undefined) {
        // throw Error('type is missing')
        console.log('pass type to cog_pool function'); //! remove in production
        return;
    }
    console.log('type', type)
    const IdList = {
        'volunteer': `${process.env.REACT_APP_AWS_COGNITO_VOL_CLIENT_ID}`,
        'organization': `${process.env.REACT_APP_AWS_COGNITO_ORG_CLIENT_ID}`,
        'corporate': `${process.env.REACT_APP_AWS_COGNITO_CORP_CLIENT_ID}`,
        'admin': `${process.env.REACT_APP_AWS_COGNITO_ADMIN_CLIENT_ID}`
    }
    console.log()
    const clientid = IdList[type]
    console.log('client id', clientid)
    const poolData = {
        UserPoolId: `${process.env.REACT_APP_AWS_POOL_ID}`,
        ClientId: clientid
    }

    return new CognitoUserPool(poolData);
}