import React, { useState, useRef } from "react";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import { resend_code, verify_email } from "../../aws_cognito/verify";
import { useMediaQuery } from "react-responsive";
import logo from "../../images/Logo.png";
import verify from "../../images/Vector.png";
import { create_user } from "../../api";
import login from "../../aws_cognito/login";
import { ConsoleLogger } from "@aws-amplify/core";

export default function Verify({ type }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [verifyState, setVerifyState] = useState(false);
  const { state } = useLocation();
  let [first, setFirst] = useState();
  let [second, setSecond] = useState();
  let [third, setThird] = useState();
  let [fourth, setFourth] = useState();
  let [fifth, setFifth] = useState();
  let [sixth, setSixth] = useState();
  const [otp, setOtp] = useState();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const num1 = useRef();

  const num2 = useRef();

  const num3 = useRef();

  const num4 = useRef();

  const num5 = useRef();

  const num6 = useRef();

  const handleOtp1Change = (value) => {
    setFirst(value);
  };

  const handleOtp2Change = (value) => {
    setSecond(value);
  };

  const handleOtp3Change = (value) => {
    setThird(value);
  };

  const handleOtp4Change = (value) => {
    setFourth(value);
  };

  const handleOtp5Change = (value) => {
    setFifth(value);
  };

  const handleOtp6Change = (value) => {
    sixth = value
    setSixth(value);

    if (value != '') {
      handleSubmit()
    }
  };

  const handleSubmit = async () => {
    console.log(verifyState, "verifyState");
    setLoading(true);
    const num = first + second + third + fourth + fifth + sixth;
    setOtp(num);
    console.log(num, "otp");
    try {
      if (verifyState) {
        await login(state.email, state.pw, type);
        setLoading(false);

        localStorage.setItem("userType", type);
        navigate(`/${type}/profileSetup`, {
          replace: true,
          state: { email: state.email },
        });
        console.log(first, second, third, fourth, fifth, sixth, "otp");
        console.log(otp, "otp num");
      } else {
        let res;

        res = await verify_email(num, state.email, type);
        setLoading(false);

        console.log(res, "res");
        if (res.error === null) {
          verifyState=true
          setVerifyState(true);
          setLoading(false);
          handleSubmit()
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

      alert(error.message);
    }
  };

  const onPaste = (e) => {
    e.preventDefault()
    const otp = e.clipboardData.getData('text/plain')

    const setStates = [setFirst, setSecond, setThird, setFourth, setFifth, setSixth]

    for (let i = 0; i < Math.min(otp.length, 6); ++i) {
      setStates[i](otp[i])
    }

    num6.current.focus()
  }

  const handleContinue = () => {
    navigate("/profileSetup");
  };
  // const handleVerify = () => {
  //   console.log(first, second, third, fourth);
  //   setVerifyState(true);
  //   console.log(otp);
  // };
  const inputfocus = (elmnt) => {
    if (elmnt.key === 'Control') return 

    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      console.log(elmnt.target.tabIndex);
      const next = elmnt.target.tabIndex;
      if (next === 6) {
        num5.current.focus();
      } else if (next === 5) {
        num4.current.focus();
      } else if (next === 4) {
        num3.current.focus();
      } else if (next === 3) {
        num2.current.focus();
      } else if (next === 2) {
        num1.current.focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next === 1) {
        num2.current.focus();
      } else if (next === 2) {
        num3.current.focus();
      } else if (next === 3) {
        num4.current.focus();
      } else if (next === 4) {
        num5.current.focus();
      } else if (next === 5) {
        num6.current.focus();
      }
    }
  };
  return (
    <>
      <div
        className={
          isMobile
            ? "w-[80%] flex flex-col z-10 h-screen bg-white p-8 overflow-y-scroll"
            : "w-[47%] flex flex-col z-10 h-screen bg-white p-8 overflow-y-scroll"
        }
      >
        {/* {isMobile ? (
          <div className="flex flex-row justify-center items-center -mt-14 bg-white rouded-full">
            <img src={logo} alt="logo" className="w-36" />
          </div>
        ) : (
          <></>
        )} */}
        <div className="flex flex-start p-4 underline decoration-blue underline-offset-5 items-start">
          <a
            href="/"
            className={`text-blue ${
              isMobile ? "-mx-12 -mt-8" : "p-4"
            } font-bold underline underline-offset-5 w-max cursor-pointer`}
          >
            {"<"} Back
          </a>
        </div>
        {isMobile ? (
          <></>
        ) : (
          <div className="flex flex-row justify-center -mt-10">
            <img src={logo} alt="logo" className="w-36" />
          </div>
        )}

        <div className="flex flex-col justify-center mt-8 text-3xl font-inter font-bold">
          <h1 className="flex justify-center">
            Verify E-mail<span className="text-blue">.</span>
          </h1>
        </div>
        <p className="text-center text-md font-inter mt-6">
          We’ve sent an code to <strong>&nbsp;{state?.email}</strong>
        </p>
        <div className="flex flex-col justify-center py-20">
          {verifyState ? (
            <div className="flex flex-row justify-center">
              <img
                style={{ width: "30px", height: "30px" }}
                src={verify}
                alt="verify"
              />{" "}
              <p className="text-green ml-2"> Verified!</p>
            </div>
          ) : (
            <h4 className="flex justify-center">Enter Code</h4>
          )}
          <div className="flex flex-row justify-center mt-6">
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition"
              maxLength={1}
              required
              ref={num1}
              value={first}
              tabIndex="1"
              onChange={(e) => handleOtp1Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
              maxLength={1}
              required
              ref={num2}
              value={second}
              tabIndex="2"
              onChange={(e) => handleOtp2Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
              maxLength={1}
              required
              ref={num3}
              value={third}
              tabIndex="3"
              onChange={(e) => handleOtp3Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
              maxLength={1}
              required
              ref={num4}
              value={fourth}
              tabIndex="4"
              onChange={(e) => handleOtp4Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
              maxLength={1}
              required
              ref={num5}
              value={fifth}
              tabIndex="5"
              onChange={(e) => handleOtp5Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              onPaste={onPaste}
              type="text"
              className="w-10 h-12 border-2 rounded bg-transparent outline-blue text-center font-semibold text-xl spin-button-none border-blue focus:border-blue-700 focus:text-blue-700 text-black-400 transition ml-4"
              maxLength={1}
              required
              ref={num6}
              value={sixth}
              tabIndex="6"
              onChange={(e) => handleOtp6Change(e.target.value)}
              onKeyUp={(e) => inputfocus(e)}
            />
          </div>
        </div>
        <div className="flex justify-center -mt-8">
          <>
            {loading ? (
              <button
                disabled
                type="button"
                className="w-28 h-10 bg-sky-300  text-white font-semibold text-sm rounded-md"
              >
                <svg
                  role="status"
                  class="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Verifying...
              </button>
            ) : (
              <>
                { !verifyState &&
                    <button
                      className=" w-28 h-10 bg-blue  text-white font-semibold text-sm rounded-md"
                      onClick={handleSubmit}
                    >
                      {"Verify"}
                    </button>
                }
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}
