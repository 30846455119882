import React, { useRef, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import categories from '../../../utils/categories'
import { FocusContext } from '../Focusable/Focusable'
import { createGeneralPost } from '../../../redux/slices/createPostSlice'
import { ClipLoader } from 'react-spinners'
import { pushPost } from '../../../redux/slices/postsSlice'
import CommunityWallContext from '../CommunityWallContext'
import defaultAvatar from '../../../images/default.jpg'
import BloodRequirement from '../BloodRequirement/BloodRequirement'
import CreateOppNonCorpModal from '../../../pages/Volunteers/Opportunities/CreateOppNonCorpModal'
import OpportunityModal from '../../../pages/Volunteers/Opportunities/OppportunityModal'
import './CreatePost.css'

export default function CreatePost({typeUser }) {
    const { loading, userProfile } = useSelector(state => state.currentProfile)
    const profilePic = userProfile?.data?.profilePic
    const createPostState = useSelector((state) => state.createPost)
    const focusContext = useContext(FocusContext)
    const { categoriesRef } = useContext(CommunityWallContext)
    const dispatch = useDispatch()
    const submitting = useRef(false)
    const type = userProfile?.data?.type
    const [bloodRequirement, setBloodRequirement] = useState(0);
    const [nonCorpModalOpen, setNonCorpModalOpen] = useState(false);
    const [genModalOpen, setGenModalOpen] = useState(false);
    const [genModalValue, setGenModalValue] = useState("");

    const [postData, setPostData] = useState({ 
        content: '',
        categories: [],
        files: []
    })

    const handleBloodRequirement = ()=>{
        setBloodRequirement(!bloodRequirement)
    }
    const handleVolunteerOpp = () =>
    {
        if (typeUser === "corporate") {
            setGenModalOpen(true);
        } else {
            setNonCorpModalOpen(true);
        }
    }
    const onSubmit = () => {
        if (submitting.current)
            return

        submitting.current = true

        const formData = new FormData()
        formData.set('content', postData.content.trim())
        formData.set('categories', JSON.stringify(postData.categories))
        
        for (const file of postData.files) {
            formData.append('pictures', file)
        }

        dispatch(createGeneralPost({ type, formData }))
    }

    useEffect(() => {
        if (createPostState.status == 'uploaded') {
            const lastUploaded = createPostState.lastUploaded
            const intersection = lastUploaded.categories.filter(x => categoriesRef.current.includes(x))

            if (!categoriesRef.current.length || intersection.length) {
                dispatch(pushPost(lastUploaded))
            }

            setPostData({ content: '', categories: [], files: [] })
            focusContext.defocusElement()
            
            submitting.current = false
        }
    }, [createPostState.status])

    return (
        <>
            <div id='create-post' className={`p-5 pb-0 md:pr-8 flex flex-col items-center ${focusContext.focus ? 'focus' : ''}`}>
                { focusContext.focus &&
                    <span className='inline-block h-3 w-[12px] m-1 mb-2 cursor-pointer text-[0.7rem] self-end absolute right-3 top-4' onClick={focusContext.defocusElement}>
                        <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-45'></span>
                        <span className='inline-block translate-y-1 h-[1px] w-full bg-gray-800 absolute rotate-[-45deg]'></span>
                    </span>
                }

                <span className={`inline-block font-semibold text-l self-start my-2 text-[#222]  ${focusContext.focus ? 'mt-4' : ''}`}>Create new post</span>

                <div className='flex flex-col w-full mt-4'>
                    <div className="flex">
                        <img className='rounded-full w-10 h-10 mr-4' src={(loading || !profilePic || profilePic.trim() == '') ? defaultAvatar : profilePic} />

                        <div className='flex flex-col w-full'>
                            <textarea rows={1}
                                maxLength={2000} 
                                className='scrollable bg-[#F4F4F4] rounded-md px-3 xs:px-4 py-[0.7rem] text-[0.7rem] xs:text-[0.8rem] w-full outline-none' 
                                placeholder='Write something User...'
                                onChange={(e) => setPostData({ ...postData, content: e.target.value })}
                                onFocus={focusContext.focusElement}
                                value={postData.content} />    

                        </div>
                    </div>

                    <div className={`${focusContext.focus ? 'buttons-collapse' : ''} buttons flex w-full mt-3 xs:ml-10`}>
                        <button className='flex items-center rounded-md hover:bg-[#f2f2f2] p-2' onClick={handleBloodRequirement}>
                            <svg width="15" height="20" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.90004 1.95017C8.025 2.83251 6.5477 4.38544 5.16286 6.29305L8.90004 1.95017ZM8.90004 1.95017C9.74672 2.80609 11.2037 4.34409 12.5817 6.23766L12.5818 6.23785C14.4144 8.75527 16.0002 11.7564 16.0002 14.55C16.0002 17.4545 15.1442 19.3871 13.8987 20.5958L13.8983 20.5962C12.646 21.8123 10.892 22.4 8.9002 22.4C6.90816 22.4 5.15441 21.8119 3.90187 20.596L3.9017 20.5958C2.65675 19.3876 1.8002 17.455 1.8002 14.55C1.8002 11.754 3.35165 8.78846 5.16279 6.29315L8.90004 1.95017ZM8.90143 0.753309L8.90279 0.756598L8.90143 0.753309ZM8.90143 0.753309L8.9002 0.750338L8.90143 0.753309Z" fill="white" stroke="#E46666" strokeWidth="3.2"/>
                            </svg>

                            <span className='text-[0.7rem] sm:text-[0.78rem] font-semibold ml-2 text-[#444]'>
                                Blood Requirement
                            </span>
                        </button>
                        <button className='flex items-center rounded-md hover:bg-[#f2f2f2] p-3' onClick={handleVolunteerOpp}>
                            <svg width="21" height="19" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.25 2.25H22.5V0H20V2.25H10V0H7.5V2.25H3.75C2.75544 2.25 1.80161 2.60558 1.09835 3.23851C0.395088 3.87145 0 4.72989 0 5.625L0 27H30V5.625C30 4.72989 29.6049 3.87145 28.9017 3.23851C28.1984 2.60558 27.2446 2.25 26.25 2.25ZM2.5 5.625C2.5 5.32663 2.6317 5.04048 2.86612 4.82951C3.10054 4.61853 3.41848 4.5 3.75 4.5H26.25C26.5815 4.5 26.8995 4.61853 27.1339 4.82951C27.3683 5.04048 27.5 5.32663 27.5 5.625V9H2.5V5.625ZM2.5 24.75V11.25H27.5V24.75H2.5Z" fill="#D2CC27"/>
                                <path d="M21.25 14.625H18.75V16.875H21.25V14.625Z" fill="#D2CC27"/>
                                <path d="M16.25 14.625H13.75V16.875H16.25V14.625Z" fill="#D2CC27"/>
                                <path d="M11.25 14.625H8.75V16.875H11.25V14.625Z" fill="#D2CC27"/>
                                <path d="M21.25 19.125H18.75V21.375H21.25V19.125Z" fill="#D2CC27"/>
                                <path d="M16.25 19.125H13.75V21.375H16.25V19.125Z" fill="#D2CC27"/>
                                <path d="M11.25 19.125H8.75V21.375H11.25V19.125Z" fill="#D2CC27"/>
                            </svg>
                            <span className='text-[0.7rem] sm:text-[0.78rem] font-semibold ml-2 text-[#444]'>
                                Volunteering Opportunity
                            </span>
                        </button>
                    </div>
                </div>
                
                <div id='post-details' className={`${focusContext.focus ? 'mt-5' : 'mt-3'} overflow-hidden max-h-0 ${(postData.files.length > 0 ? 'with-file' : '')}`}>
                    <span className='self-start text-[0.8rem] font-light'>Select a category for your post</span>

                    <div className='scrollable mt-5 h-[80px] overflow-y-auto'>
                        {categories.map((x, i) => (
                            <span key={i} 
                                className={`category ${postData.categories.includes(x) ? 'active' : ''} inline-block rounded-full py-2 px-3 mr-3 mb-4 text-[0.65rem] cursor-pointer`}
                                onClick={(e) => {
                                    const category = e.target.innerHTML

                                    if (postData.categories.includes(category)) 
                                        setPostData({ ...postData, categories: postData.categories.filter(x => x != category) })

                                    else setPostData({ ...postData, categories: [...postData.categories, category] })
                                }}>
                                {x}
                            </span>
                        ))}
                    </div>

                    <div className='flex w-full items-end mt-12 mb-2'>
                        <input id='file-input' 
                            multiple
                            type="file" 
                            accept='.png,.jpg,.jpeg' 
                            className='hidden pointer-events-none' 
                            onInput={(e) => setPostData({ ...postData, files: Array.from(e.target.files).slice(0, process.env.REACT_APP_MAX_POST_IMAGES) })} />

                        <label htmlFor='file-input' className='text-[0.8rem] text-semibold p-2 rounded-md hover:bg-gray-200 self-end cursor-pointer'>
                            Attach a File
                        </label>

                        <button id='post-upload-button' disabled={postData.content.trim() == '' || createPostState.status == 'uploading'} className={`flex items-center justify-center ml-auto md:w-[8rem] w-[5rem] py-2 md:py-3 bg-[#22A5FF] text-white rounded-md font-semibold md:text-sm text-[0.8rem]`} onClick={onSubmit}>
                            { createPostState.status == 'uploading' &&
                                <ClipLoader size={15} color='#eee' />
                            }

                            <span className={`${createPostState.status == 'uploading' ? 'ml-2' : ''}`}>
                                { createPostState.status == 'uploading' ? 'posting' : 'post' }
                            </span>
                        </button>
                    </div>
                
                    { postData.files.length > 0 &&
                        <div className='overflow-x-scroll max-w-full flex w-full mt-5 pb-1'>
                            {postData.files.map((x, i) => {
                                const url = URL.createObjectURL(x)

                                return (
                                    <img className='w-[80px] h-[80px] mx-1 object-cover rounded' key={url} src={url} />
                                )
                            })}
                        </div>
                    }
                </div>
            </div>

            { bloodRequirement == 1 &&
                <div className='blood-req-popup'>
                    <BloodRequirement  bloodRequirement={bloodRequirement}  setBloodReq={setBloodRequirement}  data={0} edit={false}/>
                </div>
            }
            {nonCorpModalOpen && 
                    <CreateOppNonCorpModal setNonCorpModalOpen={setNonCorpModalOpen} setGenModalOpen={setGenModalOpen} setGenModalValue={setGenModalValue} />
                }

            { genModalOpen &&
                <OpportunityModal typeUser={typeUser} setOpportunityModalOpen={setGenModalOpen} opportunityType={genModalValue} />
            }
        </>
    )
}