import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import get_current_user from "../../aws_cognito/get_user";
import axios from "axios";

export default function ReqCredit({ setModalIsOpen, orgId }) {
  const [inputList, setInputList] = useState([{ cause: "", hoursClaimed: "" }]);
  const [description, setDescription] = useState("");
  const [descLength, setDescLength] = useState(0);
  const [orgData, setOrgData] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false)

  const clickHandler = (e) => {
    setCertificate(e.target.files[0]);
    const [file] = e.target.files;
    if (file) {
      setImg(URL.createObjectURL(file));
    }
  };

  const resetCertificate = () => {
    setCertificate(null);
    setImg(null);
  };

  useEffect(() => {
    const fetchAndSetOrgData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/impactOrg/${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${
              (
                await get_current_user("volunteer")
              ).data.idToken
            }`,
          },
        }
      );
      setOrgData(res.data?.data);
    };
    fetchAndSetOrgData();
  }, [orgId]);

  const handleInputChange = (index, e) => {
    const list = [...inputList];
    list[index].cause = e.target.value;
    setInputList(list);
  };
  const handleInputChange2 = (index, e) => {
    const list = [...inputList];
    list[index].hoursClaimed = e.target.value;
    setInputList(list);
  };

  const handleRemoveClick = (index, e) => {
    const list = [...inputList];
    list.splice(index, 1);
    console.log(list)
    setInputList(list);
  };

  const handleAddClick = (index, e) => {
    setInputList([...inputList, { cause: "", hoursClaimed: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // console.log(inputList, "data");
    // console.log(description, "description");
    try {
      console.log("clicked");
      const user = await get_current_user("volunteer");
      console.log(user, "userCheck");
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/credit-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.data.idToken}`,
          },
          body: JSON.stringify({
            impactOrgId: orgId,
            causes: inputList,
            description: description,
          }),
        }
      );
      const response = await data.json();
      if (response.status === "success") {
        if (certificate) {
          const formData = new FormData();
          formData.append("picture", certificate);
          const res2 = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/credit-request/image/${response.data._id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${user.data.idToken}`,
              },
            }
          );
        }
        setModalIsOpen(false);
        setInputList([{ cause: "", hoursClaimed: "" }]);
        setDescription("");
        setModalIsOpen(false);
        window.alert("Request sent successfully");
        window.location.reload(false);
      } else {
        alert(response.data);
      }
    } catch (err) {
      console.log(err.message);
      window.alert("Something went wrong! Please try again.");
    }

    finally {
      setLoading(false)
    }
  };

  return (
    <div className="">
      <div className="flex justify-between w-full">
        <p className="font-bold">Request for credit</p>
        <div>
          <button
            onClick={() => setModalIsOpen(false)}
            className=" flex bg-transparent border-0 text-[#837B7B]  text-xl items-center justify-center"
          >
            <span className="bg-transparent text-[#837B7B] h-4 w-8">x</span>
          </button>
        </div>
      </div>
      <p className="py-4">What cause did you work for?</p>
      <form onSubmit={handleSubmit}>
        {inputList.map((x, i) => (
          <div
            className={`flex w-full flex-col sm:flex-row justify-between sm:first:items-center mt-4 `}
          >
            <div className="relative z-0 flex items-center sm:w-1/2">
              <p className="flex items-center pr-2">{i + 1}.</p>
              <select
                type="text"
                id="cause"
                className="py-2 px-3 bg-gray-100 w-[15rem] sm:w-[25rem] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=" "
                onChange={(e) => handleInputChange(i, e)}
                value={inputList[i].cause}
                required
              >
                <option value="" selected={true} disabled={true}>
                  -- select an option --{" "}
                </option>
                {orgData?.causes?.map((data, index) => {
                  return (
                    <option key={data.label} value={data.label}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="relative z-0 flex items-center sm:w-1/2 justify-evenly m-1 sm:m-0">
              <label>hours volunteered</label>
              <input
                type="number"
                className="py-2 px-3 bg-gray-100 w-[3rem] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                onChange={(e) => handleInputChange2(i, e)}
                onKeyDown={(e) => {
                  if (e.key == '-' || e.key == '.' || e.key == 'e') {
                    e.preventDefault()
                  }
                }}
                required
                value={inputList[i].hoursClaimed}
              ></input>
              {i === 0 ? (
              <AiOutlineDelete className={`cursor-pointer text-xl ${inputList.length == 1 ? 'text-[#efa3a3]' : 'text-[#FF4C4C]'}`} onClick={inputList.length == 1 ? null : (e) => handleRemoveClick(i, e)} />
            ) : (
              <AiOutlineDelete
                className="cursor-pointer text-xl text-[#FF4C4C]"
                onClick={(e) => handleRemoveClick(i, e)}
              />
            )}
            </div>
            
          </div>
        ))}
        <div className="flex items-center justify-center sm:justify-end py-4">
          <button onClick={handleAddClick} className="flex gap-2">
            <AiOutlinePlus className="text-xl text-blue" />
            <p className="text-blue">Add another</p>
          </button>
        </div>
        <label className="pt-2 pb-2">Description</label>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="relative z-0 flex w-full py-8">
            <textarea
              type="text"
              id="description"
              className="pt-2 pb-8 px-3 bg-gray-100 w-[100%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
              placeholder=" "
              required
              maxLength={2000}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescLength(e.target.value.length);
                console.log(description);
              }}
            />
            <div className="absolute bottom-1 right-1 text-[#141414] text-sm bg-white px-2 py-1 rounded-full shadow-xl">
              <p className="text-[#a8a4a4] text-sm">{descLength}/2000</p>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between mt-4">
          <p className="text-sm font-bold">Attach proof:</p>
          {/* <label htmlFor="file"> */}
          {!img ? (
            <label
              htmlFor="file"
              className="w-[60%] bg-[#cceaff] text-[#22A5FF] h-[2.5rem] ml-4 rounded-lg flex items-center justify-center cursor-pointer"
            >
              <p>Upload</p>
              <input
                id="file"
                type="file"
                name="file"
                accept=".pdf,.jpg,.jpeg,.png"
                className="hidden w-full"
                onChange={clickHandler}
              />
            </label>
          ) : (
            <div className="w-[60%] ml-auto relative">
              <img src={img} alt="img" className="ml-auto rounded-md w-52" />
              <button
                className="absolute text-black right-2 top-1"
                onClick={resetCertificate}
              >
                X
              </button>
            </div>
          )}
          {/* </label> */}
        </div>

        <div className="flex items-center justify-end py-4">
          <button
            disabled={loading}
            className={`${loading ? 'opacity-60' : 'cursor-pointer'} flex items-center justify-center px-8 py-3 font-bold text-white rounded-lg bg-blue hover:bg-blue-500`}
            type="submit"
          >
            { loading &&
              <svg
              role="status"
              class="inline mr-1 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            }

            Send request
          </button>
        </div>
      </form>
    </div>
  );
}