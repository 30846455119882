import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import get_current_user from "../../aws_cognito/get_user";
import { useSelector } from "react-redux";
import pdfImg from "../../images/pdf.jpg";
import avatar from "../../pages/Admin/dummyData/images/placeholder-avatar.jpg";

export default function EditCreditAccept({ setModalIsOpen, request }) {
    const [rating, setRating] = useState(request.qualityOfWork);
    const [feedback, setFeedback] = useState(request.feedback);
    const [feedbackLength, setFeedbackLength] = useState(request.feedback.length);
    const volunteerName=useSelector((state) => state.profileForm.name)
    const [causesData, setCausesData] = useState(request?.causes);
    const [certificate, setCertificate] = useState(null);
    const [img, setImg] = useState(null);
    const [bLoading,setBLoading]=useState(false);
    const clickHandler = (e) => {
        setCertificate(e.target.files[0]);
        const [file] = e.target.files;
        if (file) {
        setImg(URL.createObjectURL(file));
        }
    };

    const resetCertificate = () => {
        setCertificate(null);
        setImg(null);
    };

    const handleCauseHoursChange = (e, causeId) => {
        setCausesData(
        causesData.map((cause) => {
            if (cause._id === causeId) {
            return {
                ...cause,
                hoursWorked: e.target.value,
            };
            }
            return cause;
        })
        );
    };

    async function updateCreditRequest(e, creditReqId) {
        e.preventDefault();
        setBLoading(true);
        try {
        const NGOData = await get_current_user("organization");

        if (certificate) {
            const formData = new FormData();
            formData.append("file", certificate);
            const res2 = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/credit-request/certificate/${creditReqId}`,
            formData,
            {
                headers: {
                Authorization: `Bearer ${NGOData.data.idToken}`,
                },
            }
            );
        }

        const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/credit-request/edit/${creditReqId}`,
            { 
                qualityOfWork: rating,
                status: "Approved",
                feedback,
                causes: causesData.map((cause) => {
                    return {
                    _id: cause._id,
                    hoursWorked: cause.hoursWorked,
                    cause: cause.cause,
                    };
                }),
            },
            {
            headers: {
                Authorization: `Bearer ${NGOData.data.idToken}`,
            },
            }
        );
        
        window.alert("Edited Credit Grant Successfully");
    setBLoading(false);
        window.location.reload(false);
        } catch (err) {
        setBLoading(false);
        window.alert("Error approving request");
        console.log(err);
        }
    }

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRating(newRating ? newRating : 1);
    };

    return (
        <div className="h-screen md:max-h-[40rem] md:min-w-[60rem] overflow-hidden overflow-y-auto">
        <div className="flex justify-between p-4">
            <p className="font-bold">Edit Credit Request</p>
            <div>
            <button
                onClick={() => setModalIsOpen(false)}
                className="  flex bg-transparent border-0 text-[#837B7B]  text-xl items-center justify-center"
            >
                <span className="bg-transparent text-[#837B7B] h-4 w-8">x</span>
            </button>
            </div>
        </div>
{/* flex flex-col md:flex-row w-[100%] px-4 justify-between */}
            <div className="flex flex-col px-4">
                <div className="flex flex-row items-center">
                    <img
                    src={request?.volunteerProfilePic || avatar}
                    alt="face"
                    className={`rounded-full w-[3rem] h-[3rem] z-10`}
                    />
                    <p className="ml-4 font-bold">
                    {volunteerName || "No Name"}
                    </p>
                </div>
        {/* <div className="flex flex-col max-w-[30rem] flex-1">
            
            <div className="flex flex-row">
                <div className="flex flex-row justify-between w-[100%] mt-4">
                <div className="flex flex-col">
                    <p className="font-bold">Cause</p>
                    {request?.causes?.map((item) => (
                    <p className="mt-2 text-[#7B7979]">{item.cause}</p>
                    ))}
                </div>
                <div className="flex flex-col w-[60%] ">
                    <p className="font-bold"> Hours claimed</p>
                    {request?.causes?.map((item) => (
                    <p className="mt-2 text-[#7B7979]">{item.hoursClaimed}</p>
                    ))}
                </div>
                </div>
            </div>
            <p className="pr-8 my-8 md:mt-12 text-[#7B7979] text-md">
                {request?.description}
            </p>
            <div className="my-4">
                <p className="mb-2 font-bold">Proof</p>
                { !request?.proofImage &&
                <p>no proof attached</p>
                }

                { request?.proofImage && 
                <img
                    className="rounded w-80"
                    src={request?.proofImage}
                    alt="Proof"
                />
                }
            </div>
        </div> */}

            <form
            onSubmit={(e) => updateCreditRequest(e, request._id)}
            className="flex flex-col flex-1"
            >
            <div className="flex flex-row gap-12 my-4">
                <div className="flex flex-col ">
                <p className="font-bold">Cause</p>
                {request?.causes?.map((item) => (
                    <p className="mt-2">{item.cause}</p>
                ))}
                </div>

                <div className="flex flex-col">
                <p className="font-bold"> Hours worked</p>
                {causesData.map((item, idx) => (
                    <input
                    required
                    type="number"
                    onChange={(e) => handleCauseHoursChange(e, item._id)}
                    className="py-2 px-3 bg-gray-100 w-[3.5rem] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white mt-2"
                    value={item.hoursWorked}
                    />
                ))}
                </div>
            </div>
            <div className="flex items-center gap-12 my-4">
                <p className="mr-8 text-sm font-bold">Quality of work:</p>
                <div>
                <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    value={rating}
                    size={30}
                    activeColor="#FFCE31"
                />
                </div>
            </div>
            <div className="relative flex flex-row justify-between pb-5 mt-4">
                <label className="text-sm font-bold">Feedback:</label>
                <textarea
                type="text"
                id="bio"
                required
                maxLength={2000}
                className="h-28 ml-8 py-2 px-3 bg-gray-100 w-[60%] text-sm text-[#141414]  border-2 border-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#22A5FF] peer focus:bg-white"
                placeholder=""
                value={feedback}
                onChange={(e) => {
                    setFeedback(e.target.value);
                    setFeedbackLength(e.target.value.length);
                }}
                ></textarea>
                <p className="absolute bottom-0 text-sm text-gray-600 right-2">
                {feedbackLength}/2000
                </p>
            </div>

            <div className="flex flex-row justify-between mt-4">
                <p className="text-sm font-bold">Attach certificate:</p>
                        {/* <label htmlFor="file"> */}
                        
                {!img ? (
                <label
                    htmlFor="file"
                    className="w-[60%] bg-[#cceaff] text-[#22A5FF] h-[2.5rem] ml-4 rounded-lg flex items-center justify-center cursor-pointer"
                >
                    <p>Upload</p>
                    <input
                    id="file"
                    type="file"
                    name="file"
                    accept="application/pdf"
                    className="hidden w-full"
                    onChange={clickHandler}
                    />
                </label>
                ) : (
                <div className="w-[60%] ml-auto relative">
                    <img
                    src={pdfImg}
                    alt="img"
                    className="w-1/2 mx-auto rounded-md h-36"
                    />
                    <button
                    className="absolute text-black right-2 top-1"
                    onClick={resetCertificate}
                    >
                    X
                    </button>
                </div>
                )}
                {/* </label> */}
                    </div>
                    
            <div className="flex justify-end mt-8">
                {bLoading===true ?
                (<button disabled className='bg-blue hover:bg-blue-500 text-white font-bold py-3 px-4 rounded-lg cursor-pointer w-[40%] text-md'>
                    <svg
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                    />
                    </svg>
                    Editing Grant
                </button>) : (
                    <button
                type="submit"
                className="bg-blue hover:bg-blue-500 text-white font-bold py-3 px-4 rounded-lg cursor-pointer w-[40%] text-md "
                >
                Grant Credit
                </button>
                        )}
                
            </div>
            </form>
        </div>
        </div>
    );
}
