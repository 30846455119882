import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import Verify from "./pages/Volunteers/Verify/Verify";
import Landing from "./pages/Landing/Landing";
import Signup from "./pages/Volunteers/Signup/Signup";
import CorporateSignup from "./pages/Corporate/Signup/Signup";
import CommunityWall from "./pages/Volunteers/CommunityWall/CommunityWall"
import Opportunities from "./pages/Volunteers/Opportunities/Opportunities"
import Chat from "./pages/Chat/Chat";
import ViewPost from "./pages/Posts/ViewPost"
import ProfileSetup from "./pages/Volunteers/ProfileSetup/ProfileSetup";
import ExtendedProfileSetup from "./pages/Volunteers/ProfileSetup/ExtendedProfileSetup";
import Preferences from "./pages/Volunteers/Preferences/Preferences";
import Thankyou from "./pages/Volunteers/ThankYou/Thankyou";
import Login from "./pages/Volunteers/Login/Login";
import ForgotPass from "./pages/Volunteers/ForgotPass/ForgotPass";
import LeaderboardVolunteer from "./pages/Volunteers/Leaderboard/Leaderboard"; 
import ResetPass from "./pages/Volunteers/ResetPass/ResetPass";
import get_current_user from "./aws_cognito/get_user";
import Profile from "./pages/Volunteers/Profile/Profile";
import CorporateProfileSetup from "./pages/Corporate/ProfileSetup/ProfileSetup";
import CorporateLogin from "./pages/Corporate/Login/Login";
import VerifyCorp from "./pages/Corporate/Verify/Verify";
import OrganisationProfileSetup from "./pages/Organisations/ProfileSetup/ProfileSetup";
import logout from "./aws_cognito/logout";
import { confirmPassword, forgotPassword } from "./aws_cognito/forgot_password";

import AdminLogin from "./pages/Admin/Login/Login";
import AdminForgot from "./pages/Admin/ForgotPass/Forgot";
import AdminReset from "./pages/Admin/Reset/Reset";
import Analytics from "./pages/Admin/Analytics/Analytics";
import Admin from "./pages/Admin/Admin";
import AdminSearch from "./pages/Admin/components/Search/Search";
import LeaderBoard from "./pages/Admin/Leaderboard/LeaderBoard";
import VolunteerProfile from "./pages/Admin/Profiles/VolunteerProfile";

import ThankYou from "./pages/Corporate/ThankYou/ThankYou";
import Forgot from "./pages/Corporate/ForgotPass/Forgot";
import Reset from "./pages/Corporate/Reset/Reset";
import OrganizationSignup from "./pages/Organisations/Signup/Signup";
import OrgThankYou from "./pages/Organisations/ThankYou/ThankYou";
import VerifyOrg from "./pages/Organisations/Verify/Verify";
import OrgForgot from "./pages/Organisations/ForgotPass/Forgot";
import OrgReset from "./pages/Organisations/Reset/Reset";
import OrgLogin from "./pages/Organisations/Login/Login";
import { get_token } from "./aws_cognito/get_token";
import Test from "./Test";
import ProfileMultiStep from "./components/ProfileSetup/ProfileMultiStep";
import Sidebar from "./components/Sidebar/Sidebar";
import CorporateProfile from "./pages/Corporate/Profile/Profile";
import OrgProfile from "./pages/Organisations/Profile/Profile";
import Search from "./pages/Search/Search";
import ReqCredit from "./components/ReqCredit/ReqCredit";

import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import CreditAccept from "./components/CreditAccept/CreditAccept";
import Settings from "./pages/Settings/Settings";
import LeaderboardCorporate from "./pages/Corporate/Leaderboard/LeaderboardCorporate";
import LeaderboardOrg from "./pages/Organisations/Leaderboard/LeaderboardOrg";
import Notifications from './components/Notifications/Notifications'

import { useDispatch, useSelector } from "react-redux";
import { setUserStatuses, changeUserStatus, addMessage, updateLastSeen, chatSocketAction, updateUserTyping, addAdminMessage } from "./redux/slices/chatSlice";
import { socketEventEnum } from "./utils/enum";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  const { userProfile } = useSelector(state => state.currentProfile)
  const token = userProfile.data?.idToken

  useEffect(() => {
    if (token) {
      dispatch(chatSocketAction(socketEventEnum.CONNECT, (socket) => socket.connect(token)))

      dispatch(chatSocketAction(socketEventEnum.USERS_ONLINE, (socket) => 
        socket.on(socketEventEnum.USERS_ONLINE, (data) => { 
          dispatch(setUserStatuses(data))
        })
      ))

      dispatch(chatSocketAction(socketEventEnum.PRIVATE_MESSAGE_CREATED, (socket) => 
        socket.on(socketEventEnum.PRIVATE_MESSAGE_CREATED, (data) => { 
          dispatch(addMessage(data))
        })
      ))

      dispatch(chatSocketAction(socketEventEnum.USER_STATUS_CHANGE, (socket) => 
        socket.on(socketEventEnum.USER_STATUS_CHANGE, (data) => { 
          dispatch(changeUserStatus(data))
        })
      ))

      dispatch(chatSocketAction(socketEventEnum.OTHER_SAW_MESSAGES, (socket) => 
        socket.on(socketEventEnum.OTHER_SAW_MESSAGES, (data) => { 
          dispatch(updateLastSeen(data))
        })
      ))

      dispatch(chatSocketAction(socketEventEnum.OTHER_TYPING, (socket) => 
        socket.on(socketEventEnum.OTHER_TYPING, (data) => { 
          dispatch(updateUserTyping(data))
        })
      ))

      dispatch(chatSocketAction(socketEventEnum.BROADCAST_MESSAGE_CREATED, (socket) => 
        socket.on(socketEventEnum.BROADCAST_MESSAGE_CREATED, (data) => { 
          dispatch(addAdminMessage(data))
        })
      ))
    }
  }, [token])

  return (
    <div className="App">
      { notifications.show &&
        <Notifications />
      }

      <Router>
        <Routes>
          <Route path="*" element={<PageNotFound/>} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/post/:id" element={<ViewPost />} />

          <Route exact path="/volunteer/signup" element={<Signup />} />
          <Route exact path="/volunteer/verify" element={<Verify />} />
          <Route exact path="/volunteer/login" element={<Login />} />
          <Route exact path="/volunteer/reset" element={<ResetPass />} />
          <Route
            exact
            path="/volunteer/password-reset"
            element={<ForgotPass />}
          />

          <Route exact path="/corporate/login" element={<CorporateLogin />} />
          <Route exact path="/corporate/signup" element={<CorporateSignup />} />
          <Route exact path="/corporate/verify" element={<VerifyCorp />} />
          <Route exact path="/corporate/password-reset" element={<Forgot />} />
          <Route exact path="/corporate/reset" element={<Reset />} />

          <Route
            exact
            path="/organization/signup"
            element={<OrganizationSignup />}
          />
          <Route exact path="/organization/login" element={<OrgLogin />} />
          <Route exact path="/organization/verify" element={<VerifyOrg />} />
          <Route
            exact
            path="/organization/password-reset"
            element={<OrgForgot />}
          />
          <Route exact path="/organization/reset" element={<OrgReset />} />

          {/* Admin auth routes start */}
          <Route exact path="/admin/login" element={<AdminLogin />} />
          <Route exact path="/admin/password-reset" element={<AdminForgot />} />
          <Route exact path="/admin/reset" element={<AdminReset />} />
          {/* Admin auth routes end */}

          {/** Protected routes **/}

          {/* Volunteer protected routes start */}
          <Route element={<ProtectedRoute type={"volunteer"} />}>
            <Route
              exact
              path="/volunteer/profileSetup"
              element={<ProfileSetup />}
            />
            <Route
              exact
              path="/volunteer/search"
              element={<Search typeUser="volunteer" />}
            />
            <Route
              exact
              path="/volunteer/settings"
              element={<Settings typeUser="volunteer" />}
            />
            <Route
              exact
              path="volunteer/leaderboard"
              element={<LeaderboardVolunteer typeUser="volunteer" />}
            />
            <Route
              exact
              path="/volunteer/view-volunteer/:id"
              element={<Profile typeUser="volunteer" me={false} />}
            />
            <Route
              exact
              path="/volunteer/view-corporate/:id"
              element={<CorporateProfile typeUser="volunteer" me={false} />}
            />
            <Route
              exact
              path="/volunteer/view-organization/:id"
              element={<OrgProfile typeUser="volunteer" me={false} />}
            />
            {/* <Route
            exact
            path="/volunteer/extendedProfileSetup"
            element={<ExtendedProfileSetup />}
            /> */}
            <Route
              exact
              path="/volunteer/preferences"
              element={<Preferences />}
            />
            <Route
              exact
              path="/volunteer/profile"
              element={<Profile typeUser="volunteer" me={true} />}
            />
            <Route
              exact
              path="/volunteer/community-wall"
              element={<CommunityWall type="volunteer" />}
            />
            <Route
              exact
              path="/volunteer/chat"
              element={<Chat type="volunteer" />}
            />
            <Route
              exact
              path="/volunteer/opportunities"
              element={<Opportunities type="volunteer" />}
            />

            <Route exact path="/volunteer/thankyou" element={<Thankyou />} />
          </Route>
          {/* Volunteer protected routes end */}

          {/* Corporate protected routes start */}
          <Route element={<ProtectedRoute type={"corporate"} />}>
            <Route
              exact
              path="/corporate/search"
              element={<Search typeUser="corporate" />}
            />
            <Route
              exact
              path="/corporate/view-volunteer/:id"
              element={<Profile typeUser="corporate" me={false} />}
            />
            <Route
              exact
              path="/corporate/settings"
              element={<Settings typeUser="corporate" />}
            />
            <Route
              exact
              path="/corporate/view-corporate/:id"
              element={<CorporateProfile typeUser="corporate" me={false} />}
            />
            <Route
              exact
              path="/corporate/view-organization/:id"
              element={<OrgProfile typeUser="corporate" me={false} />}
            />
            <Route exact path="/sidebar" element={<Sidebar />} />
            <Route exact path="/reqCredit" element={<ReqCredit />} />
            <Route
              exact
              path="/corporate/leaderBoard"
              element={<LeaderboardCorporate />}
            />

            <Route
              exact
              path="/corporate/profileSetup"
              element={<CorporateProfileSetup />}
            />

            <Route exact path="/corporate/thankyou" element={<ThankYou />} />

            <Route
              exact
              path="/corporate/profile"
              element={<CorporateProfile typeUser="corporate" me={true} />}
            />

            <Route
              exact
              path="/corporate/community-wall"
              element={<CommunityWall type="corporate" />}
            />
            <Route
              exact
              path="/corporate/chat"
              element={<Chat type="corporate" />}
            />
            <Route
              exact
              path="/corporate/opportunities"
              element={<Opportunities type="corporate" />}
            />
          </Route>
          
          {/* Corporate protected routes end */}

          {/* Organization protected routes start */}
          <Route element={<ProtectedRoute type={"organization"} />}>
            <Route
              exact
              path="/organization/ProfileSetup"
              element={<OrganisationProfileSetup />}
            />
            <Route
              exact
              path="/organization/search"
              element={<Search typeUser="organization" />}
            />
            <Route
              exact
              path="/organization/settings"
              element={<Settings typeUser="organization" />}
            />
            <Route
              exact
              path="/organization/leaderboard"
              element={<LeaderboardOrg typeUser="organization" />}
            />
            <Route
              exact
              path="/organization/view-volunteer/:id"
              element={<Profile typeUser="organization" me={false} />}
            />
            <Route
              exact
              path="/organization/view-corporate/:id"
              element={<CorporateProfile typeUser="organization" me={false} />}
            />
            <Route
              exact
              path="/organization/view-organization/:id"
              element={<OrgProfile typeUser="organization" me={false} />}
            />

            <Route
              exact
              path="/organization/thankyou"
              element={<OrgThankYou />}
            />

            <Route
              exact
              path="/organization/profile/"
              element={<OrgProfile typeUser="organization" me={true} />}
            />

            <Route
              exact
              path="/organization/community-wall"
              element={<CommunityWall type="organization" />}
            />
            <Route
              exact
              path="/organization/chat"
              element={<Chat type="organization" />}
            />
            <Route
              exact
              path="/organization/opportunities"
              element={<Opportunities type="organization" />}
            />
          </Route>
          
          {/* Organization protected routes end */}

          {/* Admin Protected Routes Start */}
          <Route element={<ProtectedRoute type={"admin"} />}>
            <Route
              exact
              path="/admin/view-volunteer/:id"
              element={<Profile typeUser="admin" />}
            />
            <Route
              exact
              path="/admin/view-corporate/:id"
              element={<CorporateProfile typeUser="admin" />}
            />
            <Route
              exact
              path="/admin/view-organization/:id"
              element={<OrgProfile typeUser="admin" />}
            />

            <Route path="admin" element={<Admin />}>
              <Route
                exact
                path=""
                element={<Navigate to="/admin/analytics" />}
              />
              <Route exact path="analytics" element={<Analytics />} />
              <Route
                exact
                path="search"
                element={<Search typeUser="admin" />}
              />
              <Route exact path="leaderboard" element={<LeaderBoard />} />
              {/* <Route
                exact
                path="view-profile/volunteer/:id"
                element={<VolunteerProfile />}
              /> */}

              <Route
                exact
                path="/admin/community-wall"
                element={<CommunityWall type="admin" />}
              />
              <Route
                exact
                path="/admin/chat"
                element={<Chat type="admin" />}
              />
              <Route
              exact
              path="/admin/opportunities"
              element={<Opportunities type="admin" />}
            />
            </Route>
          </Route>
          {/* Admin Protected Routes End */}

          <Route exact path="/creditAccept" element={<CreditAccept />} />

          <Route exact path="/sidebar" element={<Sidebar />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
