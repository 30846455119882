import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import VolunteeringHours from "./volunteeringHours";

export default function PersonalInfo() {
  const data = useSelector((state) => state.userProfile.userProfile);
  const profile = data.data;
  const [showModal, setShowModal] = useState(false);
  const work = profile?.career ?? [];
  return (
    <>
      {profile.hide===false && <div className="flex flex-col w-full grid-cols-2 gap-10 xl:grid xl:divide-x mt-9 xl:gap-0">
        <VolunteeringHours showModal={showModal} setShowModal={setShowModal} />
        <div className="flex-col w-full space-y-5">
          <div className="space-y-2">
            <p className="font-bold">Bio</p>
            {profile?.interests?.length !== 0 ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Interests</h1>
                <div className="flex flex-wrap gap-3 my-1 text-xs">
                  {profile?.interests?.map((data, index) => {
                    return (
                      <p
                        key={index}
                        className="bg-[#D4EDFE] text-blue font-bold py-2 px-3 rounded-full"
                      >
                        {data.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {profile?.skills.length !== 0 ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Skills</h1>
                <div className="flex flex-wrap gap-3 my-1 text-xs">
                  {profile?.skills?.map((data, index) => {
                    return (
                      <p
                        key={index}
                        className="bg-[#D4EDFE] text-blue font-bold py-2 px-3 rounded-full"
                      >
                        {data.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {profile?.location !== "" ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Primary Location</h1>
                <div className="flex gap-3 text-xs">
                  <p className="bg-[#D4EDFE] text-blue font-bold py-2 px-3 rounded-full">
                    {profile?.location}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="space-y-3">
            <p className="font-bold">Volunteering</p>

            {profile?.motivation !== "" ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Volunteer Motivation</h1>
                <p className="text-[#919191] text-xs">{profile?.motivation}</p>
              </div>
            ) : null}
            {profile?.volunteerMode !== "" ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Mode of volunteering: </h1>
                <span className="text-[#919191] text-xs">
                  {profile?.volunteerMode}
                </span>
              </div>
            ) : null}

            {profile?.availableHrs !== 0 ? (
              <div className="flex flex-wrap items-center gap-2 text-xs md:text-sm">
                <h1 className="font-medium ">
                  No. of volunteering hours per week:{" "}
                  <span className="text-xs font-bold text-blue">
                    {profile?.availableHrs}
                  </span>
                </h1>
                <button
                  onClick={() => setShowModal(true)}
                  className="text-xs font-semibold underline text-blue underline-offset-4 "
                >
                  View detailed
                </button>
              </div>
            ) : null}

            {profile?.volunteeringLocation?.length !== 0 ? (
              <div className="space-y-1 text-xs md:text-sm">
                <h1 className="font-medium">Volunteering Locations</h1>
                <div className="flex flex-wrap gap-3 my-1 text-xs">
                  {profile?.volunteeringLocation?.map((data, index) => {
                    return (
                      <p
                        key={index}
                        className="bg-[#D4EDFE] text-blue font-bold py-2 px-3 rounded-full"
                      >
                        {data.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col pb-5 md:mt-0 space-y-9 xl:pl-5 ">
          <div className="space-y-4">
            <div className="text-xs md:text-sm ">
              <h1 className="font-bold">Languages known</h1>
            </div>
            <div className="flex-col space-y-5">
              <div className="flex justify-end gap-4 text-[#919191] font-medium text-xs md:text-sm">
                <p>read</p>
                <p>write</p>
                <p>speak</p>
              </div>
              {profile?.languages?.map((data, index) => {
                return (
                  <div className="flex justify-between w-full " key={index}>
                    <h1 className="text-xs font-light md:text-sm">
                      {data.label}
                    </h1>
                    <div className="flex gap-5 ">
                      <label htmlFor=""></label>
                      <input
                        type="radio"
                        className="w-3 md:w-4"
                        checked={data.read}
                        disabled={
                          data.read === false || data.read === undefined
                        }
                        name=""
                        readOnly
                      />
                      <label htmlFor=""></label>
                      <input
                        type="radio"
                        className="w-3 md:w-4"
                        checked={data.write}
                        disabled={
                          data.write === false || data.write === undefined
                        }
                        name=""
                        readOnly
                      />
                      <label htmlFor=""></label>
                      <input
                        className="w-3 md:w-4"
                        type="radio"
                        checked={data.speak}
                        disabled={
                          data.speak === false || data.speak === undefined
                        }
                        name=""
                        readOnly
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {profile?.edu_level !== "" ? (
            <div className="space-y-1 text-xs md:text-sm">
              <h1 className="font-bold">Education</h1>
              <p className="text-[#919191] text-xs">
                {profile?.edu_level} at {profile?.school}
              </p>
            </div>
          ) : null}
          {/* 
          {work[0]?.designation !== "" && work[0]?.designation !== undefined ? (
            <div className="space-y-1 text-xs md:text-sm">
              <h1 className="font-bold">Work Experience</h1>
              <p className="text-[#919191] text-xs">
                {work[0]?.designation} at {work[0]?.companyName} for over{" "}
                {work[0]?.yearOfExp} years{" "}
              </p>
            </div>
          ) : null} */}

          {work[0]?.designation !== "" && work[0]?.designation !== undefined ? (
            <div>
              <h1 className="font-bold">Work Experience</h1>
              {
                work.map((workItem)=>(
                  <div className="space-y-1 my-3 text-xs md:text-sm">
                    <p className="text-[#919191] text-xs">
                      {workItem?.designation} at {workItem?.companyName} for over{" "}
                      {workItem?.yearOfExp} years{" "}
                    </p>
                  </div>
                ))
              }
            </div>
          ) : null}

          <div className="space-y-3 text-xs md:text-sm">
            <h1 className="font-bold">Blood Donations</h1>
            {profile?.bloodType !== "" ? (
              <p className="text-[#919191] text-xs">
                Blood Group:{" "}
                <span className="font-bold text-black">
                  &nbsp;{profile?.bloodType}
                </span>
              </p>
            ) : null}
            {profile?.donationType !== "" ? (
              <p className="text-[#919191] text-xs">
                Donation Type:
                <span className="font-bold text-black">
                  &nbsp;{profile?.donationType}
                </span>
              </p>
            ) : null}
            {profile?.availableLocations?.length !== 0 ? (
              <div className="text-xs md:text-sm ">
                <h1 className="text-xs font-bold">Blood donation locations</h1>
                <div className="flex flex-wrap gap-3 my-1 text-xs">
                  {profile?.availableLocations?.map((data, index) => {
                    return (
                      <p
                        key={index}
                        className="bg-[#D4EDFE] text-blue font-bold py-2 px-3 rounded-full"
                      >
                        {data.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div> }
      
    </>
  );
}
