import React,{useState,useEffect,useRef} from 'react'
import './participantsListModal.css'
import { GrFormClose } from "react-icons/gr";
import defaultAvatar from '../../../images/default.jpg'
import get_current_user from '../../../aws_cognito/get_user'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
const ParticipantsListModal = ({ setParticipantsListOpen, opportunityData ,type}) => {
    const [participants, setParticipants] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getParticipants();
    }, [])
    const wrapperRef = useRef();
    useEffect(() => {
        const onWindowClick = (e) => {
        if (e.target == wrapperRef.current) {
            setParticipantsListOpen(false);
        }
        };

        window.addEventListener("click", onWindowClick);

        return () => {
        window.removeEventListener("click", onWindowClick);
        };
    }, []);
    const params = {
        limit: process.env.REACT_APP_POSTS_FETCH_LIMIT
    }
    const getParticipants = async () => {
        const user = await get_current_user(type)
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts/${opportunityData._id}/getParticipants`, {
            params,
            headers: {
                Authorization: `Bearer ${user.data.idToken}`
            }
        })
            .then(res => {
                console.log(res.data);
                setParticipants(res.data)
        }).catch(err => {
            console.log(err)
        }
        )
    }
    const handleClose = (e) => {
        setParticipantsListOpen(false);
    }
    return (
        <div ref={wrapperRef} className="participantsListModalWrapper">
            <div className="participantsListModalBackground">
                <div className="participantsListModalContainer">
                    <div style={{display: 'flex',flexDirection:'row',justifyContent: 'space-between'}}>
                        <div style={{ fontSize: '0.8rem' }}>{opportunityData.participants} participants for <em>{opportunityData.title}</em></div>
                        <div style={{marginLeft:'1rem'}}>
                            <button onClick={handleClose}>
                                <GrFormClose />
                            </button>
                        </div>
                    </div>
                    {participants.map((participant) => {
                        return <div style={{display: 'flex',flexDirection:'column',justifyContent: 'space-between',margin:'0.2rem 0'}}>
                            <div style={{display: 'flex',flexDirection:'row',justifyContent: 'space-between',alignItems: 'center'}}>
                                <div className='flex items-center py-1'>
                                    <img className='w-12 h-12 rounded-full' src={participant.user.profilePic==""?defaultAvatar:participant.user.profilePic} />
                                    <div className='flex flex-col ml-3'>
                                        <p className='text-[0.8rem] font-bold'>{participant.user.name}</p>
                                        <p className='text-[0.7rem]  '>{participant.userType}</p>
                                    </div>
                                    
                                </div>
                                <button
                                    className="text-[0.8rem]  font-bold text-blue" onClick={() =>
                                        navigate(`/${type}/view-${participant.userType}/${participant.user._id}`)
                                    } style={{cursor:'pointer'}} >View Profile
                                </button>
                            </div>
                        </div>
                    })}
                    
                </div>
            </div>
        </div>
    )
}

export default ParticipantsListModal