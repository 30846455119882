import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactTags } from "react-tag-autocomplete";
import get_skills_token from "../../api/get_skills_token";
import {
  deleteArray,
  deleteSkills,
  updateArray,
  updateBio,
  updateDetails,
  updateLanguageType,
  updateSkills,
} from "../../redux/slices/profileSlice";
import {
  interestOptions,
  languagesOptions,
  locationOptions,
  skillsOptions,
} from "../Preferences/data/data";
import AvailableTime from "./AvailableTime";
import "./Bio.css";
import {
  availableHrs,
  days,
  location,
  modeOptions,
  volunteerMotivation,
} from "./data";

export default function Bio({ submitRef }) {
  const dispatch = useDispatch();
  const [skills, setSkills] = useState({
    isBusy: false,
    suggestions: [],
  });
  const [gender, setGender] = useState("");
  const profile = useSelector((state) => state.profileForm);
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  const onAddSkills = useCallback(
    (newTag) => {
      if (profile.skills.length < 5) {
        // setSkills([...skills, newTag]);
        dispatch(updateArray({ type: "skills", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [profile.skills]
  );

  const OnDeleteSkills = useCallback(
    (tagIndex) => {
      // setSkills(skills.filter((_,i) => i !== tagIndex));
      dispatch(deleteArray({ type: "skills", tagIndex }));
    },
    [profile.skills]
  );

  const onAddInterest = useCallback(
    (newTag) => {
      if (profile.interests.length < 5) {
        // console.log(tag);
        // setInterests([...interests, newTag]);
        dispatch(updateArray({ type: "interests", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [profile.interests]
  );
  const onDeleteInterest = useCallback(
    (tagIndex) => {
      // setInterests(interests.filter((_, i) => i !== tagIndex));
      dispatch(deleteArray({ type: "interests", tagIndex }));
    },
    [profile.interests]
  );
  const onAddLanguages = useCallback(
    (newTag) => {
      if (profile.languages.length < 5) {
        //console.log(typeof profile.languages);
        // setInterests([...interests, newTag]);
        dispatch(updateArray({ type: "languages", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [profile.languages]
  );
  const onDeleteLanguages = useCallback(
    (tagIndex) => {
      // setInterests(interests.filter((_, i) => i !== tagIndex));
      dispatch(deleteArray({ type: "languages", tagIndex }));
    },
    [profile.languages]
  );

  const onAddLocation = useCallback(
    (newTag) => {
      if (profile.volunteeringLocation.length < 3) {
        // setInterests([...interests, newTag]);
        dispatch(updateArray({ type: "volunteeringLocation", newTag }));
      } else {
        alert("Maximum tags limit reached");
      }
    },
    [profile.volunteeringLocation]
  );
  const onDeleteLocation = useCallback(
    (tagIndex) => {
      // setInterests(interests.filter((_, i) => i !== tagIndex));
      dispatch(deleteArray({ type: "volunteeringLocation", tagIndex }));
    },
    [profile.volunteeringLocation]
  );

  // const [values, setValues] = useState({
  //   name: state.name,
  //   email: state.email,
  //   DOB: state.DOB,
  //   contact: state.contact,
  //   aim: state.aim,
  //   gender: state.gender,
  //   skills: state.skills,
  //   motivation: state.motivation,
  //   availability: state.availability,
  //   location: state.location,
  //   interests: state.interests,
  //   availableHrs: state.availableHrs,
  // });

  const [phoneError, setPhoneError] = useState("");
  const handleValues = (e) => {
    // e.preventDefault();
    // const { name, value } = e.target;
    // console.log(profile);
    // const { name, value } = e.target;
    // setValues({ ...values, [name]: value });

    if (e.target.name == "phone_number") {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 10);
      }
      if (e.target.value.length != 10) {
        setPhoneError("Please enter a valid contact number.");
      } else {
        setPhoneError("");
      }
    }

    dispatch(updateDetails({ name: e.target.name, value: e.target.value }));
  };

  // const handleAddDay = (checked, index, day) => {
  //   setInputTime([
  //     ...inputTime,
  //     {
  //       day: day,
  //       startTime: profile.startDate,
  //       endTime: profile.endTime,
  //     },
  //   ]);

  //   console.log(inputTime);

  // const list = [...inputTime];
  // console.log(index);
  // list.splice(index, 1);
  // setInputTime(list);

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({ defaultValues: bio });

  // const onSubmit = (data) => {
  //   console.log(data);
  //   dispatch(updateBio(data));

  //   console.log(JSON.stringify(state, null, 2));
  //   console.log(bio);
  // };

  // dispatch(
  //   updateDetails({
  //     name: values.name,
  //     email: values.email,
  //     DOB: values.DOB,
  //     contact: values.contact,
  //     aim: values.aim,
  //     gender: values.gender,
  //     skills: values.skills,
  //     motivation: values.motivation,
  //     availability: values.availability,
  //     location: values.location,
  //     interests: values.interests,
  //     availableHrs: values.availableHrs,
  //   })
  // );

  // console.log(skills);
  // console.log(profile);
  function debounce(fn, delay = 300) {
    let timeoutID;

    return function (...args) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }

  function wait(delay = 300) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async function fetchData(value) {
    if (value.length < 3) {
      return;
    }
    setSkills({ ...skills, suggestions: [], isBusy: true });
    try {
      const query = encodeURIComponent(value);
      await wait();

      const token = await get_skills_token();

      const response = await fetch(
        `https://emsiservices.com/skills/versions/latest/skills?q=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // if (response.ok) {
      const json = await response.json();
      console.log(json.data);

      return json.data.map((item, index) => ({
        value: Math.random(),
        label: item.name,
      }));
      // } else {
      //   throw Error(`The API returned a ${response.status}`);
      // }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const onInput = useCallback(
    debounce(async (value) => {
      console.log(skills.isBusy);
      if (skills.isBusy) return;

      try {
        const suggestions = await fetchData(value);

        if (suggestions) {
          setSkills({ ...skills, suggestions, isBusy: false });
        }
      } catch (error) {
        console.error(error);
        setSkills({ isBusy: false });
      }
    }),
    [skills]
  );

  const noOptionsText = skills.isBusy ? "Loading..." : "No skills found";

  const handleUpdateCheckBox = (e, index) => {
    const { name, checked } = e.target;
    dispatch(updateLanguageType({ id: index, type: name, value: checked }));
  };

  return (
    <>
      <div className="flex flex-col border-b border-[#DDDDDD]">
        <p className="font-medium">Bio</p>
        <div>
          <form>
            <div className="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="name"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleValues}
                  value={profile?.name}
                  className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                />
              </div>
              <div>
                <label
                  for="email"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  disabled
                  onChange={handleValues}
                  value={userProfile?.data?.email}
                  className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                />
              </div>
              <div>
                <label
                  for="date"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Date of birth
                </label>
                <input
                  type="date"
                  onChange={handleValues}
                  value={profile?.DOB}
                  id="date"
                  name="DOB"
                  className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                />
              </div>
              <div>
                <label
                  for="number"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Contact Number
                </label>
                <input
                  type="number"
                  onChange={handleValues}
                  name="phone_number"
                  value={profile?.phone_number}
                  id="number"
                  className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                />
                {phoneError && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      fontSize: "11px",
                      // position: "absolute",
                      // marginTop: "55px",
                    }}
                  >
                    {phoneError}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                for="reason"
                className="block mb-2 text-xs font-normal text-lightGray"
              >
                Why are you here
              </label>
              <input
                type="text"
                name="reason"
                onChange={handleValues}
                id="reason"
                value={profile?.reason}
                className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
              />
            </div>
            {/* <button ref={submitRef} type="submit" style={{ display: "none" }} /> */}
            <div className="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="gender"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Gender
                </label>
                <div className="flex gap-3 pt-2 items-center">
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      id="Male"
                      name="gender"
                      onChange={handleValues}
                      defaultChecked={profile?.gender === "Male"}
                      value="Male"
                      className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0  "
                    />
                    <label
                      for="Male"
                      className="block  text-sm font-normal text-black"
                    >
                      Male
                    </label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      id="Female"
                      onChange={handleValues}
                      value="Female"
                      defaultChecked={profile?.gender === "Female"}
                      name="gender"
                      className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0    p-2.5 "
                    />
                    <label
                      for="Female"
                      className="block  text-sm font-normal text-black"
                    >
                      Female
                    </label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      id="Others"
                      name="gender"
                      value="Others"
                      defaultChecked={profile?.gender === "Others"}
                      onChange={handleValues}
                      className=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0    p-2.5 "
                    />
                    <label
                      for="Others"
                      className="block  text-sm font-normal text-black"
                    >
                      Others
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <label
                  for="skills"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Skills
                </label>
                {/* <ReactTags
                  placeholderText={
                    profile?.skills?.length === 0 ? "Select..." : ""
                  }
                  selected={profile?.skills}
                  suggestions={skillsOptions}
                  onAdd={onAddSkills}
                  onDelete={OnDeleteSkills}
                  noOptionsText="No matching skills"
                /> */}
                <ReactTags
                  ariaDescribedBy="description-4"
                  id="demo-4"
                  noOptionsText={noOptionsText}
                  onAdd={onAddSkills}
                  onDelete={OnDeleteSkills}
                  onInput={onInput}
                  placeholderText={
                    profile?.skills?.length === 0 ? "Select..." : ""
                  }
                  selected={profile?.skills}
                  suggestions={skills.suggestions}
                />
              </div>
              <div>
                <label
                  for="interests"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Interests
                </label>
                <ReactTags
                  placeholderText={
                    profile?.interests?.length === 0 ? "Select..." : ""
                  }
                  selected={profile.interests}
                  suggestions={interestOptions}
                  onAdd={onAddInterest}
                  onDelete={onDeleteInterest}
                  noOptionsText="No matching interests"
                />
              </div>
              <div>
                <label
                  for="location"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Primary Location
                </label>
                <select
                  id="dropdown"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                  onChange={handleValues}
                  name="location"
                  value={profile.location}
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {location.map((data, index) => {
                    return (
                      <>
                        <option key={index} value={data}>
                          {data}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
            <p className="font-medium border-t pt-5">Volunteering</p>
            <div className="grid gap-6 my-6 md:grid-cols-4">
              <div>
                <label
                  for="motivation"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Volunteer Motivation
                </label>
                <select
                  id="dropdown"
                  onChange={handleValues}
                  name="motivation"
                  value={
                    volunteerMotivation.includes(profile.motivation)
                      ? profile.motivation
                      : "Other"
                  }
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {volunteerMotivation.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  for="volunteerMode"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Mode of Volunteering
                </label>
                <select
                  id="dropdown"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                  onChange={handleValues}
                  value={profile?.volunteerMode}
                  name="volunteerMode"
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {modeOptions?.map((data, index) => {
                    return (
                      <>
                        <option key={index} value={data}>
                          {data}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  for="number"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Volunteering locations
                </label>
                <ReactTags
                  placeholderText={
                    profile?.volunteeringLocation?.length === 0
                      ? "Select..."
                      : ""
                  }
                  selected={profile?.volunteeringLocation}
                  suggestions={locationOptions}
                  onAdd={onAddLocation}
                  onDelete={onDeleteLocation}
                  noOptionsText="No matching interests"
                />
              </div>

              <div>
                <label
                  for="availabilityHrs"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Available hours per week
                </label>
                <select
                  id="dropdown"
                  onChange={handleValues}
                  name="availableHrs"
                  value={profile?.availableHrs}
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-0 w-full p-3 "
                >
                  <option value="" selected={true} disabled={true}>
                    Select..
                  </option>
                  {availableHrs?.map((data, index) => {
                    return <option key={index}>{data}</option>;
                  })}
                </select>
              </div>
              {!volunteerMotivation.includes(profile.motivation) ||
              profile.motivation === "Other" ? (
                <div>
                  <label
                    for="motivation"
                    class="block mb-2 text-xs font-normal text-lightGray"
                  >
                    Other
                  </label>
                  <input
                    type="text"
                    name="motivation"
                    id="motivation"
                    class=" border border-gray-300 text-black text-sm rounded-lg focus:ring-0 peer focus:border focus:border-blue block w-full p-2.5 focus:outline-none focus:appearance-none"
                    required
                    defaultValue={profile.motivation}
                    placeholder="Describe"
                    onChange={handleValues}
                  />
                </div>
              ) : null}
            </div>
            <div className="grid gap-6 my-6 md:grid-cols-2">
              <div className="flex-col space-y-6">
                <label
                  for="availabilityHrs"
                  className="block mb-2 text-xs font-normal text-lightGray"
                >
                  Available Time
                </label>

                {days?.map((day, index) => {
                  return <AvailableTime day={day} key={index} index={index} />;
                })}
              </div>
              <div className="lg:border-l md:px-5 lg:border-[#DDDDDD]">
                <div className="flex flex-col gap-5">
                  <div>
                    <label
                      for="languages"
                      className="block mb-2 text-xs font-normal text-lightGray"
                    >
                      Languages known
                    </label>
                    <ReactTags
                      placeholderText={
                        profile?.languages?.length === 0 ? "Select..." : ""
                      }
                      selected={profile?.languages}
                      suggestions={languagesOptions}
                      onAdd={onAddLanguages}
                      onDelete={onDeleteLanguages}
                      noOptionsText="No matching languages"
                    />
                  </div>

                  <div className="flex-col space-y-5">
                    <div className="flex justify-end gap-8 text-[#919191] font-medium text-xs md:text-sm mr-7">
                      <p>read</p>
                      <p>write</p>
                      <p>speak</p>
                    </div>
                    {profile?.languages?.map((data, index) => {
                      return (
                        <div className="flex justify-between " key={index}>
                          <h1 className="font-light text-xs md:text-base">
                            {data.label}
                          </h1>
                          <div className="flex  gap-[26px] ">
                            <label htmlFor="read"></label>
                            <input
                              type="checkbox"
                              className="w-3 md:w-4"
                              checked={data.read}
                              onClick={(e) => handleUpdateCheckBox(e, index)}
                              name="read"
                            />
                            <label htmlFor="write"></label>
                            <input
                              type="checkbox"
                              className="w-3 md:w-4"
                              checked={data.write}
                              onClick={(e) => handleUpdateCheckBox(e, index)}
                              name="write"
                            />
                            <label htmlFor="speak"></label>
                            <input
                              className="w-3 md:w-4"
                              type="checkbox"
                              checked={data.speak}
                              onClick={(e) => handleUpdateCheckBox(e, index)}
                              name="speak"
                            />
                            <label
                              htmlFor=""
                              className="text-[#CDCDCD] font-bold cursor-pointer"
                              onClick={() => onDeleteLanguages(index)}
                            >
                              X
                            </label>
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="flex justify-between ">
                      <h1 className="font-light text-xs md:text-base">
                        Malayalam
                      </h1>
                      <div className="flex  gap-[26px] ">
                        <label htmlFor="lang2Read"></label>
                        <input
                          type="radio"
                          name="lang2Read"
                          id="lang2Read"
                          disabled={true}
                          className="w-3 md:w-4"
                        />
                        <label htmlFor="lang2Write"></label>
                        <input
                          type="radio"
                          name="lang2Write"
                          id="lang2Write"
                          defaultChecked={true}
                          className="w-3 md:w-4"
                          disabled={true}
                        />
                        <label htmlFor="lang2Speak"></label>
                        <input
                          className="w-3 md:w-4 "
                          type="radio"
                          name="lang2Speak"
                          id="lang2Speak"
                          defaultChecked={true}
                        />
                        <label htmlFor="" className="text-[#CDCDCD] font-bold">
                          X
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
