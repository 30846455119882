import axios from 'axios'

export const getConnections = (token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/user/connections`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getConversations = (token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/chat/conversation`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const createConversation = (other, token) => 
    axios.post(`${process.env.REACT_APP_CHAT_API}/chat/conversation`, 
        {
            other: {
                _id: other._id,
                type: other.type
            }
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )

export const getMessages = (other, query, token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/chat/user/${other.type}/${other._id}`, {
        params: query,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getPresignedUrl = (fileType, token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/user/presignedUrl`, {
        params: {
            fileType
        },
        
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getBroadcastMessages = (query, token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/broadcast`, {
        params: query,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getBroadcastMessagesForType = (type, query, token) => 
    axios.get(`${process.env.REACT_APP_CHAT_API}/broadcast/${type}`, {
        params: query,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const getBroadcastConversation = (token) =>
    axios.get(`${process.env.REACT_APP_CHAT_API}/broadcast/conversation`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const updateLastSeenBroadcast = (token) => 
    axios.post(`${process.env.REACT_APP_CHAT_API}/broadcast/conversation`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })