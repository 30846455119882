import cog_pool from "./user_pool";
import { CognitoUserSession, CognitoUser, CognitoAccessToken, CognitoRefreshToken, CognitoIdToken } from "amazon-cognito-identity-js";
export const create_session = async (id_token, access_token, refresh_token, type) => {
    console.log(id_token)
    const AccessToken = new
        CognitoAccessToken({
            AccessToken: access_token,
        });
    // create a CognitoIdToken using the response idToken   
    const IdToken = new CognitoIdToken({
        IdToken: id_token
    });
    // create a RefreshToken using the response refreshToken 
    const RefreshToken = new
        CognitoRefreshToken({
            RefreshToken: refresh_token
        });
    const sessionData = {
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
    };
    const session = new CognitoUserSession(
        sessionData
    );
    const user = new CognitoUser({
        Username: session.getIdToken().payload.email,
        Pool: cog_pool(type),
    });
    user.setSignInUserSession(session);
    return user;
}