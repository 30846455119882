export const interestOptions = [
  { value: 1, label: "Rural Development" },
  { value: 2, label: "Children's education" },
  { value: 3, label: "Women’s health" },
  { value: 4, label: "Women empowerment" },
  { value: 5, label: "Wildlife conservation" },
  { value: 6, label: "Marine conservation" },
  { value: 1, label: "Waste management" },
  { value: 2, label: "Community development" },
  { value: 3, label: "Water Conservation" },
  { value: 4, label: "Air quality conservation" },
  { value: 5, label: "Animal Care" },
  { value: 6, label: "Sports" },
  { value: 1, label: "Volunteer for orphanage" },
  { value: 2, label: "Volunteer for old age home" },
  { value: 3, label: "Medical Volunteer" },
  { value: 4, label: "Disaster Management" },
  { value: 5, label: "SDG 1: No Poverty" },
  { value: 6, label: "SDG 2: Zero Hunger" },
  { value: 1, label: "SDG 3: Good Health and Well-being" },
  { value: 2, label: "SDG 4: Quality Education" },
  { value: 3, label: "SDG 5: Gender Equality" },
  { value: 4, label: "SDG 6: Clean Water and Sanitation" },
  { value: 5, label: "SDG 7: Affordable and Clean Energy" },
  { value: 6, label: "SDG 8: Decent Work and Economic Growth" },
  { value: 1, label: "SDG 9: Industry, Innovation and Infrastructure" },
  { value: 2, label: "SDG 10: Reduced Inequality" },
  { value: 3, label: "SDG 11: Sustainable Cities and Communities" },
  { value: 4, label: "SDG 12: Responsible Consumption and Production" },
  { value: 5, label: "SDG 13: Climate Action" },
  { value: 6, label: "SDG 14: Life Below Water" },
  { value: 1, label: "SDG 15: Life on Land" },
  { value: 2, label: "SDG 16: Peace and Justice Strong Institutions" },
  { value: 3, label: "SDG 17: Partnerships to achieve the Goal" },
  { value: 4, label: "Child rights" },
  { value: 5, label: "Poverty" },
  { value: 6, label: "Social Injustice" },
  { value: 1, label: "Environment Conservation" },
  { value: 2, label: "Human Rights" },
  { value: 3, label: "Care for elderly people" },
  { value: 4, label: "Animal Rights" },
  { value: 5, label: "Sanitation and Hygiene" },
  { value: 6, label: "Humanitarian Relief" },
  { value: 1, label: "Health and Nutrition" },
  { value: 2, label: "Literacy and Education" },
  { value: 3, label: "Refugee Crisis" },
  { value: 4, label: "Disease Control" },
  { value: 5, label: "Welfare for differently abled" },
  { value: 6, label: "Tribal welfare" },
  { value: 1, label: "HIV/AIDS" },
  { value: 2, label: "LGBTQ Rights and Welfare" },
  { value: 3, label: "Unemployment" },
  { value: 4, label: "Art & Culture" },
  { value: 5, label: "Menstrual Health & Hygiene" },
  { value: 6, label: "Migrants & Refugee Rights" },
  { value: 1, label: "News" },
  { value: 2, label: "Funds / Grants" },
  { value: 3, label: "Career Opportunities" },
  { value: 4, label: "Internship" },
  { value: 5, label: "Scholarships & Fellowship" },
  { value: 6, label: "Government Announcements" },
  { value: 4, label: "Courses / Training Programs" },
  { value: 5, label: "Events" },
  { value: 6, label: "Sell / Buy" },
  { value: 6, label: "Any" },
];

export const skillsOptions = [
  { value: 1, label: "CSR Consulting" },
  { value: 2, label: "Fund Raising" },
  { value: 3, label: "Cause Marketing" },
  { value: 4, label: "Proposal Writing" },
  { value: 5, label: "Capacity building" },
  { value: 6, label: "People Management" },
  { value: 7, label: "Coaching and Counselling" },
  { value: 8, label: "Impact Business Modelling" },
  { value: 9, label: "Sustainable Development Consulting" },
  { value: 10, label: "Community Investments" },
  { value: 11, label: "Contracts and Agreements" },
  { value: 12, label: "Investment Banking" },
  { value: 13, label: "Strategizing and planning" },
  { value: 14, label: "Legal consulting" },
  { value: 15, label: "Design Thinking" },
  { value: 16, label: "App development" },
  { value: 17, label: "Videography" },
  { value: 18, label: "Audio production" },
  { value: 19, label: "Editing" },
  { value: 20, label: "Coordinate events" },
  { value: 21, label: "Photoshop" },
  { value: 22, label: "Photography" },
  { value: 23, label: "Law" },
  { value: 24, label: "Journalism" },
  { value: 25, label: "Animation" },
  { value: 26, label: "Data scientist" },
  { value: 27, label: "Sales leadership" },
  { value: 28, label: "UX design" },
  { value: 29, label: "SEO/SEM marketing" },
  { value: 30, label: "Content writing" },
  { value: 31, label: "CAD" },
  { value: 32, label: "Lean manufacturing" },
  { value: 33, label: "Multivariate analysis" },
  { value: 34, label: "Linear regression" },
  { value: 35, label: "Prototyping" },
  { value: 36, label: "Workflow development" },
  { value: 37, label: "STEM skills" },
  { value: 38, label: "Web: HTML, CSS, Javascript" },
  { value: 39, label: "Payment processing " },
  { value: 40, label: "Automated Billing Systems" },
  { value: 41, label: "CRM Platforms" },
  { value: 42, label: "Research" },
  { value: 43, label: "Troubleshooting" },
  { value: 44, label: "Google Drive" },
  { value: 45, label: "Spreadsheets" },
  { value: 46, label: "Email" },
  { value: 47, label: "Presentations/Slideshows" },
  { value: 48, label: "Database Management" },
  { value: 49, label: "Quickbooks" },
  { value: 50, label: "Social media" },
  { value: 51, label: "Web" },
  { value: 52, label: "Typing" },
  { value: 53, label: "Graphics" },
  { value: 54, label: "Enterprise systems" },
  { value: 55, label: "WPM" },
  { value: 56, label: "Research" },
  { value: 57, label: "Forecasting" },
  { value: 58, label: "Data mining" },
  { value: 59, label: "Data presentation" },
  { value: 60, label: "Resource management" },
  { value: 61, label: "Data engineering" },
  { value: 62, label: "Database management" },
  { value: 63, label: "Data and metrics interpreting" },
  { value: 64, label: "Reporting" },
  { value: 65, label: "Diagnostics" },
  { value: 66, label: "Creativity" },
  { value: 67, label: "Theorizing" },
  { value: 68, label: "SEO/SEM: Ahrefs, SEMRush, SEO Power Suite, Majestic" },
  { value: 69, label: "PPC" },
  {
    value: 70,
    label: "Social media marketing and paid social media advertising",
  },
  { value: 71, label: "Writing" },
  { value: 72, label: "CRO and A/B testing" },
  { value: 73, label: "Email marketing and automation" },
  { value: 74, label: "HubSpot, Aritic PinPoint, ONTRAPORT, Infusionsoft" },
  { value: 75, label: "Funnel management" },
  { value: 76, label: "UX Design" },
  { value: 77, label: "Data visualization" },
  { value: 78, label: "Google Analytics and Google Search Console" },
  { value: 79, label: "AdWords, Facebook Paid Ads" },
  { value: 80, label: "Visual communication" },
  { value: 81, label: "Slideshows" },
  { value: 82, label: "Research" },
  { value: 83, label: "Data analysis" },
  { value: 84, label: "Reporting" },
  { value: 85, label: "Persuasion" },
  { value: 86, label: "Graphic design" },
  { value: 87, label: "Business knowledge" },
  { value: 88, label: "Budgeting" },
  { value: 89, label: "Project management" },
  { value: 90, label: "Hiring" },
  { value: 91, label: "Finance" },
  { value: 92, label: "Office management skills" },
  { value: 93, label: "Logistics" },
  { value: 94, label: "New business development" },
  { value: 95, label: "Negotiating" },
  { value: 96, label: "Planning" },
  { value: 97, label: "Project scheduling" },
  { value: 98, label: "Strategic planning" },
  { value: 99, label: "Project lifecycle management" },
  { value: 100, label: "Agile software" },
  { value: 101, label: "Scrum management" },
  { value: 102, label: "Financial modeling" },
  { value: 103, label: "Kanban" },
  { value: 104, label: "Forecasting" },
  { value: 105, label: "Performance tracking" },
  { value: 106, label: "Budgeting" },
  {
    value: 107,
    label: "Basic writing: grammar, punctuation, spelling, vocabulary",
  },
  { value: 108, label: "Note-taking" },
  { value: 109, label: "Letter writing" },
  { value: 110, label: "Email writing" },
  {
    value: 111,
    label: "Business writing reports, press releases, content management",
  },
  { value: 112, label: "Data visualization" },
  { value: 113, label: "Creative writing: plotting, worldbuilding, dialogue" },
  { value: 114, label: "Academic writing" },
  { value: 115, label: "Storytelling" },
  { value: 116, label: "Copywriting" },
  { value: 117, label: "SEO" },
  { value: 118, label: "Editing" },
  { value: 119, label: "Journalism" },
  { value: 120, label: "Social media" },
  { value: 121, label: "Presentation writing" },
  { value: 122, label: "Growth Strategist" },
  { value: 123, label: "Legal services" },
  { value: 124, label: "Teaching / Tutoring " },
  { value: 125, label: "Virtual Asisstant / Admin support" },
  { value: 126, label: "Translation" },
  { value: 127, label: "Community Manager" },
  { value: 128, label: "Audio / Video production" },
  { value: 129, label: "Human Resource Management " },
  { value: 130, label: "Curriculam developer" },
  { value: 131, label: "Mobile App development" },
  { value: 132, label: "Management Consulting " },
  { value: 133, label: "Financial / Accounting Services" },
  { value: 134, label: "Technology Consulting " },
  { value: 135, label: "Customer Service" },
  { value: 136, label: "SROI" },
  { value: 137, label: "Social Impact  Assessment " },
  { value: 138, label: "Market Research / Validation" },
  { value: 139, label: "Surveys/Polls" },
  { value: 140, label: "Data Analysis" },
];
export const causesOptions = [
  { value: 1, label: "Rural Development" },
  { value: 2, label: "Children's education" },
  { value: 3, label: "Women’s health" },
  { value: 4, label: "Women empowerment" },
  { value: 5, label: "Wildlife conservation" },
  { value: 6, label: "Marine conservation" },
  { value: 7, label: "Waste management" },
  { value: 8, label: "Community development" },
  { value: 9, label: "Water Conservation" },
  { value: 10, label: "Air quality conservation" },
  { value: 11, label: "Animal Care" },
  { value: 12, label: "Sports" },
  { value: 13, label: "Volunteer for orphanage" },
  { value: 14, label: "Volunteer for old age home" },
  { value: 15, label: "Medical Volunteer" },
  { value: 16, label: "Disaster Management" },
  { value: 17, label: "SDG 1: No Poverty" },
  { value: 18, label: "SDG 2: Zero Hunger" },
  { value: 19, label: "SDG 3: Good Health and Well-being" },
  { value: 20, label: "SDG 4: Quality Education" },
  { value: 21, label: "SDG 5: Gender Equality" },
  { value: 22, label: "SDG 6: Clean Water and Sanitation" },
  { value: 23, label: "SDG 7: Affordable and Clean Energy" },
  { value: 24, label: "SDG 8: Decent Work and Economic Growth" },
  { value: 25, label: "SDG 9: Industry, Innovation and Infrastructure" },
  { value: 26, label: "SDG 10: Reduced Inequality" },
  { value: 27, label: "SDG 11: Sustainable Cities and Communities" },
  { value: 28, label: "SDG 12: Responsible Consumption and Production" },
  { value: 29, label: "SDG 13: Climate Action" },
  { value: 30, label: "SDG 14: Life Below Water" },
  { value: 31, label: "SDG 15: Life on Land" },
  { value: 32, label: "SDG 16: Peace and Justice Strong Institutions" },
  { value: 33, label: "SDG 17: Partnerships to achieve the Goal" },
  { value: 34, label: "Child rights" },
  { value: 35, label: "Poverty" },
  { value: 36, label: "Social Injustice" },
  { value: 37, label: "Environment Conservation" },
  { value: 38, label: "Human Rights" },
  { value: 39, label: "Care for elderly people" },
  { value: 40, label: "Animal Rights" },
  { value: 41, label: "Sanitation and Hygiene" },
  { value: 42, label: "Humanitarian Relief" },
  { value: 43, label: "Health and Nutrition" },
  { value: 44, label: "Literacy and Education" },
  { value: 45, label: "Refugee Crisis" },
  { value: 46, label: "Disease Control" },
  { value: 47, label: "Welfare for differently abled" },
  { value: 48, label: "Tribal welfare" },
  { value: 49, label: "HIV/AIDS" },
  { value: 50, label: "LGBTQ Rights and Welfare" },
  { value: 51, label: "Unemployment" },
  { value: 52, label: "Art & Culture" },
  { value: 53, label: "Menstrual Health & Hygiene" },
  { value: 54, label: "Migrants & Refugee Rights" },
];

export const bloodGroupOptions = [
  { value: 1, label: "React" },
  { value: 2, label: "UI/UX" },
  { value: 3, label: "Django" },
  { value: 4, label: "Javascript" },
  { value: 5, label: "HTML" },
  { value: 6, label: "CSS" },
];
// to generate array of objects for any category
// let arr = [];
//   states.map((data, index) => (arr = [...arr, ...data.districts]));
//   states.map((data, index) => arr.push(data.state));
//   arr = arr.map((data, index) => {
//     return {
//       value: index + 1,
//       label: data,
//     };
//   });

//   console.log(JSON.stringify(arr));

export const locationOptions = [
  { value: 522, label: "Pan-India" },
  { value: 1, label: "Adilabad" },
  { value: 2, label: "Agar Malwa" },
  { value: 3, label: "Agatti" },
  { value: 4, label: "Agra" },
  { value: 5, label: "Ahmedabad" },
  { value: 6, label: "Ahmednagar" },
  { value: 7, label: "Aizawl" },
  { value: 8, label: "Ajmer" },
  { value: 9, label: "Akola" },
  { value: 10, label: "Alappuzha" },
  { value: 11, label: "Aligarh" },
  { value: 12, label: "Alipurduar" },
  { value: 13, label: "Alirajpur" },
  { value: 14, label: "Allahabad" },
  { value: 15, label: "Almora" },
  { value: 16, label: "Alwar" },
  { value: 17, label: "Ambala" },
  { value: 18, label: "Ambedkar Nagar" },
  { value: 19, label: "Amethi (Chatrapati Sahuji Mahraj Nagar)" },
  { value: 20, label: "Amini" },
  { value: 21, label: "Amravati" },
  { value: 22, label: "Amreli" },
  { value: 23, label: "Amritsar" },
  { value: 24, label: "Amroha (J.P. Nagar)" },
  { value: 25, label: "Anand" },
  { value: 26, label: "Anantapur" },
  { value: 27, label: "Anantnag" },
  { value: 28, label: "Andhra Pradesh" },
  { value: 29, label: "Androth" },
  { value: 30, label: "Angul" },
  { value: 31, label: "Anjaw" },
  { value: 32, label: "Anuppur" },
  { value: 33, label: "Araria" },
  { value: 34, label: "Aravalli" },
  { value: 35, label: "Ariyalur" },
  { value: 36, label: "Arunachal Pradesh" },
  { value: 37, label: "Arwal" },
  { value: 38, label: "Ashoknagar" },
  { value: 39, label: "Assam" },
  { value: 40, label: "Auraiya" },
  { value: 41, label: "Aurangabad" },
  { value: 42, label: "Aurangabad" },
  { value: 43, label: "Azamgarh" },
  { value: 44, label: "Bagalkot" },
  { value: 45, label: "Bageshwar" },
  { value: 46, label: "Baghpat" },
  { value: 47, label: "Bahraich" },
  { value: 48, label: "Baksa" },
  { value: 49, label: "Balaghat" },
  { value: 50, label: "Balangir" },
  { value: 51, label: "Balasore" },
  { value: 52, label: "Ballari (Bellary)" },
  { value: 53, label: "Ballia" },
  { value: 54, label: "Balod" },
  { value: 55, label: "Baloda Bazar" },
  { value: 56, label: "Balrampur" },
  { value: 57, label: "Balrampur" },
  { value: 58, label: "Banaskantha (Palanpur)" },
  { value: 59, label: "Banda" },
  { value: 60, label: "Bandipore" },
  { value: 61, label: "Banka" },
  { value: 62, label: "Bankura" },
  { value: 63, label: "Banswara" },
  { value: 64, label: "Barabanki" },
  { value: 65, label: "Baramulla" },
  { value: 66, label: "Baran" },
  { value: 67, label: "Bareilly" },
  { value: 68, label: "Bargarh" },
  { value: 69, label: "Barmer" },
  { value: 70, label: "Barnala" },
  { value: 71, label: "Barpeta" },
  { value: 72, label: "Barwani" },
  { value: 73, label: "Bastar" },
  { value: 74, label: "Basti" },
  { value: 75, label: "Bathinda" },
  { value: 76, label: "Beed" },
  { value: 77, label: "Begusarai" },
  { value: 78, label: "Belagavi (Belgaum)" },
  { value: 79, label: "Bemetara" },
  { value: 80, label: "Bengaluru (Bangalore) Rural" },
  { value: 81, label: "Bengaluru (Bangalore) Urban" },
  { value: 82, label: "Betul" },
  { value: 83, label: "Bhadohi" },
  { value: 84, label: "Bhadradri Kothagudem" },
  { value: 85, label: "Bhadrak" },
  { value: 86, label: "Bhagalpur" },
  { value: 87, label: "Bhandara" },
  { value: 88, label: "Bharatpur" },
  { value: 89, label: "Bharuch" },
  { value: 90, label: "Bhavnagar" },
  { value: 91, label: "Bhilwara" },
  { value: 92, label: "Bhind" },
  { value: 93, label: "Bhiwani" },
  { value: 94, label: "Bhojpur" },
  { value: 95, label: "Bhopal" },
  { value: 96, label: "Bidar" },
  { value: 97, label: "Bihar" },
  { value: 98, label: "Bijapur" },
  { value: 99, label: "Bijnor" },
  { value: 100, label: "Bikaner" },
  { value: 101, label: "Bilaspur" },
  { value: 102, label: "Bilaspur" },
  { value: 103, label: "Birbhum" },
  { value: 104, label: "Bishnupur" },
  { value: 105, label: "Biswanath" },
  { value: 106, label: "Bithra" },
  { value: 107, label: "Bokaro" },
  { value: 108, label: "Bongaigaon" },
  { value: 109, label: "Botad" },
  { value: 110, label: "Boudh" },
  { value: 111, label: "Budaun" },
  { value: 112, label: "Budgam" },
  { value: 113, label: "Bulandshahr" },
  { value: 114, label: "Buldhana" },
  { value: 115, label: "Bundi" },
  { value: 116, label: "Burdwan (Bardhaman)" },
  { value: 117, label: "Burhanpur" },
  { value: 118, label: "Buxar" },
  { value: 119, label: "Cachar" },
  { value: 120, label: "Central Delhi" },
  { value: 121, label: "Chamarajanagar" },
  { value: 122, label: "Chamba" },
  { value: 123, label: "Chamoli" },
  { value: 124, label: "Champawat" },
  { value: 125, label: "Champhai" },
  { value: 126, label: "Chandauli" },
  { value: 127, label: "Chandel" },
  { value: 128, label: "Chandigarh" },
  { value: 129, label: "Chandigarh (UT)" },
  { value: 130, label: "Chandrapur" },
  { value: 131, label: "Changlang" },
  { value: 132, label: "Charaideo" },
  { value: 133, label: "Charkhi Dadri" },
  { value: 134, label: "Chatra" },
  { value: 135, label: "Chennai" },
  { value: 136, label: "Chethlath" },
  { value: 137, label: "Chhatarpur" },
  { value: 138, label: "Chhattisgarh" },
  { value: 139, label: "Chhindwara" },
  { value: 140, label: "Chhota Udepur" },
  { value: 141, label: "Chikballapur" },
  { value: 142, label: "Chikkamagaluru (Chikmagalur)" },
  { value: 143, label: "Chirang" },
  { value: 144, label: "Chitradurga" },
  { value: 145, label: "Chitrakoot" },
  { value: 146, label: "Chittoor" },
  { value: 147, label: "Chittorgarh" },
  { value: 148, label: "Churachandpur" },
  { value: 149, label: "Churu" },
  { value: 150, label: "Coimbatore" },
  { value: 800, label: "Cochin" },
  { value: 151, label: "Cooch Behar" },
  { value: 152, label: "Cuddalore" },
  { value: 153, label: "Cuttack" },
  { value: 154, label: "Dadra & Nagar Haveli" },
  { value: 155, label: "Dadra and Nagar Haveli (UT)" },
  { value: 156, label: "Dahod" },
  { value: 157, label: "Dakshin Dinajpur (South Dinajpur)" },
  { value: 158, label: "Dakshina Kannada" },
  { value: 159, label: "Daman" },
  { value: 160, label: "Daman and Diu (UT)" },
  { value: 161, label: "Damoh" },
  { value: 162, label: "Dangs (Ahwa)" },
  { value: 163, label: "Dantewada (South Bastar)" },
  { value: 164, label: "Darbhanga" },
  { value: 165, label: "Darjeeling" },
  { value: 166, label: "Darrang" },
  { value: 167, label: "Datia" },
  { value: 168, label: "Dausa" },
  { value: 169, label: "Davangere" },
  { value: 170, label: "Dehradun" },
  { value: 171, label: "Delhi (NCT)" },
  { value: 172, label: "Deogarh" },
  { value: 173, label: "Deoghar" },
  { value: 174, label: "Deoria" },
  { value: 175, label: "Devbhoomi Dwarka" },
  { value: 176, label: "Dewas" },
  { value: 177, label: "Dhalai" },
  { value: 178, label: "Dhamtari" },
  { value: 179, label: "Dhanbad" },
  { value: 180, label: "Dhar" },
  { value: 181, label: "Dharmapuri" },
  { value: 182, label: "Dharwad" },
  { value: 183, label: "Dhemaji" },
  { value: 184, label: "Dhenkanal" },
  { value: 185, label: "Dholpur" },
  { value: 186, label: "Dhubri" },
  { value: 187, label: "Dhule" },
  { value: 188, label: "Dibang Valley" },
  { value: 189, label: "Dibrugarh" },
  { value: 190, label: "Dima Hasao" },
  { value: 191, label: "Dimapur" },
  { value: 192, label: "Dindigul" },
  { value: 193, label: "Dindori" },
  { value: 194, label: "Diu" },
  { value: 195, label: "Doda" },
  { value: 196, label: "Dumka" },
  { value: 197, label: "Dungarpur" },
  { value: 198, label: "Durg" },
  { value: 199, label: "East Champaran (Motihari)" },
  { value: 200, label: "East Delhi" },
  { value: 201, label: "East Garo Hills" },
  { value: 202, label: "East Godavari" },
  { value: 203, label: "East Jaintia Hills" },
  { value: 204, label: "East Kameng" },
  { value: 205, label: "East Khasi Hills" },
  { value: 206, label: "East Siang" },
  { value: 207, label: "East Sikkim" },
  { value: 208, label: "East Singhbhum" },
  { value: 209, label: "Ernakulam" },
  { value: 210, label: "Erode" },
  { value: 211, label: "Etah" },
  { value: 212, label: "Etawah" },
  { value: 213, label: "Faizabad" },
  { value: 214, label: "Faridabad" },
  { value: 215, label: "Faridkot" },
  { value: 216, label: "Farrukhabad" },
  { value: 217, label: "Fatehabad" },
  { value: 218, label: "Fatehgarh Sahib" },
  { value: 219, label: "Fatehpur" },
  { value: 220, label: "Fazilka" },
  { value: 221, label: "Ferozepur" },
  { value: 222, label: "Firozabad" },
  { value: 223, label: "Gadag" },
  { value: 224, label: "Gadchiroli" },
  { value: 225, label: "Gajapati" },
  { value: 226, label: "Ganderbal" },
  { value: 227, label: "Gandhinagar" },
  { value: 228, label: "Ganjam" },
  { value: 229, label: "Garhwa" },
  { value: 230, label: "Gariyaband" },
  { value: 231, label: "Gautam Buddha Nagar" },
  { value: 232, label: "Gaya" },
  { value: 233, label: "Ghaziabad" },
  { value: 234, label: "Ghazipur" },
  { value: 235, label: "Gir Somnath" },
  { value: 236, label: "Giridih" },
  { value: 237, label: "Goa" },
  { value: 238, label: "Goalpara" },
  { value: 239, label: "Godda" },
  { value: 240, label: "Golaghat" },
  { value: 241, label: "Gomati" },
  { value: 242, label: "Gonda" },
  { value: 243, label: "Gondia" },
  { value: 244, label: "Gopalganj" },
  { value: 245, label: "Gorakhpur" },
  { value: 246, label: "Gujarat" },
  { value: 247, label: "Gumla" },
  { value: 248, label: "Guna" },
  { value: 249, label: "Guntur" },
  { value: 250, label: "Gurdaspur" },
  { value: 251, label: "Gurgaon" },
  { value: 252, label: "Gwalior" },
  { value: 253, label: "Hailakandi" },
  { value: 254, label: "Hamirpur" },
  { value: 255, label: "Hamirpur" },
  { value: 256, label: "Hanumangarh" },
  { value: 257, label: "Hapur (Panchsheel Nagar)" },
  { value: 258, label: "Harda" },
  { value: 259, label: "Hardoi" },
  { value: 260, label: "Haridwar" },
  { value: 261, label: "Haryana" },
  { value: 262, label: "Hassan" },
  { value: 263, label: "Hathras" },
  { value: 264, label: "Haveri" },
  { value: 265, label: "Hazaribag" },
  { value: 266, label: "Himachal Pradesh" },
  { value: 267, label: "Hingoli" },
  { value: 268, label: "Hisar" },
  { value: 269, label: "Hojai" },
  { value: 270, label: "Hooghly" },
  { value: 271, label: "Hoshangabad" },
  { value: 272, label: "Hoshiarpur" },
  { value: 273, label: "Howrah" },
  { value: 274, label: "Hyderabad" },
  { value: 275, label: "Idukki" },
  { value: 276, label: "Imphal East" },
  { value: 277, label: "Imphal West" },
  { value: 278, label: "Indore" },
  { value: 279, label: "Jabalpur" },
  { value: 280, label: "Jagatsinghapur" },
  { value: 281, label: "Jagtial" },
  { value: 282, label: "Jaipur" },
  { value: 283, label: "Jaisalmer" },
  { value: 284, label: "Jajpur" },
  { value: 285, label: "Jalandhar" },
  { value: 286, label: "Jalaun" },
  { value: 287, label: "Jalgaon" },
  { value: 288, label: "Jalna" },
  { value: 289, label: "Jalore" },
  { value: 290, label: "Jalpaiguri" },
  { value: 291, label: "Jammu" },
  { value: 292, label: "Jammu and Kashmir" },
  { value: 293, label: "Jamnagar" },
  { value: 294, label: "Jamtara" },
  { value: 295, label: "Jamui" },
  { value: 296, label: "Jangaon" },
  { value: 297, label: "Janjgir-Champa" },
  { value: 298, label: "Jashpur" },
  { value: 299, label: "Jaunpur" },
  { value: 300, label: "Jayashankar Bhoopalpally" },
  { value: 301, label: "Jehanabad" },
  { value: 302, label: "Jhabua" },
  { value: 303, label: "Jhajjar" },
  { value: 304, label: "Jhalawar" },
  { value: 305, label: "Jhansi" },
  { value: 306, label: "Jharkhand" },
  { value: 307, label: "Jharsuguda" },
  { value: 308, label: "Jhunjhunu" },
  { value: 309, label: "Jind" },
  { value: 310, label: "Jiribam" },
  { value: 311, label: "Jodhpur" },
  { value: 312, label: "Jogulamba Gadwal" },
  { value: 313, label: "Jorhat" },
  { value: 314, label: "Junagadh" },
  { value: 315, label: "Kabirdham (Kawardha)" },
  { value: 316, label: "Kachchh" },
  { value: 317, label: "Kadmath" },
  { value: 318, label: "Kaimur (Bhabua)" },
  { value: 319, label: "Kaithal" },
  { value: 320, label: "Kakching" },
  { value: 321, label: "Kalaburagi (Gulbarga)" },
  { value: 322, label: "Kalahandi" },
  { value: 323, label: "Kalimpong" },
  { value: 324, label: "Kalpeni" },
  { value: 325, label: "Kamareddy" },
  { value: 326, label: "Kamjong" },
  { value: 327, label: "Kamrup" },
  { value: 328, label: "Kamrup Metropolitan" },
  { value: 329, label: "Kanchipuram" },
  { value: 330, label: "Kandhamal" },
  { value: 331, label: "Kangpokpi" },
  { value: 332, label: "Kangra" },
  { value: 333, label: "Kanker (North Bastar)" },
  { value: 334, label: "Kannauj" },
  { value: 335, label: "Kannur" },
  { value: 336, label: "Kanpur Dehat" },
  { value: 337, label: "Kanpur Nagar" },
  { value: 338, label: "Kanshiram Nagar (Kasganj)" },
  { value: 339, label: "Kanyakumari" },
  { value: 340, label: "Kapurthala" },
  { value: 341, label: "Karaikal" },
  { value: 342, label: "Karauli" },
  { value: 343, label: "Karbi Anglong" },
  { value: 344, label: "Kargil" },
  { value: 345, label: "Karimganj" },
  { value: 346, label: "Karimnagar" },
  { value: 347, label: "Karnal" },
  { value: 348, label: "Karnataka" },
  { value: 349, label: "Karur" },
  { value: 350, label: "Kasaragod" },
  { value: 351, label: "Kathua" },
  { value: 352, label: "Katihar" },
  { value: 353, label: "Katni" },
  { value: 354, label: "Kaushambi" },
  { value: 355, label: "Kavaratti" },
  { value: 356, label: "Kendrapara" },
  { value: 357, label: "Kendujhar (Keonjhar)" },
  { value: 358, label: "Kerala" },
  { value: 359, label: "Khagaria" },
  { value: 360, label: "Khammam" },
  { value: 361, label: "Khandwa" },
  { value: 362, label: "Khargone" },
  { value: 363, label: "Kheda (Nadiad)" },
  { value: 364, label: "Khordha" },
  { value: 365, label: "Khowai" },
  { value: 366, label: "Khunti" },
  { value: 367, label: "Kilthan" },
  { value: 368, label: "Kinnaur" },
  { value: 369, label: "Kiphire" },
  { value: 370, label: "Kishanganj" },
  { value: 371, label: "Kishtwar" },
  { value: 372, label: "Kodagu" },
  { value: 373, label: "Koderma" },
  { value: 374, label: "Kohima" },
  { value: 375, label: "Kokrajhar" },
  { value: 376, label: "Kolar" },
  { value: 377, label: "Kolasib" },
  { value: 378, label: "Kolhapur" },
  { value: 379, label: "Kolkata" },
  { value: 380, label: "Kollam" },
  { value: 381, label: "Komaram Bheem Asifabad" },
  { value: 382, label: "Kondagaon" },
  { value: 383, label: "Koppal" },
  { value: 384, label: "Koraput" },
  { value: 385, label: "Korba" },
  { value: 386, label: "Korea (Koriya)" },
  { value: 387, label: "Kota" },
  { value: 388, label: "Kottayam" },
  { value: 389, label: "Kozhikode" },
  { value: 390, label: "Kra Daadi" },
  { value: 391, label: "Krishna" },
  { value: 392, label: "Krishnagiri" },
  { value: 393, label: "Kulgam" },
  { value: 394, label: "Kullu" },
  { value: 395, label: "Kupwara" },
  { value: 396, label: "Kurnool" },
  { value: 397, label: "Kurukshetra" },
  { value: 398, label: "Kurung Kumey" },
  { value: 399, label: "Kushinagar (Padrauna)" },
  { value: 400, label: "Lahaul &amp; Spiti" },
  { value: 401, label: "Lakhimpur" },
  { value: 402, label: "Lakhimpur - Kheri" },
  { value: 403, label: "Lakhisarai" },
  { value: 404, label: "Lakshadweep (UT)" },
  { value: 405, label: "Lalitpur" },
  { value: 406, label: "Latehar" },
  { value: 407, label: "Latur" },
  { value: 408, label: "Lawngtlai" },
  { value: 409, label: "Leh" },
  { value: 410, label: "Lohardaga" },
  { value: 411, label: "Lohit" },
  { value: 412, label: "Longding" },
  { value: 413, label: "Longleng" },
  { value: 414, label: "Lower Dibang Valley" },
  { value: 415, label: "Lower Siang" },
  { value: 416, label: "Lower Subansiri" },
  { value: 417, label: "Lucknow" },
  { value: 418, label: "Ludhiana" },
  { value: 419, label: "Lunglei" },
  { value: 420, label: "Madhepura" },
  { value: 421, label: "Madhubani" },
  { value: 422, label: "Madhya Pradesh" },
  { value: 423, label: "Madurai" },
  { value: 424, label: "Mahabubabad" },
  { value: 425, label: "Mahabubnagar" },
  { value: 426, label: "Maharajganj" },
  { value: 427, label: "Maharashtra" },
  { value: 428, label: "Mahasamund" },
  { value: 429, label: "Mahe" },
  { value: 430, label: "Mahendragarh" },
  { value: 431, label: "Mahisagar" },
  { value: 432, label: "Mahoba" },
  { value: 433, label: "Mainpuri" },
  { value: 434, label: "Majuli" },
  { value: 435, label: "Malappuram" },
  { value: 436, label: "Malda" },
  { value: 437, label: "Malkangiri" },
  { value: 438, label: "Mamit" },
  { value: 439, label: "Mancherial" },
  { value: 440, label: "Mandi" },
  { value: 441, label: "Mandla" },
  { value: 442, label: "Mandsaur" },
  { value: 443, label: "Mandya" },
  { value: 444, label: "Manipur" },
  { value: 445, label: "Mansa" },
  { value: 446, label: "Mathura" },
  { value: 447, label: "Mau" },
  { value: 448, label: "Mayurbhanj" },
  { value: 449, label: "Medak" },
  { value: 450, label: "Medchal" },
  { value: 451, label: "Meerut" },
  { value: 452, label: "Meghalaya" },
  { value: 453, label: "Mehsana" },
  { value: 454, label: "Mewat" },
  { value: 455, label: "Minicoy" },
  { value: 456, label: "Mirzapur" },
  { value: 457, label: "Mizoram" },
  { value: 458, label: "Moga" },
  { value: 459, label: "Mokokchung" },
  { value: 460, label: "Mon" },
  { value: 461, label: "Moradabad" },
  { value: 462, label: "Morbi" },
  { value: 463, label: "Morena" },
  { value: 464, label: "Morigaon" },
  { value: 465, label: "Muktsar" },
  { value: 466, label: "Mumbai City" },
  { value: 467, label: "Mumbai Suburban" },
  { value: 468, label: "Mungeli" },
  { value: 469, label: "Munger (Monghyr)" },
  { value: 470, label: "Murshidabad" },
  { value: 471, label: "Muzaffarnagar" },
  { value: 472, label: "Muzaffarpur" },
  { value: 473, label: "Mysuru (Mysore)" },
  { value: 474, label: "Nabarangpur" },
  { value: 475, label: "Nadia" },
  { value: 476, label: "Nagaland" },
  { value: 477, label: "Nagaon" },
  { value: 478, label: "Nagapattinam" },
  { value: 479, label: "Nagarkurnool" },
  { value: 480, label: "Nagaur" },
  { value: 481, label: "Nagpur" },
  { value: 482, label: "Nainital" },
  { value: 483, label: "Nalanda" },
  { value: 484, label: "Nalbari" },
  { value: 485, label: "Nalgonda" },
  { value: 486, label: "Namakkal" },
  { value: 487, label: "Namsai" },
  { value: 488, label: "Nanded" },
  { value: 489, label: "Nandurbar" },
  { value: 490, label: "Narayanpur" },
  { value: 491, label: "Narmada (Rajpipla)" },
  { value: 492, label: "Narsinghpur" },
  { value: 493, label: "Nashik" },
  { value: 494, label: "Navsari" },
  { value: 495, label: "Nawada" },
  { value: 496, label: "Nawanshahr (Shahid Bhagat Singh Nagar)" },
  { value: 497, label: "Nayagarh" },
  { value: 498, label: "Neemuch" },
  { value: 499, label: "Nellore" },
  { value: 500, label: "New Delhi" },
  { value: 501, label: "Nilgiris" },
  { value: 502, label: "Nirmal" },
  { value: 503, label: "Nizamabad" },
  { value: 504, label: "Noney" },
  { value: 505, label: "North 24 Parganas" },
  { value: 506, label: "North Delhi" },
  { value: 507, label: "North East  Delhi" },
  { value: 508, label: "North Garo Hills" },
  { value: 509, label: "North Goa" },
  { value: 510, label: "North Sikkim" },
  { value: 511, label: "North Tripura" },
  { value: 512, label: "North West  Delhi" },
  { value: 513, label: "Nuapada" },
  { value: 514, label: "Odisha" },
  { value: 515, label: "Osmanabad" },
  { value: 516, label: "Pakur" },
  { value: 517, label: "Palakkad" },
  { value: 518, label: "Palamu" },
  { value: 519, label: "Palghar" },
  { value: 520, label: "Pali" },
  { value: 521, label: "Palwal" },
  { value: 523, label: "Panchkula" },
  { value: 524, label: "Panchmahal (Godhra)" },
  { value: 525, label: "Panipat" },
  { value: 526, label: "Panna" },
  { value: 527, label: "Papum Pare" },
  { value: 528, label: "Parbhani" },
  { value: 529, label: "Paschim Medinipur (West Medinipur)" },
  { value: 530, label: "Patan" },
  { value: 531, label: "Pathanamthitta" },
  { value: 532, label: "Pathankot" },
  { value: 533, label: "Patiala" },
  { value: 534, label: "Patna" },
  { value: 535, label: "Pauri Garhwal" },
  { value: 536, label: "Peddapalli" },
  { value: 537, label: "Perambalur" },
  { value: 538, label: "Peren" },
  { value: 539, label: "Phek" },
  { value: 540, label: "Pherzawl" },
  { value: 541, label: "Pilibhit" },
  { value: 542, label: "Pithoragarh" },
  { value: 543, label: "Pondicherry" },
  { value: 544, label: "Poonch" },
  { value: 545, label: "Porbandar" },
  { value: 546, label: "Prakasam" },
  { value: 547, label: "Pratapgarh" },
  { value: 548, label: "Pratapgarh" },
  { value: 549, label: "Puducherry (UT)" },
  { value: 550, label: "Pudukkottai" },
  { value: 551, label: "Pulwama" },
  { value: 552, label: "Pune" },
  { value: 553, label: "Punjab" },
  { value: 554, label: "Purba Medinipur (East Medinipur)" },
  { value: 555, label: "Puri" },
  { value: 556, label: "Purnia (Purnea)" },
  { value: 557, label: "Purulia" },
  { value: 558, label: "RaeBareli" },
  { value: 559, label: "Raichur" },
  { value: 560, label: "Raigad" },
  { value: 561, label: "Raigarh" },
  { value: 562, label: "Raipur" },
  { value: 563, label: "Raisen" },
  { value: 564, label: "Rajanna Sircilla" },
  { value: 565, label: "Rajasthan" },
  { value: 566, label: "Rajgarh" },
  { value: 567, label: "Rajkot" },
  { value: 568, label: "Rajnandgaon" },
  { value: 569, label: "Rajouri" },
  { value: 570, label: "Rajsamand" },
  { value: 571, label: "Ramanagara" },
  { value: 572, label: "Ramanathapuram" },
  { value: 573, label: "Ramban" },
  { value: 574, label: "Ramgarh" },
  { value: 575, label: "Rampur" },
  { value: 576, label: "Ranchi" },
  { value: 577, label: "Rangareddy" },
  { value: 578, label: "Ratlam" },
  { value: 579, label: "Ratnagiri" },
  { value: 580, label: "Rayagada" },
  { value: 581, label: "Reasi" },
  { value: 582, label: "Rewa" },
  { value: 583, label: "Rewari" },
  { value: 584, label: "Ri Bhoi" },
  { value: 585, label: "Rohtak" },
  { value: 586, label: "Rohtas" },
  { value: 587, label: "Rudraprayag" },
  { value: 588, label: "Rupnagar" },
  { value: 589, label: "Sabarkantha (Himmatnagar)" },
  { value: 590, label: "Sagar" },
  { value: 591, label: "Saharanpur" },
  { value: 592, label: "Saharsa" },
  { value: 593, label: "Sahibganj" },
  { value: 594, label: "Sahibzada Ajit Singh Nagar (Mohali)" },
  { value: 595, label: "Saiha" },
  { value: 596, label: "Salem" },
  { value: 597, label: "Samastipur" },
  { value: 598, label: "Samba" },
  { value: 599, label: "Sambalpur" },
  { value: 600, label: "Sambhal (Bhim Nagar)" },
  { value: 601, label: "Sangareddy" },
  { value: 602, label: "Sangli" },
  { value: 603, label: "Sangrur" },
  { value: 604, label: "Sant Kabir Nagar" },
  { value: 605, label: "Saran" },
  { value: 606, label: "Satara" },
  { value: 607, label: "Satna" },
  { value: 608, label: "Sawai Madhopur" },
  { value: 609, label: "Sehore" },
  { value: 610, label: "Senapati" },
  { value: 611, label: "Seoni" },
  { value: 612, label: "Sepahijala" },
  { value: 613, label: "Seraikela-Kharsawan" },
  { value: 614, label: "Serchhip" },
  { value: 615, label: "Shahdara" },
  { value: 616, label: "Shahdol" },
  { value: 617, label: "Shahjahanpur" },
  { value: 618, label: "Shajapur" },
  { value: 619, label: "Shamali (Prabuddh Nagar)" },
  { value: 620, label: "Sheikhpura" },
  { value: 621, label: "Sheohar" },
  { value: 622, label: "Sheopur" },
  { value: 623, label: "Shimla" },
  { value: 624, label: "Shivamogga (Shimoga)" },
  { value: 625, label: "Shivpuri" },
  { value: 626, label: "Shopian" },
  { value: 627, label: "Shravasti" },
  { value: 628, label: "Siang" },
  { value: 629, label: "Siddharth Nagar" },
  { value: 630, label: "Siddipet" },
  { value: 631, label: "Sidhi" },
  { value: 632, label: "Sikar" },
  { value: 633, label: "Sikkim" },
  { value: 634, label: "Simdega" },
  { value: 635, label: "Sindhudurg" },
  { value: 636, label: "Singrauli" },
  { value: 637, label: "Sirmaur (Sirmour)" },
  { value: 638, label: "Sirohi" },
  { value: 639, label: "Sirsa" },
  { value: 640, label: "Sitamarhi" },
  { value: 641, label: "Sitapur" },
  { value: 642, label: "Sivaganga" },
  { value: 643, label: "Sivasagar" },
  { value: 644, label: "Siwan" },
  { value: 645, label: "Solan" },
  { value: 646, label: "Solapur" },
  { value: 647, label: "Sonbhadra" },
  { value: 648, label: "Sonepur" },
  { value: 649, label: "Sonipat" },
  { value: 650, label: "Sonitpur" },
  { value: 651, label: "South 24 Parganas" },
  { value: 652, label: "South Delhi" },
  { value: 653, label: "South East Delhi" },
  { value: 654, label: "South Garo Hills" },
  { value: 655, label: "South Goa" },
  { value: 656, label: "South Salmara-Mankachar" },
  { value: 657, label: "South Sikkim" },
  { value: 658, label: "South Tripura" },
  { value: 659, label: "South West  Delhi" },
  { value: 660, label: "South West Garo Hills " },
  { value: 661, label: "South West Khasi Hills" },
  { value: 662, label: "Sri Ganganagar" },
  { value: 663, label: "Srikakulam" },
  { value: 664, label: "Srinagar" },
  { value: 665, label: "Sukma" },
  { value: 666, label: "Sultanpur" },
  { value: 667, label: "Sundargarh" },
  { value: 668, label: "Supaul" },
  { value: 669, label: "Surajpur  " },
  { value: 670, label: "Surat" },
  { value: 671, label: "Surendranagar" },
  { value: 672, label: "Surguja" },
  { value: 673, label: "Suryapet" },
  { value: 674, label: "Tamenglong" },
  { value: 675, label: "Tamil Nadu" },
  { value: 676, label: "Tapi (Vyara)" },
  { value: 677, label: "Tarn Taran" },
  { value: 678, label: "Tawang" },
  { value: 679, label: "Tehri Garhwal" },
  { value: 680, label: "Telangana" },
  { value: 681, label: "Tengnoupal" },
  { value: 682, label: "Thane" },
  { value: 683, label: "Thanjavur" },
  { value: 684, label: "Theni" },
  { value: 685, label: "Thiruvananthapuram" },
  { value: 686, label: "Thoothukudi (Tuticorin)" },
  { value: 687, label: "Thoubal" },
  { value: 688, label: "Thrissur" },
  { value: 689, label: "Tikamgarh" },
  { value: 690, label: "Tinsukia" },
  { value: 691, label: "Tirap" },
  { value: 692, label: "Tiruchirappalli" },
  { value: 693, label: "Tirunelveli" },
  { value: 694, label: "Tiruppur" },
  { value: 695, label: "Tiruvallur" },
  { value: 696, label: "Tiruvannamalai" },
  { value: 697, label: "Tiruvarur" },
  { value: 698, label: "Tonk" },
  { value: 699, label: "Tripura" },
  { value: 700, label: "Tuensang" },
  { value: 701, label: "Tumakuru (Tumkur)" },
  { value: 702, label: "Udaipur" },
  { value: 703, label: "Udalguri" },
  { value: 704, label: "Udham Singh Nagar" },
  { value: 705, label: "Udhampur" },
  { value: 706, label: "Udupi" },
  { value: 707, label: "Ujjain" },
  { value: 708, label: "Ukhrul" },
  { value: 709, label: "Umaria" },
  { value: 710, label: "Una" },
  { value: 711, label: "Unakoti" },
  { value: 712, label: "Unnao" },
  { value: 713, label: "Upper Siang" },
  { value: 714, label: "Upper Subansiri" },
  { value: 715, label: "Uttar Dinajpur (North Dinajpur)" },
  { value: 716, label: "Uttar Pradesh" },
  { value: 717, label: "Uttara Kannada (Karwar)" },
  { value: 718, label: "Uttarakhand" },
  { value: 719, label: "Uttarkashi" },
  { value: 720, label: "Vadodara" },
  { value: 721, label: "Vaishali" },
  { value: 722, label: "Valsad" },
  { value: 723, label: "Varanasi" },
  { value: 724, label: "Vellore" },
  { value: 725, label: "Vidisha" },
  { value: 726, label: "Vijayapura (Bijapur)" },
  { value: 727, label: "Vikarabad" },
  { value: 728, label: "Viluppuram" },
  { value: 729, label: "Virudhunagar" },
  { value: 730, label: "Visakhapatnam" },
  { value: 731, label: "Vizianagaram" },
  { value: 732, label: "Wanaparthy" },
  { value: 733, label: "Warangal (Rural)" },
  { value: 734, label: "Warangal (Urban)" },
  { value: 735, label: "Wardha" },
  { value: 736, label: "Washim" },
  { value: 737, label: "Wayanad" },
  { value: 738, label: "West Bengal" },
  { value: 739, label: "West Champaran" },
  { value: 740, label: "West Delhi" },
  { value: 741, label: "West Garo Hills" },
  { value: 742, label: "West Godavari" },
  { value: 743, label: "West Jaintia Hills" },
  { value: 744, label: "West Kameng" },
  { value: 745, label: "West Karbi Anglong" },
  { value: 746, label: "West Khasi Hills" },
  { value: 747, label: "West Siang" },
  { value: 748, label: "West Sikkim" },
  { value: 749, label: "West Singhbhum" },
  { value: 750, label: "West Tripura" },
  { value: 751, label: "Wokha" },
  { value: 752, label: "YSR Kadapa" },
  { value: 753, label: "Yadadri Bhuvanagiri" },
  { value: 754, label: "Yadgir" },
  { value: 755, label: "Yamunanagar" },
  { value: 756, label: "Yanam" },
  { value: 757, label: "Yavatmal" },
  { value: 758, label: "Zunheboto" },
];

export const languagesOptions = [
  { value: 1, label: "Amharic" },
  { value: 2, label: "Arabic" },
  { value: 3, label: "Basque" },
  { value: 4, label: "Bengali" },
  { value: 5, label: "English (UK)" },
  { value: 6, label: "Portuguese (Brazil)" },
  { value: 7, label: "Bulgarian" },
  { value: 8, label: "Catalan" },
  { value: 9, label: "Cherokee" },
  { value: 10, label: "Croatian" },
  { value: 11, label: "Czech" },
  { value: 12, label: "Danish" },
  { value: 13, label: "Dutch" },
  { value: 14, label: "English (US)" },
  { value: 15, label: "Estonian" },
  { value: 16, label: "Filipino" },
  { value: 17, label: "Finnish" },
  { value: 18, label: "French" },
  { value: 19, label: "German" },
  { value: 20, label: "Greek" },
  { value: 21, label: "Gujarati" },
  { value: 22, label: "Hebrew" },
  { value: 23, label: "Hindi" },
  { value: 24, label: "Hungarian" },
  { value: 25, label: "Icelandic" },
  { value: 26, label: "Indonesian" },
  { value: 27, label: "Italian" },
  { value: 28, label: "Japanese" },
  { value: 29, label: "Kannada" },
  { value: 30, label: "Korean" },
  { value: 31, label: "Latvian" },
  { value: 32, label: "Lithuanian" },
  { value: 33, label: "Malay" },
  { value: 34, label: "Malayalam" },
  { value: 35, label: "Marathi" },
  { value: 36, label: "Norwegian" },
  { value: 37, label: "Polish" },
  { value: 38, label: "Portuguese (Portugal)" },
  { value: 39, label: "Romanian" },
  { value: 40, label: "Russian" },
  { value: 41, label: "Serbian" },
  { value: 42, label: "Chinese (PRC)" },
  { value: 43, label: "Slovak" },
  { value: 44, label: "Slovenian" },
  { value: 45, label: "Spanish" },
  { value: 46, label: "Swahili" },
  { value: 47, label: "Swedish" },
  { value: 48, label: "Tamil" },
  { value: 49, label: "Telugu" },
  { value: 50, label: "Thai" },
  { value: 51, label: "Chinese (Taiwan)" },
  { value: 52, label: "Turkish" },
  { value: 53, label: "Urdu" },
  { value: 54, label: "Ukrainian" },
  { value: 55, label: "Vietnamese" },
  { value: 56, label: "Welsh" },
];

export const schoolOptions = ["IIT", "MIT", "Other"];

export const thematicOptions = [
  {
    value: 1,
    label:
      "Healthcare (hospitals, medical services, public health, disease, drug use and mental health)",
  },
  {
    value: 2,
    label: "Education (schools, universities, scholarships, research)",
  },
  {
    value: 3,
    label:
      "Arts and Culture (museums, cultural preservation, community development, libraries, etc.)",
  },
  {
    value: 4,
    label:
      "Children & Youth (orphans, children & youth in crisis, adolescent, child, and youth development)",
  },
  {
    value: 5,
    label:
      "Economically Disadvantaged Populations (food & shelter, and social services)",
  },
  {
    value: 6,
    label:
      "Human Rights (advocacy, legal aid, refugees, minority rights, etc.)",
  },
  { value: 7, label: "Disability and Accessibility" },
  {
    value: 8,
    label:
      "Gender Equity and Inclusion (including LBTQI+, and sexual reproductive health and rights)",
  },
  { value: 9, label: "Environment Protection / Climate Change" },
  {
    value: 10,
    label: "Homelessness (food & shelter, and other social services)",
  },
  {
    value: 11,
    label: "Animal Protection (shelter, animal rescue, healthcare, etc.)",
  },
  {
    value: 12,
    label:
      "Livelihood (including youth & adults: skills development training, job search, employment applications, etc.)",
  },
  {
    value: 13,
    label: "Recreational Activities (music, dance, sports & lifeskills)",
  },
  { value: 14, label: "Elderly Care (social support, shelter & food)" },
  {
    value: 15,
    label: "Humanitarian Aid, Disaster Risk Reduction and Management",
  },
  {
    value: 16,
    label:
      "Marginalized Groups Population (Dalits, Adivasi, religious minorities)",
  },
  {
    value: 17,
    label:
      "Financial Inclusion (micro finance, SHGs, income generation programmes)",
  },
  {
    value: 18,
    label:
      "Governance and participation (including digital privacy and data rights)",
  },
  { value: 19, label: "Other" },
];
